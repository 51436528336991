const on_dev = false;
module.exports = {
  appName: "TONKLA CA",
  fbKey: "AIzaSyCr1Z1WoR-tWAvx0uMVdowq2RUgHHna6dk",
  fbAuthDomain: "tonkla-a1378.firebaseapp.com",
  endpoint: {
    tonkla:
      on_dev === true
        ? "http://localhost:3001"
        : // : "https://tonkla-backend.vercel.app",
          "https://tonkla-backend.azurewebsites.net",
    // fileUpload: "http://localhost/wdsfile/file.php",
    fileUpload: "https://tca.tonkla.ac.th/upload/file.php",
  },
  cookie_name: "tonkla-cookie-ca",
};
