import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Button from "@mui/material/Button";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import PageNumberBox from "./PageNumberBox";
import LinearProgress from "@mui/material/LinearProgress";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function TableStudentStatus({ match, title }) {
  const [filter, setFilter] = useState();
  const [registers, setRegisters] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function getFilter() {
      setFilter({
        collection: "registers",
        select:
          "prefix first_name last_name nickname status_date waiver_date register_date visit_schedules",
        match,
        skip: 0,
        limit: 20,
        sort: { "visit_schedules.visit_date": 1 },
      });
    }
    if (match) getFilter();
  }, [match]);
  useEffect(() => {
    async function fetchReport() {
      setLoading(true);
      let registers = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        filter,
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      setRegisters(registers.data);
      setLoading(false);
    }
    if (filter) fetchReport();
  }, [filter]);
  function changePage(value) {
    let S = (value - 1) * filter.limit;
    setFilter({ ...filter, skip: S });
  }

  async function renderDownload() {
    let Filter = { ...filter, limit: 0 };
    let result = await axios.post(Conf.endpoint.tonkla + "/ca/query", Filter, {
      headers: { Authorization: cookie.access_token },
    });
    let Download = {
      columns: [
        { label: "No.", value: "no" },
        { label: "Full name", value: "fullname" },
        { label: "Nickname", value: "nickname" },
        { label: "เยี่ยมชม", value: "visit" },
        { label: "สมัครเรียน", value: "register" },
        { label: "มอบตัว", value: "present" },
        {
          label: "สละสิทธิ",
          value: "leave",
        },
      ],
      sheet: [],
    };
    let no = 1;
    for (let r of result.data.result) {
      let data = {
        no: no++,
        fullname: r.prefix + " " + r.first_name + " " + r.last_name,
        nickname: r.nickname,
        visit: "",
        register: "",
        value: "",
        leave: "",
      };
      data.visit = r.visit_schedules[0].visit_date;
      data.register = r.register_date;
      for (let status of r.status_date) {
        if (status.label === "มอบตัว") data.present = status.stamp_date;
      }
      if (r.waiver_date) data.leave = r.waiver_date;
      Download.sheet.push(data);
    }
    cookie.excel = Download;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
    setTimeout(() => {
      document.getElementById("report_student_status_frame").src =
        process.env.PUBLIC_URL + "/report/excel";
    }, 500);
  }
  return (
    <div>
      {registers && (
        <>
          <br />
          {title && (
            <Grid container>
              <Grid item style={{ padding: "18px 10px", paddingBottom: "0px" }}>
                <Icon>group</Icon>
              </Grid>
              <Grid item>
                <h3 style={{ fontWeight: 400 }}>รายชื่อผู้สมัครเรียน</h3>
              </Grid>
            </Grid>
          )}

          <iframe
            style={{ border: "none", width: "0px", height: "0px" }}
            id="report_student_status_frame"
            name="report_student_status_frame"
            title="report_student_status_frame"
          ></iframe>
          <Grid container>
            <Grid item xs={9}>
              <PageNumberBox
                count={parseInt((registers.count - 1) / filter.limit) + 1}
                onChangePage={(value) => changePage(value)}
                page={parseInt(filter.skip / filter.limit) + 1}
              />
            </Grid>
            <Grid item xs={3} align="right" style={{ paddingTop: "10px" }}>
              <Button
                color="primary"
                variant="outlined"
                startIcon={<Icon>download</Icon>}
                size="small"
                onClick={() => renderDownload()}
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <br />

          {/* {JSON.stringify(registers.result.length)} */}

          {loading === false ? (
            <ShowTable registers={registers} skip={filter.skip} />
          ) : (
            <LinearProgress />
          )}
        </>
      )}
    </div>
  );
}

function ShowTable({ registers, skip }) {
  const [totals, setTotals] = useState([]);
  useEffect(() => {
    if (registers) {
      //		สละสิทธิ
      let Totals = [0, 0, 0, 0];
      for (let register of registers.result) {
        let i = register.status_date.findIndex((j) => j.label === "เยี่ยมชม");
        if (i > -1) Totals[0]++;
        i = register.status_date.findIndex((j) => j.label === "สมัครเรียน");
        if (i > -1) Totals[1]++;
        i = register.status_date.findIndex((j) => j.label === "มอบตัว");
        if (i > -1) Totals[2]++;
      }
      Totals[3] = registers.result.filter((j) => j.waiver_date).length;
      setTotals(Totals);
    }
  }, [registers]);

  function ShowStatusDate(status, status_date, register) {
    let r = "-";
    if (status === "เยี่ยมชม") {
      if (register.visit_schedules[0])
        r = register.visit_schedules[0].visit_date;
    } else if (status === "สมัครเรียน" && register.register_date) {
      r = register.register_date;
    } else {
      let i = status_date.findIndex((j) => j.label === status);
      if (i > -1) r = status_date[i].stamp_date;
    }
    return r;
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="td"
                align="center"
                style={{ width: "80px" }}
              >
                No.
              </TableCell>

              <TableCell className="td" align="center">
                Full name
              </TableCell>
              <TableCell
                className="td"
                align="center"
                style={{ width: "150px" }}
              >
                Nickname
              </TableCell>
              <TableCell
                className="td"
                align="center"
                style={{ width: "120px" }}
              >
                เยี่ยมชม
              </TableCell>
              <TableCell
                className="td"
                align="center"
                style={{ width: "120px" }}
              >
                สมัครเรียน
              </TableCell>
              <TableCell
                className="td"
                align="center"
                style={{ width: "120px" }}
              >
                มอบตัว
              </TableCell>
              <TableCell
                className="td"
                align="center"
                style={{ width: "120px" }}
              >
                สละสิทธิ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registers.result.map((register, key) => (
              <TableRow key={key}>
                <TableCell className="td" align="center">
                  {key + skip + 1}
                </TableCell>
                <TableCell className="td">
                  {register.prefix} {register.first_name} {register.last_name}
                </TableCell>
                <TableCell className="td" align="center">
                  {register.nickname}
                </TableCell>
                {["เยี่ยมชม", "สมัครเรียน", "มอบตัว"].map((status, key) => (
                  <TableCell key={key} className="td" align="center">
                    {ShowStatusDate(status, register.status_date, register)}
                  </TableCell>
                ))}
                <TableCell className="td" align="center">
                  {register.waiver_date || "-"}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3} align="center" className="bold">
                รวม
              </TableCell>
              <TableCell align="center" className="bold">
                {totals[0]}
              </TableCell>
              <TableCell align="center" className="bold">
                {totals[1]}
              </TableCell>
              <TableCell align="center" className="bold">
                {totals[2]}
              </TableCell>
              <TableCell align="center" className="bold">
                {totals[3]}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
