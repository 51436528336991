import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Conf from "../Conf";
import {
  prefixs,
  student_prefixs,
  jobs,
  months,
  promotion_media,
  influence_factors,
  contact_relations,
} from "../const";
import { GridInputItem, GridSelectItem } from "../components/InputForm";

export default function VisitForm(props) {
  const [form, setForm] = useState({});
  const [students, setStudents] = useState([
    { no: 1 },
    { no: 2 },
    { no: 3 },
    { no: 4 },
    { no: 5 },
  ]);
  const [numberStudents, setNumberStudents] = useState(1);
  const [days, setDays] = useState([]);
  const [years, setYears] = useState([]);

  const [influences, setInfluences] = useState(influence_factors);
  const [medias, setMedias] = useState(promotion_media);
  const styles = {
    groupHead: {
      paddingBottom: "20px",
      fontSize: "1rem",
      fontWeight: 400,
      marginTop: "20px",
    },
    subGroupHeader: {
      color: "#888",
      fontSize: ".9rem",
      paddingLeft: "15px",
    },
  };
  useEffect(() => {
    var r = [];
    for (let i = 1; i < 32; i++) r.push({ value: i, label: i });
    setDays(r);
    var dt = new Date();
    dt = dt.getFullYear() + 543;
    r = [];
    for (let i = dt; i > dt - 20; i--) r.push({ value: i - 543, label: i });
    setYears(r);
  }, []);
  useEffect(() => {
    async function getRegister() {
      console.log("FORM");
      let Form = JSON.parse(JSON.stringify(props.form));
      let Students = Form.students;
      delete Form.students;
      setStudents(Students);
      setNumberStudents(Form.numberStudents);

      for (let i = 0; i < promotion_media.length; i++) {
        promotion_media[i].checked = false;
      }
      for (let media of Form.promotion_media) {
        let i = promotion_media.findIndex((j) => j.value === media);
        promotion_media[i].checked = false;
        if (i > -1) promotion_media[i].checked = true;
      }
      for (let i = 0; i < influence_factors.length; i++) {
        influence_factors[i].checked = false;
      }
      for (let factor of Form.influence_factors) {
        let i = influence_factors.findIndex((j) => j.value === factor);
        if (i > -1) influence_factors[i].checked = true;
      }
      setForm(Form);
    }

    if (props.form) getRegister();
  }, [props]);
  function changeValue(e) {
    var id = e.target.id;
    var value = e.target.value || "";
    setForm({ ...form, [id]: value });
  }
  function changeStudentValue(e, key) {
    var arrId = e.target.id.split("-");
    var id = arrId[0];
    var value = e.target.value || "";
    var Students = JSON.parse(JSON.stringify(students));
    Students[key] = { ...Students[key], [id]: value };
    setStudents(Students);
  }
  async function zipSearch(e) {
    let result = await axios.get(
      Conf.endpoint.tonkla + "/ca/zip/" + e.target.value
    );
    if (result.data && result.data.zip_code) {
      result = result.data;
      console.log(result);
      setForm({
        ...form,
        province: result.province,
        district: result.district,
      });
    }
  }
  async function nextStep() {
    form.promotion_media = medias
      .filter((j) => j.checked === true)
      .map((media) => media.value);
    form.influence_factors = influences
      .filter((j) => j.checked === true)
      .map((influence) => influence.value);
    var send = JSON.parse(JSON.stringify(form));

    // Check Require
    var studentRequires = [
      "prefix",
      "first_name",
      "last_name",
      "nickname",
      "birth_day",
      "birth_month",
      "birth_year",
      "relation",
    ];
    var requires = [
      "contact_prefix",
      "contact_first_name",
      "contact_last_name",
      "email",
      "phone",
      "job",
      "workplace",
      "house_number",
      "village",
      "moo",
      "area",
      "zip_code",
      "area",
    ];
    for (let req of requires) {
      let Class = document.getElementById(req).getAttribute("class");
      if (form[req] && form[req] !== "") {
        Class = Class.replaceAll("error-text-field", "");
        document.getElementById(req).setAttribute("class", Class);
        requires = requires.filter((j) => j !== req);
      } else {
        Class += " error-text-field";
        document.getElementById(req).setAttribute("class", Class);
      }
    }
    for (let i = 0; i < numberStudents; i++) {
      for (let req of studentRequires) {
        let id = req + "-" + i;
        let Class = document.getElementById(id).getAttribute("class");
        if (students[i][req] && students[i][req] !== "") {
          Class = Class.replaceAll("error-text-field", "");
          document.getElementById(id).setAttribute("class", Class);
          studentRequires = studentRequires.filter((j) => j !== req);
        } else {
          Class += " error-text-field";
          document.getElementById(id).setAttribute("class", Class);
        }
      }

      students[i].birth_date =
        students[i].birth_year +
        "-" +
        String(students[i].birth_month).padStart(2, "0") +
        "-" +
        String(students[i].birth_day).padStart(2, "0");
    }

    if (requires.length > 0 || studentRequires.length > 0) {
      alert("Please fill complete information");
    } else {
      send.students = JSON.parse(JSON.stringify(students));
      send.numberStudents = numberStudents;
      console.log(send);
      props.onFillCompleted(send);
      /*
      let result = await axios.put(
        Conf.endpoint.tonkla + "/ca/register",
        send,
        {
          headers: { Authorization: props.otp || cookie.access_token },
        }
      );
      if (result.data.error) alert(result.data.error.message);
      else {
        alert("Save completed");
        if (props.otp) {
          props.onSaveCompleted(result.data);
        }
      }
      */
    }
  }
  return (
    <div style={{ paddingTop: "15px" }}>
      <Grid
        container
        component={Paper}
        spacing={1}
        style={{ padding: "15px 15px" }}
      >
        <Grid item xs={12} align="center" style={{ paddingBottom: "10px" }}>
          <h2 style={{ marginBottom: "5px", fontWeight: 400 }}>
            Survey for Tonkla School Visitor
          </h2>
          <div>(แบบสอบถามเยี่ยมชม)</div>
        </Grid>

        <Grid item xs={12} style={styles.groupHead}>
          1. Visitor Information{" "}
          <div style={styles.subGroupHeader}>(ข้อมูลผู้เยี่ยมชม)</div>
        </Grid>
        <>
          <GridSelectItem
            size={[12, 2, 2, 2]}
            label="Prefix"
            id="contact_prefix"
            value={form.contact_prefix}
            changeValue={changeValue}
            options={prefixs}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Contact First Name"
            placeholder="ชื่อ ผู้มาติดต่อ"
            id="contact_first_name"
            value={form.contact_first_name}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Contact Last Name"
            placeholder="นามสกุล ผู้มาติดต่อ"
            id="contact_last_name"
            value={form.contact_last_name}
            changeValue={changeValue}
          />
          {/* <GridInputItem
            size={[12, 12, 8, 10]}
            label="Address"
            placeholder="ที่อยู่"
            id="address"
            value={form.address}
            changeValue={changeValue}
          /> */}

          <GridInputItem
            size={[6, 6, 3, 3]}
            label="House number"
            placeholder="บ้านเลขที่"
            id="house_number"
            value={form.house_number}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label="Village"
            placeholder="หมู่บ้าน"
            id="village"
            value={form.village}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label="Moo"
            placeholder="หมู่"
            id="moo"
            value={form.moo}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label="Sub district"
            placeholder="ตำบล"
            id="area"
            value={form.area}
            changeValue={changeValue}
          />
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField
              fullWidth
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  zipSearch(e);
                }
              }}
              onBlur={(e) => zipSearch(e)}
              onChange={changeValue}
              InputLabelProps={{ shrink: true }}
              size="small"
              label={<div>Zip code</div>}
              placeholder="รหัสไปรษณีย์"
              id="zip_code"
              value={form.zip_code || ""}
              variant="outlined"
            />
          </Grid>
          <GridInputItem
            size={[6, 6, 3, 3]}
            label={<div>District</div>}
            placeholder="อำเภอ"
            id="district"
            value={form.district}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label={<div>Province</div>}
            placeholder="จังหวัด"
            id="province"
            value={form.province}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[6, 6, 3, 3]}
            label="Mobile Number"
            placeholder="โทร"
            id="phone"
            value={form.phone}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 12, 12, 12]}
            label="Domicile/Habitation"
            placeholder="ภูมิลำเนา"
            id="hometown"
            value={form.hometown}
            changeValue={changeValue}
          />

          <GridSelectItem
            size={[12, 6, 6, 6]}
            label="Career"
            id="job"
            value={form.job}
            changeValue={changeValue}
            options={jobs}
          />
          <GridInputItem
            size={[12, 6, 6, 6]}
            label="Workplace"
            placeholder="สถานที่ทำงาน"
            id="workplace"
            value={form.workplace}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 6, 6, 6]}
            label="e-Mail"
            placeholder="e-Mail"
            id="email"
            value={form.email}
            changeValue={changeValue}
          />
          <GridInputItem
            size={[12, 6, 6, 6]}
            label="Line ID"
            placeholder="Line ID"
            id="line_id"
            value={form.line_id}
            changeValue={changeValue}
          />
        </>
        <Grid item xs={12} style={styles.groupHead}>
          2. Student Information
          <div style={styles.subGroupHeader}>(ข้อมูลนักเรียน)</div>
        </Grid>
        <Grid item xs={12} sm={5} md={3} lg={2}>
          <div style={{ paddingLeft: "15px" }}>Student Number</div>
          <div style={styles.subGroupHeader}>จำนวนนักเรียนที่เข้าเยี่ยมชม</div>
        </Grid>
        <GridSelectItem
          size={[12, 4, 3, 2]}
          label="Student Number"
          id="numberStudents"
          value={numberStudents}
          changeValue={(e) => {
            setNumberStudents(
              e.target.value !== "-" ? parseInt(e.target.value) : 1
            );
          }}
          options={[
            { label: "1" },
            { label: "2" },
            { label: "3" },
            { label: "4" },
            { label: "5" },
          ]}
        />
        {students
          .filter((j) => j.no <= numberStudents)
          .map((student, key) => (
            <React.Fragment key={key}>
              <Grid
                item
                xs={12}
                style={{ padding: "15px 15px", fontWeight: 400 }}
              >
                ข้อมูลนักเรียนคนที่ {key + 1} :
              </Grid>
              <GridSelectItem
                size={[12, 2, 2, 2]}
                label="Prefix"
                id={"prefix-" + key}
                value={student.prefix}
                changeValue={(e) => changeStudentValue(e, key)}
                options={student_prefixs}
              />
              <GridInputItem
                size={[12, 5, 5, 5]}
                label="Student's name"
                placeholder="ชื่อนักเรียน"
                id={"first_name-" + key}
                value={student.first_name}
                changeValue={(e) => changeStudentValue(e, key)}
              />
              <GridInputItem
                size={[12, 5, 5, 5]}
                label="Student's surname"
                placeholder="ชื่อนักเรียน"
                id={"last_name-" + key}
                value={student.last_name}
                changeValue={(e) => changeStudentValue(e, key)}
              />
              <GridInputItem
                size={[12, 12, 6, 6]}
                label="Student's nickname"
                placeholder="ชื่อเล่น"
                id={"nickname-" + key}
                value={student.nickname}
                changeValue={(e) => changeStudentValue(e, key)}
              />

              <GridSelectItem
                size={[12, 4, 2, 2]}
                label="Birth day"
                id={"birth_day-" + key}
                value={student.birth_day}
                changeValue={(e) => changeStudentValue(e, key)}
                options={days}
              />
              <GridSelectItem
                size={[12, 4, 2, 2]}
                label="Birth month"
                id={"birth_month-" + key}
                value={student.birth_month}
                changeValue={(e) => changeStudentValue(e, key)}
                options={months}
              />
              <GridSelectItem
                size={[12, 4, 2, 2]}
                label="Birth year"
                id={"birth_year-" + key}
                value={student.birth_year}
                changeValue={(e) => changeStudentValue(e, key)}
                options={years}
              />
              <GridSelectItem
                size={[12, 6, 6, 6]}
                label="Relation with visitor"
                id={"relation-" + key}
                value={student.relation}
                changeValue={(e) => changeStudentValue(e, key)}
                options={contact_relations}
              />

              <GridInputItem
                size={[12, 6, 6, 6]}
                label="Other Ralation"
                placeholder="ความสัมพันธ์อื่น ๆ"
                id={"other_relation-" + key}
                value={student.other_relation}
                changeValue={(e) => changeStudentValue(e, key)}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                label="Old School Name"
                placeholder="ชื่อโรงเรียนเก่า"
                id={"old_school-" + key}
                value={student.old_school}
                changeValue={(e) => changeStudentValue(e, key)}
              />
            </React.Fragment>
          ))}
        <Grid item xs={12} style={styles.groupHead}>
          3. Where did you find the information about our school ?
          <div style={styles.subGroupHeader}>
            (ท่านรู้จักโรงเรียนต้นกล้า
            หรือได้รับข่าวสารของโรงเรียนจากแหล่งข้อมูลใด ?)
          </div>
        </Grid>
        <Grid item xs={12}>
          <CheckForm
            checkboxs={medias}
            other={form.promotion_other}
            id="promotion_media"
            onCheck={(key, checked) => {
              let arr = JSON.parse(JSON.stringify(medias));
              arr[key].checked = checked;
              setMedias(arr);
            }}
            onChangeOther={(e) =>
              setForm({ ...form, promotion_other: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} style={styles.groupHead}>
          3. What are the factors that influence your decision about a school
          for your child?
          <div style={styles.subGroupHeader}>
            (ปัจจัยที่มีผลต่อการตัดสินใจเลือกโรงเรียน ?)
          </div>
        </Grid>
        <Grid item xs={12}>
          <CheckForm
            checkboxs={influences}
            other={form.influence_factor_other}
            onCheck={(key, checked) => {
              let arr = JSON.parse(JSON.stringify(influences));
              arr[key].checked = checked;
              setInfluences(arr);
            }}
            onChangeOther={(e) =>
              setForm({ ...form, influence_factor_other: e.target.value })
            }
            id="influence_factors"
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => props.onChangeStep(-1)}
            startIcon={<Icon>arrow_back_ios</Icon>}
          >
            <div>Previous Step</div>
          </Button>
        </Grid>
        <Grid item xs={6} align="right">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ minWidth: "180px" }}
            onClick={nextStep}
            endIcon={<Icon>navigate_next</Icon>}
          >
            <div>Next Step</div>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const CheckForm = (props) => {
  const [other, setOther] = useState();
  const [checkboxs, setCheckboxs] = useState([]);
  useEffect(() => {
    setOther(props.other || "");
    setCheckboxs(props.checkboxs || []);
  }, [props]);
  function checkItem(e, key) {
    let checked = e.target.checked;
    if (props.onCheck) props.onCheck(key, checked);
  }
  function changeOther(e) {
    if (props.onChangeOther) props.onChangeOther(e);
  }
  return (
    <div style={{ paddingLeft: "10px" }}>
      <Grid container>
        {checkboxs.map((ch, key) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={key}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ch.checked}
                  onChange={(e) => checkItem(e, key)}
                  color="primary"
                />
              }
              label={<div>{ch.label}</div>}
            />
          </Grid>
        ))}
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <GridInputItem
            size={[12, 12, 12, 12]}
            label="Other"
            placeholder="อื่น ๆ"
            id={props.id + "_other"}
            value={other}
            changeValue={(e) => changeOther(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
