import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

import axios from "axios";
import Conf from "../Conf";
// const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function DialogZip({ state, onClose, onSelect }) {
  const open = state.open;
  const [areas, setAreas] = useState();
  useEffect(() => {
    (async () => {
      if (state.open === true && state.code && state.code !== "") {
        let address = await axios.post(
          Conf.endpoint.tonkla + "/ca/query/nopermission",
          {
            collection: "zip_areas",
            limit: 0,
            match: { zip_code: state.code },
            sort: "area",
          }
        );
        console.log(address);
        setAreas(address.data.result);
      } else setAreas([]);
    })();
  }, [state]);

  return (
    <div>
      {areas && (
        <Dialog open={open} maxWidth="lg">
          <DialogTitle>รหัสไปรษณีย์ {state.code}</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className="td"
                      style={{ width: "60px" }}
                    >
                      ลำดับ
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td"
                      style={{ width: "180px" }}
                    >
                      ตำบล
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td"
                      style={{ width: "180px" }}
                    >
                      อำเภอ
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td"
                      style={{ width: "180px" }}
                    >
                      จังหวัด
                    </TableCell>
                    <TableCell
                      align="center"
                      className="td"
                      style={{ width: "40px" }}
                    />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {areas.map((area, key) => (
                    <TableRow hover key={key}>
                      <TableCell className="td" align="center">
                        {key + 1}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {area.area}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {area.district}
                      </TableCell>
                      <TableCell className="td" align="center">
                        {area.province}
                      </TableCell>

                      <TableCell className="controlTd" align="center">
                        <IconButton
                          color="primary"
                          onClick={() => onSelect(area)}
                        >
                          <Icon>check_circle</Icon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {areas.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={5}
                        align="center"
                        style={{ color: "#666" }}
                      >
                        ไม่พบข้อมูล
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
