import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Icon from "@mui/material/Icon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const styles = {
  questionLabel: {
    width: "250px",
    verticalAlign: "top",
    fontWeight: 400,
    paddingLeft: "20px",
  },
};
export default function FormOccupationalTherapist(props) {
  const [form, setForm] = useState({ register_id: props.register_id });
  useEffect(() => {
    async function getForm() {
      let register_id = props.register_id;
      let form = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "therapist_interviews",
          match: { register_id: register_id },
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      if (form.data.result[0]) setForm(form.data.result[0]);
    }
    if (props.register_id) getForm();
  }, [props]);
  async function save() {
    let result = await axios.put(
      Conf.endpoint.tonkla + "/ca/interview/therapist",
      form,
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    if (result.data.error) alert(result.data.error.message);
    else alert("Save completed");
  }
  return (
    <div>
      <br />
      <h2 style={{ textAlign: "center", fontWeight: 400 }}>
        แบบรายงานผลการประเมินพัฒนาการโดยนักกิจกรรมบำบัด
      </h2>
      <br />
      <br />
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={styles.questionLabel} className="bold">
                1. ข้อมูลโดยทั่วไป
              </TableCell>

              <TableCell className="controlTd">
                <TextField
                  size="small"
                  multiline
                  rows={4}
                  fullWidth
                  value={form.general_info}
                  onChange={(e) =>
                    setForm({ ...form, general_info: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={styles.questionLabel} className="bold">
                2. ผลการประเมิน DENVER II สำหรับ 1-6 ขวบ
              </TableCell>

              <TableCell className="controlTd">
                <RadioChoice
                  choices={[
                    "ปกติ",
                    "สงสัย",
                    "ไม่สามารถประเมินได้",
                    "อายุเกินเกณฑ์ของแบบประเมิน",
                  ]}
                  value={form.DENVER}
                  onChange={(val) => {
                    setForm({ ...form, DENVER: val });
                  }}
                />
                <TextField
                  style={{ margin: "10px auto 5px" }}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="รายละเอียด"
                  value={form.other_DENVER || ""}
                  onChange={(e) =>
                    setForm({ ...form, other_DENVER: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={styles.questionLabel} className="bold">
                3. DTVP-Test สำหรับเด็ก สำหรับเด็กประถม
              </TableCell>

              <TableCell className="controlTd">
                <RadioChoice
                  choices={[
                    "อยู่ในค่าเฉลี่ย",
                    "ต่ำกว่าค่าเฉลี่ย",
                    "อายุเกินเกณฑ์ของแบบประเมิน",
                  ]}
                  value={form.DTVP}
                  onChange={(val) => {
                    setForm({ ...form, DTVP: val });
                  }}
                />
                <TextField
                  style={{ margin: "10px auto 5px" }}
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label="รายละเอียด"
                  value={form.other_DTVP || ""}
                  onChange={(e) =>
                    setForm({ ...form, other_DTVP: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.questionLabel} className="bold">
                4. พฤติกรรมที่เห็นเป็นปัญหา
              </TableCell>

              <TableCell className="controlTd">
                <TextField
                  size="small"
                  multiline
                  rows={4}
                  fullWidth
                  value={form.problem || ""}
                  onChange={(e) =>
                    setForm({ ...form, problem: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={styles.questionLabel} className="bold">
                5. โปรแกรมฝึกที่แนะนำสำหรับผู้ปกครอง
              </TableCell>

              <TableCell className="controlTd">
                <TextField
                  size="small"
                  multiline
                  rows={4}
                  fullWidth
                  value={form.program || ""}
                  onChange={(e) =>
                    setForm({ ...form, program: e.target.value })
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={styles.questionLabel} className="bold">
                6. สรุปผล
              </TableCell>

              <TableCell className="controlTd">
                <RadioChoice
                  choices={[
                    "ผ่าน",
                    "ผ่านแบบมีเงื่อนไข",
                    "แนะนำให้เข้ารับการฝึกพัฒนาการและเข้ารับการประเมินอีกครั้ง",
                  ]}
                  value={form.conclusion}
                  onChange={(val) => {
                    setForm({ ...form, conclusion: val });
                  }}
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell className="controlTd" colSpan={2}>
                <div style={{ margin: "10px auto 5px 10px", fontWeight: 400 }}>
                  เพิ่มเติม
                </div>
                <TextField
                  size="small"
                  multiline
                  rows={4}
                  fullWidth
                  value={form.note}
                  onChange={(e) => setForm({ ...form, note: e.target.value })}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        onClick={save}
        style={{ width: "180px", marginTop: "20px" }}
        startIcon={<Icon>save</Icon>}
      >
        Save
      </Button>
    </div>
  );
}

function RadioChoice(props) {
  const { choices, value, onChange } = props;
  return (
    <div>
      <RadioGroup
        style={{ marginLeft: "10px" }}
        value={value || null}
        onChange={(e) => onChange(e.target.value)}
      >
        {choices.map((choice, key) => (
          <FormControlLabel
            key={key}
            value={choice}
            control={<Radio />}
            label={<div>{choice}</div>}
          />
        ))}
      </RadioGroup>
    </div>
  );
}
