import React, { useState, useEffect } from "react";
import { getPermission, jsonSort } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import MultiType from "../charts/MultiType";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import TableStudentStatus from "../components/TableStudentStatus";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
export default function VisitOverall({ page }) {
  const [profile, setProfile] = useState();
  const [filter, setFilter] = useState();
  const [report, setReport] = useState();
  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      setProfile(profile);
      setFilter({ collection: "registers" });
    })();
  }, []);
  useEffect(() => {
    async function getRegisters() {
      let report = await axios.get(
        Conf.endpoint.tonkla + "/ca/report/overall",
        {
          headers: { Authorization: cookie.access_token },
        }
      );

      setReport(report.data);
    }
    if (filter) getRegisters();
  }, [filter]);

  async function renderDownload() {
    let Report = { ...report };
    let statuses = [
      { label: "เยี่ยมชม", field: "visit" },
      { label: "สมัครเรียน", field: "register" },
      { label: "มอบตัว", field: "present" },
    ];
    Report = await jsonSort(Report.result, "year");
    let sheet = [];
    let no = 1;
    for (let y = Report[0].year; y <= Report[Report.length - 1].year; y++) {
      let data = {
        no: no++,
        year: y,
        visit: 0,
        register: 0,
        present: 0,
        percent_buy: 0,
        percent_register: 0,
      };
      for (let status of statuses) {
        let i = Report.findIndex(
          (j) => j.status === status.label && j.year === y
        );
        if (i > -1) {
          data = { ...data, [status.field]: Report[i].count };
        }
      }
      data.percent_buy = (data.register / data.visit) * 100 || 0;
      data.percent_register = (data.present / data.visit) * 100 || 0;
      data.percent_buy = data.percent_buy.toFixed(2);
      data.percent_register = data.percent_register.toFixed(2);
      sheet.push(data);
    }
    let Download = {
      columns: [
        { label: "ลำดับ", value: "no" },
        { label: "ปีการศึกษา", value: "year" },
        { label: "เยี่ยมชม (คน)", value: "visit" },
        { label: "สมัครเรียน (คน)", value: "register" },
        { label: "มอบตัว (คน)", value: "present" },
        { label: "ร้อยละผู้ซื้อใบสมัครต่อการเยี่ยมชม", value: "percent_buy" },
        {
          label: "ร้อยละผู้มอบตัวต่อการสมัครเรียน",
          value: "percent_register",
        },
      ],
      sheet,
    };

    cookie.excel = Download;
    localStorage.setItem(Conf.cookie_name, JSON.stringify(cookie));
    setTimeout(() => {
      document.getElementById("report_frame").src =
        process.env.PUBLIC_URL + "/report/excel";
    }, 500);
  }
  return (
    <div className="content">
      {filter && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <Grid container style={{ marginTop: "40px" }}>
            <Grid item xs={9}>
              <PageNameBox page={page} />
            </Grid>
            <Grid item xs={3} align="right">
              <Button
                color="primary"
                variant="outlined"
                startIcon={<Icon>download</Icon>}
                size="small"
                onClick={() => renderDownload()}
              >
                Download
              </Button>
            </Grid>
          </Grid>
          <iframe
            style={{ border: "none", width: "0px", height: "0px" }}
            id="report_frame"
            name="report_frame"
            title="report_frame"
          ></iframe>
          <br />
          {report && (
            <>
              <ReportTable report={report} />
              <br />
              <ChartArea
                state={{ title: "จำนวนการรับสมัคร", report: report }}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

function ChartArea({ state }) {
  const [data, setData] = useState();
  // const [data2, setData2] = useState();
  useEffect(() => {
    async function createData() {
      let datasets = [
        {
          type: "bar",
          label: "เยี่ยมชม",
          borderColor: "rgba(255, 206, 86, 1)",
          backgroundColor: "rgba(255, 206, 86, 0.7)",
          borderWidth: 1,
          fill: false,
          data: [],
        },
        {
          type: "bar",
          label: "สมัครเรียน",
          borderColor: "rgba(255, 99, 132, 1)",
          backgroundColor: "rgba(255, 99, 132, 0.7)",
          borderWidth: 1,
          fill: false,
          data: [],
        },
        {
          type: "bar",
          label: "มอบตัว",
          borderColor: "rgba(54, 162, 235, 1)",
          backgroundColor: "rgba(54, 162, 235, 0.7)",
          borderWidth: 1,
          fill: false,
          data: [],
        },
      ];
      let labels = [];
      for (let y of state.report.school_years) {
        labels.push("ปีการศึกษา " + (y + 543));
        let result = state.report.result.filter((j) => j.year === y);
        let key = 0;
        for (let data of datasets) {
          let i = result.findIndex((j) => j.status === data.label);

          if (result[i]) datasets[key].data.push(result[i].count);
          else datasets[key].data.push(0);
          key++;
        }
      }

      let data = {
        labels: labels,
        datasets: datasets,
      };
      setData(data);
    }
    // async function createData2() {
    //   console.log(state.report);
    //   let data = {
    //     labels: ["สมัครเรียน", "มอบตัว"],
    //     datasets: [
    //       {
    //         type: "doughnut",
    //         label: "สมัครเรียน",
    //         data: [12, 19],
    //         backgroundColor: [
    //           "rgba(255, 99, 132, 0.7)",
    //           "rgba(54, 162, 235, 0.7)",
    //         ],
    //         borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
    //         borderWidth: 1,
    //       },
    //     ],
    //   };
    //   setData2(data);
    // }
    if (state.report) {
      createData();
      // createData2();
    }
  }, [state]);
  return (
    <div>
      {data && (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper style={{ padding: "20px 20px" }}>
              <MultiType
                state={{
                  title: state.title,
                  data: data,
                  height: 100,
                  version: 1,
                }}
              />
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6}>
            <Paper style={{ padding: "20px 20px" }}>
              <MultiType
                state={{
                  title: "ร้อยละผู้มอบตัวต่อการสมัครเรียน",
                  data: data2,
                  height: 150,
                }}
              />
            </Paper>
          </Grid> */}
        </Grid>
      )}
    </div>
  );
}

function ReportTable({ report }) {
  const [listFilter, setListFilter] = useState();
  useEffect(() => {
    setListFilter(null);
  }, [report]);
  function getStatusCount(year, status) {
    let i = report.result.findIndex(
      (j) => j.year === year && j.status === status
    );
    if (i > -1) return report.result[i].count;
    else return 0;
  }
  function calRegisterPercent(year) {
    let visitCount = getStatusCount(year, "เยี่ยมชม");
    let registerCount = getStatusCount(year, "สมัครเรียน");
    let r = (registerCount / visitCount) * 100 || 0;
    r = r.toFixed(2);
    return r;
  }
  function calRegistationPercent(year) {
    let visitCount = getStatusCount(year, "สมัครเรียน");
    let registerCount = getStatusCount(year, "มอบตัว");
    let r = (registerCount / visitCount) * 100 || 0;
    r = r.toFixed(2);
    return r;
  }
  function showList(year, status) {
    let i = report.result.findIndex(
      (j) => j.year === year && j.status === status
    );
    if (i > -1 && report.result[i].register_ids) {
      setListFilter({ _id: { $in: report.result[i].register_ids } });
    } else {
      setListFilter(null);
    }
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className="td"
                align="center"
                style={{ width: "80px" }}
                id="head1"
              >
                ลำดับ
              </TableCell>
              <TableCell className="td" align="center" id="head2">
                ปีการศึกษา
              </TableCell>
              <TableCell className="td" align="center" id="head3">
                เยี่ยมชม (คน)
              </TableCell>
              <TableCell className="td" align="center" id="head4">
                สมัครเรียน (คน)
              </TableCell>
              <TableCell className="td" align="center" id="head5">
                มอบตัว (คน)
              </TableCell>
              <TableCell className="td" align="center" id="head6">
                ร้อยละผู้ซื้อใบ
                <br />
                สมัครต่อการเยี่ยมชม
              </TableCell>
              <TableCell className="td" align="center" id="head7">
                ร้อยละผู้มอบตัวต่อ
                <br />
                การสมัครเรียน
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.school_years.map((year, key) => (
              <TableRow key={key}>
                <TableCell className="td" align="center">
                  {key + 1}
                </TableCell>
                <TableCell className="td" align="center">
                  {year + 543}
                </TableCell>
                {["เยี่ยมชม", "สมัครเรียน", "มอบตัว"].map((status, key) => (
                  <TableCell className="td" align="center" key={key}>
                    <Button
                      onClick={() => showList(year, status)}
                      className="kanit controlTd"
                    >
                      {getStatusCount(year, status)}
                    </Button>
                  </TableCell>
                ))}
                {/* <TableCell className="td" align="center">
                  <Button
                    onClick={() =>
                      console.log(
                        report.result.filter(
                          (j) => j.year === year && j.status === "เยี่ยมชม"
                        )[0].register_ids
                      )
                    }
                    className="kanit controlTd"
                  >
                    {getStatusCount(year, "เยี่ยมชม")}
                  </Button>
                </TableCell>
                <TableCell className="td" align="center">
                  {getStatusCount(year, "สมัครเรียน")}
                </TableCell>
                <TableCell className="td" align="center">
                  {getStatusCount(year, "มอบตัว")}
                </TableCell> */}
                <TableCell className="td" align="center">
                  {calRegisterPercent(year)}
                </TableCell>
                <TableCell className="td" align="center">
                  {calRegistationPercent(year)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {listFilter && (
        <>
          <TableStudentStatus match={listFilter} title="รายชื่อผู้สมัครเรียน" />
          <br />
          <br />
        </>
      )}
    </div>
  );
}
