import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import StepLabel from "@mui/material/StepLabel";
// import FormRegistration from "./FormRegistration";
import FormFamilyInfo from "./FormFamilyInfo";
import FormStudentInfo from "./FormStudentInfo";
import FormHealthy from "./FormHealthy";
import FormPickupPerson from "./FormPickupPerson";

import Conf from "../../Conf";
import { jsonSort } from "../../js/main";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function VisitSelfForm(props) {
  const params = useParams();
  const [state, setState] = useState();
  useEffect(() => {
    (async () => {
      if (params.access_token) {
        let access_token = params.access_token;

        let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
        let Register = await axios.get(
          Conf.endpoint.tonkla + "/ca/register/form/attend/" + access_token,
          { headers: { Authorization: OTP.data } }
        );
        if (Register.data && Register.data._id) {
          setState({
            finished: false,
            register_id: Register.data._id,
            access_token,
          });
        }
      }
    })();

    // async function getOTP() {
    //   let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
    //   let Register = await axios.get(
    //     Conf.endpoint.tonkla + "/ca/register/" + params.register_id,
    //     { headers: { Authorization: OTP.data } }
    //   );
    //   console.log(Register);
    // }
    // getOTP();
  }, [params]);
  /*
  async function upload() {
    return new Promise(async (resolve) => {
      let formData = new FormData();
      let fileNo = 0;
      for (let i = 0; i < 4; i++) {
        let file = document.getElementById("person-img-" + i).files[0];
        if (file) {
          formData.append(fileNo++, file, state.register_id + "_" + i);
        }
      }
      if (fileNo > 0) {
        let result = await axios.post(
          "https://tca.tonkla.ac.th/upload/upload.php?register_id=" +
            state.register_id,
          formData,
          {
            headers: { Authorization: state.access_token },
          }
        );
        let r = [];
        for (let file of result.data) {
          let arr = file.split("/");
          let i = arr[1].split(".");
          i = i[0].split("_").pop();
          i = parseInt(i);
          r[i] = file;
        }
        resolve(r);
      } else resolve([]);
    });
  }
  */

  async function upload(formPickup) {
    return new Promise(async (resolve) => {
      let persons = JSON.parse(JSON.stringify(formPickup));
      let priority = 1;
      for (let person of persons) {
        person.priority = priority++;
        if (person.file) {
          let path = await axios.put(Conf.endpoint.fileUpload, {
            file_stream: person.file,
            file_type: "pickup",
          });
          console.log(path);
          person.photo = "file.php?id=" + path.data._id;
          delete person.file;
        }
      }
      resolve(persons);
    });
  }
  async function save(form, formHealthy, formPickup) {
    let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
    let access_token = params.access_token;

    let Healthy = await axios.put(
      Conf.endpoint.tonkla + "/ca/healthy/self/" + access_token,
      formHealthy,
      { headers: { Authorization: OTP.data } }
    );
    if (Healthy.data && Healthy.data._id) {
      formPickup = await upload(formPickup);
      /*
      let i = 0;
      for (let photo of photos) {
        if (photo) formPickup[i].photo = photo;
        i++;
      }
      */
      let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
      let pickups = await axios.put(
        Conf.endpoint.tonkla + "/ca/register/form/pickup/" + access_token,
        { register_id: state.register_id, persons: formPickup },
        { headers: { Authorization: OTP.data } }
      );
      if (pickups.data && pickups.data.success !== false) {
        let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
        let Register = await axios.put(
          Conf.endpoint.tonkla +
            "/ca/register/self/registration/" +
            access_token,
          form,
          { headers: { Authorization: OTP.data } }
        );
        if (Register.data.success && Register.data.success === true) {
          setState({ finished: true });
          alert("Save completed");
        }
      }
    }
  }
  return (
    <div style={{ borderTop: "3px solid #3f51b5" }}>
      <Container>
        <br />
        {state && (
          <>
            {state.finished === true ? (
              <FillCompleted />
            ) : (
              <MainTab
                register_id={state.register_id}
                onSave={save}
                access_token={params.access_token}
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
}

function MainTab({ register_id, onSave, access_token }) {
  const [form, setForm] = useState();
  const [formHealthy, setFormHealthy] = useState();
  const [formPickup, setFormPickup] = useState([{}, {}, {}, {}]);
  const [tabStep, setTabStep] = useState(0);
  const [options, setOptions] = useState();
  const steps = [
    "ข้อมูลนักเรียน",
    "ข้อมูลครอบครัว",
    "ข้อมูลสุขภาพและพฤติกรรม",
    "ผู้ปกครองรับส่งนักเรียน",
  ];

  useEffect(() => {
    (async () => {
      let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
      let pickup_persons = await axios.get(
        Conf.endpoint.tonkla + "/ca/register/form/pickup/" + access_token,
        { headers: { Authorization: OTP.data } }
      );
      console.log(pickup_persons.data);
      if (pickup_persons.data && pickup_persons.data.length > 0) {
        setFormPickup(pickup_persons.data);
      } // else setFormPickup([{}, {}, {}, {}]);
    })();
  }, [access_token]);

  useEffect(() => {
    (async () => {
      let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
      let Register = await axios.get(
        Conf.endpoint.tonkla + "/ca/register/" + register_id,
        { headers: { Authorization: OTP.data } }
      );
      if (Register.data.error) alert(Register.data.error.message);
      else setForm(Register.data);

      OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
      let healthy = await axios.get(
        Conf.endpoint.tonkla + "/ca/healthy/register/" + register_id,
        { headers: { Authorization: OTP.data } }
      );
      if (healthy.data && healthy.data._id) {
        healthy = healthy.data;
      } else healthy = {};
      setFormHealthy(healthy);
    })();
  }, [register_id]);

  useEffect(() => {
    (async () => {
      if (!options) {
        let Options = {};
        let result = await axios.post(Conf.endpoint.tonkla + "/options");
        for (let option of result.data) {
          let type = option.type;
          if (!Options[type]) Options[type] = [];
          Options[type].push(option);
        }
        Options.prefix_alt = [];
        let prefixs = JSON.parse(
          JSON.stringify(Options.prefix.filter((j) => j.label_alt !== ""))
        );
        for (let option of prefixs) {
          option.label = option.label_alt;
          let i = Options.prefix_alt.findIndex((j) => j.label === option.label);
          if (i < 0) Options.prefix_alt.push(option);
        }

        Options.student_prefix_alt = [];
        let student_prefixs = JSON.parse(
          JSON.stringify(
            Options.student_prefix.filter((j) => j.label_alt !== "")
          )
        );
        for (let option of student_prefixs) {
          option.label = option.label_alt;
          let i = Options.student_prefix_alt.findIndex(
            (j) => j.label === option.label
          );
          if (i < 0) Options.student_prefix_alt.push(option);
        }

        Options.origin = await jsonSort(Options.origin, "label");
        Options.nationality = await jsonSort(Options.nationality, "label");
        setOptions(Options);
      }
    })();
  }, [options]);

  function changeForm(field, value) {
    setForm({ ...form, [field]: value });
  }
  function changeFields(fields) {
    setForm({ ...form, ...fields });
  }
  function changeHealthyFields(fields) {
    setFormHealthy({ ...formHealthy, ...fields });
  }
  function changeHealthyForm(field, value) {
    setFormHealthy({ ...formHealthy, [field]: value });
  }
  function changeStep(s) {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setTabStep(s);
  }
  async function sendForm() {
    onSave(form, formHealthy, formPickup);
  }
  return (
    <div>
      {form && options && (
        <>
          <Stepper activeStep={tabStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>
                  <div>{label}</div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />
          {
            {
              0: (
                <>
                  <FormStudentInfo
                    onNext={() => setTabStep(1)}
                    changeForm={changeForm}
                    state={{
                      student_prefixs: options.student_prefix,
                      student_prefixs_en: options.student_prefix_alt,
                      prefixs: options.prefix,
                      prefixs_en: options.prefix_alt,
                      nationalities: options.nationality,
                      origins: options.origin,
                      religions: options.religion,
                      register_documents: options.register_document,
                    }}
                    changeFields={changeFields}
                    student={form}
                  />
                  <div style={{ textAlign: "right", padding: "30px 0px" }}>
                    <Button
                      onClick={() => changeStep(1)}
                      style={{ width: "150px" }}
                      variant="contained"
                      endIcon={<Icon>navigate_next</Icon>}
                    >
                      Next
                    </Button>
                  </div>
                </>
              ),
              // 1: (
              //   <>
              //     <FormRegistration
              //       onNext={() => changeStep(2)}
              //       changeForm={changeForm}
              //       form={form}
              //     />

              //     <Grid container>
              //       <Grid item xs={6}>
              //         <Button
              //           variant="contained"
              //           startIcon={<Icon>arrow_back_ios_new</Icon>}
              //           style={{ width: "180px" }}
              //           onClick={() => changeStep(0)}
              //         >
              //           Back
              //         </Button>
              //       </Grid>
              //       <Grid item xs={6} align="right">
              //         <Button
              //           variant="contained"
              //           endIcon={<Icon>navigate_next</Icon>}
              //           style={{ width: "180px" }}
              //           onClick={() => changeStep(2)}
              //         >
              //           Next
              //         </Button>
              //       </Grid>
              //     </Grid>
              //   </>
              // ),
              1: (
                <>
                  <FormFamilyInfo
                    state={{
                      prefixs: options.prefix,
                      prefixs_en: options.prefix_alt,
                      nationalities: options.nationality,
                      origins: options.origin,
                      religions: options.religion,
                      person_status: options.person_status,
                      jobs: options.job,
                      family_status: options.family_status,
                    }}
                    form={form}
                    changeForm={changeForm}
                    changeFields={changeFields}
                  />
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        startIcon={<Icon>arrow_back_ios_new</Icon>}
                        style={{ width: "180px" }}
                        onClick={() => changeStep(0)}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button
                        variant="contained"
                        endIcon={<Icon>navigate_next</Icon>}
                        style={{ width: "180px" }}
                        onClick={() => changeStep(2)}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                </>
              ),
              2: (
                <>
                  <FormHealthy
                    // form={form}
                    changeForm={changeHealthyForm}
                    changeFields={changeHealthyFields}
                    form={formHealthy}
                  />
                  <br />
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        startIcon={<Icon>arrow_back_ios_new</Icon>}
                        style={{ width: "180px" }}
                        onClick={() => changeStep(1)}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button
                        variant="contained"
                        endIcon={<Icon>navigate_next</Icon>}
                        style={{ width: "180px" }}
                        onClick={() => changeStep(3)}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                </>
              ),
              3: (
                <>
                  <FormPickupPerson
                    // form={form}
                    state={{
                      prefixs: options.prefix,
                    }}
                    changeForm={(form) => setFormPickup(form)}
                    changeFields={changeHealthyFields}
                    form={formPickup}
                  />
                  <br />
                  <Grid container>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        startIcon={<Icon>arrow_back_ios_new</Icon>}
                        style={{ width: "180px" }}
                        onClick={() => changeStep(2)}
                      >
                        Back
                      </Button>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <Button
                        variant="contained"
                        endIcon={<Icon>send</Icon>}
                        style={{ width: "180px" }}
                        onClick={sendForm}
                      >
                        Send Form
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                </>
              ),
            }[tabStep]
          }
        </>
      )}
    </div>
  );
}

function FillCompleted() {
  return (
    <Container maxWidth="sm" style={{ paddingTop: "20px", height: "99vh" }}>
      <Paper style={{ padding: "20px 20px", textAlign: "center" }}>
        <h3 style={{ fontWeight: 400 }}>
          โรงเรียนต้นกล้าได้รับแบบฟอร์มของท่านแล้ว
        </h3>
        <div style={{ marginTop: "10px" }}>
          <a href="https://www.tonkla.ac.th" title="โรงเรียนต้นกล้า">
            เยี่ยมชมเว็บไซต์โรงเรียน
          </a>
        </div>
        <br />
      </Paper>
    </Container>
  );
}
