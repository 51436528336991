export const student_prefixs = [
  { value: "เด็กหญิง", label: "เด็กหญิง (Girl)" },
  { value: "เด็กชาย", label: "เด็กชาย (Boy)" },
  { value: "นาย", label: "นาย (Mr.)" },
  { value: "นางสาว", label: "นางสาว (Miss)" },
];
export const prefixs = [
  { value: "นาย", label: "นาย (Mr.)" },
  { value: "นาง", label: "นาง (Mrs.)" },
  { value: "นางสาว", label: "นางสาว (Miss)" },
];

export const register_statuses = [
  { value: "เยี่ยมชม", label: "เยี่ยมชม" },
  { value: "สมัครเรียน", label: "สมัครเรียน" },
  { value: "มอบตัว", label: "มอบตัว" },
];

export const months = [
  { value: 1, label: "มกราคม" },
  { value: 2, label: "กุมภาพันธ์" },
  { value: 3, label: "มีนาคม" },
  { value: 4, label: "เมษายน" },
  { value: 5, label: "พฤษภาคม" },
  { value: 6, label: "มิถุนายน" },
  { value: 7, label: "กรกฎาคม" },
  { value: 8, label: "สิงหาคม" },
  { value: 9, label: "กันยายน" },
  { value: 10, label: "ตุลาคม" },
  { value: 11, label: "พฤศจิกายน" },
  { value: 12, label: "ธันวาคม" },
];
export const jobs = [
  { value: "ข้าราชการ", label: "ข้าราชการ", alt_label: "Officialdom" },
  { value: "เกษตรกร", label: "เกษตรกร", alt_label: "Farmer" },
  {
    value: "พนักงานราชการ",
    label: "พนักงานราชการ",
    alt_label: "Government employee",
  },
  {
    value: "พนักงานบริษัทฯ",
    label: "พนักงานบริษัทฯ",
    alt_label: "Office worker",
  },
  {
    value: "พนักงานรัฐวิสาหกิจ",
    label: "พนักงานรัฐวิสาหกิจ",
  },
  { value: "ลูกจ้าง", label: "ลูกจ้าง", alt_label: "Employee" },
  { value: "ธุรกิจส่วนตัว", label: "ธุรกิจส่วนตัว", alt_label: "Own business" },
  { value: "ค้าขาย", label: "ค้าขาย" },
  { value: "อื่น ๆ", label: "อื่น ๆ", alt_label: "Other" },
];

export const individuals_responsible = [
  {
    value: "Father",
    label_en: "Father",
    label_th: "บิดา",
  },
  {
    value: "Mother",
    label_en: "Mother",
    label_th: "มารดา",
  },
  {
    value: "Great Grand Father/Mother",
    label_en: "Great Grand Father/Mother",
    label_th: "ทวด",
  },
  {
    value: "Grand Father/Mother",
    label_en: "Grand Father/Mother",
    label_th: "ปู่ย่า / ตายาย",
  },
  {
    value: "Guardian",
    label_en: "Guardian",
    label_th: "พี่เลี้ยง",
  },
];
export const parents_married_status = [
  /*
  {
    label_en: "Father and mother together",
    label_th: "บิดา-มารดา อยู่ร่วมกัน",
    value: "Father and mother together",
  },
  {
    label_th: "บิดา-มารดา หย่าร้าง",
    label_en: "Divorce",
    value: "Divorce",
  },
  {
    label_en: "Separated",
    label_th: "บิดา-มารดา ยังไม่ได้หย่าร้างแต่แยกกันอยู่",
    value: "Separated",
  },
  {
    label_th: "Lost contact",
    label_en: "บิดา-มารดา ขาดการติดต่อ",
    value: "Lost contact",
  },
  {
    label_en: "Father has new family",
    label_th: "บิดามีภรรยาใหม่",
    value: "Father has new family",
  },
  {
    label_en: "Mother has new family",
    label_th: "มารดามีสามีใหม่",
    value: "Mother has new family",
  },
  */
  {
    label_th: "อยู่ร่วมกัน",
    label_en: "Father and mother together",
    value: "อยู่ร่วมกัน",
  },
  {
    label_th: "หย่าร้าง",
    label_en: "Divorce",
    value: "หย่าร้าง",
  },
  {
    label_th: "แยกกันอยู่",
    label_en: "Separate",
    value: "แยกกันอยู่",
  },
  {
    label_th: "บิดาเสียชีวิต",
    label_en: "Father died",
    value: "บิดาเสียชีวิต",
  },
  {
    label_th: "มารดาเสียชีวิต",
    label_en: "Mother died",
    value: "มารดาเสียชีวิต",
  },
  {
    label_th: "บิดาและมารดาเสียชีวิต",
    label_en: "Father and Mother died",
    value: "บิดาและมารดาเสียชีวิต",
  },
];
export const contact_relations = [
  {
    value: "บิดา",
    label: "บิดา (Father)",
    label_en: "Father",
    label_th: "บิดา",
  },
  {
    value: "มารดา",
    label: "มารดา (Mother)",
    label_en: "Mother",
    label_th: "มารดา",
  },
  {
    value: "อื่น ๆ",
    label: "อื่น ๆ (Other)",
    label_en: "Guardian (Not father and mother)",
    label_th: "อื่น ๆ",
  },
];
export const stay_with_choices = [
  {
    value: "บิดาและมารดา",
    label_en: "Father and mother",
    label_th: "บิดาและมารดา",
  },
  {
    value: "บิดา",
    label_en: "Father",
    label_th: "บิดา",
  },
  {
    value: "มารดา",
    label_en: "Mother",
    label_th: "มารดา",
  },
  {
    value: "อื่น ๆ",
    label_en: "Guardian (Not father and mother)",
    label_th: "อื่น ๆ",
  },
];
export const consider_chooses = [
  {
    value: "Teaching and learning philosophy",
    label_en: "Teaching and learning philosophy",
    label_th: "แนวทางและรูปแบบการเรียนการสอน",
  },
  {
    value: "Child’s happiness",
    label_en: "Child’s happiness",
    label_th: "บุตรหลานมีความสุขในการเรียน",
  },
  {
    value: "Academic curriculum",
    label_en: "Academic curriculum",
    label_th: "งานวิชาการ",
  },
  {
    value: "Student care",
    label_en: "Student care",
    label_th: "การดูแลนักเรียนดี",
  },
  {
    value: "English program",
    label_en: "English program",
    label_th: "งานภาษาอังกฤษ",
  },
  {
    value: "Referred by Tonkla parents",
    label_en: "Referred by Tonkla parents",
    label_th: "มีผู้ปกครองต้นกล้า/ ผู้อื่นแนะนำมา",
  },
  {
    value: "Tuition fee",
    label_en: "Tuition fee",
    label_th: "ค่าธรรมเนียมโรงเรียน",
  },
  {
    value: "School safety",
    label_en: "School safety",
    label_th: "ความปลอดภัยภายในโรงเรียน",
  },
  {
    value: "School environment",
    label_en: "School environment",
    label_th: "บรรยากาศโรงเรียน",
  },
  {
    value: "Location",
    label_en: "Location",
    label_th: "เดินทางสะดวกใกล้บ้าน",
  },
];

export const promotion_media = [
  {
    label: "School website (เว็บไซต์โรงเรียน)",
    value: "website",
    checked: false,
  },
  { label: "Facebook (เฟสบุ๊ค)", value: "facebook", checked: false },
  { label: "Instragram (อินสตาแกรม)", value: "instragram", checked: false },
  {
    label: "Radio Advertisement (โฆษณาวิทยุ)",
    value: "radio advertisement",
    checked: false,
  },
  {
    label: "Internet (ค้นหาจากอินเทอร์เน็ต)",
    value: "internet",
    checked: false,
  },
  {
    label: "Advertisement boards (ป้าย Cut-out/ Bill Board)",
    value: "advertisement boards",
    checked: false,
  },
  { label: "Leaflets (แผ่นพับโฆษณา)", value: "leaflets", checked: false },
  {
    label:
      "Referred by Tonkla parents (เพื่อน-ญาติหรือผู้ปกครองนักเรียนต้นกล้า แนะนำมา)",
    value: "referred",
    checked: false,
  },
  { label: "Event (จัดบูธ)", value: "event", checked: false },
];

export const weekDays = [
  { name: "Sunday", abbrev: "Sun", value: 0 },
  { name: "Monday", abbrev: "Mon", value: 1 },
  { name: "Tuesday", abbrev: "Tue", value: 2 },
  { name: "Wednesday", abbrev: "Wed", value: 3 },
  { name: "Thursday", abbrev: "Thu", value: 4 },
  { name: "Friday", abbrev: "Fri", value: 5 },
  { name: "Saturday", abbrev: "Sat", value: 6 },
];

export const influence_factors = [
  {
    label:
      "Teaching and learning philosophy (แนวทางการจัดการศึกษาและรูปแบบการจัดการเรียนรู้)",
    value: "Teaching and learning philosophy",
    checked: false,
  },
  {
    label: "Academic curriculum (งานวิชาการ)",
    value: "Academic curriculum",
    checked: false,
  },
  {
    label: "English program (ภาษาอังกฤษ)",
    value: "English program",
    checked: false,
  },
  {
    label: "Tuition fee (ค่าธรรมเนียมการศึกษา)",
    value: "Tuition fee",
    checked: false,
  },
  {
    label: "School environment (อาคารสถานที่และบรรยากาศของโรงเรียน)",
    value: "School environment",
    checked: false,
  },
  {
    label: "Location (เดินทางสะดวกใกล้บ้าน)",
    value: "Location",
    checked: false,
  },
  {
    label: "Child’s happiness (บุตรหลานมีความสุขในการเรียน)",
    value: "Child’s happiness",
    checked: false,
  },
  {
    label: "Student care (การดูแลนักเรียนดี)",
    value: "Student care",
    checked: false,
  },
  {
    label: "School safety (ความปลอดภัยในโรงเรียน)",
    value: "School safety",
    checked: false,
  },
];
export const Degrees = [
  { value: "Undergraduate", label: "Undergraduate" },
  { value: "Bachelor's degree", label: "Bachelor's degree" },
  { value: "Master's degree", label: "Master's degree" },
  { value: "PhD", label: "PhD" },
];

export const not_purchase_choice = [
  {
    value: 1,
    label_en: "Not enough information to make decision",
    label_th: "ข้อมูลยังไม่เพียงพอต่อการตัดสินใจ",
  },
  {
    value: 2,
    label_en: "Require more time and discuss with family",
    label_th: "ขอเวลาศึกษาข้อมูล/ปรึกษาครอบครัว",
  },
  {
    value: 3,
    label_en: "My child is underage",
    label_th: "บุตรหลานอายุไม่ถึงเกณฑ์",
  },
  {
    value: 4,
    label_en: "School's educational philosophy",
    label_th: "แนวทางและรู้แบบการเรียนการสอนของโรงเรียน",
  },
  {
    value: 5,
    label_en: "Concern about futher education",
    label_th: "กังวลเรื่องการศึกษาต่อของบุตรหลานในระดับที่สูงขึ้น",
  },
  {
    value: 6,
    label_en: "Class size and student care",
    label_th: "การดูแลนักเรียน/จำนวนนักเรียนต่อห้อง",
  },
  {
    value: 7,
    label_en: "Safety",
    label_th: "ความปลอดภัยภายในโรงเรียน",
  },
  {
    value: 8,
    label_en: "Tuition fee",
    label_th: "ค่าใช้จ่าย",
  },
  {
    value: 9,
    label_en: "Traveling distance",
    label_th: "ระยะทางการเดินทางมาโรงเรียน",
  },
];

export const satisfaction_choice = [
  {
    id: 1,
    no: "1",
    label_en: "Admission staff",
    label_th: "ผู้รับเยี่ยมชม",
  },
  {
    id: 2,
    no: "2",
    label_en: "School visit process",
    label_th: "ขั้นตอนการเยี่ยมชม",
  },
  {
    id: 3,
    no: "3",
    label_en: "Impression",
    label_th: "สิ่งที่ประทับใจ",
  },
  {
    id: 4,
    subOf: 1,
    no: "1.1",
    label_en: "Warm welcome and greeting",
    label_th: "การทักทายเมื่อแรกพบ",
  },
  {
    id: 5,
    subOf: 1,
    no: "1.2",
    label_en: "Admission staff's personalities",
    label_th: "บุคลิกของผู้รับเยี่ยมชม",
  },
  {
    id: 6,
    subOf: 1,
    no: "1.3",
    label_en: "Clear communication",
    label_th: "การสื่อสารชัดเจน",
  },
  {
    id: 7,
    subOf: 1,
    no: "1.4",
    label_en: "Give information",
    label_th: "สามารถอธิบายข้อมูลที่ต้องการทราบ",
  },
  {
    id: 8,
    subOf: 1,
    no: "1.5",
    label_en: "Allow visitors to ask questions",
    label_th: "เปิดโอกาสให้สอบถามข้อซักถาม",
  },
  {
    id: 9,
    subOf: 2,
    no: "2.1",
    label_en: "School reception",
    label_th: "การต้อนรับ",
  },
  {
    id: 10,
    subOf: 2,
    no: "2.2",
    label_en: "School visit process",
    label_th: "ลำดับขั้นในการเยี่ยมชม",
  },
  {
    id: 11,
    subOf: 2,
    no: "2.3",
    label_en: "Duration of school visit process",
    label_th: "ระยะเวลาในการเยี่ยมชม",
  },

  {
    id: 12,
    subOf: 3,
    no: "3.1",
    label_en: "Cleanness",
    label_th: "ความสะอาด",
  },
  {
    id: 13,
    subOf: 3,
    no: "3.2",
    label_en: "Facility",
    label_th: "อาคารสถานที่",
  },
  {
    id: 14,
    subOf: 3,
    no: "3.3",
    label_en: "Educational philosophy",
    label_th: "แนวทางในการจัดการศึกษา",
  },
];

export const registration_documents = [
  {
    label_th: "สูติบัตรนักเรียน",
    label_en: "Student’s Birth Certificate",
    type: "นักเรียน",
  },
  {
    label_th: "บัตรประชาชนนักเรียน",
    label_en: "Student ID card",
    type: "นักเรียน",
  },
  {
    label_th: "ทะเบียนบ้านนักเรียน",
    label_en: "Copy of student’s house certificate",
    type: "นักเรียน",
  },
  {
    label_th: "รูปถ่ายนักเรียน",
    label_en: "2 inches of student photo (2 copies)",
    type: "นักเรียน",
  },
  {
    label_th: "ใบเปลี่ยนชื่อตัว",
    label_en: "Copy of student name change registration certificate",
    type: "นักเรียน",
  },
  {
    label_th: "เอกสารผลการเรียน (ปพ.1)",
    type: "นักเรียน",
  },

  {
    label_th: "บัตรประชาชนบิดา",
    label_en: "Father ID card",
    type: "บิดา",
  },
  {
    label_th: "ทะเบียนบ้านบิดา",
    label_en: "Copy of father house certificate",
    type: "บิดา",
  },
  {
    label_th: "รูปถ่ายบิดา",
    label_en: "2 inches of father photo (2 copies)",
    type: "บิดา",
  },
  {
    label_th: "ใบเปลี่ยนชื่อตัวบิดา",
    label_en: "Copy of father’s first name change registration certificate",
    type: "บิดา",
  },
  {
    label_th: "ใบเปลี่ยนชื่อสกุลบิดา",
    label_en: "Copy of father’s last name change registration certificate",
    type: "บิดา",
  },

  {
    label_th: "บัตรประชาชนมารดา",
    label_en: "Mother ID card",
    type: "มารดา",
  },
  {
    label_th: "ทะเบียนบ้านมารดา",
    label_en: "Copy of mother house certificate",
    type: "มารดา",
  },
  {
    label_th: "รูปถ่ายมารดา",
    label_en: "2 inches of mother photo (2 copies)",
    type: "มารดา",
  },
  {
    label_th: "ใบเปลี่ยนชื่อตัวมารดา",
    label_en: "Copy of mother’s first name change registration certificate",

    type: "มารดา",
  },
  {
    label_th: "ใบเปลี่ยนชื่อสกุลมารดา",
    label_en: "Copy of father’s last name change registration certificate",
    type: "มารดา",
  },
  /*
  {
    label_th: "สูติบัตรของนักเรียน",
    label_en: "Student’s Birth Certificate",
    value: "สูติบัตรของนักเรียน",
  },
  {
    label_th: "สำเนาทะเบียนบ้านของนักเรียน",
    label_en: "Copy of student’s house certificate",
    value: "สำเนาทะเบียนบ้านของนักเรียน",
  },
  {
    label_th: "รูปถ่ายนักเรียน 2 นิ้ว 3 ใบ",
    label_en: "3- 2 inches  student photos",
    value: "รูปถ่ายนักเรียน 2 นิ้ว 3 ใบ",
  },

  {
    label_th: "สำเนาบัตรประชาชนของบิดา",
    label_en: "Copy of father’s ID Card",
    value: "สำเนาบัตรประชาชนของบิดา",
  },
  {
    label_th: "สำเนาทะเบียนบ้านของบิดา",
    label_en: "Copy of father’s house certificate",
    value: "สำเนาทะเบียนบ้านของบิดา",
  },
  {
    label_th: "รูปถ่ายบิดา 2 นิ้ว 2 ใบ",
    label_en: "2- 2 inches father photos",
    value: "รูปถ่ายบิดา 2 นิ้ว 2 ใบ",
  },
  {
    label_th: "สำเนาบัตรประชาชนของมารดา",
    label_en: "Copy of mother’s ID Card",
    value: "สำเนาบัตรประชาชนของมารดา",
  },
  {
    label_th: "สำเนาทะเบียนบ้านของมารดา",
    label_en: "Copy of mother’s house certificate",
    value: "สำเนาทะเบียนบ้านของมารดา",
  },
  {
    label_th: "รูปถ่ายมารดา 2 นิ้ว 2 ใบ",
    label_en: "2- 2 inches mother photos",
    value: "รูปถ่ายมารดา 2 นิ้ว 2 ใบ",
  },
  {
    label_th: "เอกสารความร่วมมือระหว่างผู้ปกครองและโรงเรียน",
    label_en: "Document for cooperation between parents and school",
    value: "เอกสารความร่วมมือระหว่างผู้ปกครองและโรงเรียน",
  },
  {
    label_th: "หลักฐานผลการเรียนเดิม  (ป.1 ภาคเรียนที่ 2 ขึ้นไป)",
    label_en: "Report card from previous school (P1 semester two and onwards)",
    value: "หลักฐานผลการเรียนเดิม  (ป.1 ภาคเรียนที่ 2 ขึ้นไป)",
  },
  */
];

export const home_types = [
  {
    label_th: "บ้านของตนเอง",
    label_en: "Own house",
    value: "บ้านของตนเอง",
  },
  {
    label_th: "บ้านเช่า",
    label_en: "Rent",
    value: "บ้านเช่า",
  },
  {
    label_th: "หอพัก",
    label_en: "Dormitory",
    value: "หอพัก",
  },
];

export const genders = [
  { value: "หญิง", label: "หญิง" },
  { value: "ชาย", label: "ชาย" },
];
