import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";

// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { getStampTime } from "../../js/main";
import {
  GridInputItem,
  GridSelectItem,
  RadioForm,
} from "../../components/InputForm";
import dayjs from "dayjs";

const styles = {
  title: { fontWeight: 400, padding: "13px 15px", fontSize: "1rem" },
  subTitle: { color: "#888", fontSize: ".9rem", paddingTop: "3px" },
};
export default function FormFamilyInfo(props) {
  const { state, changeFields } = props;
  const form = props.form || {};
  const [brothersForm, setBrothersForm] = useState({});
  function changeValue(e) {
    let id = e.target.id;
    let value = e.target.value;
    props.changeForm(id, value);
  }
  async function addBrothers() {
    if (!brothersForm.study_at_tonkla) brothersForm.study_at_tonkla = false;
    if (!brothersForm.birth_date) {
      let d = document.getElementById("brothers_birth_date").value;
      d = await getStampTime(d);
      brothersForm.birth_date = d.iso_date;
    }
    let brothers = JSON.parse(JSON.stringify(form.brothers || []));
    if (brothersForm.key > -1) {
      brothers[brothersForm.key] = brothersForm;
    } else {
      brothers.push(brothersForm);
    }
    // setForm({ ...form, brothers });
    props.changeForm("brothers", brothers);
    setBrothersForm({});
  }
  function selectBrother(key) {
    let brother = JSON.parse(JSON.stringify(form.brothers[key]));
    brother.key = key;
    setBrothersForm(brother);
  }
  function delBrothers() {
    let Brothers = JSON.parse(JSON.stringify(form.brothers));

    if (brothersForm.key > -1) {
      Brothers = Brothers.filter((j) => j.fullname !== brothersForm.fullname);
      //   setForm({ ...form, brothers: Brothers });
      props.changeForm("brothers", Brothers);
    }
    setBrothersForm({});
  }
  return (
    <div>
      {form && state && (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} align="center" component="h1">
              ข้อมูลด้านครอบครัว
              <div style={{ color: "#888", fontSize: "1rem" }}>
                Family Information
              </div>
            </Grid>
            <>
              <Grid item xs={12} sm={7} style={styles.title}>
                ข้อมูลบิดา
                <div style={styles.subTitle}>(Father Information)</div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                style={{ paddingTop: "15px" }}
                align="right"
              >
                {form.father_is_curator}
                <FormControlLabel
                  onChange={(e) =>
                    changeFields({ [e.target.id]: e.target.checked })
                  }
                  control={
                    <Checkbox
                      id="father_is_curator"
                      checked={form.father_is_curator || false}
                    />
                  }
                  label={<div>เป็นผู้ปกครอง</div>}
                />
              </Grid>
              <GridSelectItem
                size={[12, 3, 3, 3]}
                label="Prefix"
                id="father_prefix"
                value={form.father_prefix}
                changeValue={changeValue}
                options={state.prefixs}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="First name"
                placeholder="ชื่อ"
                id="father_first_name"
                value={form.father_first_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Middle name"
                placeholder="ชื่อกลาง"
                id="father_middle_name"
                value={form.father_middle_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Last name"
                placeholder="นามสกุล"
                id="father_last_name"
                value={form.father_last_name}
                changeValue={changeValue}
              />

              <GridSelectItem
                size={[12, 3, 3, 3]}
                label="Prefix (EN)"
                id="father_prefix_en"
                value={form.father_prefix_en || "-"}
                changeValue={changeValue}
                options={state.prefixs_en}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="First name (EN)"
                placeholder="ชื่อ (ภาษาอังกฤษ)"
                id="father_first_name_en"
                value={form.father_first_name_en}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Middle name (EN)"
                placeholder="ชื่อกลาง (ภาษาอังกฤษ)"
                id="father_middle_name_en"
                value={form.father_middle_name_en}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Last name (EN)"
                placeholder="นามสกุล (ภาษาอังกฤษ)"
                id="father_last_name_en"
                value={form.father_last_name_en}
                changeValue={changeValue}
              />

              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Nationality"
                id="father_nationality"
                value={form.father_nationality || "-"}
                changeValue={changeValue}
                options={state.nationalities}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Origin"
                id="father_origin"
                value={form.father_origin || "-"}
                changeValue={changeValue}
                options={state.origins}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Religion"
                id="father_religion"
                value={form.father_religion || "-"}
                changeValue={changeValue}
                options={state.religions}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="Tel"
                placeholder="โทรศัพท์มือถือ"
                id="father_phone"
                value={form.father_phone}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="e-Mail"
                placeholder="อีเมล"
                id="father_email"
                value={form.father_email}
                changeValue={changeValue}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="อาชีพ"
                id="father_job"
                value={form.father_job}
                changeValue={changeValue}
                options={state.jobs}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="Workplace"
                placeholder="สถานที่ทำงาน"
                id="father_workplace"
                value={form.father_workplace}
                changeValue={changeValue}
              />
              <GridInputItem
                type="number"
                size={[12, 4, 4, 4]}
                label="Salary"
                placeholder="เงินเดือน"
                id="father_income"
                value={form.father_income}
                changeValue={changeValue}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="สถานภาพ"
                id="father_status"
                value={form.father_status || "-"}
                changeValue={changeValue}
                options={state.person_status}
              />
            </>
            <>
              <Grid item xs={12} sm={7} style={styles.title}>
                ข้อมูลมารดา
                <div style={styles.subTitle}>(Mother Information)</div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                style={{ paddingTop: "15px" }}
                align="right"
              >
                <FormControlLabel
                  onChange={(e) =>
                    changeFields({ [e.target.id]: e.target.checked })
                  }
                  control={
                    <Checkbox
                      id="mother_is_curator"
                      checked={form.mother_is_curator || false}
                    />
                  }
                  label={<div>เป็นผู้ปกครอง</div>}
                />
              </Grid>
              <GridSelectItem
                size={[12, 3, 3, 3]}
                label="Prefix"
                id="mother_prefix"
                value={form.mother_prefix}
                changeValue={changeValue}
                options={state.prefixs}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="First name"
                placeholder="ชื่อ"
                id="mother_first_name"
                value={form.mother_first_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Middle name"
                placeholder="ชื่อกลาง"
                id="mother_middle_name"
                value={form.mother_middle_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Last name"
                placeholder="นามสกุล"
                id="mother_last_name"
                value={form.mother_last_name}
                changeValue={changeValue}
              />

              <GridSelectItem
                size={[12, 3, 3, 3]}
                label="Prefix (EN)"
                id="mother_prefix_en"
                value={form.mother_prefix_en || "-"}
                changeValue={changeValue}
                options={state.prefixs}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="First name (EN)"
                placeholder="ชื่อ (ภาษาอังกฤษ)"
                id="mother_first_name_en"
                value={form.mother_first_name_en}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Middle name (EN)"
                placeholder="ชื่อกลาง (ภาษาอังกฤษ)"
                id="mother_middle_name_en"
                value={form.mother_middle_name_en}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Last name (EN)"
                placeholder="นามสกุล (ภาษาอังกฤษ)"
                id="mother_last_name_en"
                value={form.mother_last_name_en}
                changeValue={changeValue}
              />

              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Nationality"
                id="mother_nationality"
                value={form.mother_nationality || "-"}
                changeValue={changeValue}
                options={state.nationalities}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Origin"
                id="mother_origin"
                value={form.mother_origin || "-"}
                changeValue={changeValue}
                options={state.origins}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Religion"
                id="mother_religion"
                value={form.mother_religion || "-"}
                changeValue={changeValue}
                options={state.religions}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="Tel"
                placeholder="โทรศัพท์มือถือ"
                id="mother_phone"
                value={form.mother_phone}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="e-Mail"
                placeholder="อีเมล"
                id="mother_email"
                value={form.mother_email}
                changeValue={changeValue}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="อาชีพ"
                id="mother_job"
                value={form.mother_job}
                changeValue={changeValue}
                options={state.jobs}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="Workplace"
                placeholder="สถานที่ทำงาน"
                id="mother_workplace"
                value={form.mother_workplace}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                type="number"
                label="Salary"
                placeholder="เงินเดือน"
                id="mother_income"
                value={form.mother_income}
                changeValue={changeValue}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="สถานภาพ"
                id="mother_status"
                value={form.mother_status || "-"}
                changeValue={changeValue}
                options={state.person_status}
              />
            </>

            <>
              <Grid item xs={12} style={styles.title}>
                การติดต่อหากเกิดเหตุกรณีฉุกเฉินหรือเร่งด่วน
                (หากไม่สามารถติดต่อผู้ปกครองที่แจ้งไว้ข้างบนได้)
                <div style={styles.subTitle}>
                  Emergency contact (in case the school cannot contact parents)
                </div>
              </Grid>

              <GridSelectItem
                size={[12, 3, 3, 3]}
                label="Prefix"
                id="other_contact_prefix"
                value={form.other_contact_prefix}
                changeValue={changeValue}
                options={state.prefixs}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="First name"
                placeholder="ชื่อ"
                id="other_contact_first_name"
                value={form.other_contact_first_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Middle name"
                placeholder="ชื่อกลาง"
                id="other_contact_middle_name"
                value={form.other_contact_middle_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Last name"
                placeholder="นามสกุล"
                id="other_contact_last_name"
                value={form.other_contact_last_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Nickname"
                placeholder="ชื่อเล่น"
                id="other_contact_nickname"
                value={form.other_contact_nickname}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Ralation"
                placeholder="ความสัมพันธ์"
                id="other_contact_relation"
                value={form.other_contact_relation}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Phone number"
                placeholder="หมายเลขโทรที่บ้าน"
                id="other_contact_phone"
                value={form.other_contact_phone}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Mobile"
                placeholder="หมายเลขโทรมือถือ"
                id="other_contact_mobile"
                value={form.other_contact_mobile}
                changeValue={changeValue}
              />
            </>
            <>
              <Grid item xs={12}>
                <RadioForm
                  title_th="สถานะภาพครอบครัว"
                  title_en="Family Status"
                  styles={{ title: styles.title, subTitle: styles.subTitle }}
                  // choices={parents_married_status}
                  choices={state.family_status}
                  grids={[12, 6, 4, 4]}
                  value={form.parents_married_status}
                  // other={form.other_parents_married_status}
                  onCheck={(value) => {
                    props.changeForm("parents_married_status", value);
                  }}
                  // onChangeOther={(e) =>
                  //   props.changeForm(
                  //     "other_parents_married_status",
                  //     e.target.value
                  //   )
                  // }
                />
              </Grid>
            </>
            <>
              <Grid item xs={12} style={styles.title}>
                จำนวนพี่/น้องร่วมบิดา – มารดา (ไม่รวมนักเรียน) เรียงตามลำดับ
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="td"
                          align="center"
                          style={{ width: "60px" }}
                        >
                          ลำดับ
                        </TableCell>
                        <TableCell className="td" align="center">
                          ชื่อ-สกุล (ระบุคำนำหน้า)
                        </TableCell>
                        <TableCell
                          className="td"
                          align="center"
                          style={{ width: "160px" }}
                        >
                          วันเกิด
                        </TableCell>
                        <TableCell
                          className="td"
                          align="center"
                          style={{ width: "160px" }}
                        >
                          การศึกษา
                        </TableCell>
                        <TableCell
                          className="td"
                          align="center"
                          style={{ width: "90px" }}
                        >
                          เรียนต้นกล้า
                        </TableCell>
                        <TableCell style={{ width: "40px" }} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className="controlTd" align="center">
                          <IconButton
                            color="inherit"
                            component="span"
                            onClick={delBrothers}
                          >
                            <Icon style={{ color: "#f00" }}>clear</Icon>
                          </IconButton>
                        </TableCell>
                        <TableCell className="controlTd">
                          <TextField
                            fullWidth
                            size="small"
                            id="fullname"
                            variant="outlined"
                            placeholder="Fullname (with prefix)"
                            value={brothersForm.fullname || ""}
                            onChange={(e) => {
                              setBrothersForm({
                                ...brothersForm,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell className="controlTd">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={dayjs(brothersForm.birth_date || null)}
                              onChange={async (newValue) => {
                                let d = await getStampTime(newValue);
                                setBrothersForm({
                                  ...brothersForm,
                                  birth_date: d.iso_date,
                                });
                              }}
                              format="YYYY-MM-DD"
                              slotProps={{
                                textField: {
                                  id: "brothers_birth_date",
                                  InputLabelProps: { shrink: true },
                                  fullWidth: true,
                                  size: "small",
                                },
                              }}
                            />
                          </LocalizationProvider>
                        </TableCell>
                        <TableCell className="controlTd">
                          <TextField
                            fullWidth
                            id="degree"
                            variant="outlined"
                            placeholder="Degree"
                            value={brothersForm.degree || ""}
                            size="small"
                            onChange={(e) => {
                              setBrothersForm({
                                ...brothersForm,
                                [e.target.id]: e.target.value,
                              });
                            }}
                          />
                        </TableCell>

                        <TableCell className="controlTd" align="center">
                          <Checkbox
                            id="study_at_tonkla"
                            checked={brothersForm.study_at_tonkla || false}
                            onChange={(e) => {
                              setBrothersForm({
                                ...brothersForm,
                                [e.target.id]: e.target.checked,
                              });
                            }}
                          />
                        </TableCell>
                        <TableCell className="controlTd" align="center">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={addBrothers}
                          >
                            <Icon>add</Icon>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      {form.brothers &&
                        form.brothers.map((brother, key) => (
                          <TableRow key={key}>
                            <TableCell className="td" align="center">
                              {key + 1}
                            </TableCell>
                            <TableCell className="td">
                              {brother.fullname}
                            </TableCell>
                            <TableCell className="td" align="center">
                              {brother.birth_date}
                            </TableCell>
                            <TableCell className="td" align="center">
                              {brother.degree}
                            </TableCell>
                            <TableCell className="td" align="center">
                              {brother.study_at_tonkla === true && (
                                <Icon style={{ color: "#080" }}>done</Icon>
                              )}
                            </TableCell>
                            <TableCell className="controlTd" align="center">
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={() => selectBrother(key)}
                              >
                                <Icon style={{ fontSize: "1.2rem" }}>edit</Icon>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </>

            <>
              <Grid item xs={12} style={styles.title}>
                กรณีผู้ปกครองมิใช่บิดา-มารดา โปรดระบุด้านล่าง
                <div style={styles.subTitle}>
                  Specific Guardian (Not father and mother)
                </div>
              </Grid>
              <GridSelectItem
                size={[12, 3, 3, 3]}
                label="Prefix"
                id="guardian_prefix"
                value={form.guardian_prefix}
                changeValue={changeValue}
                options={state.prefixs}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="First name"
                placeholder="ชื่อ"
                id="guardian_first_name"
                value={form.guardian_first_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Middle name"
                placeholder="ชื่อกลาง"
                id="guardian_middle_name"
                value={form.guardian_middle_name}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Last name"
                placeholder="นามสกุล"
                id="guardian_last_name"
                value={form.guardian_last_name}
                changeValue={changeValue}
              />

              <GridSelectItem
                size={[12, 3, 3, 3]}
                label="Prefix (EN)"
                id="guardian_prefix_en"
                value={form.guardian_prefix_en}
                changeValue={changeValue}
                options={state.prefixs_en}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="First name (EN)"
                placeholder="ชื่อ (ภาษาอังกฤษ)"
                id="guardian_first_name_en"
                value={form.guardian_first_name_en}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Middle name (EN)"
                placeholder="ชื่อกลาง (ภาษาอังกฤษ)"
                id="guardian_middle_name_en"
                value={form.guardian_middle_name_en}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 3, 3, 3]}
                label="Last name (EN)"
                placeholder="นามสกุล (ภาษาอังกฤษ)"
                id="guardian_last_name_en"
                value={form.guardian_last_name_en}
                changeValue={changeValue}
              />

              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Nationality"
                id="guardian_nationality"
                value={form.guardian_nationality || "-"}
                changeValue={changeValue}
                options={state.nationalities}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Origin"
                id="guardian_origin"
                value={form.guardian_origin || "-"}
                changeValue={changeValue}
                options={state.origins}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Religion"
                id="guardian_religion"
                value={form.guardian_religion || "-"}
                changeValue={changeValue}
                options={state.religions}
              />

              <GridInputItem
                size={[12, 4, 4, 4]}
                label="Tel"
                placeholder="โทรศัพท์มือถือ"
                id="guardian_phone"
                value={form.guardian_phone}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="e-Mail"
                placeholder="อีเมล"
                id="guardian_email"
                value={form.guardian_email}
                changeValue={changeValue}
              />
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="อาชีพ"
                id="guardian_job"
                value={form.guardian_job}
                changeValue={changeValue}
                options={state.jobs}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="Workplace"
                placeholder="สถานที่ทำงาน"
                id="guardian_workplace"
                value={form.guardian_workplace}
                changeValue={changeValue}
              />
              <GridInputItem
                type="number"
                size={[12, 4, 4, 4]}
                label="Salary"
                placeholder="เงินเดือน"
                id="guardian_income"
                value={form.guardian_income}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 4, 4, 4]}
                label="Relation"
                placeholder="ความสัมพันธ์"
                id="guardian_relation"
                value={form.guardian_relation}
                changeValue={changeValue}
              />
            </>
          </Grid>
          <br />
        </>
      )}
    </div>
  );
}
