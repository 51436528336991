import React, { useState, useEffect } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { getPermission } from "../js/main";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Users({ page }) {
  const [profile, setProfile] = useState();
  const [users, setUsers] = useState();
  const [user, setUser] = useState({});
  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      setProfile(profile);
    })();
  }, []);
  useEffect(() => {
    async function fetchUsers() {
      let users = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "users",
          match: { "permissions.appName": "ca" },
          sort: "usn",
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      setUsers(users.data);
    }
    if (profile) fetchUsers();
  }, [profile]);
  async function save() {
    if (user.usn && user.fullname && user.usn !== "" && user.fullname !== "") {
      let result = await axios.put(Conf.endpoint.tonkla + "/ca/user", user, {
        headers: { Authorization: cookie.access_token },
      });
      if (result.data.error) alert(result.data.error.message);
      else {
        let Users = JSON.parse(JSON.stringify(users));
        if (result.data.is_new === true) {
          Users.result.push(result.data);
          setUsers(Users);
        } else {
          let i = Users.result.findIndex((j) => j._id === user._id);
          if (i > -1) {
            Users.result[i].usn = user.usn;
            Users.result[i].fullname = user.fullname;
            setUsers(Users);
          }
        }
        alert("Save Completed");
        setUser({});
      }
    } else {
      alert("Please fill completed information");
    }
  }
  async function deleteUser() {
    if (user._id) {
      if (window.confirm("Confirm to delete user")) {
        let result = await axios.delete(
          Conf.endpoint.tonkla + "/ca/user/" + user._id,
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (result.data.error) alert(result.data.error.message);
        else {
          let Users = JSON.parse(JSON.stringify(users));
          Users.result = Users.result.filter((j) => j._id !== user._id);
          setUsers(Users);
          setUser({});
        }
      }
    }
  }
  return (
    <div className="content">
      {users && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ width: "80px" }}
                    className="td"
                  >
                    No.
                  </TableCell>
                  <TableCell align="center" className="td">
                    Username (@tonkla.ac.th)
                  </TableCell>
                  <TableCell align="center" className="td">
                    Full name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ width: "80px" }}
                    className="td"
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="controlTd" align="center">
                    <IconButton
                      color="secondary"
                      component="span"
                      disabled={!user._id}
                      onClick={deleteUser}
                    >
                      <Icon style={{ fontSize: "1.3rem" }}>clear</Icon>
                    </IconButton>
                  </TableCell>
                  <TableCell className="controlTd">
                    <TextField
                      size="small"
                      id="usn"
                      variant="outlined"
                      value={user.usn || ""}
                      onChange={(e) =>
                        setUser({ ...user, [e.target.id]: e.target.value })
                      }
                      fullWidth
                      placeholder="ชื่อผู้ใช้งาน"
                    />
                  </TableCell>
                  <TableCell className="controlTd">
                    <TextField
                      size="small"
                      value={user.fullname || ""}
                      id="fullname"
                      variant="outlined"
                      onChange={(e) =>
                        setUser({ ...user, [e.target.id]: e.target.value })
                      }
                      fullWidth
                      placeholder="ชื่อ-นามสกุล"
                    />
                  </TableCell>
                  <TableCell className="controlTd" align="center">
                    <IconButton color="primary" component="span" onClick={save}>
                      <Icon style={{ fontSize: "1.3rem" }}>save</Icon>
                    </IconButton>
                  </TableCell>
                </TableRow>
                {users.result.map((u, key) => (
                  <TableRow key={key}>
                    <TableCell className="td" align="center">
                      {key + 1}
                    </TableCell>
                    <TableCell className="td">{u.usn}</TableCell>
                    <TableCell className="td">{u.fullname}</TableCell>
                    <TableCell className="controlTd" align="center">
                      {user._id !== u._id ? (
                        <>
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={() => setUser(u)}
                          >
                            <Icon style={{ fontSize: "1.3rem" }}>edit</Icon>
                          </IconButton>
                        </>
                      ) : (
                        <>
                          <IconButton
                            color="inherit"
                            component="span"
                            onClick={() => setUser({})}
                          >
                            <Icon style={{ fontSize: "1.3rem", color: "#0c0" }}>
                              undo
                            </Icon>
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
}
