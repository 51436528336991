import firebase from "firebase/compat/app";
import { fbKey, fbAuthDomain } from "./Conf";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: fbKey,
  authDomain: fbAuthDomain,
  //   projectId: 'YOUR_PROJECT_ID',
  //   storageBucket: 'YOUR_STORAGE_BUCKET',
  //   messagingSenderId: 'YOUR_MESSAGING_SENDER_ID',
  //   appId: 'YOUR_APP_ID',
  databaseURL: "https://tonkla-a1378.firebaseio.com",
  projectId: "tonkla-a1378",
  storageBucket: "tonkla-a1378.appspot.com",
  messagingSenderId: "1094459691353",
  appId: "1:1094459691353:web:df251052b61fb6c9babc48",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
