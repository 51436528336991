import React, { useEffect, useState } from "react";
import { getPermission, jsonSort } from "../js/main";
import PageNameBox from "../components/PageNameBox";
// import RegistrationForm from "../components/RegistrationForm";
import FormStudentInfo from "../forms/self/FormStudentInfo";
import FormFamilyInfo from "../forms/self/FormFamilyInfo";
import FormPickupPerson from "../forms/self/FormPickupPerson";
import FormHealthy from "../forms/self/FormHealthy";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import Conf from "../Conf";
import RegisterList from "../components/RegisterList";
import { useNavigate, useParams } from "react-router-dom";
// import FamilyInfoForm from "../components/FamilyInfoForm";
// import HealthyForm from "../components/HealthyForm";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Registration(props) {
  const params = useParams();
  const [profile, setProfile] = useState();
  const [state, setState] = useState({ showPage: null });
  const [filter, setFilter] = useState({
    collection: "registers",
    match: {},
    keyword: {
      value: "",
      fields: [
        "contact_first_name",
        "contact_last_name",
        "first_name",
        "last_name",
        "nickname",
        "phone",
        "email",
      ],
    },
    selectAll: false,
    limit: 50,
  });
  const [registers, setRegisters] = useState();
  useEffect(() => {
    const renderPage = async () => {
      let p = await getPermission();
      if (p) {
        setProfile(p);
      } else window.location = process.env.PUBLIC_URL + "/";
    };
    renderPage();
  }, []);
  useEffect(() => {
    if (params.register_id) setState({ showPage: "tab" });
    else setState({ showPage: "search" });
  }, [params]);

  useEffect(() => {
    async function getStudents() {
      let Filter = JSON.parse(JSON.stringify(filter));
      if (Filter.selectAll === true) {
        Filter.match = {
          $and: [
            { "status_date.label": { $ne: "ไม่ผ่าน" } },
            { interview_therapist: { $exists: true } },
            { interview_staff: { $exists: true } },
          ],
        };
      } else {
        Filter.match = {
          $and: [
            { "status_date.label": { $ne: "ไม่ผ่าน" } },
            { "status_date.label": { $ne: "มอบตัว" } },
            { interview_therapist: { $exists: true } },
            { interview_staff: { $exists: true } },
          ],
        };
      }
      let registers = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        Filter,
        { headers: { Authorization: cookie.access_token } }
      );
      setRegisters(registers.data);
    }
    if (filter) getStudents();
  }, [filter]);

  async function goSearch(e) {
    let keyword = JSON.parse(JSON.stringify(filter.keyword));
    keyword.value = e.target.value;
    setFilter({ ...filter, keyword: keyword });
    setState({ ...state, showPage: "search" });
  }
  return (
    <div>
      {profile && (
        <div className="content">
          <br />
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <PageNameBox page={props.page} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} align="right">
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Search"
                placeholder="ค้นหา..."
                variant="outlined"
                size="small"
                onKeyUp={(e) => e.key === "Enter" && goSearch(e)}
              />
              <br />

              <FormControlLabel
                control={
                  <Checkbox
                    id="showAll"
                    checked={filter.selectAll}
                    onChange={(e) => {
                      setFilter({ ...filter, selectAll: e.target.checked });
                    }}
                  />
                }
                label={<div>แสดงทั้งหมด</div>}
              />
            </Grid>
          </Grid>

          <br />
          {
            {
              search: (
                <SearchArea registers={registers} history={props.history} />
              ),
              tab: <TabArea register_id={params.register_id || null} />,
            }[state.showPage]
          }
        </div>
      )}
    </div>
  );
}
function SearchArea(props) {
  const navigate = useNavigate();
  const { registers } = props;
  return (
    <div>
      {registers && (
        <RegisterList
          registers={registers}
          state={{ showDate: "registration_date", checkStatus: ["registered"] }}
          onSelect={(register) => {
            navigate(
              process.env.PUBLIC_URL + "/form/registration/" + register._id
            );
          }}
        />
      )}
    </div>
  );
}

function TabArea(props) {
  const [state, setState] = useState({ showTab: 0, openMailDialog: false });
  const [form, setForm] = useState({});
  const [formHealthy, setFormHealthy] = useState({});
  const [options, setOptions] = useState();
  const [formPickup, setFormPickup] = useState([{}, {}, {}, {}]);
  useEffect(() => {
    (async () => {
      let register_id = props.register_id;
      let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
      let Register = await axios.get(
        Conf.endpoint.tonkla + "/ca/register/" + register_id,
        { headers: { Authorization: OTP.data } }
      );
      if (Register.data.error) {
        alert(Register.data.error.message);
      } else setForm(Register.data);

      OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
      let healthy = await axios.get(
        Conf.endpoint.tonkla + "/ca/healthy/register/" + register_id,
        { headers: { Authorization: OTP.data } }
      );
      if (healthy.data && healthy.data._id) {
        healthy = healthy.data;
      } else healthy = {};
      setFormHealthy(healthy);

      let pickup = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "pickup_persons",
          match: { register_id: register_id },
          sort: { priority: 1 },
        },
        { headers: { Authorization: cookie.access_token } }
      );
      if (pickup.data.result && pickup.data.result.length > 0) {
        setFormPickup(pickup.data.result);
      } else setFormPickup([{}, {}, {}, {}]);
    })();
  }, [props.register_id]);

  useEffect(() => {
    (async () => {
      if (!options) {
        let Options = {};
        let result = await axios.post(Conf.endpoint.tonkla + "/options");
        for (let option of result.data) {
          let type = option.type;
          if (!Options[type]) Options[type] = [];
          Options[type].push(option);
        }
        Options.prefix_alt = [];
        let prefixs = JSON.parse(
          JSON.stringify(Options.prefix.filter((j) => j.label_alt !== ""))
        );
        for (let option of prefixs) {
          option.label = option.label_alt;
          let i = Options.prefix_alt.findIndex((j) => j.label === option.label);
          if (i < 0) Options.prefix_alt.push(option);
        }

        Options.student_prefix_alt = [];
        let student_prefixs = JSON.parse(
          JSON.stringify(
            Options.student_prefix.filter((j) => j.label_alt !== "")
          )
        );
        for (let option of student_prefixs) {
          option.label = option.label_alt;
          let i = Options.student_prefix_alt.findIndex(
            (j) => j.label === option.label
          );
          if (i < 0) Options.student_prefix_alt.push(option);
        }

        Options.origin = await jsonSort(Options.origin, "label");
        Options.nationality = await jsonSort(Options.nationality, "label");
        setOptions(Options);
      }
    })();
  }, [options]);
  async function save() {
    let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
    let Register = await axios.put(
      Conf.endpoint.tonkla + "/ca/register/registration",
      form,
      { headers: { Authorization: cookie.access_token } }
    );
    if (Register.data.error) alert(Register.data.error.message);
    else alert("Save completed");
  }
  async function saveHealthy() {
    let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
    let send = formHealthy;
    if (props && props.register_id) send.register_id = props.register_id;
    let Register = await axios.put(Conf.endpoint.tonkla + "/ca/healthy", send, {
      headers: { Authorization: cookie.access_token },
    });
    console.log(props.register_id);
    if (Register.data.error) alert(Register.data.error.message);
    else alert("Save completed");
  }
  /*
  async function upload() {
    return new Promise(async (resolve) => {
      let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
      let formData = new FormData();
      let fileNo = 0;
      for (let i = 0; i < 4; i++) {
        let file = document.getElementById("person-img-" + i).files[0];
        if (file) {
          formData.append(fileNo, file, form._id + "_" + i);
          console.log(form._id + "_" + i);
          fileNo++;
        }
      }
      if (fileNo > 0) {
        let result = await axios.post(
          "https://tca.tonkla.ac.th/upload/upload.php?register_id=" + form._id,
          formData,
          { headers: { Authorization: cookie.access_token } }
        );
        let r = [];
        for (let file of result.data) {
          let arr = file.split("/");
          let i = arr[1].split(".");
          i = i[0].split("_").pop();
          i = parseInt(i);
          r[i] = file;
        }
        resolve(r);
      } else resolve([]);
    });
  }
  */

  async function upload() {
    return new Promise(async (resolve) => {
      let persons = JSON.parse(JSON.stringify(formPickup));
      let priority = 1;
      for (let person of persons) {
        person.priority = priority++;
        if (person.file) {
          let path = await axios.put(
            Conf.endpoint.fileUpload,
            { file_stream: person.file, file_type: "pickup" },
            { headers: { Authorization: cookie.access_token } }
          );
          person.photo = "file.php?id=" + path.data._id;
          delete person.file;
        }
      }
      resolve(persons);
    });
  }

  async function savePickup() {
    let checkEmpty = await formPickup.findIndex(
      (j) => j.first_name && j.first_name !== ""
    );
    if (checkEmpty < 0)
      alert("กรุณากรอกข้อมูลผู้ปกครองรับส่งนักเรียนอย่างน้อย 1 คน");
    else {
      let persons = await upload();
      let pickups = await axios.put(
        Conf.endpoint.tonkla + "/ca/register/form/pickup",
        { register_id: form._id, persons: persons },
        { headers: { Authorization: cookie.access_token } }
      );
      if (pickups.data.error) alert(pickups.data.error.message);
      else alert("Save completed");
    }
  }

  async function attend() {
    let cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
    let Attend = await axios.put(
      Conf.endpoint.tonkla + "/ca/register/attend/" + form._id,
      form,
      { headers: { Authorization: cookie.access_token } }
    );
    if (Attend.data.error) alert(Attend.data.error.message);
    else {
      alert("Attended");
    }
  }
  return (
    <div>
      <SendMailDialog
        state={{ open: state.openMailDialog, email: form.email }}
        onClose={() => setState({ ...state, openMailDialog: false })}
        form={form}
      />
      {options && (
        <Box
          sx={{ width: "100%" }}
          component={Paper}
          style={{ padding: "10px 10px" }}
        >
          <Tabs
            value={state.showTab}
            onChange={(e, val) => setState({ ...state, showTab: val })}
          >
            <Tab label={<div style={{ fontSize: ".9rem" }}>ใบมอบตัว</div>} />
            <Tab
              label={<div style={{ fontSize: ".9rem" }}>ข้อมูลครอบครัว</div>}
            />
            <Tab
              label={
                <div style={{ fontSize: ".9rem" }}>
                  ด้านสุขภาพพฤติกรรมและอุปนิสัย
                </div>
              }
            />
            <Tab
              label={
                <div style={{ fontSize: ".9rem" }}>ผู้ปกครองรับส่งนักเรียน</div>
              }
            />
          </Tabs>
          <br />
          {
            {
              // 0: <RegistrationForm state={{ register_id: props.register_id }} />,
              0: (
                <div style={{ padding: "0px 10px" }}>
                  <FormStudentInfo
                    changeForm={(id, value) =>
                      setForm({ ...form, [id]: value })
                    }
                    changeFields={(values) => {
                      setForm({ ...form, ...values });
                    }}
                    state={{
                      student_prefixs: options.student_prefix,
                      student_prefixs_en: options.student_prefix_alt,
                      prefixs: options.prefix,
                      prefixs_en: options.prefix_alt,
                      nationalities: options.nationality,
                      origins: options.origin,
                      religions: options.religion,
                      register_documents: options.register_document,
                    }}
                    student={form}
                  />
                  <div style={{ margin: "30px auto 20px" }}>
                    <Grid container>
                      <Grid item xs={12} xm={12} md={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={save}
                          style={{ marginRight: "10px", width: "150px" }}
                          startIcon={<Icon>save</Icon>}
                        >
                          Save
                        </Button>
                        {form.status_date &&
                          form.status_date.findIndex(
                            (j) => j.label === "มอบตัว"
                          ) < 0 && (
                            <Button
                              variant="contained"
                              className="bg_orange"
                              color="error"
                              onClick={attend}
                              style={{ marginRight: "10px", width: "150px" }}
                              startIcon={<Icon>send</Icon>}
                            >
                              Attend
                            </Button>
                          )}
                      </Grid>
                      <Grid item xs={12} xm={12} md={6} align="right">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() =>
                            (document.getElementById("printFrame").src =
                              process.env.PUBLIC_URL +
                              "/print/registration/" +
                              form._id)
                          }
                          style={{ marginRight: "10px" }}
                          startIcon={<Icon>print</Icon>}
                        >
                          Print
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() =>
                            setState({ ...state, openMailDialog: true })
                          }
                          startIcon={<Icon>mail</Icon>}
                        >
                          Send Form
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ),

              // 1: <FamilyInfoForm state={{ register_id: props.register_id }} />,

              1: (
                <div style={{ padding: "0px 10px" }}>
                  <FormFamilyInfo
                    state={{
                      prefixs: options.prefix,
                      prefixs_en: options.prefix_alt,
                      nationalities: options.nationality,
                      origins: options.origin,
                      religions: options.religion,
                      person_status: options.person_status,
                      jobs: options.job,
                      family_status: options.family_status,
                    }}
                    form={form}
                    changeForm={(id, value) =>
                      setForm({ ...form, [id]: value })
                    }
                    changeFields={(values) => {
                      setForm({ ...form, ...values });
                    }}
                  />
                  <Grid container style={{ paddingBottom: "30px" }}>
                    <Grid item xs={12} xm={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={save}
                        startIcon={<Icon>save</Icon>}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ),
              2: (
                <div>
                  <FormHealthy
                    // changeForm={() => console.log(null)}
                    // changeFields={() => console.log(null)}
                    form={formHealthy}
                    changeForm={(id, value) =>
                      setFormHealthy({ ...formHealthy, [id]: value })
                    }
                    changeFields={(values) => {
                      setFormHealthy({ ...formHealthy, ...values });
                    }}
                  />

                  <Grid container style={{ padding: "20px 0px" }}>
                    <Grid item xs={12} xm={12}>
                      <Button
                        variant="contained"
                        style={{ width: "150px" }}
                        color="primary"
                        onClick={saveHealthy}
                        startIcon={<Icon>save</Icon>}
                      >
                        Save
                      </Button>

                      <Button
                        variant="contained"
                        color="success"
                        onClick={() =>
                          (document.getElementById("printFrame").src =
                            process.env.PUBLIC_URL +
                            "/print/healthy/" +
                            form._id)
                        }
                        style={{ marginLeft: "10px", width: "150px" }}
                        startIcon={<Icon>print</Icon>}
                      >
                        Print
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              ),
              3: (
                <div>
                  <FormPickupPerson
                    // form={form}
                    state={{
                      prefixs: options.prefix,
                      prefixs_alt: options.prefix_alt,
                    }}
                    changeForm={(form) => {
                      setFormPickup(form);
                    }}
                    form={formPickup}
                  />
                  <div style={{ textAlign: "right", paddingTop: "20px" }}>
                    <Button
                      variant="contained"
                      endIcon={<Icon>send</Icon>}
                      style={{ width: "180px" }}
                      // onClick={sendForm}
                      onClick={savePickup}
                    >
                      Send Form
                    </Button>
                  </div>
                </div>
              ),
            }[state.showTab]
          }
        </Box>
      )}
      <iframe
        id="printFrame"
        name="printFrame"
        title="printFrame"
        style={{ width: "0px", height: "0px", border: "none" }}
      ></iframe>
    </div>
  );
}

function SendMailDialog({ state, onClose, form }) {
  const [email, setEmail] = useState("");
  useEffect(() => {
    setEmail(state.email);
  }, [state.email]);

  async function sendMail() {
    if (email && email !== "") {
      let send = {
        email: email,
        name: form.prefix + " " + form.first_name + " " + form.last_name,
        register_id: form._id,
      };
      console.log(send);
      let result = await axios.post(
        Conf.endpoint.tonkla + "/ca/register/sendattendform",
        send,
        { headers: { Authorization: cookie.access_token } }
      );
      if (result.data.error) alert(result.data.error.message);
      else {
        alert("Send completed");
        onClose();
      }
    }
  }
  return (
    <div>
      <Dialog open={state.open}>
        <DialogTitle id="alert-dialog-title">ส่งแบบฟอร์มมอบตัว</DialogTitle>
        <DialogContent>
          <TextField
            label="e-Mail"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            style={{ minWidth: "350px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button autoFocus onClick={sendMail}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
