import React, { Component } from "react";
import axios from "axios";
import Conf from "../Conf";
// import { influence_factors } from "../const";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import { getPermission } from "../js/main";
import { useParams } from "react-router-dom";

// import { DriveEtaRounded } from "@material-ui/icons";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default class RegisterApplication extends Component {
  state = {
    profile: this.props.profile,
    form: {},
  };
  params = useParams();
  async componentDidMount() {
    let p = await getPermission();
    if (!p) window.location = process.env.PUBLIC_URL + "/";
    else this.renderPage();
  }
  async renderPage() {
    var form = await this.getRegister();
    if (form._id) this.setState({ form }, () => window.print());
  }
  getRegister() {
    return new Promise(async (resolve) => {
      let result = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          match: { _id: this.params.id },
          collection: "registers",
          limit: 1,
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      if (result.data.count > 0) resolve(result.data.result[0]);
    });
  }
  calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);
    var d = new Date();
    var m = dob.getMonth() + 1;
    var m2 = d.getMonth() + 1;
    if (m2 >= m) m = m2 - m;
    else m = 12 - m + m2;
    var r = {
      year_count: age_dt.getUTCFullYear() - 1970,
      month_count: m,
    };
    return r;
  }
  render() {
    const { form } = this.state;
    return (
      <div>
        <div
          style={{
            width: "21.5cm",
            padding: ".6cm .6cm",
            paddingTop: "0cm",
            margin: "auto",
          }}
        >
          <>
            <div>
              <FieldLabel attr={{ label: "รหัส", width: "1cm" }} />
              <FieldValue
                attr={{
                  label: `${form.form_no || ""}`,
                  width: "4cm",
                  align: "center",
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "วันที่", width: "1cm" }} />
              <FieldValue
                attr={{
                  label: `${form.register_date || "-"}`,
                  width: "4cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{ label: "เวลา", width: "1cm", align: "center" }}
              />
              <FieldValue
                attr={{
                  label: `.`,
                  width: "4.6cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{
                  label: "ครูผู้รับเยี่ยมชม",
                  width: "3.2cm",
                  align: "center",
                }}
              />
              <FieldValue
                attr={{
                  label: form.counselor || `.`,
                  width: "7.1cm",
                  align: "center",
                }}
              />
            </div>
            <div
              style={{
                borderBottom: "1px solid #111",
                paddingBottom: "0.2cm",
                marginBottom: "0.2cm",
              }}
            >
              <FieldLabel attr={{ label: "สัมภาษณ์วันที่", width: "2.3cm" }} />
              <FieldValue
                attr={{
                  label: ".",
                  width: "6.3cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{
                  label: "คัดกรองพัฒนาการวันที่",
                  width: "4cm",
                  align: "center",
                }}
              />
              <FieldValue
                attr={{
                  label: form.counselor || `.`,
                  width: "8.5cm",
                  align: "center",
                }}
              />
            </div>
          </>
          <div
            style={{ fontWeight: "500", fontSize: "14pt", textAlign: "center" }}
          >
            Survey for Tonkla School visitors
          </div>
          <div
            style={{
              fontWeight: "500",
              fontSize: "12pt",
              textAlign: "center",
              color: "#666",
            }}
          >
            (แบบสอบถามเยี่ยมชม)
          </div>
          <div style={{ fontSize: "13pt" }}>1. Visitor information</div>
          <>
            <FieldAltLabel
              attr={{
                label: "ข้อมูลผู้เยี่ยมชม",
                width: "2.8cm",
                align: "center",
              }}
            />
            <div>
              <FieldLabel
                attr={{ label: "Name Mr./Mrs./Miss", width: "3.5cm" }}
              />
              <FieldValue
                attr={{
                  label: `${form.contact_prefix} ${form.contact_first_name} ${form.contact_last_name}`,
                  width: "9cm",
                }}
              />
              <FieldLabel
                attr={{ label: "Mobile Number", width: "3cm", align: "center" }}
              />
              <FieldValue
                attr={{
                  label: `${form.phone}`,
                  width: "5.4cm",
                  align: "center",
                }}
              />
            </div>
            <div>
              <FieldAltLabel
                attr={{
                  label: "(ชื่อ-สกุล) นาย / นาง / นางสาว ",
                  width: "13cm",
                }}
              />
              <FieldAltLabel
                attr={{
                  label: "(โทร)",
                  width: "3cm",
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "Address", width: "1.7cm" }} />
              <FieldValue
                attr={{
                  label: (
                    <>
                      {form.house_number && form.house_number !== "" && (
                        <span style={{ marginRight: "15px" }}>
                          เลขที่ {form.house_number}
                        </span>
                      )}
                      {form.village && form.village !== "" && (
                        <span style={{ marginRight: "15px" }}>
                          หมู่บ้าน {form.village}
                        </span>
                      )}
                      {form.moo && form.moo !== "" && (
                        <span style={{ marginRight: "15px" }}>
                          หมู่ {form.moo}
                        </span>
                      )}
                      <span style={{ marginRight: "15px" }}>{form.area}</span>
                      <span style={{ marginRight: "15px" }}>
                        {form.district}
                      </span>
                      <span style={{ marginRight: "15px" }}>
                        {form.province}
                      </span>
                      <span style={{ marginRight: "15px" }}>
                        {form.zip_code}
                      </span>
                    </>
                  ),
                  // `${form.house_number || ""} ${form.village || ""} ${
                  //   form.moo || ""
                  // }`,
                  width: "19.4cm",
                }}
              />
            </div>
            <div>
              <FieldAltLabel
                attr={{
                  label: "ที่อยู่",
                  width: "12.7cm",
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "Sub district", width: "2.2cm" }} />
              <FieldValue
                attr={{
                  label: `${form.area}`,
                  width: "4.5cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{ label: "District", width: "2cm", align: "center" }}
              />
              <FieldValue
                attr={{
                  label: `${form.district}`,
                  width: "4.8cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{ label: "Province", width: "2cm", align: "center" }}
              />
              <FieldValue
                attr={{
                  label: `${form.province}`,
                  width: "5.2cm",
                  align: "center",
                }}
              />
            </div>
            <div>
              <FieldAltLabel attr={{ label: "ตำบล", width: "7.3cm" }} />
              <FieldAltLabel attr={{ label: "อำเภอ", width: "6.9cm" }} />
              <FieldAltLabel attr={{ label: "จังหวัด", width: "2cm" }} />
            </div>
            <div>
              <FieldLabel
                attr={{ label: "Domicile/Habitation", width: "3.8cm" }}
              />
              <FieldValue
                attr={{
                  label: form.hometown,
                  width: "17.3cm",
                }}
              />
            </div>
            <div>
              <FieldAltLabel attr={{ label: "ภูมิลำเนา", width: "21cm" }} />
            </div>
            <div>
              <FieldLabel attr={{ label: "Career", width: "1.5cm" }} />
              <FieldValue
                attr={{
                  label: form.job,
                  width: "7.8cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{ label: "Workplace", width: "2.7cm", align: "center" }}
              />
              <FieldValue
                attr={{
                  label: form.workplace,
                  width: "8.9cm",
                  align: "center",
                }}
              />
            </div>
            <div>
              <FieldAltLabel attr={{ label: "อาชีพ", width: "9.6cm" }} />
              <FieldAltLabel
                attr={{
                  label: "สถานที่ทำงาน",
                  width: "2.7cm",
                  align: "center",
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "E-mail", width: "1.5cm" }} />
              <FieldValue
                attr={{
                  label: form.email,
                  width: "19.6cm",
                  align: "center",
                }}
              />
            </div>
          </>
          <>
            <div style={{ fontSize: "13pt", marginTop: ".5cm" }}>
              2. Student information
            </div>
            <FieldAltLabel
              attr={{
                label: "ข้อมูลนักเรียน",
                width: "2.5cm",
                align: "center",
              }}
            />
            <div>
              <FieldLabel attr={{ label: "Name Boy/Girl", width: "2.8cm" }} />
              <FieldValue
                attr={{
                  label: `${form.prefix} ${form.first_name} ${
                    form.middle_name || ""
                  } ${form.last_name}`,
                  width: "18.3cm",
                }}
              />
            </div>
            <div>
              <FieldAltLabel
                attr={{
                  label: "ชื่อนักเรียน เด็กชาย / เด็กหญิง",
                  width: "5.8cm",
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "Nickname", width: "2cm" }} />
              <FieldValue
                attr={{
                  label: form.nickname,
                  width: "5.2cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{
                  label: "Date of bith",
                  width: "2.2cm",
                  align: "center",
                }}
              />
              <FieldValue
                attr={{
                  label: form.birth_date,
                  width: "3.5cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{ label: "Age", width: "1.2cm", align: "center" }}
              />
              <FieldValue
                attr={{
                  label: this.calculate_age(new Date(form.birth_date))
                    .year_count,
                  width: "1.6cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{ label: "Years", width: "1.2cm", align: "center" }}
              />
              <FieldValue
                attr={{
                  label: this.calculate_age(new Date(form.birth_date))
                    .month_count,
                  width: "1.6cm",
                  align: "center",
                }}
              />
              <FieldLabel
                attr={{ label: "Months old", width: "2.2cm", align: "center" }}
              />
            </div>
            <div>
              <FieldAltLabel
                attr={{
                  label: "ชื่อเล่น",
                  width: "7.5cm",
                }}
              />
              <FieldAltLabel
                attr={{
                  label: "วันเกิด",
                  width: "6.1cm",
                }}
              />
              <FieldAltLabel
                attr={{
                  label: "อายุ",
                  width: "3cm",
                }}
              />
              <FieldAltLabel
                attr={{
                  label: "ปี",
                  width: "2.9cm",
                }}
              />
              <FieldAltLabel
                attr={{
                  label: "เดือน",
                  width: "1cm",
                }}
              />
            </div>
            <div>
              <FieldLabel
                attr={{ label: "Relationship with visitor", width: "4.5cm" }}
              />
              <FieldValue
                attr={{
                  label: form.relation,
                  width: "16.6cm",
                }}
              />
            </div>
          </>
          <>
            <div style={{ fontSize: "13pt", marginTop: ".5cm" }}>
              3. Where did you find the information about our school (May answer
              more than one)
            </div>
            <FieldAltLabel
              attr={{
                label:
                  "ท่านรู้จักโรงเรียนต้นกล้า หรือได้รับข่าวสารของโรงเรียนจากแหล่งข้อมูลใด (ตอบได้มากกว่า 1 ข้อ)",
                width: "12.8cm",
                align: "center",
              }}
            />

            <div>
              <FieldLabel attr={{ label: "", width: ".5cm" }} />
              <FieldCheckboxValue
                attr={{
                  label: "School website",
                  width: "4.8cm",
                  alt_label: "Website ของโรงเรียน",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("website") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "School Facebook",
                  width: "4.8cm",
                  alt_label: "Facebook ของโรงเรียน",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("facebook") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "School Instagram",
                  width: "4.8cm",
                  alt_label: "Instagram",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("instragram") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "Leaflets",
                  width: "4.8cm",
                  alt_label: "แผ่นพับโฆษณา",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("leaflets") > -1,
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "", width: ".5cm" }} />
              <FieldCheckboxValue
                attr={{
                  label: "Advertisement boards",
                  width: "4.8cm",
                  alt_label: "ป้ายทางเข้าโรงเรียน",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("advertisement boards") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "Event",
                  width: "4.8cm",
                  alt_label: "จัดบูธ",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("event") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "Friend or relative (name)",
                  width: "5.2cm",
                  alt_label: "เพื่อนหรือคนรู้จัก",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("referred") > -1,
                }}
              />
              <FieldValue
                attr={{
                  label: "",
                  width: "5.8cm",
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "", width: ".5cm" }} />
              <FieldCheckboxValue
                attr={{
                  label: "Internet",
                  width: "4.8cm",
                  alt_label: "ค้นหาจากอินเทอร์เน็ต",
                  checked:
                    form.promotion_media &&
                    form.promotion_media.indexOf("internet") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "Other",
                  width: "2cm",
                  alt_label: "อื่น ๆ",
                  checked: form.promotion_other && form.promotion_other !== "",
                }}
              />
              <FieldValue
                attr={{
                  label: form.promotion_other,
                  width: "13.8cm",
                }}
              />
            </div>
          </>
          <>
            <div style={{ fontSize: "13pt", marginTop: ".5cm" }}>
              4. What are the factors that influence your decision about a
              school for your child? (May answer more than one)
            </div>
            <FieldAltLabel
              attr={{
                label:
                  "ปัจจัยที่มีผลต่อการตัดสินใจเลือกโรงเรียน (เลือกได้มากกว่า 1 ข้อ)",
                width: "9cm",
                align: "center",
              }}
            />
            <div>
              <FieldLabel attr={{ label: "", width: ".5cm" }} />
              <FieldCheckboxValue
                attr={{
                  label: "Learning approach and style of teaching",
                  width: "8cm",
                  alt_label: "แนวทางการจัดการศึกษาและรูปแบบการจัดการเรียนรู้",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf(
                      "Teaching and learning philosophy"
                    ) > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "Academicals",
                  width: "4.5cm",
                  alt_label: "งานวิชาการ",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("Academic curriculum") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "English",
                  width: "3cm",
                  alt_label: "ภาษาอังกฤษ",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("English program") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "School Fees",
                  width: "3.7cm",
                  alt_label: "ค่าธรรมเนียมการเรียน",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("Tuition fee") > -1,
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "", width: ".5cm" }} />
              <FieldCheckboxValue
                attr={{
                  label: "Children are happy with learning",
                  width: "8cm",
                  alt_label: "บุตรหลานมีความสุขในการเรียน",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("Child’s happiness") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "The atmosphere",
                  width: "4.5cm",
                  alt_label: "อาคารสถานที่และบรรยากาศ",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("School environment") > -1,
                }}
              />

              <FieldCheckboxValue
                attr={{
                  label: "Student care",
                  width: "6cm",
                  alt_label: "การดูแลนักเรียนดี",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("Student care") > -1,
                }}
              />
            </div>
            <div>
              <FieldLabel attr={{ label: "", width: ".5cm" }} />
              <FieldCheckboxValue
                attr={{
                  label: "Conveniently close to home",
                  width: "8cm",
                  alt_label: "เดินทางสะดวก ใกล้บ้าน",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("Location") > -1,
                }}
              />
              <FieldCheckboxValue
                attr={{
                  label: "Other",
                  width: "2cm",
                  alt_label: "อื่น ๆ",
                  checked:
                    form.influence_factors &&
                    form.influence_factors.indexOf("other") > -1,
                }}
              />
              <FieldValue
                attr={{
                  label: form.influence_factor_other,
                  width: "10.6cm",
                }}
              />
            </div>
          </>
        </div>
      </div>
    );
  }
}

class FieldLabel extends Component {
  render() {
    const { attr } = this.props;
    return (
      <div
        style={{
          fontSize: "11pt",
          //   border: "1px solid #ccc",
          width: attr.width,
          display: "inline-block",
          textAlign: attr.align || "left",
        }}
      >
        {attr.label}
      </div>
    );
  }
}

class FieldAltLabel extends Component {
  render() {
    const { attr } = this.props;
    return (
      <div
        style={{
          fontWeight: "300",
          color: "#666",
          fontSize: "9pt",
          //   border: "1px solid #ccc",
          width: attr.width,
          display: "inline-block",
          textAlign: attr.align || "left",
        }}
      >
        {attr.label}
      </div>
    );
  }
}

class FieldValue extends Component {
  render() {
    const { attr } = this.props;
    return (
      <div
        style={{
          fontSize: "11pt",
          fontWeight: "300",
          borderBottom: "1px dotted #999",
          borderWidth: "thin",
          width: attr.width,
          display: "inline-block",
          paddingLeft: ".1cm",
          paddingRight: ".1cm",
          textAlign: attr.align || "left",
        }}
      >
        {attr.label || ""}
      </div>
    );
  }
}

class FieldCheckboxValue extends Component {
  render() {
    const { attr } = this.props;
    return (
      <div
        style={{
          fontSize: "11pt",
          fontWeight: "300",
          width: attr.width,
          display: "inline-block",
          textAlign: attr.align || "left",
        }}
      >
        <Grid container>
          <Grid item style={{ paddingTop: "2px" }}>
            {attr.checked === true ? (
              <Icon style={{ marginRight: ".2cm", fontSize: "1.4em" }}>
                done
              </Icon>
            ) : (
              <Icon style={{ marginRight: ".2cm", fontSize: "1.4em" }}>
                check_box_outline_blank
              </Icon>
            )}
          </Grid>
          <Grid item>{attr.label || "-"}</Grid>
        </Grid>
        <div
          style={{
            paddingLeft: ".7cm",
            fontWeight: "300",
            color: "#666",
            fontSize: "9pt",
          }}
        >
          {attr.alt_label}
        </div>
      </div>
    );
  }
}
