import React, { useEffect, useState } from "react";
import { FormArea } from "./Application";
import { getPermission } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import DialogChangeStatus from "../components/DialogChangeStatus";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import Conf from "../Conf";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RegisterList from "../components/RegisterList";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import { Stack } from "@mui/system";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Student(props) {
  const params = useParams();
  const [keyword, setKeyword] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [profile, setProfile] = useState();
  const [state, setState] = useState();
  const [form, setForm] = useState();
  // const [levels, setLevels] = useState();
  // const [focusYear, setFocusYear] = useState([]);
  const getProfile = async () => {
    let p = await getPermission();
    if (p) setProfile(p);
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    const renderPage = async () => {
      getProfile();
    };
    renderPage();
    // getGroups();
  }, []);
  useEffect(() => {
    async function getInterview() {
      return new Promise(async (resolve) => {
        let register_id = params.id;
        if (register_id) {
          let interview = await axios.post(
            Conf.endpoint.tonkla + "/ca/query",
            {
              collection: "interviews",
              match: { register_id: register_id },
              limit: 1,
              select: "level grade_year term",
            },
            { headers: { Authorization: cookie.access_token } }
          );
          if (interview.data.count > 0) resolve(interview.data.result[0]);
          else resolve({});
        } else resolve({});
      });
    }
    async function getForm() {
      let register_id = params.id;
      let Form = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        { collection: "registers", match: { _id: register_id }, limit: 1 },
        { headers: { Authorization: cookie.access_token } }
      );
      if (Form.data.count && Form.data.count > 0) {
        Form = Form.data.result[0];
        if (!Form.level) Form.register_level = "-";
        else {
          Form.register_level = JSON.stringify({
            name: Form.level,
            year: Form.grade_year,
          });
        }
        if (!Form.level && !Form.grade_year) {
          let interview = await getInterview();
          Form.term = interview.term || "-";
          interview = JSON.stringify({
            name: interview.level,
            year: interview.grade_year,
          });
          Form.register_level = interview;
        }
        setForm(Form);
      }
    }
    if (params && params.id) {
      getForm();
      setState({ page: "form", showChangeSatus: false });
    } else {
      setState({ page: "search", showChangeSatus: false });
    }
  }, [params]);
  async function save() {
    let send = JSON.parse(JSON.stringify(form));
    let level = {};
    if (form.register_level && form.register_level !== "-") {
      level = JSON.parse(form.register_level);
      send.level = level.name;
      send.grade_year = level.year;
    } else {
      send.level = null;
      send.grade_year = null;
    }

    let deleteFields = [
      "contact_first_name",
      "contact_last_name",
      "contact_prefix",
      "counselor_note",
      "email",
      "hometown",
      "influence_factor_other",
      "influence_factors",
      "isAttended",
      "job",
      "old_school",
      "other_relation",
      "phone",
      "promotion_media",
      "promotion_other",
      "province",
      "register_level",
      "relation",
      "status_date",
      "village",
      "visit_schedules",
      "workplace",
    ];
    for (let field of deleteFields) {
      delete send[field];
    }
    let register = await axios.put(
      Conf.endpoint.tonkla + "/ca/register",
      send,
      { headers: { Authorization: cookie.access_token } }
    );
    if (register.data.error) alert(register.data.error.message);
    else {
      alert("Save completed");
      let i = register.data.status_date.findIndex(
        (j) => j.label === "สมัครเรียน"
      );
      if (i < 0) {
        setState({ ...state, showChangeSatus: true });
      }
    }
  }
  return (
    <>
      {profile && (
        <div className="content">
          {form && form._id && (
            <DialogChangeStatus
              open={state.showChangeSatus}
              status="สมัครเรียน"
              register_id={form._id}
              onClose={() => setState({ ...state, showChangeSatus: false })}
            />
          )}

          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <Grid container style={{ margin: "40px auto 20px" }}>
            <Grid item xs={12} sm={6} md={8}>
              <PageNameBox page={props.page} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} align="right">
              <Stack sx={{ maxWidth: "300px" }}>
                <TextField
                  fullWidth
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      setKeyword(e.target.value);
                      setState({ ...state, page: "search" });
                    }
                  }}
                  style={{ maxWidth: "300px" }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  label="Search"
                  placeholder="ค้นหา..."
                  variant="outlined"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="showAll"
                      checked={selectAll}
                      onChange={(e) => {
                        setSelectAll(e.target.checked);
                      }}
                    />
                  }
                  label={<div>แสดงทั้งหมด</div>}
                />
              </Stack>
            </Grid>
          </Grid>
          {state.page === "form" && form && (
            <>
              <h2
                style={{
                  fontWeight: 400,
                  margin: "10px auto 20px",
                  textAlign: "center",
                  fontSize: "1.5rem",
                }}
              >
                Application Form (ใบสมัครเรียน)
              </h2>
              {form.register_code && (
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "1.2rem",
                    fontWeight: 400,
                    color: "#00c",
                  }}
                >
                  No. {form.register_code}
                </div>
              )}
              <FormArea
                form={form}
                onChangeInput={(id, value) => {
                  setForm({ ...form, [id]: value });
                }}
                showSubmit={false}
              />
              <h2
                style={{
                  fontWeight: 400,
                  margin: "50px auto 40px",
                  textAlign: "center",
                  fontSize: "1.5rem",
                }}
              >
                For staff (สำหรับเจ้าหน้าที่)
              </h2>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{ paddingBottom: "10px" }}
                  >
                    <DatePicker
                      fullWidth
                      format="YYYY-MM-DD"
                      label="Date of interview"
                      value={
                        form.interview_date ? dayjs(form.interview_date) : null
                      }
                      onChange={(value) =>
                        setForm({
                          ...form,
                          interview_date: moment(new Date(value)).format(
                            "YYYY-MM-DD"
                          ),
                        })
                      }
                      placeholder="วันที่นัดสัมภาษณ์"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          InputLabelProps: { shrink: true },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={2}
                    style={{ paddingBottom: "10px" }}
                  >
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel shrink={true}>Timing</InputLabel>
                      <Select
                        native
                        label="Timing"
                        value={form.interview_time || "-"}
                        onChange={(e) =>
                          setForm({ ...form, interview_time: e.target.value })
                        }
                      >
                        <option value="-"></option>
                        {[
                          "09:00 - 09:30",
                          "09:30 - 10:00",
                          "10:00 - 10:30",
                          "10:30 - 11:00",
                          "11:00 - 11:30",
                          "11:30 - 12:00",
                        ].map((y, key) => (
                          <option value={y} key={key}>
                            {y}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      value={form.staff_attending || ""}
                      onChange={(e) =>
                        setForm({ ...form, staff_attending: e.target.value })
                      }
                      placeholder="ผู้รับสมัคร"
                      label="Staff attending"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <DatePicker
                      fullWidth
                      format="YYYY-MM-DD"
                      label="Register date"
                      value={
                        form.register_date ? dayjs(form.register_date) : null
                      }
                      onChange={(value) =>
                        setForm({
                          ...form,
                          register_date: moment(new Date(value)).format(
                            "YYYY-MM-DD"
                          ),
                        })
                      }
                      placeholder="วันที่รับสมัคร"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          InputLabelProps: { shrink: true },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      value={form.bill_no || ""}
                      onChange={(e) =>
                        setForm({ ...form, bill_no: e.target.value })
                      }
                      placeholder="เลขที่ใบเสร็จ"
                      label="Bill no."
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <DatePicker
                      fullWidth
                      margin="normal"
                      format="YYYY-MM-DD"
                      label="Register bill date"
                      value={dayjs(form.register_bill_date) || new Date()}
                      InputLabelProps={{ shrink: true }}
                      onChange={(value) => {
                        setForm({
                          ...form,
                          register_bill_date: moment(new Date(value)).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }}
                      placeholder="วันที่ซื้อใบสมัคร"
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          InputLabelProps: { shrink: true },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingTop: "20px", paddingBottom: "40px" }}
                  >
                    <Button
                      variant="contained"
                      color="inherit"
                      className="bg_green"
                      size="large"
                      style={{ minWidth: "200px" }}
                      startIcon={<Icon>print</Icon>}
                      onClick={() => {
                        document.getElementById("printFrame").src =
                          process.env.PUBLIC_URL +
                          "/print/application/" +
                          form._id;
                      }}
                    >
                      Print
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    align="right"
                    style={{ paddingTop: "20px", paddingBottom: "40px" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      style={{ minWidth: "200px" }}
                      startIcon={<Icon>save</Icon>}
                      onClick={save}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </LocalizationProvider>
              <iframe
                id="printFrame"
                name="printFrame"
                title="printFrame"
                style={{ width: "0px", height: "0px", border: "none" }}
              ></iframe>
            </>
          )}
          {state.page === "search" && (
            <SearchArea
              history={props.history}
              keyword={keyword}
              state={{ selectAll }}
            />
          )}
        </div>
      )}
    </>
  );
}

function SearchArea(props) {
  const navigate = useNavigate();
  const [filter, setFilter] = useState();
  const [registers, setRegisters] = useState();
  useEffect(() => {
    async function createFilter() {
      let match = {
        $and: [
          { "status_date.label": "เยี่ยมชม" },
          { "status_date.label": { $ne: "สมัครเรียน" } },
          { waiver: { $ne: true } },
        ],
      };
      if (props.state.selectAll === true) {
        match = { "status_date.label": "เยี่ยมชม" };
      }
      let filter = {
        collection: "registers",
        match: match,
        keyword: {
          value: props.keyword || "",
          fields: [
            "contact_first_name",
            "contact_last_name",
            "first_name",
            "last_name",
            "nickname",
            "phone",
            "email",
          ],
        },
        limit: 50,
      };
      setFilter(filter);
    }
    createFilter();
  }, [props.keyword, props.state]);
  useEffect(() => {
    async function getRegisters() {
      let registers = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        filter,
        { headers: { Authorization: cookie.access_token } }
      );
      setRegisters(registers.data);
    }
    if (filter) getRegisters();
  }, [filter]);
  return (
    <div>
      {registers && (
        <RegisterList
          registers={registers}
          state={{
            checkStatus: ["registerPay"],
          }}
          onSelect={(register) => {
            navigate(
              process.env.PUBLIC_URL + "/form/staff/register/" + register._id
            );
          }}
        />
      )}
    </div>
  );
}
