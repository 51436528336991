import React, { useEffect, useState, useRef } from "react";
import { getPermission, getDocument } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import PageNumberBox from "../components/PageNumberBox";
import RegisterList from "../components/RegisterList";
import FormInterviewStaff from "../components/FormInterviewStaff";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import LinearProgress from "@mui/material/LinearProgress";
import SimpleBreadcrumbs from "../components/SimpleBreadcrumbs";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import {
  GridInputItem,
  GridSelectItem,
  RadioForm,
} from "../components/InputForm";
import FormOccupationalTherapist from "../components/FormOccupationalTherapist";

import Checkbox from "@mui/material/Checkbox";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import Conf from "../Conf";
import {
  consider_chooses,
  individuals_responsible,
  Degrees,
  parents_married_status,
} from "../const";
import { useNavigate, useParams } from "react-router-dom";

const styles = {
  groupHead: {
    paddingBottom: "20px",
    fontSize: "1.2rem",
    fontWeight: 400,
    marginTop: "10px",
  },
  subGroupHeader: {
    color: "#888",
    fontSize: ".9rem",
  },
  subLabel: {
    color: "#888",
    fontSize: ".8rem",
  },
  tabFont: {
    color: "#555",
    fontSize: "1rem",
    fontWeight: 400,
  },
};

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
export default function Interview(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const getProfile = async () => {
    let p = await getPermission();
    if (p) setProfile(p);
    else window.location = process.env.PUBLIC_URL + "/";
  };
  const keywordRef = useRef("");
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState();
  const [registers, setRegisters] = useState({});
  const [showPage, setShowPage] = useState();
  // const [interviewId, setInterviewId] = useState();
  const [filter, setFilter] = useState();
  useEffect(() => {
    const renderPage = async () => {
      getProfile();
      if (!params.register_id) {
        setFilter({
          selectAll: false,
          collection: "registers",
          skip: 0,
          limit: 50,
        });
      } else {
        let Register = await getDocument("registers", params.register_id);
        setRegister(Register);
        setShowPage("InterviewForm");
      }
    };
    renderPage();
  }, [params]);

  useEffect(() => {
    async function getRegisters() {
      setLoading(true);
      if (filter.selectAll === true) {
        filter.match = {
          $and: [
            // { interview: { $exists: true } },
            { "status_date.label": "สมัครเรียน" },
          ],
        };
        // filter.match = { "status_date.label": "สมัครเรียน" };
      } else {
        filter.match = {
          $and: [
            // { interview: { $exists: true } },
            { "status_date.label": "สมัครเรียน" },
            {
              $or: [
                { interview_staff: { $exists: false } },
                { interview_therapist: { $exists: false } },
              ],
            },
          ],
        };
      }
      let result = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        filter,
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      setRegisters(result.data);
      setShowPage("RegisterList");
      setLoading(false);
    }
    if (filter) getRegisters();
  }, [filter]);

  function goSearch() {
    let Filter = {
      value: keywordRef.current.value,
      fields: ["contact", "first_name", "last_name", "nickname", "form_id"],
    };
    setFilter({
      collection: "registers",
      selectAll: filter && filter.selectAll ? filter.selectAll : false,
      skip: 0,
      limit: 50,
      keyword: Filter,
    });
  }

  function changePage(value) {
    let S = (value - 1) * filter.limit;
    setFilter({ ...filter, skip: S });
  }
  function selectRegister(register) {
    navigate(process.env.PUBLIC_URL + "/form/interview/" + register._id);
  }
  return (
    <>
      {loading === true && <LinearProgress />}
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
          <Grid container style={{ margin: "25px auto 10px" }}>
            <Grid xs={12} sm={8} item style={{ paddingBottom: "10px" }}>
              {showPage === "RegisterList" && (
                <PageNumberBox
                  count={parseInt((registers.count - 1) / filter.limit) + 1}
                  onChangePage={(value) => changePage(value)}
                  page={parseInt(filter.skip / filter.limit) + 1}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              align="right"
              style={{ marginTop: "5px" }}
            >
              <TextField
                fullWidth
                inputRef={keywordRef}
                size="small"
                label="Search"
                placeholder="ค้นหา..."
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onKeyUp={(e) => e.key === "Enter" && goSearch()}
              />
              <div>
                <FormControlLabel
                  checked={
                    filter && filter.selectAll ? filter.selectAll : false
                  }
                  onChange={() =>
                    setFilter({ ...filter, selectAll: !filter.selectAll })
                  }
                  control={<Checkbox color="primary" />}
                  label={<div>แสดงทั้งหมด</div>}
                />
              </div>
            </Grid>
          </Grid>
          {showPage && (
            <>
              {
                {
                  RegisterList: (
                    <>
                      {registers.result && (
                        <>
                          <RegisterList
                            registers={registers}
                            onSelect={(register) => selectRegister(register)}
                            state={{
                              showDate: "interview_date",
                              checkStatus: ["notPass"],
                            }}
                          />
                        </>
                      )}
                    </>
                  ),
                  InterviewForm: (
                    <>
                      {register && register._id && (
                        <InterviewForm
                          id={register.interview}
                          register={register}
                          history={props.history}
                          onLoading={(value) => setLoading(value)}
                        />
                      )}
                    </>
                  ),
                }[showPage]
              }
            </>
          )}
        </div>
      )}
    </>
  );
}

export function InterviewForm(props) {
  const { register } = props;
  const [form, setForm] = useState({});
  // const [groups, setGroups] = useState([]);
  // const [levels, setLevels] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [level, setLevel] = useState();
  // const [gradeYears, setGradeYears] = useState([]);
  const [showTab, setShowTab] = useState(0);
  /*
  const programs = [
    {
      label: "MEP (Mini English Program)",
    },
    {
      label: "IEP (Integrated English Program)",
    },
    {
      label: "IP (Inter Program)",
    },
  ];*/
  useEffect(() => {
    /*
    async function setDefaultForm() {
      let Groups = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        { collection: "groups", limit: 1000, select: "-student_id" },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      Groups = Groups.data.result;
      let newLevels = [];
      let newGroups = [];
      for (let group of Groups) {
        let i = newLevels.findIndex((j) => j.label === group.level);
        if (i < 0) {
          newLevels.push({ label: group.level, value: group.level });
        }
        newGroups.push({
          label: group.name,
          value: group._id,
          level: group.level,
          level_year: group.level_year,
          level_abbrev: group.level_abbrev,
        });
      }
      // setLevels(newLevels);
      // setGroups(newGroups);
    }
    */
    async function setDefaultProvinces() {
      let Provinces = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "zip_areas",
          limit: 10000,
          select: "province",
          sort: "province",
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      let newProvinces = [];
      for (let province of Provinces.data.result) {
        let i = newProvinces.findIndex((j) => j.label === province.province);
        if (i < 0)
          newProvinces.push({
            value: province.province,
            label: province.province,
          });
      }
      setProvinces(newProvinces);
    }

    // setDefaultForm();
    setDefaultProvinces();
  }, []);
  useEffect(() => {
    function getLevel(register_id) {
      return new Promise(async (resolve) => {
        let register = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          {
            collection: "registers",
            limit: 1,
            select: "level grade_year",
            match: { _id: register_id },
          },
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (register.data && register.data.count > 0) {
          register = register.data.result[0];
          resolve({ level: register.level, grade_year: register.grade_year });
        } else resolve({ level: null, grade_year: null });
      });
    }
    function getForm() {
      return new Promise(async (resolve) => {
        let Form = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          {
            collection: "interviews",
            limit: 1,
            match: { register_id: props.register._id },
          },
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (Form.data.count > 0) {
          Form = Form.data.result[0];
          if (
            !Form.level ||
            Form.level === "" ||
            !Form.grade_year ||
            Form.grade_year === ""
          ) {
            let { level, grade_year } = await getLevel(Form.register_id);
            if (level && (!Form.level || Form.level === "")) Form.level = level;
            if (grade_year && (!Form.grade_year || Form.grade_year === ""))
              Form.grade_year = grade_year;
          }
          resolve(Form);
        } else resolve({});
      });
    }
    async function renderComponent() {
      setLoading(true);
      let consider = await consider_chooses.map((item) => {
        item.checked = false;
        return item;
      });
      let Individuals_responsible = await individuals_responsible.map(
        (item) => {
          item.checked = false;
          return item;
        }
      );
      let DefaultForm = {
        consider_chooses: consider,
        other_consider_choose: "",
        individuals_responsible: Individuals_responsible,
        other_individuals_responsible: "",
      };
      if (props.id !== "new") {
        let Form = await getForm();
        // setLevel(Form.level);
        if (Form.consider_chooses) {
          for (let ch of Form.consider_chooses) {
            let i = DefaultForm.consider_chooses.findIndex(
              (j) => j.value === ch
            );
            if (i > -1) DefaultForm.consider_chooses[i].checked = true;
          }
          for (let ch of Form.individuals_responsible) {
            let i = DefaultForm.individuals_responsible.findIndex(
              (j) => j.value === ch
            );
            if (i > -1) DefaultForm.individuals_responsible[i].checked = true;
          }
          delete Form.consider_chooses;
        }
        delete Form.individuals_responsible;

        DefaultForm = { ...DefaultForm, ...Form };

        setForm(DefaultForm);
      } else {
        setForm(DefaultForm);
      }
      setLoading(false);
    }
    if (props.id) renderComponent();
  }, [props]);
  /*
  useEffect(() => {
    if (form.level && form.level !== "") {
      let gradeYears = [];
      for (let group of groups.filter((j) => j.level === form.level)) {
        if (group.level_year !== "-") {
          let i = gradeYears.findIndex((j) => j.label === group.level_year);
          if (i < 0)
            gradeYears.push({
              label: group.level_year,
              value: group.level_year,
            });
        }
      }
      setGradeYears(gradeYears);
    }
  }, [form.level, groups]);
  */
  async function saveForm() {
    let send = JSON.parse(JSON.stringify(form));
    if (!send.consider_chooses) send.consider_chooses = [];
    send.consider_chooses = send.consider_chooses
      .filter((j) => j.checked === true)
      .map((item) => item.value);
    if (!send.individuals_responsible) send.individuals_responsible = [];

    send.individuals_responsible = send.individuals_responsible
      .filter((j) => j.checked === true)
      .map((item) => item.value);
    send.register_id = register._id;
    let result = await axios.put(
      Conf.endpoint.tonkla + "/ca/register/interview",
      send,
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    if (result.data.error) alert(result.data.error.message);
    else alert("Save Completed");
  }
  const changeTab = (event, newValue) => {
    setShowTab(newValue);
  };
  return (
    <div>
      <SimpleBreadcrumbs
        breadcrumbs={[
          {
            label: "แบบสำรวจข้อมูลเพื่อเข้ารับการสัมภาษณ์",
            href: process.env.PUBLIC_URL + "/form/interview",
          },
          {
            label:
              register.prefix +
              " " +
              register.first_name +
              " " +
              register.last_name,
            href: process.env.PUBLIC_URL + "/form/interview/" + register._id,
          },
          {
            label: "Form",
          },
        ]}
        history={props.history}
      />
      {loading === true && <LinearProgress />}
      <Paper style={{ padding: "10px 15px" }}>
        <Tabs
          value={showTab}
          onChange={changeTab}
          aria-label="basic tabs example"
        >
          <Tab label={<div style={styles.tabFont}>ส่วนของผู้ปกครอง</div>} />
          <Tab label={<div style={styles.tabFont}>ส่วนของเจ้าหน้าที่</div>} />
          <Tab
            label={<div style={styles.tabFont}>ส่วนของนักกิจกรรมบำบัด</div>}
          />
        </Tabs>
        {
          {
            0: (
              <>
                <h1 className="alignCenter" style={{ marginBottom: "10px" }}>
                  Interview Questionnaire
                </h1>
                <div className="alignCenter">
                  (แบบสำรวจข้อมูลเพื่อเข้ารับการสัมภาษณ์)
                </div>
                <br />
                <div className="bold">
                  Please completed all of the following questions before
                  entering the interview. We kindly ask that parents fill in all
                  of the information truthfully.
                </div>
                <div>
                  คำชี้แจง : ขอให้ผู้ปกครองกรอกข้อมูลให้ครบถ้วน
                  และตรงตามความจริง และกรอกข้อมูลให้เรียบร้อยก่อนการเข้าสัมภาษณ์
                </div>
                <br />

                <Grid container spacing={1}>
                  <Grid item xs={12} style={styles.groupHead}>
                    1. Why did you choose Tonkla School? (Specify 3 reasons in
                    order of importance)
                    <div style={styles.subGroupHeader}>
                      คำชี้แจง: ขอให้ผู้ปกครองกรอกข้อมูลให้ครบถ้วน
                      และตรงตามความเป็นจริง
                      และกรอกข้อมูลให้เรียบร้อยก่อนการเข้าสัมภาษณ์
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <CheckForm
                      checkboxs={form.consider_chooses}
                      other={form.other_consider_choose}
                      id="why_choose"
                      onCheck={(key, checked) => {
                        let arr = JSON.parse(
                          JSON.stringify(form.consider_chooses)
                        );
                        arr[key].checked = checked;
                        setForm({ ...form, consider_chooses: arr });
                      }}
                      onChangeOther={(e) =>
                        setForm({
                          ...form,
                          other_consider_choose: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <>
                    <Grid item xs={12} style={styles.groupHead}>
                      2. Student and Parent Information
                      <div style={styles.subGroupHeader}>
                        ข้อมูลนักเรียนและข้อมูลส่วนตัวของครอบครัวนักเรียน
                      </div>
                    </Grid>
                    <Grid item xs={7} style={{ paddingBottom: "15px" }}>
                      <span style={{ fontWeight: 400, marginRight: "15px" }}>
                        Student’s Name :
                      </span>{" "}
                      {register.prefix} {register.first_name}{" "}
                      {register.last_name}
                    </Grid>
                    <Grid item xs={5} style={{ paddingBottom: "15px" }}>
                      <span style={{ fontWeight: 400, marginRight: "15px" }}>
                        Nickname :
                      </span>{" "}
                      {register.nickname}
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: "15px" }}>
                      <span style={{ fontWeight: 400, marginRight: "15px" }}>
                        ระดับชั้น :
                      </span>{" "}
                      {register.level} {register.grade_year}
                      {register.program && (
                        <span style={{ marginLeft: "10px" }}>
                          ({register.program})
                        </span>
                      )}
                    </Grid>
                    {/* <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      2.1 Choose grade level:
                    </Grid>
                    <GridSelectItem
                      size={[12, 6, 3, 3]}
                      label="มีความประสงค์จะสมัครเรียนระดับชั้น"
                      id="level"
                      value={form.level}
                      changeValue={(e) => {
                        // setLevel(e.target.value);
                        setForm({
                          ...form,
                          level: e.target.value,
                          grade_year: "",
                        });
                      }}
                      options={levels}
                    />
                    <GridSelectItem
                      size={[12, 6, 2, 2]}
                      label="ระดับชั้น"
                      id="grade_year"
                      value={form.grade_year}
                      changeValue={(e) =>
                        setForm({ ...form, [e.target.id]: e.target.value })
                      }
                      options={gradeYears}
                    />
                    <GridSelectItem
                      size={[12, 6, 2, 2]}
                      label="ภาคเรียนที่"
                      id="term"
                      value={form.term}
                      changeValue={(e) =>
                        setForm({ ...form, term: e.target.value })
                      }
                      options={[
                        { label: "1", value: "1" },
                        { label: "2", value: "2" },
                      ]}
                    />
                    <GridSelectItem
                      size={[12, 6, 5, 5]}
                      label="Program "
                      id="program"
                      value={form.program}
                      changeValue={(e) => {
                        setForm({ ...form, program: e.target.value });
                      }}
                      options={programs}
                    /> */}
                    <Grid
                      item
                      xs={12}
                      style={{ paddingBottom: "15px", paddingTop: "10px" }}
                    >
                      2.1 Has your child been enrolled in school before?
                      <div style={styles.subLabel}>
                        นักเรียนเคยเข้าโรงเรียนมาก่อนหรือไม่
                      </div>
                    </Grid>
                    <GridInputItem
                      size={[12, 7, 7, 7]}
                      label="Old school name"
                      placeholder="ชื่อโรงเรียเก่า"
                      id="old_school"
                      value={form.old_school || register.old_school}
                      changeValue={(e) =>
                        setForm({ ...form, [e.target.id]: e.target.value })
                      }
                    />
                    <GridSelectItem
                      size={[12, 5, 5, 5]}
                      label="Old school province"
                      id="old_school_province"
                      value={
                        form.old_school_province || register.old_school_province
                      }
                      changeValue={(e) =>
                        setForm({ ...form, [e.target.id]: e.target.value })
                      }
                      options={provinces}
                    />

                    <Grid
                      item
                      xs={12}
                      style={{ paddingBottom: "10px", paddingTop: "10px" }}
                    >
                      2.2 Parent Information
                      <div style={styles.subLabel}>ข้อมูลของบิดา-มารดา</div>
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                      A. Status of Father and Mother
                      <div style={styles.subLabel}>ข้อมูลของบิดา-มารดา</div>
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                      <RadioForm
                        choices={parents_married_status}
                        grids={[12, 6, 3, 3]}
                        value={form.parents_married_status}
                        other={form.other_parents_married_status}
                        onCheck={(value) => {
                          setForm({ ...form, parents_married_status: value });
                        }}
                        onChangeOther={(e) =>
                          setForm({
                            ...form,
                            other_parents_married_status: e.target.value,
                          })
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      style={{ paddingLeft: "20px" }}
                    >
                      If separated or divorced, the student stays primarily with
                      <div style={styles.subLabel}>
                        หากแยกกันอยู่ โดยนักเรียนอยู่กับ
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={8}
                      style={{ paddingLeft: "20px" }}
                    >
                      <RadioGroup
                        row
                        onChange={(e) =>
                          setForm({ ...form, stay_with: e.target.value })
                        }
                        value={form.stay_with || ""}
                      >
                        <FormControlLabel
                          value="Father"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>Father</div>
                              <div style={styles.subLabel}>พ่อ</div>
                            </div>
                          }
                          style={{ marginRight: "60px" }}
                        />
                        <FormControlLabel
                          value="Mother"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>Mother</div>
                              <div style={styles.subLabel}>แม่</div>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                    <EducationForm
                      id="father_"
                      label_th="การศึกษาของบิดา"
                      label_en="B. Education level of father"
                      value={form}
                      onChangeForm={(Form) => setForm({ ...form, ...Form })}
                    />
                    <EducationForm
                      id="mother_"
                      label_th="การศึกษาของมารดา"
                      label_en="C. Education level of mother"
                      value={form}
                      onChangeForm={(Form) => setForm({ ...form, ...Form })}
                    />
                  </>

                  {/* No 3 */}
                  <>
                    <Grid item xs={12} style={styles.groupHead}>
                      3. Parenting Style
                      <div style={styles.subGroupHeader}>วิธีการเลี้ยงดู</div>
                    </Grid>

                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      3.1 Individuals responsible for the child (answer more
                      than one if applicable)
                      <div style={styles.subGroupHeader}>
                        คนที่มีบทบาทต่อการดูแลนักเรียนในปัจจุบัน (ตอบมากกว่า 1
                        ข้อ)
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <CheckForm
                        checkboxs={form.individuals_responsible}
                        other={form.other_individuals_responsible}
                        id="individuals_responsible"
                        onCheck={(key, checked) => {
                          let arr = JSON.parse(
                            JSON.stringify(form.individuals_responsible)
                          );
                          arr[key].checked = checked;
                          setForm({ ...form, individuals_responsible: arr });
                        }}
                        onChangeOther={(e) =>
                          setForm({
                            ...form,
                            other_individuals_responsible: e.target.value,
                          })
                        }
                      />
                    </Grid>
                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      3.2 Number of hours each parent cares for the child each
                      day
                      <div style={styles.subGroupHeader}>
                        เวลาของพ่อและแม่ในการดูแลนักเรียนต่อวัน
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ paddingLeft: "20px" }}
                    >
                      Father
                      <div style={styles.subLabel}>บิดา</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={9}
                      style={{ paddingLeft: "20px" }}
                    >
                      <RadioGroup
                        row
                        value={form.father_care_hour || ""}
                        onChange={(e) =>
                          setForm({ ...form, father_care_hour: e.target.value })
                        }
                      >
                        <FormControlLabel
                          value="1-3 Hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>1-3 Hours</div>
                              <div style={styles.subLabel}>1-3 ชั่วโมง</div>
                            </div>
                          }
                          style={{ marginRight: "60px" }}
                        />
                        <FormControlLabel
                          value="4-6 Hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>4-6 Hours</div>
                              <div style={styles.subLabel}>4-6 ชั่วโมง</div>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="More than 6 Hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>More than 6 Hours</div>
                              <div style={styles.subLabel}>
                                มากกว่า 6 ชั่วโมง
                              </div>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ paddingLeft: "20px" }}
                    >
                      Mother
                      <div style={styles.subLabel}>มารดา</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={9}
                      style={{ paddingLeft: "20px" }}
                    >
                      <RadioGroup
                        row
                        value={form.mother_care_hour || ""}
                        onChange={(e) =>
                          setForm({ ...form, mother_care_hour: e.target.value })
                        }
                      >
                        <FormControlLabel
                          value="1-3 Hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>1-3 Hours</div>
                              <div style={styles.subLabel}>1-3 ชั่วโมง</div>
                            </div>
                          }
                          style={{ marginRight: "60px" }}
                        />
                        <FormControlLabel
                          value="4-6 Hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>4-6 Hours</div>
                              <div style={styles.subLabel}>4-6 ชั่วโมง</div>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="More than 6 Hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>More than 6 Hours</div>
                              <div style={styles.subLabel}>
                                มากกว่า 6 ชั่วโมง
                              </div>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                      3.3 Activities that parents participate in with their
                      children
                      <div style={styles.subGroupHeader}>
                        กิจกรรมที่ผู้ปกครองทำร่วมกับนักเรียนต่อวัน
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ paddingLeft: "20px" }}
                    >
                      Play Time
                      <div style={styles.subLabel}>เล่น</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={9}
                      style={{ paddingLeft: "20px" }}
                    >
                      <RadioGroup
                        row
                        value={form.curator_play_time || ""}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            curator_play_time: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="less than 2 hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>less than 2 hours</div>
                              <div style={styles.subLabel}>
                                ต่ำกว่า 2 ชั่วโมง
                              </div>
                            </div>
                          }
                          style={{ marginRight: "60px" }}
                        />
                        <FormControlLabel
                          value="more than 2 hours"
                          control={<Radio color="primary" />}
                          label={
                            <div>
                              <div>more than 2 hours</div>
                              <div style={styles.subLabel}>
                                มากกว่า 2 ชั่วโมง
                              </div>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ paddingLeft: "20px" }}
                    >
                      Read/Telling the stories
                      <div style={styles.subLabel}>อ่าน/เล่านิทาน</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={9}
                      style={{ paddingLeft: "20px" }}
                    >
                      <RadioGroup
                        onChange={(e) =>
                          setForm({ ...form, telling_story: e.target.value })
                        }
                        value={form.telling_story || ""}
                      >
                        <RadioInput
                          label_th="ไม่สม่ำเสมอ"
                          label_en="Irregular"
                          value="Irregular"
                        />
                        <RadioInput
                          label_th="สม่ำเสมอ (น้อยกว่า 30 นาที)"
                          label_en="regularly (less than 30 minute)"
                          value="less than 30 minute"
                        />
                        <RadioInput
                          label_th="สม่ำเสมอ (มากกว่า 30 นาที)"
                          label_en="regularly (more than 30 minute)"
                          value="more than 30 minute"
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ paddingLeft: "20px" }}
                    >
                      Promotes housework
                      <div style={styles.subLabel}>ส่งเสริมการช่วยงานบ้าน</div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={9}
                      style={{ paddingLeft: "20px" }}
                    >
                      <RadioGroup
                        row
                        onChange={(e) =>
                          setForm({ ...form, housework: e.target.value })
                        }
                        value={form.housework || ""}
                      >
                        <RadioInput
                          label_th="สม่ำเสมอ"
                          label_en="Regularly"
                          value="Regularly"
                        />
                        <RadioInput
                          label_th="ไม่สม่ำเสมอ"
                          label_en="Not Regularly"
                          value="Not Regularly"
                        />
                      </RadioGroup>
                    </Grid>

                    {[
                      {
                        title_en: "3.4 How does the student eat?",
                        title_th: "นักเรียนทานข้าวอย่างไร",
                        id: "parenting_eating",
                        row: true,
                        choices: [
                          {
                            label_th: "ทานเอง",
                            label_en: "By himself/herself",
                            value: "By himself/herself",
                          },
                          {
                            label_th: "ต้องป้อน",
                            label_en: "Fed by an adult",
                            value: "Fed by an adult",
                          },
                          {
                            label_th: "แล้วแต่อารมณ์",
                            label_en: "Depends on the child’s mood",
                            value: "Depends on the child’s mood",
                          },
                        ],
                        value: form.parenting_eating || "",
                      },
                      {
                        id: "parenting_sleeping",
                        title_en:
                          "3.5	How often does the student wake up/go to bed on time?",
                        title_th: "นักเรียนเข้านอน/ตื่นนอน ตรงเวลาหรือไม่",
                        row: true,
                        value: form.parenting_sleeping || "",
                        choices: [
                          {
                            label_th: "ตรงเวลาทุกวัน",
                            label_en: "On time every day",
                            value: "On time every day",
                          },
                          {
                            label_th: "ไม่ตรงเวลา",
                            label_en: "Not on time",
                            value: "Not on time",
                          },
                        ],
                      },
                      {
                        id: "parenting_media_using",
                        title_en:
                          "3.6	Is the student using social media or technology (Mobile Phone/Tablet/Television)?",
                        title_th:
                          "นักเรียนใช้สื่อ/เทคโนโลยีหรือไม่ (มือถือ/แท็บเล็ต/ทีวี)",
                        row: true,
                        value: form.parenting_media_using || "",
                        choices: [
                          {
                            label_th: "ใช่",
                            label_en: "YES",
                            value: "YES",
                          },
                          {
                            label_th: "ไม่ใช่",
                            label_en: "NO",
                            value: "NO",
                          },
                        ],
                      },
                      {
                        id: "parenting_using_media_hour",
                        title_en:
                          "3.7	If yes how long is the student using social media per day?",
                        title_th:
                          "หากนักเรียนใช้สื่อ/ใช้เทคโนโลยีระยะเวลาที่ใช้ใน 1 วัน",
                        value: form.parenting_using_media_hour || "",
                        choices: [
                          {
                            label_th: "น้อยกว่า 1 ชั่วโมง",
                            label_en: "Less than 1 hour/day",
                            value: "Less than 1 hour/day",
                          },
                          {
                            label_th: "2 ชั่วโมง",
                            label_en: "2 hours/day",
                            value: "2 hours/day",
                          },
                          {
                            label_th: "มากกว่า 2 ชั่วโมง",
                            label_en: "More than 2 hours/day",
                            value: "More than 2 hours/day",
                          },
                        ],
                      },
                      {
                        id: "parenting_spoil",
                        title_en: "3.8	How often do you spoil your child?",
                        title_th: "ท่านเลี้ยงลูกตามใจหรือไม่",
                        value: form.parenting_spoil || "",
                        choices: [
                          {
                            label_th: "ตามใจ",
                            label_en: "Often",
                            value: "Often",
                          },
                          {
                            label_th: "ตามใจบางครั้ง",
                            label_en: "Sometimes",
                            value: "Sometimes",
                          },
                          {
                            label_th: "ไม่ตามใจ",
                            label_en: "Never",
                            value: "Never",
                          },
                        ],
                      },
                      {
                        id: "parenting_offended_reaction",
                        title_en:
                          "3.9	When the student is being offended, what is his/her reaction?",
                        title_th: "เมื่อนักเรียนถูกขัดใจ มีพฤติกรรมอย่างไร",
                        value: form.parenting_offended_reaction || "",
                        choices: [
                          {
                            label_th: "เข้าใจข้อตกลง",
                            label_en: "Understand the situation",
                            value: "Understand the situation",
                          },
                          {
                            label_th: "กรีดร้อง ทิ้งตัว",
                            label_en: "Scream, drop his/her body",
                            value: "Scream, drop his/her body",
                          },
                          {
                            label_th: "ร้องไห้งอแง",
                            label_en: "Cry",
                            value: "Cry",
                          },
                          {
                            label_th: "ทำร้ายตัวเอง/ผู้อื่น",
                            label_en: "Self-harms or harms others",
                            value: "Self-harms or harms others",
                          },
                        ],
                      },
                      {
                        id: "parenting_offended_reaction_manage",
                        title_en:
                          "3.10	How do you react to the behavior from inquiry no. 3.9?",
                        title_th:
                          "ผู้ปกครองรับมือกับพฤติกรรมของนักเรียนตามข้อ 3.9 อย่างไร",
                        value: form.parenting_offended_reaction_manage || "",
                        choices: [
                          {
                            label_th: "เฉย ๆ",
                            label_en: "Inaction",
                            value: "Inaction",
                          },
                          {
                            label_th: "เบี่ยงเบนความสนใจนักเรียน",
                            label_en: "Divert",
                            value: "Divert",
                          },
                          {
                            label_th: "ไม่รู้จะทำอย่างไร",
                            label_en: "Unsure",
                            value: "Unsure",
                          },
                          {
                            label_th: "พยายามอธิบายเหตุผลให้นักเรียนเข้าใจ",
                            label_en:
                              "Try to give the student understanding/explanation",
                            value:
                              "Try to give the student understanding/explanation",
                          },
                        ],
                      },
                    ].map((question, key) => (
                      <ChioceQuestion
                        key={key}
                        onSelected={(value) =>
                          setForm({ ...form, [question.id]: value })
                        }
                        title_en={question.title_en}
                        title_th={question.title_th}
                        row={true}
                        value={question.value}
                        choices={question.choices}
                      />
                    ))}
                  </>

                  {/* No 4 */}
                  <>
                    <Grid item xs={12} style={styles.groupHead}>
                      4. School Policies
                      <div style={styles.subGroupHeader}>นโยบายโรงเรียน</div>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "20px" }}>
                      4.1 Give a score to rank the importance of each category
                      (the three categories should total 100 points)
                      <div style={styles.subLabel}>
                        ผู้ปกครองกรุณาให้น้ำหนักความสุข ความคิด ความรู้
                        (ให้แต่ละหัวข้อกี่คะแนนรวมกัน 3 รายการ คะแนนเต็ม 100
                        คะแนน){" "}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                      <TextField
                        type="number"
                        label="Happiness"
                        placeholder="ความสุข"
                        variant="outlined"
                        size="small"
                        id="score_happyess"
                        onChange={(e) =>
                          setForm({ ...form, [e.target.id]: e.target.value })
                        }
                        value={form.score_happyess || ""}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                      <TextField
                        type="number"
                        label="Thinking"
                        placeholder="ความคิด"
                        variant="outlined"
                        size="small"
                        id="score_thinking"
                        onChange={(e) =>
                          setForm({ ...form, [e.target.id]: e.target.value })
                        }
                        value={form.score_thinking || ""}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                      <TextField
                        type="number"
                        label="Knowledge"
                        placeholder="ความรู้"
                        variant="outlined"
                        size="small"
                        id="score_knowledge"
                        onChange={(e) =>
                          setForm({ ...form, [e.target.id]: e.target.value })
                        }
                        value={form.score_knowledge || ""}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    {[
                      {
                        id: "accept_read",
                        title_en:
                          "4.2	Parents are willing to set aside approximately 15 minutes per day for their child to read (children should be able to read a book of their choice)",
                        title_th:
                          "ผู้ปกครองยินดีอ่านหนังสือที่นักเรียนชอบ  (ไม่ใช่ผู้ปกครองอยากให้อ่าน) ให้บุตรหลานวันละ 15 นาที",
                        row: true,
                        value: form.accept_read || "",
                        choices: [
                          {
                            label_th: "Agree",
                            label_en: "ยินดี",
                            value: "Agree",
                          },
                          {
                            label_th: "Disagree",
                            label_en: "ไม่ยินดี",
                            value: "Disagree",
                          },
                        ],
                      },
                      {
                        id: "accept_no_junkfood",
                        title_en:
                          "4.3	Parents are willing to restrict their child from eating fast food, soft drinks, snacks and sweets, etc.",
                        title_th:
                          "ผู้ปกครองยินดีหลีกเลี่ยงการนำพาบุตรหลานทานอาหารฟาสฟู้ด  น้ำอัดลม  ขนมกรุบกรอบ ขนมหวาน เป็นต้น",
                        row: true,
                        value: form.accept_no_junkfood || "",
                        choices: [
                          {
                            label_th: "Agree",
                            label_en: "ยินดี",
                            value: "Agree",
                          },
                          {
                            label_th: "Disagree",
                            label_en: "ไม่ยินดี",
                            value: "Disagree",
                          },
                        ],
                      },
                      {
                        id: "accept_no_bad_activity",
                        title_en:
                          "4.4	Parents are willing to refrain from doing things that are not beneficial to the well-being of the household. For example, watching soap operas, drinking or having a negative attitude.",
                        title_th:
                          "ผู้ปกครองยินดี งดเว้นสิ่งที่ไม่เป็นประโยชน์ในบ้าน เช่น ละครน้ำเน่า  การดื่มสุรา  หรือการมี ทัศนคติเชิงลบ เป็นต้น",
                        row: true,
                        value: form.accept_no_bad_activity || "",
                        choices: [
                          {
                            label_th: "Agree",
                            label_en: "ยินดี",
                            value: "Agree",
                          },
                          {
                            label_th: "Disagree",
                            label_en: "ไม่ยินดี",
                            value: "Disagree",
                          },
                        ],
                      },
                      {
                        id: "accept_meeting",
                        title_en:
                          "4.5 Parents are willing to cooperate with having family meetings regularly, including family counseling.",
                        title_th:
                          "ผู้ปกครองยินดีให้ความร่วมมือกับการเข้าร่วมประชุมทั้งการประชุมรวมและปรึกษารายครอบครัว",
                        value: form.accept_meeting || "",
                        choices: [
                          { label_th: "ยินดี", label_en: "Pleased" },
                          { label_th: "ไม่ยินดี", label_en: "Not Pleased" },
                        ],
                      },
                      {
                        id: "accept_training",
                        title_en:
                          "4.6 Parents are willing to participate in training about child care courses for both physical and mental health by studying the Tonkla School’s policies.",
                        title_th:
                          "ผู้ปกครองยินดีเข้าร่วมลงทะเบียนเรียน/อบรมเรื่องการดูแลบุตรหลานทั้งทางสุขภาพกาย ใจและศึกษาในแนวทางของโรงเรียน",
                        value: form.accept_training || "",
                        choices: [
                          { label_th: "ยินดี", label_en: "Pleased" },
                          { label_th: "ไม่ยินดี", label_en: "Not Pleased" },
                        ],
                      },
                      {
                        id: "accept_non_intensive",
                        title_en:
                          "4.7 Parents agree to provide non-intensive and natural education for their children based on their age.",
                        title_th:
                          "ผู้ปกครองเห็นด้วยกับการจัดการศึกษาที่ไม่เร่งรัดและจัดการศึกษาตามธรรมชาติของช่วงวัย",
                        value: form.accept_non_intensive || "",
                        choices: [
                          { label_th: "ยินดี", label_en: "Agree" },
                          { label_th: "ไม่ยินดี", label_en: "Disagree" },
                        ],
                      },
                      {
                        id: "accept_living_learning",
                        title_en:
                          "4.8 Parents agree that the student comes to school for learning how to live with others, be patient, show appreciation, and manage emotions, etc.",
                        title_th:
                          "ผู้ปกครองยอมรับที่จะให้นักเรียนมาโรงเรียนเพื่อเรียนรู้การใช้ชีวิตร่วมกับผู้อื่น อดทน รอคอย ดีใจ เสียใจ สมหวัง ผิดหวัง เป็นต้น",
                        value: form.accept_living_learning || "",
                        choices: [
                          { label_th: "ยินดี", label_en: "Agree" },
                          { label_th: "ไม่ยินดี", label_en: "Disagree" },
                        ],
                      },
                      {
                        id: "accept_school_management",
                        title_en:
                          "4.9 Parents will trust school management and will not interfere with the work of the teachers and the school.",
                        title_th:
                          "ผู้ปกครองมีความเชื่อมั่นในการจัดการศึกษาของโรงเรียน (Trust) และยินดีให้ความร่วมมือโดยไม่เข้าไปแทรกแซงการทำงานของครูและโรงเรียน",
                        value: form.accept_school_management || "",
                        choices: [
                          { label_th: "ยินดี", label_en: "Agree" },
                          { label_th: "ไม่ยินดี", label_en: "Disagree" },
                        ],
                      },
                      {
                        id: "accept_conflict_management",
                        title_en:
                          "4.10 If there is a conflict between students, parents will give students the opportunity to learn and manage the problem by his/herself",
                        title_th:
                          "หากเกิดความขัดแย้งระหว่างนักเรียน ผู้ปกครองจะเปิดโอกาสให้นักเรียนได้เรียนรู้และจัดการปัญหาต่าง ๆ ด้วยตนเอง",
                        value: form.accept_conflict_management || "",
                        choices: [
                          { label_th: "ยินดี", label_en: "Agree" },
                          { label_th: "ไม่ยินดี", label_en: "Disagree" },
                        ],
                      },
                    ].map((question, key) => (
                      <ChioceQuestion
                        key={key}
                        onSelected={(value) =>
                          setForm({ ...form, [question.id]: value })
                        }
                        title_en={question.title_en}
                        title_th={question.title_th}
                        value={question.value}
                        row={true}
                        choices={question.choices}
                      />
                    ))}
                  </>

                  {/* No 5 */}
                  <>
                    <Grid item xs={12} style={styles.groupHead}>
                      5. Planning for further study
                      <div style={styles.subGroupHeader}>
                        การวางแผนการศึกษาต่อ
                      </div>
                    </Grid>
                    {[
                      {
                        id: "planning_study",
                        title_en: "5.1 Willing to study at Tonkla until",
                        title_th: "ประสงค์เรียนโรงเรียนต้นกล้าถึงระดับ",
                        value: form.planning_study || "",
                        choices: [
                          {
                            label_th: "ระดับชั้นอนุบาล 2",
                            label_en: "Through Kindergarten level 2",
                          },
                          {
                            label_th: "ระดับชั้นอนุบาล 3",
                            label_en: "Through Kindergarten Level 3",
                          },
                          {
                            label_th: "ระดับชั้นประถมศึกษาปีที่ 6",
                            label_en: "Through Grade 6",
                          },
                        ],
                      },
                      {
                        id: "planning_future_study",
                        title_en: "5.2 Type of schools for future study",
                        title_th: "โรงเรียนที่มีความประสงค์จะไปศึกษาต่อ",
                        value: form.planning_future_study || "",
                        choices: [
                          {
                            label_th: "โปรแกรมปกติ",
                            label_en: "Normal Program ",
                          },
                          {
                            label_th: "International School/ต่างประเทศ",
                            label_en: "International School/Foreign Country",
                          },
                          {
                            label_th: "โปรแกรมภาษาอังกฤษ",
                            label_en: "English Program",
                          },
                        ],
                      },
                    ].map((question, key) => (
                      <ChioceQuestion
                        key={key}
                        onSelected={(value) =>
                          setForm({ ...form, [question.id]: value })
                        }
                        title_en={question.title_en}
                        title_th={question.title_th}
                        row={true}
                        value={question.value}
                        choices={question.choices}
                      />
                    ))}
                  </>
                </Grid>

                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Icon>save</Icon>}
                  style={{ width: "150px", margin: "20px auto 10px" }}
                  onClick={saveForm}
                >
                  Save
                </Button>
              </>
            ),
            1: (
              <FormInterviewStaff
                register={{
                  _id: register._id,
                  visit_schedules: register.visit_schedules,
                  status_date: register.status_date,
                }}
              />
            ),
            2: <FormOccupationalTherapist register_id={register._id} />,
          }[showTab]
        }
      </Paper>
      <br />
      <br />
      <br />
    </div>
  );
}
export const ChioceQuestion = (props) => {
  const { title_en, title_th, choices, row, onSelected } = props;
  return (
    <>
      <Grid item xs={12} style={{ marginTop: "10px" }}>
        {title_en}
        <div style={styles.subLabel}>{title_th}</div>
      </Grid>

      <Grid item xs={12} style={{ paddingLeft: "20px" }}>
        <RadioGroup
          row={row || false}
          onChange={(e) => onSelected(e.target.value)}
          value={props.value}
        >
          <Grid container>
            {choices.map((choice, key) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                style={{ paddingBottom: "10px" }}
                key={key}
              >
                <RadioInput
                  label_th={choice.label_th}
                  label_en={choice.label_en}
                  value={choice.value || choice.label_en}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </Grid>
    </>
  );
};

export const RadioInput = (props) => {
  const { label_th, label_en, value } = props;
  return (
    <>
      <FormControlLabel
        value={value}
        control={<Radio color="primary" />}
        style={{ marginRight: "50px" }}
        label={
          <div>
            <div>{label_en}</div>
            <div style={styles.subLabel}>{label_th}</div>
          </div>
        }
      />
    </>
  );
};

export const EducationForm = (props) => {
  const { id, label_th, label_en, onChangeForm /* , value */ } = props;
  const [form, setForm] = useState({});
  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (props.value && props.value._id && firstTime === true) {
      let id = props.id;
      let value = props.value;
      setFirstTime(false);
      setForm({
        [id + "degree"]: value[id + "degree"],
        [id + "institute"]: value[id + "institute"],
        [id + "major"]: value[id + "major"],
        [id + "job"]: value[id + "job"],
      });
    }
  }, [props, firstTime]);
  function updateForm(e) {
    let id = e.target.id;
    let value = e.target.value;
    let Form = JSON.parse(JSON.stringify(form));
    Form[id] = value;
    setForm(Form);
    onChangeForm(Form, id);
  }
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          paddingLeft: "20px",
          paddingBottom: "10px",
          paddingTop: "20px",
        }}
      >
        {label_en}
        <div style={styles.subLabel}>{label_th}</div>
      </Grid>
      <GridSelectItem
        size={[12, 4, 4, 4]}
        label="Degree"
        id={id + "degree"}
        value={form[id + "degree"]}
        changeValue={(e) => updateForm(e)}
        options={Degrees}
      />
      <GridInputItem
        size={[12, 4, 4, 4]}
        label="Institute"
        placeholder="สถาบัน"
        id={id + "institute"}
        value={form[id + "institute"]}
        changeValue={(e) => updateForm(e)}
      />
      <GridInputItem
        size={[12, 4, 4, 4]}
        label="Major"
        placeholder="สาขา"
        id={id + "major"}
        value={form[id + "major"]}
        changeValue={(e) => updateForm(e)}
      />
      <GridInputItem
        size={[12, 12, 12, 12]}
        label="Current occupation"
        placeholder="อาชีพปัจจุบัน"
        id={id + "job"}
        value={form[id + "job"]}
        changeValue={(e) => updateForm(e)}
      />
    </>
  );
};
export const CheckForm = (props) => {
  const [other, setOther] = useState();
  const [checkboxs, setCheckboxs] = useState([]);
  useEffect(() => {
    setOther(props.other || "");
    setCheckboxs(props.checkboxs || []);
  }, [props]);
  function checkItem(e, key) {
    let checked = e.target.checked;
    if (props.onCheck) props.onCheck(key, checked);
  }
  function changeOther(e) {
    if (props.onChangeOther) props.onChangeOther(e);
  }
  return (
    <div style={{ paddingLeft: "10px" }}>
      <Grid container>
        {checkboxs.map((ch, key) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            key={key}
            style={{ paddingBottom: "5px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={ch.checked}
                  onChange={(e) => checkItem(e, key)}
                  color="primary"
                />
              }
              label={
                <div>
                  <div>{ch.label_en}</div>
                  <div style={{ fontSize: ".8rem", color: "#999" }}>
                    {ch.label_th}
                  </div>
                </div>
              }
            />
          </Grid>
        ))}
        <Grid item xs={12} style={{ paddingTop: "10px" }}>
          <GridInputItem
            size={[12, 12, 12, 12]}
            label="Other"
            placeholder="อื่น ๆ"
            id={props.id + "_other"}
            value={other}
            changeValue={(e) => changeOther(e)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
