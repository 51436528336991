import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";

export const GridInputItem = (props) => {
  const {
    size,
    label,
    placeholder,
    changeValue,
    id,
    value,
    enterValue,
    rows,
    type,
    disabled,
  } = props;
  const [error, setError] = useState(false);
  useEffect(() => {
    setError(props.error);
  }, [props]);
  return (
    <>
      <Grid
        item
        xs={size[0]}
        sm={size[1]}
        md={size[2]}
        lg={size[3]}
        style={props.gridStyle || { paddingBottom: "10px" }}
      >
        <TextField
          disabled={disabled || false}
          className=""
          type={type || "text"}
          error={error || false}
          label={label}
          variant="outlined"
          fullWidth
          id={id}
          multiline={rows ? true : false}
          rows={rows || 1}
          value={value || ""}
          placeholder={placeholder}
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={changeValue}
          onKeyUp={(e) => e.key === "Enter" && enterValue && enterValue(e)}
          inputProps={{
            autoComplete: "new-password",
          }}
        />
      </Grid>
    </>
  );
};

export const GridSelectItem = (props) => {
  const { size, inputSize, label, changeValue, id, value, options } = props;
  return (
    <>
      <Grid
        item
        xs={size[0]}
        sm={size[1]}
        md={size[2]}
        lg={size[3]}
        style={{ paddingBottom: "10px" }}
      >
        <FormControl variant="outlined" fullWidth value={value}>
          <InputLabel shrink>{label}</InputLabel>
          <Select
            native
            label={label}
            id={id}
            // inputProps={{
            //   style: { padding: "11px 10px" },
            // }}
            size={inputSize || "small"}
            value={value}
            onChange={changeValue}
          >
            <option value="-"></option>
            {options &&
              options.map((option, key) => (
                <option key={key} value={option.value}>
                  {option.label}
                  {option.alt_label && ` (${option.alt_label})`}
                </option>
              ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
/*
export const CheckForm = (props) => {
  const { values, other, grids, title_th, title_en, styles } = props;
  const [checkboxs, setCheckboxs] = useState([]);
  useEffect(() => {
    async function renderQuestion() {
      let checkboxs = props.choices || [];
      for (let val of props.values) {
        let i = await checkboxs.findIndex((j) => j.value === val);
        if (i > -1) checkboxs[i].checked = true;
      }
      console.log(props.values);
      console.log(checkboxs.filter((j) => j.checked === true));
      setCheckboxs(checkboxs);
    }
    renderQuestion();
  }, [props]);
  function onCheck(e) {
    let check = e.target.checked;
    let val = e.target.value;
    let arr = JSON.parse(JSON.stringify(values));
    if (check === true) arr.push(val);
    else arr = arr.filter((j) => j !== val);
    props.onCheck(arr);
  }
  return (
    <div style={{ paddingLeft: "10px" }}>
      {JSON.stringify(values)}
      {JSON.stringify(checkboxs.filter((j) => j.checked === true))}
      <Grid container>
        <Grid
          item
          xs={12}
          style={(styles && styles.title) || { padding: "10px 0px" }}
        >
          {title_th}
          <div style={(styles && styles.subTitle) || {}}>{title_en}</div>
        </Grid>
        {checkboxs.map((ch, key) => (
          <Grid
            item
            xs={grids[0]}
            sm={grids[1]}
            md={grids[2]}
            lg={grids[3]}
            key={key}
            style={{ paddingBottom: "10px" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  value={ch.value}
                  checked={ch.checked || false}
                  onChange={onCheck}
                  color="primary"
                />
              }
              label={
                <>
                  <div style={{ fontSize: ".9rem" }}>
                    {ch.label_th} {JSON.stringify(ch.checked)}
                  </div>
                  <div style={{ fontSize: ".8rem", color: "#888" }}>
                    {ch.label_en}
                  </div>
                </>
              }
            />
          </Grid>
        ))}
        {props.onChangeOther && (
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <GridInputItem
              size={[12, 12, 12, 12]}
              label="Other"
              placeholder="อื่น ๆ"
              value={other}
              changeValue={props.onChangeOther}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
*/

export const CheckForm = (props) => {
  const { values, other, grids, title_th, title_en, styles, choices } = props;
  const [checkboxs, setCheckboxs] = useState();
  useEffect(() => {
    if (choices && values) {
      let Checkboxs = JSON.parse(JSON.stringify(choices));
      for (let val of values) {
        let i = Checkboxs.findIndex((j) => j.value === val);
        if (i > -1) Checkboxs[i].checked = true;
      }
      setCheckboxs(Checkboxs);
    }
  }, [choices, values]);

  function onCheck(e) {
    let check = e.target.checked;
    let val = e.target.value;
    let arr = JSON.parse(JSON.stringify(values));
    if (check === true) arr.push(val);
    else arr = arr.filter((j) => j !== val);
    props.onCheck(arr);
  }
  return (
    <div style={{ paddingLeft: "10px" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={(styles && styles.title) || { padding: "10px 0px" }}
        >
          {title_th}
          <div style={(styles && styles.subTitle) || {}}>{title_en}</div>
        </Grid>
        {checkboxs &&
          checkboxs.map((ch, key) => (
            <Grid
              item
              xs={grids[0]}
              sm={grids[1]}
              md={grids[2]}
              lg={grids[3]}
              key={key}
              style={{ paddingBottom: "10px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    value={ch.value}
                    checked={ch.checked || false}
                    onChange={onCheck}
                    color="primary"
                  />
                }
                label={
                  <>
                    <div style={{ fontSize: ".9rem" }}>{ch.label_th}</div>
                    <div style={{ fontSize: ".8rem", color: "#888" }}>
                      {ch.label_en}
                    </div>
                  </>
                }
              />
            </Grid>
          ))}
        {props.onChangeOther && (
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <GridInputItem
              size={[12, 12, 12, 12]}
              label="Other"
              placeholder="อื่น ๆ"
              value={other}
              changeValue={props.onChangeOther}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export const RadioForm = (props) => {
  const { value, other, grids, title_th, title_en, styles } = props;
  const [choices, setChoices] = useState([]);
  useEffect(() => {
    async function renderQuestion() {
      let choices = props.choices || [];
      setChoices(choices);
    }
    renderQuestion();
  }, [props]);
  function onCheck(e) {
    props.onCheck(e.target.value);
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} style={(styles && styles.title) || {}}>
          {title_th}
          <div style={(styles && styles.subTitle) || {}}>{title_en}</div>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" style={{ width: "100%" }}>
            <RadioGroup value={value || null} onChange={onCheck}>
              <Grid container style={{ padding: "0px 10px" }}>
                {choices.map((choice, key) => (
                  <Grid
                    item
                    xs={grids[0]}
                    sm={grids[1]}
                    md={grids[2]}
                    lg={grids[3]}
                    key={key}
                    style={{ paddingBottom: "10px" }}
                  >
                    <FormControlLabel
                      value={choice.value || choice.label}
                      control={<Radio />}
                      label={
                        <div>
                          <div style={{ fontSize: ".9rem" }}>
                            {choice.label_th || choice.label}
                          </div>
                          <div style={{ color: "#888", fontSize: ".8rem" }}>
                            {choice.label_en || choice.label_alt}
                          </div>
                        </div>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
        {props.onChangeOther && (
          <Grid item xs={12} style={{ paddingTop: "10px" }}>
            <GridInputItem
              size={[12, 12, 12, 12]}
              label="Other"
              placeholder="อื่น ๆ"
              value={other}
              changeValue={props.onChangeOther}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
