import React, { useState, useEffect } from "react";
import { getPermission, getStampTime, getSchoolYear } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import TableStudentStatus from "../components/TableStudentStatus";
import MultiType from "../charts/MultiType";
// import MultiType2 from "../charts/MultiType2";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DatePicker from "@mui/lab/DatePicker";

// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { months, register_statuses } from "../const";
// import { GridSelectItem } from "../components/InputForm";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function RptMonthlyStatus({ page }) {
  const [profile, setProfile] = useState();
  const [filter, setFilter] = useState();
  //   const [visits, setVisits] = useState();
  const [report, setReport] = useState([]);
  const [years, setYears] = useState([]);

  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      setProfile(profile);
      let dt = await getStampTime();
      setFilter({
        status: "เยี่ยมชม",
        startYear: dt.detail.year.toString(),
        toYear: (dt.detail.year + 1).toString(),
      });
    })();
  }, []);
  useEffect(() => {
    const months = [
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
    ];
    function createEmptyReport() {
      return new Promise(async (resolve) => {
        let years = [];
        let report = [];
        for (let i = filter.startYear; i <= filter.toYear; i++) {
          years.push({ value: i.toString() });
          for (let m of months) {
            report.push({
              year: i.toString(),
              month: m,
              visitCount: 0,
              register_ids: [],
            });
          }
        }
        resolve({ years, report });
      });
    }
    async function getReport(visits) {
      let { years, report } = await createEmptyReport();
      if (filter.status === "เยี่ยมชม") {
        for (let visit of visits.filter((j) => j.visit_schedules[0])) {
          let schoolYear =
            (await getSchoolYear(visit.visit_schedules[0].visit_date)) - 543;
          schoolYear = schoolYear.toString();
          let arr = visit.visit_schedules[0].visit_date.split("-");
          let i = report.findIndex(
            (j) => j.year === schoolYear && j.month === arr[1]
          );
          if (i > -1) {
            report[i].visitCount++;
            report[i].register_ids.push(visit._id);
          }
        }
      } else if (filter.status === "สมัครเรียน") {
        for (let visit of visits.filter((j) => j.register_date)) {
          let schoolYear = (await getSchoolYear(visit.register_date)) - 543;
          schoolYear = schoolYear.toString();
          let arr = visit.register_date.split("-");
          let i = report.findIndex(
            (j) => j.year === schoolYear && j.month === arr[1]
          );
          if (i > -1) {
            report[i].visitCount++;
            report[i].register_ids.push(visit._id);
          }
        }
      } else {
        for (let visit of visits) {
          for (let schedule of visit.status_date.filter(
            (j) => j.label === filter.status
          )) {
            let schoolYear = (await getSchoolYear(schedule.stamp_date)) - 543;
            schoolYear = schoolYear.toString();
            let arr = schedule.stamp_date.split("-");
            let i = report.findIndex(
              (j) => j.year === schoolYear && j.month === arr[1]
            );
            if (i > -1) {
              report[i].visitCount++;
              report[i].register_ids.push(visit._id);
            }
          }
        }
      }
      setReport(report);
      setYears(years);
    }
    async function getWaiverReport(registers) {
      let { years, report } = await createEmptyReport();
      for (let register of registers) {
        let schoolYear = (await getSchoolYear(register.waiver_date)) - 543;
        schoolYear = schoolYear.toString();
        let arr = register.waiver_date.split("-");
        let i = report.findIndex(
          (j) => j.year === schoolYear && j.month === arr[1]
        );
        if (i > -1) {
          report[i].visitCount++;
          report[i].register_ids.push(register._id);
        }
      }
      setReport(report);
      setYears(years);
    }
    async function getRegisters() {
      let match = {};

      if (filter.status === "สมัครเรียน") {
        match = {
          $and: [
            {
              register_date: {
                $gte: filter.startYear - 1 + "-07",
              },
            },
            { register_date: { $lt: filter.toYear + "-07" } },
          ],
        };
      } else if (filter.status === "เยี่ยมชม") {
        match = {
          $and: [
            {
              "visit_schedules.visit_date": {
                $gte: filter.startYear - 1 + "-07",
              },
            },
            {
              "visit_schedules.visit_date": { $lt: filter.toYear + "-07" },
            },
            {
              $or: [
                { "status_date.label": "เยี่ยมชม" },
                {
                  $and: [
                    { "visit_schedules.counselor": { $exists: true } },
                    { "visit_schedules.counselor": { $ne: "" } },
                  ],
                },
              ],
            },
          ],
        };
      } else if (filter.status === "สละสิทธิ") {
        match = {
          $and: [
            {
              waiver: true,
            },
            {
              waiver_date: { $gte: filter.startYear - 1 + "-07" },
            },
            {
              waiver_date: { $lt: filter.toYear + "-07" },
            },
          ],
        };
      } else {
        match = {
          $and: [
            {
              "status_date.label": filter.status,
            },
            {
              "status_date.stamp_date": { $gte: filter.startYear - 1 + "-07" },
            },
            {
              "status_date.stamp_date": { $lt: filter.toYear + "-07" },
            },
          ],
        };
      }

      let send = {
        collection: "registers",
        match: match,
        select: "status_date waiver_date register_date visit_schedules",
      };
      let registers = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        send,
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      if (registers.data.error) alert(registers.data.error.message);
      else if (filter.status === "สละสิทธิ")
        getWaiverReport(registers.data.result);
      else getReport(registers.data.result);
    }
    console.log(filter);
    if (filter) getRegisters();
  }, [filter]);
  async function changeDate(field, dt) {
    dt = await getStampTime(dt);
    setFilter({ ...filter, [field]: dt.detail.year.toString() });
  }
  return (
    <div className="content">
      {filter && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <>
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={5}
                  sm={4}
                  md={3}
                  lg={2}
                  style={{ paddingBottom: "10px" }}
                >
                  <FormControl
                    variant="outlined"
                    fullWidth
                    value={filter.status}
                  >
                    <InputLabel shrink>สถานะ</InputLabel>
                    <Select
                      native
                      label="สถานะ"
                      id="status"
                      inputProps={{
                        style: { padding: "9px 10px" },
                      }}
                      value={filter.status}
                      onChange={(e) => {
                        setFilter({ ...filter, status: e.target.value });
                      }}
                    >
                      {register_statuses.map((option, key) => (
                        <option key={key} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                      <option value="สละสิทธิ">สละสิทธิ</option>
                      <option value="ไม่ผ่าน">ไม่ผ่าน</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <DatePicker
                    views={["year"]}
                    format="YYYY"
                    label="ตั้งแต่ปีการศึกษา"
                    value={dayjs(filter.startYear)}
                    onChange={(newValue) => changeDate("startYear", newValue)}
                    slotProps={{
                      textField: {
                        InputLabelProps: { shrink: true },
                        fullWidth: true,
                        size: "small",
                        style: { width: "150px" },
                      },
                    }}
                  />
                  {/* <DatePicker
                    views={["year"]}
                    label="ตั้งแต่ปีการศึกษา"
                    value={filter.startYear}
                    onChange={(newValue) => {
                      changeDate("startYear", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={null}
                        size="small"
                        style={{ width: "150px" }}
                      />
                    )}
                  /> */}
                </Grid>
                <Grid item>
                  <DatePicker
                    views={["year"]}
                    format="YYYY"
                    label="ถึงปีการศึกษา"
                    value={dayjs(filter.toYear)}
                    onChange={(newValue) => changeDate("toYear", newValue)}
                    slotProps={{
                      textField: {
                        InputLabelProps: { shrink: true },
                        fullWidth: true,
                        size: "small",
                        style: { width: "150px" },
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            {report && (
              <>
                <br />
                <ReportArea report={report} years={years} />
                <br />
                <ChartArea
                  state={{ title: "สถิตการ" + filter.status, report: report }}
                />
              </>
            )}
          </>
        </>
      )}
    </div>
  );
}

function ReportArea({ report, years }) {
  const [tableFilter, setTableFilter] = useState();
  const [totalYears, setTotalYears] = useState();
  useEffect(() => {
    (async () => {
      setTableFilter(null);

      let totals = [];
      for (let r of report) {
        let i = totals.findIndex((j) => j.year === r.year);
        if (i < 0) {
          totals.push({
            year: r.year,
            count: r.visitCount,
          });
        } else {
          totals[i].count += r.visitCount;
        }
      }
      setTotalYears(totals);
    })();
  }, [report]);
  function getMonthName(key) {
    let m = months.filter((j) => j.value === key);
    return m[0].label;
  }
  function getCount(m, y) {
    m = String(m).padStart(2, "0");

    let i = report.findIndex((j) => j.year === y && j.month === m);
    if (i < 0) return 0;
    else return report[i].visitCount;
  }
  function showListTable(m, schoolYear) {
    let i = report.findIndex(
      (j) => j.year === schoolYear && j.month === String(m).padStart(2, "0")
    );
    let newFilter = null;
    if (i > -1 && report[i].register_ids.length > 0) {
      newFilter = { _id: { $in: report[i].register_ids } };
    }
    setTableFilter(newFilter);
  }
  function getYearTotal(year) {
    let i = totalYears.findIndex((j) => j.year === year);
    if (i > -1) return totalYears[i].count;
    else return 0;
  }
  return (
    <div>
      {years && report && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="td"
                  align="center"
                  style={{ width: "80px" }}
                  rowSpan={2}
                >
                  ลำดับ
                </TableCell>
                <TableCell className="td" align="center" rowSpan={2}>
                  เดือน
                </TableCell>
                <TableCell className="td" align="center" colSpan={years.length}>
                  ปีการศึกษา
                </TableCell>
              </TableRow>
              <TableRow>
                {years.map((year, key) => (
                  <TableCell className="td" key={key} align="center">
                    {parseInt(year.value) + 543}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6].map((m, key) => (
                <TableRow key={key}>
                  <TableCell className="td" align="center">
                    {key + 1}
                  </TableCell>
                  <TableCell className="td" align="center">
                    {getMonthName(m)}
                  </TableCell>

                  {years.map((year, key) => (
                    <TableCell className="td" key={key} align="center">
                      <Button
                        onClick={() => showListTable(m, year.value)}
                        style={{ padding: "0px 0px" }}
                        className="kanit"
                      >
                        {getCount(m, year.value)}
                      </Button>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                <TableCell align="center" colSpan={2} className="bold">
                  รวม
                </TableCell>
                {years.map((year, key) => (
                  <TableCell align="center" className="bold" key={key}>
                    {getYearTotal(year.value)}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {tableFilter && (
        <>
          <TableStudentStatus
            match={tableFilter}
            title="รายชื่อผู้สมัครเรียน"
          />
        </>
      )}
    </div>
  );
}

function ChartArea({ state }) {
  const [data, setData] = useState();
  useEffect(() => {
    function rendomColor() {
      return new Promise(async (resolve) => {
        let r = Math.floor(Math.random() * 256);
        let g = Math.floor(Math.random() * 256);
        let b = Math.floor(Math.random() * 256);
        let txt = "rgba(" + r + "," + g + "," + b + ",.7)";
        resolve(txt);
      });
    }
    async function createData() {
      let data = {
        labels: [
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
        ],
        datasets: [],
      };
      let datasets = [];
      for (let item of state.report) {
        let i = datasets.findIndex((j) => j.label === item.year);
        if (i < 0) {
          let color = await rendomColor();
          datasets.push({
            type: "line",
            label: item.year,
            borderColor: color,
            borderWidth: 3,
            fontSize: 40,

            backgroundColor: color,
            fill: false,
            data: [item.visitCount],
          });
        } else datasets[i].data.push(item.visitCount);
      }
      data.datasets = datasets;
      setData(data);
    }
    if (state.report) createData();
  }, [state]);
  return (
    <div>
      {data && (
        <Grid container component={Paper}>
          <Grid item xs={12} style={{ padding: "20px 20px" }}>
            <MultiType state={{ title: state.title, data: data, version: 1 }} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
