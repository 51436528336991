import React, { useState, useEffect, Fragment } from "react";
import "./styles.css";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Conf from "../Conf";
import {
  getFullDate,
  getStampTime,
  yearDiff,
  // monthDiff,
  diffYearMonthDay,
} from "../js/main";
import { registration_documents, home_types } from "../const";
import { useParams } from "react-router-dom";
// import "https://fonts.googleapis.com/css2?family=Sarabun:wght@200;300;400&display=swap";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Registration(props) {
  const params = useParams();
  const register_id = params.id;
  const [register, setRegister] = useState();
  useEffect(() => {
    async function getAgeLine() {
      return new Promise(async (resolve) => {
        let date_now = await getStampTime();
        date_now.detail.day = parseInt(date_now.detail.day);
        date_now.detail.month = parseInt(date_now.detail.month);
        if (parseInt(date_now.detail.month) < 5) {
          date_now.detail.year--;
        } else if (
          parseInt(date_now.detail.month) === 5 &&
          parseInt(date_now.detail.day) < 16
        ) {
          date_now.detail.year--;
        }
        resolve(date_now.detail.year + "-05-16");
      });
    }
    async function getHomeTypes(hourse_type) {
      return new Promise(async (resolve) => {
        let r = [];
        if (hourse_type) {
          for (let type of home_types) {
            let i = hourse_type.findIndex((j) => j === type.label_th);
            if (i > -1)
              r.push({
                label: type.label_th,
                subLabel: type.label_en,
                checked: true,
              });
            else r.push({ label: type.label_th, subLabel: type.label_en });
          }
        }
        resolve(r);
      });
    }
    async function getRegister() {
      let register = await axios.get(
        Conf.endpoint.tonkla + "/ca/query/registers/" + register_id,
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      register = register.data;
      if (register) {
        if (register.registration_date) {
          register.registration_date = await getFullDate(
            register.registration_date
          );
        }
        let dt1 = new Date(register.contact_birth_date);
        let dt2 = new Date();
        register.contact_age = await yearDiff(dt1, dt2);
        register.check_relation = [
          { label: "บิดา", subLabel: "Father" },
          { label: "มารดา", subLabel: "Mother" },
          { label: "อื่น ๆ", subLabel: "Other" },
        ];
        let i = register.check_relation.findIndex(
          (j) => j.label === register.relation
        );
        if (i > -1) register.check_relation[i].checked = true;

        register.check_house_type = home_types.map((type) => {
          return { label: type.label_th, subLabel: type.label_en };
        });

        i = register.check_house_type.findIndex(
          (j) => j.label === register.house_type
        );
        if (i > -1) register.check_house_type[i].checked = true;

        dt1 = new Date(register.birth_date);

        let ageLine = "";
        if (register.attending_year && register.attending_year !== "") {
          ageLine = register.attending_year + "-05-16";
        } else ageLine = await getAgeLine();
        let diffDate = await diffYearMonthDay(
          new Date(register.birth_date),
          new Date(ageLine)
        );
        register.age = diffDate.year;
        register.age_month = diffDate.month - register.age * 12;

        // register.age = await yearDiff(dt1, new Date(ageLine));
        // register.age_month = await monthDiff(dt1, new Date(ageLine));
        // register.age_month = register.age_month % 12;

        register.birth_date = await getFullDate(register.birth_date);

        let year_now = await getStampTime(ageLine);
        register.year_now = year_now.detail.year;

        register.home_types = await getHomeTypes(register.hourse_type);
        setRegister(register);
      }
    }
    if (register_id) getRegister();
  }, [register_id]);
  useEffect(() => {
    if (register) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [register]);
  return (
    <>
      {register && (
        <div style={{ width: "18cm", margin: "auto" }}>
          <Grid container>
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <div style={{ paddingTop: "1cm" }}>
                <div
                  style={{ fontSize: "15pt" }}
                  className="center bold sarabun"
                >
                  ใบมอบตัวนักเรียน
                </div>
                <div
                  style={{ fontSize: "8pt", color: "#888" }}
                  className="sarabun center"
                >
                  Student Registration Form
                </div>
              </div>
            </Grid>

            <Grid item xs={3} align="right">
              <div
                style={{
                  border: "2px solid #111",
                  height: "3cm",
                  width: "2.5cm",
                }}
              ></div>
            </Grid>
          </Grid>
          <div style={{ textAlign: "right", marginTop: ".5cm" }}>
            <span className="sarabun" style={{ fontSize: "9pt" }}>
              วันที่
            </span>
            <SpanFill width={7} value={register.registration_date} />
          </div>
          <div style={{ textAlign: "right", paddingTop: ".2cm" }}>
            <SpanLabel width={1.5} label="ข้าพเจ้า" subLabel="My name is" />
            <SpanFill
              width={10}
              value={
                register.contact_prefix +
                " " +
                register.contact_first_name +
                " " +
                (register.contact_middle_name || "") +
                " " +
                register.contact_last_name
              }
            />
            <SpanLabel width={1.5} label="อายุ" subLabel="Age" />
            <SpanFill width={1} value={register.contact_age} />
            <SpanLabel width={0.8} label="ปี" subLabel="Year" />
          </div>
          <div className="div-line">
            <SpanLabel
              width={3.5}
              label="เกี่ยวข้องกับนักเรียนเป็น"
              subLabel="Relevance to students"
            />
            <RadioChoice choices={register.check_relation} />
            <SpanLabel width={1} label="ระบุ" />
            <SpanFill width={6.9} value={register.other_relation} />
          </div>
          <div className="div-line">
            <SpanLabel
              width={4.8}
              label="ขอมอบตัวนักเรียน ชื่อ – นามสกุล"
              subLabel="Student Name-Surname"
            />
            <SpanFill
              width={8}
              value={
                register.prefix +
                " " +
                register.first_name +
                " " +
                (register.middle_name || "") +
                " " +
                register.last_name
              }
            />
            <SpanLabel width={1.5} label="ชื่อเล่น" subLabel="Nickname" />
            <SpanFill width={2.8} value={register.nickname} />
          </div>
          <div className="div-line">
            <SpanLabel
              width={2.3}
              label="วัน/เดือน/ปีเกิด"
              subLabel="Date of birth"
            />
            <SpanFill width={5.3} value={register.birth_date} />
            <SpanLabel
              width={3.6}
              label={"อายุ (ถึง 16 พ.ค " + (register.year_now + 543) + ")"}
              subLabel={"Age (on May 16, " + register.year_now + ")"}
            />
            <SpanFill width={1.8} value={register.age} />
            <SpanLabel width={1} label="ขวบ" subLabel="Years" />
            <SpanFill width={1.8} value={register.age_month} />
            <SpanLabel width={1} label="เดือน" subLabel="Month" />
          </div>
          <div className="div-line">
            <SpanLabel
              width={4}
              label="เลขบัตรประจำตัวประชาชน"
              subLabel="ID number"
            />
            <SpanFill
              width={2.5}
              value={register.idx}
              styles={{ fontSize: "8pt" }}
            />
            <SpanLabel width={1.2} label="สัญชาติ" subLabel="Nationality" />
            <SpanFill
              width={1.4}
              value={register.nationality}
              styles={{ fontSize: "9pt" }}
            />
            <SpanLabel width={1.4} label="เชื้อชาติ" subLabel="Nation" />
            <SpanFill
              width={1.4}
              value={register.origin}
              styles={{ fontSize: "9pt" }}
            />
            <SpanLabel width={1.2} label="ศาสนา" subLabel="Religion" />
            <SpanFill
              width={1}
              value={register.religion}
              styles={{ fontSize: "9pt" }}
            />
            <SpanLabel width={1.6} label="หมู่โลหิต" subLabel="Blood group" />
            <SpanFill
              width={0.3}
              value={register.blood_group}
              styles={{ fontSize: "9pt" }}
            />
          </div>
          <div className="div-line">
            <SpanLabel
              width={3.3}
              label="ที่อยู่ปัจจุบัน บ้านเลขที่"
              subLabel="Current address no."
            />
            <SpanFill width={2} value={register.current_house_number} />
            <SpanLabel
              width={1.8}
              label="ชื่อหมู่บ้าน"
              subLabel="Village name"
            />
            <SpanFill width={6.9} value={register.current_village} />
            <SpanLabel width={0.8} label="หมู่ที่" subLabel="Moo" />
            <SpanFill width={2} value={register.current_moo} />
          </div>
          <div className="div-line">
            <SpanLabel
              width={1.5}
              label="ตำบล"
              styles={{ textAlign: "left", paddingLeft: ".1cm" }}
              subLabel="Sub district"
            />
            <SpanFill width={3} value={register.current_area} />
            <SpanLabel width={1} label="อำเภอ" subLabel="District" />
            <SpanFill width={3.8} value={register.current_district} />
            <SpanLabel width={1} label="จังหวัด" subLabel="Province" />
            <SpanFill width={3} value={register.current_province} />
            <SpanLabel width={2} label="รหัสไปรษณีย์" subLabel="Post number" />
            <SpanFill width={1} value={register.current_zip_code} />
          </div>
          <div className="div-line">
            <SpanLabel
              width={3.7}
              label="ลักษณะที่พักของนักเรียน"
              subLabel="What kind of accommodation"
            />
            <RadioChoice choices={register.check_house_type} />
            <SpanLabel width={1.2} label="อื่น ๆ" subLabel="Other" />
            <SpanFill width={4.5} value={register.current_house_type} />
          </div>
          <div className="div-line">
            <SpanLabel
              width={6}
              styles={{ textAlign: "left", paddingLeft: ".1cm" }}
              label="ข้าพเจ้าขอส่งเอกสารการมอบตัว ดังนี้"
              subLabel="I would like to deliver the following document:"
            />
          </div>
          <div className="div-line">
            <Grid container style={{ padding: ".2cm 0cm" }}>
              {registration_documents.map((doc, key) => (
                <Grid
                  item
                  // xs={key < 9 ? 4 : 6}
                  xs={4}
                  key={key}
                  className="sarabun"
                  style={{ fontSize: "9pt" }}
                >
                  <span
                    style={{
                      margin: "0px .2cm",
                      verticalAlign: "top",
                    }}
                  >
                    {register.documents.findIndex((j) => j === doc.label_th) >
                    -1 ? (
                      <Icon>check_box</Icon>
                    ) : (
                      <Icon>check_box_outline_blank</Icon>
                    )}
                  </span>
                  <span style={{ display: "inline-block", width: "4.8cm" }}>
                    {doc.label_th}
                    <div style={{ fontSize: "7pt", color: "#999" }}>
                      {doc.label_en}
                    </div>
                  </span>
                </Grid>
              ))}
              <Grid item xs={12} style={{ fontSize: "9pt" }}>
                <span
                  style={{
                    margin: "0px .2cm",
                    verticalAlign: "top",
                  }}
                >
                  {register.other_documents &&
                  register.other_documents !== "" ? (
                    <Icon>check_box</Icon>
                  ) : (
                    <Icon>check_box_outline_blank</Icon>
                  )}
                </span>
                <span style={{ display: "inline-block" }}>
                  อื่น ๆ
                  <div style={{ fontSize: "7pt", color: "#999" }}>Other</div>
                </span>
                <SpanFill
                  width={15.8}
                  styles={{ lineHeight: "15pt" }}
                  value={register.other_documents}
                />
              </Grid>
            </Grid>
          </div>
          <div
            className="div-line sarabun"
            style={{
              fontSize: "8pt",
              textIndent: "2cm",
              textAlign: "justify",
              padding: "0cm .1cm",
            }}
          >
            ข้าพเจ้าขอรับรองว่าจะให้ความอุปการะเลี้ยงดู
            และรับผิดชอบต่อค่าธรรมเนียมต่าง ๆ ตลอดถึง
            ความประพฤติและยินดีปฏิบัติตามข้อบังคับของโรงเรียน
            และของกระทรวงศึกษาธิการ ข้าพเจ้าทราบ
            ระเบียบและข้อบังคับของโรงเรียนแล้ว
            จึงมอบตัวนักเรียนเข้าโรงเรียนต้นกล้าตั้งแต่บัดนี้เป็นต้นไป
          </div>
          <div
            className="div-line sarabun"
            style={{
              fontSize: "8pt",
              textIndent: "2cm",
              textAlign: "justify",
              color: "#999",
              padding: ".1cm .1cm",
            }}
          >
            I hereby certify that I will provide support and be responsible for
            all fees, including conduct and am willing to comply with the school
            regulations and the Ministry of Education. I understand the rules
            and regulations of the school, therefore delegating all
            responsibility of my student to Tonkla School.
          </div>

          <div className="div-line">
            <SpanLabel
              width={7}
              styles={{ textAlign: "right" }}
              label="ทั้งนี้ ให้เริ่มเข้าเรียนตั้งแต่วันที่"
              subLabel="Student will begin studying from"
            />
            <SpanFill width={6} styles={{ height: "12pt" }} value=" " />
          </div>
          <Grid container style={{ marginTop: ".5cm" }}>
            <Grid item xs={6} align="center">
              <div>
                <SpanLabel width={1.3} label="ลงชื่อ" subLabel="Sign" />
                <SpanFill width={5} styles={{ height: "12pt" }} value=" " />
                <SpanLabel width={1.5} label="ผู้ปกครอง" subLabel="Parent" />
              </div>
              <div style={{ fontSize: "10pt" }} className="sarabun">
                (
                <SpanFill
                  width={6}
                  styles={{ height: "12pt" }}
                  value={
                    register.contact_prefix +
                    register.contact_first_name +
                    " " +
                    (register.contact_middle_name || "") +
                    " " +
                    register.contact_last_name
                  }
                />
                )
                <div style={{ marginTop: ".2cm" }}>
                  .............../............................................./..............................
                </div>
              </div>
            </Grid>
            <Grid item xs={6} align="center">
              <div>
                <SpanLabel width={1.3} label="ลงชื่อ" subLabel="Sign" />
                <SpanFill width={5} styles={{ height: "12pt" }} value=" " />
                <SpanLabel
                  width={1.9}
                  label="ผู้อำนวยการ"
                  subLabel="Director"
                />
              </div>
              <div style={{ fontSize: "10pt" }} className="sarabun">
                (
                <SpanFill width={6} styles={{ height: "12pt" }} value=" " />)
                <div style={{ marginTop: ".2cm" }}>
                  .............../............................................./..............................
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="pagebreak">
            <StaffArea />
          </div>
          <br />
          <AgreementForm />
        </div>
      )}
    </>
  );
}

function AgreementForm() {
  const chkList1 = [
    {
      th: "ผู้ปกครองเห็นด้วยกับแนวคิดในการจัดการศึกษาของโรงเรียน และจะไม่เร่งรัดและเร่งรีบกับ การอ่าน การเขียนของเด็ก เพราะจะทำให้เกิดความเครียดได้ง่าย และเห็นด้วยกับการเน้นกระบวนการเรียนรู้ ผ่านการเล่น โดยใช้การเล่นเป็นสื่อกลางเพื่อการเรียนรู้ และประสบการณ์ชีวิต เพื่อให้เกิดความรัก การเรียน รักโรงเรียน และที่สำคัญเพื่อให้เด็กมีจินตนาการและความสุข",
      en: "Parents agree with the concept of the school’s education and will not rush the childs’ reading and writing processes because this may cause them stress. Parents agree with supporting the “Learn Through Play” method in order for their child to achieve a love of learning, love for school, and most importantly, to develop their imagination and happiness.",
    },
    {
      th: "ผู้ปกครองต้องมอบความไว้วางใจและเชื่อมั่น (Trust) ในแนวทางและกระบวนการของโรงเรียน มีทัศนคติเชิงบวก สนับสนุนและส่งเสริมการจัดการศึกษาตามบทบาทและหน้าที่ของผู้ปกครอง",
      en: "Parents should trust school guidelines and processes. Parents should have a positive mindset to support and promote educational management according to the roles and duties of the school, teachers, and parents",
    },
    {
      th: "ผู้ปกครองต้องไม่เข้าไปแทรกแซงการทำงานของคุณครูและโรงเรียน",
      en: "Parents must not interfere with the work of the teachers and the school.",
    },
    {
      th: "ผู้ปกครองยินดีจะเลี้ยงลูกที่สอดคล้องกับแนวทางของโรงเรียน ดังนี้",
      en: "Parents are willing to raise children that comply with the school’s guidelines as follows",
      sub: [
        {
          th: "อ่านนิทาน/หนังสือ ร่วมกับลูกวันละ 15 นาทีโดยประมาณ",
          en: "Read stories/books together with children, approximately 15 minutes a day.",
        },
        {
          th: "ส่งเสริมให้ลูกช่วยเหลือตัวเองตามศักยภาพของแต่ละช่วงวัย",
          en: "Encourage children to help themselves according to the potential of each age range.",
        },
        {
          th: "ส่งเสริมให้ลูกช่วยเหลืองานบ้านให้เหมาะสมกับวัย",
          en: "Encourage children to help with housework that is suitable for their ages.",
        },
        {
          th: "ไม่ให้ลูกทานอาหารฟาสต์ฟู้ดหรืออาหารที่ไม่มีประโยชน์",
          en: "Do not let your children eat fast food or unhealthy food.",
        },
        {
          th: "กำหนดเวลาในการใช้สื่อ เทคโนโลยี ไม่เกิน 2 ชั่วโมง/วัน",
          en: "Limit the use of technology to no more than 2 hours/day.",
        },
        {
          th: "คัดเลือกสื่อ/รายการ ที่เหมาะสมกับวัยแต่ละช่วงวัย",
          en: "Select media and programs that are suitable for children age",
        },
      ],
      break: true,
    },
    {
      th: "ผู้ปกครองต้องเข้าใจให้ชัดเจนว่า นักเรียนมาโรงเรียนเพื่อเรียนรู้ในการพัฒนาทักษะการใช้ชีวิต การอดทนรอคอย กระทบกระทั่งกับผู้อื่น มีสมหวัง มีผิดหวัง แบ่งปัน เสียใจ ดีใจ ไม่ได้มาเพื่อเรียนภาษาอังกฤษและคณิตศาสตร์เท่านั้น",
      en: "Parents must understand clearly that students come to school to learn how to develop life skills, not only the more practical subjects such as English or Math. These life skills can include patience, how to interact with others, how to deal with disappointment, how to share, and how to control emotions",
    },
    {
      th: "กรณีที่นักเรียนเกิดการกระทบกระทั่งและขัดแย้งระหว่างกัน ขอมอบให้โรงเรียนจัดการแก้ปัญหาภายในของนักเรียนไม่อนุญาตให้ผู้ปกครองเข้าไปแทรกแซง ไม่ว่ากรณีใด ๆ ทั้งสิ้น",
      en: "In the event that students are causing conflict and hurting one another, the school will handle the problems of students. Parents should not intervene in any case.",
    },
    {
      th: "เข้าร่วมประชุมกับโรงเรียนหรือพบครูประจำชั้นตามนัดหมายทุกครั้ง",
      en: "Parents should attend meetings with the school and the students’ home room teachers regularly by appointment.",
    },
    {
      th: "ให้นักเรียนลงทะเบียนเรียนตามระยะเวลาของหลักสูตร ดังนี้",
      en: "Parents must register their student for the courses as follow",
      subCol: [
        {
          th: "Pre Semester	ระหว่างเดือน เมษายน – พฤษภาคม",
          en: "Pre Semester (Between April – May)",
        },
        {
          th: "ภาคเรียนที่ 1 ระหว่างเดือน พฤษภาคม – กันยายน",
          en: "First Semester (Between May-September)",
        },
        {
          th: "ภาคเรียนที่ 2 ระหว่างเดือน พฤศจิกายน – กุมภาพันธ์",
          en: "Second Semester (Between September-February)",
        },
      ],
    },
    {
      th: "รับผิดชอบต่อค่าธรรมเนียมการเรียนต่าง ๆ ของนักเรียน หากผู้ปกครองลาออกหรือจบการศึกษาแล้วยังคงค้างชำระค่าธรรมเนียมการเรียนต่าง ๆ ผู้ปกครองยินดีที่จะให้โรงเรียนดำเนินการตามกฎหมาย",
      en: "Parents are responsible for the tuition fees of the student. If the student leaves or graduates with an outstanding payment, the school will proceed according to law",
    },
    {
      th: "โรงเรียนขอสงวนสิทธิ์การรับลงทะเบียน หากผู้ปกครองและโรงเรียนขาดขีดความสามารถในการทำงานเพื่อพัฒนานักเรียนร่วมกัน",
      en: "If the parents and the school lack the ability to develop the student together, the school reserves the right to accept or cancel registration.",
    },
  ];

  return (
    <div>
      <div style={{ fontSize: "15pt" }} className="center bold sarabun">
        บันทึกความร่วมมือระหว่างผู้ปกครองและโรงเรียน
      </div>
      <div
        style={{ fontSize: "8pt", color: "#888" }}
        className="sarabun center"
      >
        The Memorandum of Cooperation between Parents and School
      </div>
      <div
        style={{ fontSize: "15pt", marginTop: ".5cm" }}
        className="bold sarabun"
      >
        คำชี้แจง
      </div>
      <div
        style={{ fontSize: "8pt", color: "#888", marginBottom: ".2cm" }}
        className="sarabun"
      >
        Explanation
      </div>
      <div
        className="div-line sarabun"
        style={{
          fontSize: "10pt",
          textIndent: "2cm",
          textAlign: "justify",
          padding: "0cm .1cm",
        }}
      >
        โรงเรียนต้นกล้า ได้เล็งเห็นความสำคัญของการสร้างชุมชนแห่งการเรียนรู้
        โดยมีผู้ปกครองเป็นหนึ่งใน แรงขับเคลื่อนสำคัญ
        ที่จะเข้ามามีส่วนร่วมในการจัดการศึกษาที่มีคุณภาพให้แก่บุตรหลานของท่านบนพื้นฐานความเข้าใจทางการศึกษาร่วมกัน
        ดังนั้นโรงเรียนจึงขอความร่วมมือผู้ปกครอง ท่านยินดีปฏิบัติหรือไม่ ดังนี้
      </div>
      <div
        className="div-line sarabun"
        style={{
          fontSize: "8pt",
          textIndent: "2cm",
          textAlign: "justify",
          color: "#999",
          padding: ".1cm .1cm",
        }}
      >
        Tonkla School recognizes the importance of collaboration between parents
        and the school. Parents serve an integral role in a child's learning
        process. Therefore, Tonkla school believes in providing quality
        education for your child based on this mutual understanding. The school
        requests parent’s cooperation to act as follows
      </div>
      <div className="div-line sarabun bold" style={{ marginTop: ".3cm" }}>
        <span style={{ fontSize: "10pt", marginRight: ".3cm" }}>ยินดี</span>
        <span style={{ fontSize: "10pt" }}>ไม่ยินดี</span>
      </div>
      {chkList1.map((chk, key) => (
        <div key={key}>
          <Grid container spacing={2}>
            <Grid item style={{ width: "2.2cm" }}>
              <Icon style={{ marginRight: ".5cm" }}>check_box_outline</Icon>
              <Icon>check_box_outline_blank</Icon>
            </Grid>
            <Grid item style={{ width: "16.2cm" }}>
              <div
                className="div-line sarabun"
                style={{
                  fontSize: "10pt",
                  textAlign: "justify",
                  padding: "0cm .1cm",
                }}
              >
                {chk.th}
              </div>
              <div
                className="div-line sarabun"
                style={{
                  fontSize: "8pt",
                  textAlign: "justify",
                  color: "#999",
                  padding: ".1cm .1cm",
                }}
              >
                {chk.en}
              </div>
              <div
                style={{
                  marginBottom: ".1cm",
                }}
              >
                {chk.sub &&
                  chk.sub.map((sub, key) => (
                    <div key={key}>
                      <Grid container spacing={2}>
                        <Grid item style={{ width: ".8cm" }}>
                          <Icon>check_box_outline</Icon>
                        </Grid>
                        <Grid item style={{ width: "15cm" }}>
                          <div
                            className="div-line sarabun"
                            style={{
                              fontSize: "10pt",
                              textAlign: "justify",
                              padding: "0cm .1cm",
                            }}
                          >
                            {sub.th}
                          </div>
                          <div
                            className="div-line sarabun"
                            style={{
                              fontSize: "8pt",
                              textAlign: "justify",
                              color: "#999",
                              padding: "0cm .1cm",
                              paddingBottom: ".1cm",
                            }}
                          >
                            {sub.en}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                {chk.subCol &&
                  chk.subCol.map((sub, key) => (
                    <div key={key} style={{ paddingLeft: ".3cm" }}>
                      <div
                        className="div-line sarabun"
                        style={{
                          fontSize: "10pt",
                          textAlign: "justify",
                          padding: "0cm .1cm",
                        }}
                      >
                        {sub.th}
                      </div>
                      <div
                        className="div-line sarabun"
                        style={{
                          fontSize: "8pt",
                          textAlign: "justify",
                          color: "#999",
                          padding: "0cm .1cm",
                          paddingBottom: ".1cm",
                        }}
                      >
                        {sub.en}
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>
          {chk.break === true && <div className="pagebreak"></div>}
        </div>
      ))}

      <div
        className="div-line sarabun"
        style={{
          fontSize: "10pt",
          textIndent: "2cm",
          textAlign: "justify",
          padding: "0cm .1cm",
          marginTop: ".2cm",
        }}
      >
        บันทึกนี้
        เป็นส่วนหนึ่งของนโยบายโรงเรียนและข้าพเจ้าได้อ่านบันทึกความร่วมมือระหว่างผู้ปกครองและโรงเรียนอย่างถี่ถ้วนแล้ว
        ข้าพเจ้าเข้าใจดีว่าผู้ปกครองมีส่วนสำคัญในการพัฒนานักเรียนร่วมกับโรงเรียนเป็นอย่างยิ่ง
        ไม่ได้คาดหวังความสมบูรณ์จากโรงเรียนเพียงฝ่ายเดียวและยินดีปฏิบัติตามนโยบายของโรงเรียนทุกข้อ
      </div>
      <div
        className="div-line sarabun"
        style={{
          fontSize: "8pt",
          textIndent: "2cm",
          textAlign: "justify",
          color: "#999",
          padding: ".1cm .1cm",
        }}
      >
        This record is part of the school policy. I have thoroughly read all of
        the school policies and I understand that The parent is an important
        part in developing the students abilities, along with the school. I will
        not place full responsibility on the school alone and am willing to
        comply with all school policies.
      </div>

      <Grid container style={{ marginTop: "1.5cm" }}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} align="center">
          <div>
            <SpanLabel width={1.3} label="ลงชื่อ" subLabel="Sign" />
            <SpanFill width={5} styles={{ height: "12pt" }} value=" " />
            <SpanLabel width={1.9} label="ผู้ปกครอง" subLabel="Parent" />
          </div>
          <div style={{ fontSize: "10pt" }} className="sarabun">
            (
            <SpanFill width={6} styles={{ height: "12pt" }} value=" " />)
            <div style={{ marginTop: ".2cm" }}>
              .............../............................................./..............................
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function StaffArea() {
  return (
    <div
      style={{
        borderTop: "1px solid #111",
        marginTop: ".1cm",
        paddingTop: ".1cm",
      }}
    >
      <div className="sarabun">
        <div style={{ fontSize: "11pt", fontWeight: 400 }}>
          สำหรับเจ้าหน้าที่
        </div>
        <div style={{ color: "#999", fontSize: "8pt" }}>For Staff Only</div>
      </div>
      <Grid container style={{ marginTop: ".1cm" }}>
        <Grid item xs={5}>
          <SpanLabel
            width={3.6}
            label="เข้าเรียนระดับชั้น"
            subLabel="Register for"
          />
          <Icon style={{ marginRight: ".1cm" }}>check_box_outline_blank</Icon>
          <SpanLabel
            styles={{ textAlign: "left" }}
            width={2.5}
            label="เตรียมอนุบาล"
            subLabel="Pre-kindergarten"
          />
        </Grid>
        <Grid item xs={3}>
          <Icon style={{ marginRight: ".1cm" }}>check_box_outline_blank</Icon>
          <SpanLabel
            styles={{ textAlign: "left" }}
            width={2.1}
            label="อนุบาลชั้นปีที่"
            subLabel="Kindergarten"
          />
          <SpanFill width={1} value="/" />
        </Grid>
        <Grid item xs={4} style={{ paddingLeft: ".5cm" }}>
          <Icon style={{ marginRight: ".1cm" }}>check_box_outline_blank</Icon>
          <SpanLabel
            styles={{ textAlign: "left" }}
            width={2.3}
            label="ประถมศึกษาปีที่"
            subLabel="Primary"
          />
          <SpanFill width={1} value="/" />
        </Grid>
      </Grid>
      <div style={{ marginTop: ".1cm" }}>
        <SpanLabel
          styles={{ textAlign: "right" }}
          width={3.6}
          label="เลขประจำตัวนักเรียน"
          subLabel="Student ID"
        />
        <SpanFill width={4} value=" " styles={{ height: "12pt" }} />
      </div>
    </div>
  );
}

function RadioChoice(props) {
  const { choices } = props;
  const styles = {
    label: {
      padding: "0cm 0.1cm",
      display: "inline-block",
      textAlign: "center",
      fontSize: "9pt",
      verticalAlign: "top",
    },
  };
  return (
    <>
      {choices &&
        choices.map((choice, key) => (
          <Fragment key={key}>
            <span style={{ margin: "0px .2cm", verticalAlign: "top" }}>
              {choice.checked === true ? (
                <Icon>check_box</Icon>
              ) : (
                <Icon>check_box_outline_blank</Icon>
              )}
            </span>
            <span style={styles.label} className="sarabun">
              {choice.label}
              <div style={{ fontSize: "8pt", color: "#888" }}>
                {choice.subLabel}
              </div>
            </span>
          </Fragment>
        ))}
    </>
  );
}

function SpanFill(props) {
  const [style, setStyle] = useState({});
  useEffect(() => {
    function createStyle() {
      let { align, width, styles } = props;
      let style = {
        width: (width || 1) + "cm",
        textAlign: align || "center",
        borderBottom: "1px dotted #555",
        padding: "0.02cm 0.2cm",
        display: "inline-block",
        fontSize: "10pt",
        lineHeight: "10pt",
        verticalAlign: "top",
      };
      style = { ...style, ...styles };
      setStyle(style);
    }
    createStyle();
  }, [props]);
  return (
    <>
      <span style={style} className="sarabun">
        {props.value || "-"}
      </span>
    </>
  );
}

function SpanLabel(props) {
  const [style, setStyle] = useState({});
  useEffect(() => {
    function createStyle() {
      let { align, width, styles } = props;
      let style = {
        width: (width || 1) + "cm",
        textAlign: align || "center",
        display: "inline-block",
        fontSize: "9pt",
        verticalAlign: "top",
      };
      style = { ...style, ...styles };
      setStyle(style);
    }
    createStyle();
  }, [props]);
  return (
    <>
      <span style={style}>
        <div className="sarabun">{props.label}</div>
        <div style={{ fontSize: "8pt", color: "#888" }} className="sarabun">
          {props.subLabel}
        </div>
      </span>
    </>
  );
}
