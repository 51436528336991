import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import { getStampTime } from "../js/main";
export default function RegisterList(props) {
  const { registers, onSelect, state } = props;
  const [dateNow, setDateNow] = useState(0);

  useEffect(() => {
    async function getDate() {
      let dt = await getStampTime();
      setDateNow(dt.iso_date);
    }
    getDate();
  }, []);
  function checkStatus(status) {
    let r = false;
    if (state && state.checkStatus && state.checkStatus.indexOf(status) > -1)
      r = true;
    return r;
  }
  return (
    <div>
      {registers.count === 0 ? (
        <div style={{ color: "#aaa", textAlign: "center", marginTop: "30px" }}>
          No match found
        </div>
      ) : (
        <List component={Paper}>
          {registers.result.map((reg, key) => (
            <ListItem
              button
              style={
                key < registers.result.length - 1
                  ? { borderBottom: "1px solid #ddd" }
                  : {}
              }
              key={key}
              onClick={() => onSelect && onSelect(reg)}
            >
              <ListItemAvatar style={{ width: "90px" }}>
                <Avatar style={{ width: "60px", height: "60px" }}>
                  <Icon style={{ fontSize: "2.5rem" }}>person</Icon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span className="kanit">
                    {!reg.prefix && !reg.first_name && !reg.last_name ? (
                      <>No Name</>
                    ) : (
                      <>
                        {reg.prefix} {reg.first_name} {reg.last_name}{" "}
                      </>
                    )}

                    {reg.nickname && reg.nickname !== "" && (
                      <>({reg.nickname})</>
                    )}
                  </span>
                }
                secondary={
                  <>
                    {reg.contact_first_name && (
                      <>
                        <span
                          style={{ fontSize: ".8rem", color: "#393" }}
                          className="kanit"
                        >
                          {reg.contact_first_name} {reg.contact_last_name}
                        </span>
                        <br />
                      </>
                    )}
                    <span
                      style={{ fontSize: ".7rem", color: "#999" }}
                      className="kanit"
                    >
                      {!state || !state.showDate ? (
                        <ShowDate
                          label="Visit"
                          dt={reg.visit_schedules}
                          dateNow={dateNow}
                        />
                      ) : (
                        <>
                          {
                            {
                              interview_date: (
                                <ShowDate
                                  label="Interview"
                                  dt={[
                                    {
                                      visit_date: reg.interview_date,
                                      visit_time: reg.interview_time,
                                    },
                                  ]}
                                  dateNow={dateNow}
                                />
                              ),
                              registration_date: (
                                <ShowDate
                                  label="Registration date"
                                  dt={[
                                    {
                                      visit_date: reg.registration_date,
                                    },
                                  ]}
                                  dateNow={dateNow}
                                />
                              ),
                            }[state.showDate]
                          }
                        </>
                      )}
                      {checkStatus("visited") &&
                        reg.status_date.findIndex(
                          (j) => j.label === "เยี่ยมชม"
                        ) > -1 && (
                          <OtherStatus label="เยี่ยมชมแล้ว" color="#080" />
                        )}
                      {checkStatus("registered") &&
                        reg.status_date.findIndex((j) => j.label === "มอบตัว") >
                          -1 && <OtherStatus label="มอบตัวแล้ว" color="#080" />}
                      {checkStatus("registerPay") &&
                        reg.bill_no &&
                        reg.bill_no !== "" && (
                          <OtherStatus
                            label="ชำระเงินค่าสมัครแล้ว"
                            color="#080"
                          />
                        )}
                      {checkStatus("notPass") &&
                        reg.status_date.findIndex(
                          (j) => j.label === "ไม่ผ่าน"
                        ) > -1 && <OtherStatus label="ไม่ผ่าน" color="#f00" />}
                      {reg.waiver && reg.waiver === true && (
                        <OtherStatus label="สละสิทธิ" color="#f00" />
                      )}
                    </span>
                    <br />
                    <span
                      style={{ fontSize: ".7rem", color: "#090" }}
                      className="kanit"
                    >
                      e-Mail : {reg.email}, Phone : {reg.phone}
                    </span>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );
}

function OtherStatus({ label, color }) {
  return (
    <span
      className="kanit"
      style={{
        marginLeft: "10px",
        fontWeight: 400,
        color: color,
        fontSize: "1rem",
      }}
    >
      {label}
    </span>
  );
}

function ShowDate({ label, dt, dateNow }) {
  function visitDateStyle(d) {
    let r = { marginRight: "5px" };
    if (d < dateNow) r = { ...r, color: "#f00" };
    return r;
  }
  return (
    <>
      {label} :{" "}
      {dt.map((schedule, key) => (
        <span key={key} style={visitDateStyle(schedule.visit_date)}>
          {schedule.visit_date || "-"}{" "}
          <>{schedule.visit_time && "(" + schedule.visit_time + ")"}</>,
        </span>
      ))}
    </>
  );
}
