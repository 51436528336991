import Conf from "../Conf";
import axios from "axios";
import { months } from "../const";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export const fetchData = async (method, path, body) => {
  let result = await axios({
    method,
    url: Conf.endpoint.tonkla + path,
    data: body,

    headers: { Authorization: cookie.access_token },
  });
  if (result.data) {
    if (result.data.error) {
      alert(result.data.error.message);
      return null;
    } else return result.data;
  } else {
    alert("Error : bad request");
    return null;
  }
};

export const getPermission = () => {
  return new Promise(async (resolve) => {
    if (cookie && cookie.access_token) {
      let permission = await axios.get(
        Conf.endpoint.tonkla + "/ca/admin/permission",
        { headers: { Authorization: cookie.access_token } }
      );
      if (permission.data.usn) {
        resolve(permission.data);
      } else resolve(null);
    } else resolve(null);
  });
};
export const getAge = (dateString) => {
  var now = new Date();
  // var today = new Date(now.getYear(), now.getMonth(), now.getDate());

  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();

  var dob = new Date(dateString);

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};

  var yearAge = yearNow - yearDob;
  var monthAge = "";
  var dateAge = "";

  if (monthNow >= monthDob) monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) dateAge = dateNow - dateDob;
  else {
    monthAge--;
    dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  return age;
};

export const getStampTime = (dt) => {
  return new Promise(async (resolve) => {
    var d = new Date();
    if (dt) d = new Date(dt);
    var dd = String(d.getDate()).padStart(2, "0");
    var mm = String(d.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = d.getFullYear();
    var isoDate = yyyy + "-" + mm + "-" + dd;

    var h = String(d.getHours()).padStart(2, "0");
    var m = String(d.getMinutes()).padStart(2, "0");
    var s = String(d.getSeconds()).padStart(2, "0");
    resolve({
      iso_date: isoDate,
      time: h + ":" + m + ":" + s,
      detail: {
        day: dd,
        month: mm,
        year: yyyy,
        hour: h,
        minute: m,
        second: s,
      },
      stampTime: d.getTime(),
    });
  });
};

export const getFullDate = (dt) => {
  return new Promise(async (resolve) => {
    let arr = dt.split("-");
    if (arr.length === 3) {
      let r = parseInt(arr[1]) - 1;
      r = months[r].label;
      r = arr[2] + " " + r + " " + (parseInt(arr[0]) + 543);
      resolve(r);
    } else resolve(null);
  });
};

export const getCloses = () => {
  return new Promise(async (resolve) => {
    let closes = await axios.post(Conf.endpoint.tonkla + "/ca/dayclose");
    let r = [];
    for (let c of closes.data.result) {
      r.push({
        value: c.close_date,
      });
    }
    resolve(r);
  });
};

export const yearDiff = (dt1, dt2) => {
  return new Promise(async (resolve) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    let r = Math.abs(Math.round(diff / 365.25));
    resolve(r);
  });
};

export const monthDiff = (d1, d2) => {
  return new Promise(async (resolve) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    resolve(months <= 0 ? 0 : months);
  });
};

export const getLevelList = () => {
  return new Promise(async (resolve) => {
    let groups = await axios.post(
      Conf.endpoint.tonkla + "/ca/query",
      {
        collection: "groups",
      },
      { headers: { Authorization: cookie.access_token } }
    );

    let levels = [];
    for (let level of groups.data.result) {
      let label = level.level;
      if (level.level_year && level.level_year !== "-") {
        label += " " + level.level_year;
      }
      let group = { _id: level._id, name: level.name };
      let i = levels.findIndex((j) => j.label === label);
      if (i < 0) {
        levels.push({
          label,
          value: label,
          level_year: level.level_year,
          level_name: level.level,
          groups: [group],
          abbrev: level.level_abbrev,
        });
      } else {
        levels[i].groups.push(group);
      }
    }
    resolve(levels);
  });
};

export const getDocument = (collection, id) => {
  return new Promise(async (resolve) => {
    try {
      let register = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: collection,
          match: { _id: id },
        },
        { headers: { Authorization: cookie.access_token } }
      );
      if (register.data && register.data.count > 0)
        resolve(register.data.result[0]);
      else resolve({ count: 0, result: [] });
    } catch (e) {
      resolve({ count: 0, result: [] });
    }
  });
};

export const jsonSortDESC = (json, sortField) => {
  return new Promise(async (resolve) => {
    let r = await json.sort(function (a, b) {
      if (a[sortField] > b[sortField]) {
        return -1;
      } else if (a[sortField] < b[sortField]) {
        return 1;
      }
      return 0;
    });
    resolve(r);
  });
};
export const jsonSort = (json, sortField) => {
  return new Promise(async (resolve) => {
    let r = await json.sort(function (a, b) {
      if (a[sortField] < b[sortField]) {
        return -1;
      } else if (a[sortField] > b[sortField]) {
        return 1;
      }
      return 0;
    });
    resolve(r);
  });
};
export const getSchoolYear = (date) => {
  return new Promise(async (resolve) => {
    let r = "";
    let arr = date.split("-");
    if (arr.length === 3) {
      r = parseInt(arr[0]) + 543;
      /*
      if (arr[1] > "04") {
        if ((arr[1] === "05" && arr[2] > "15") || arr[1] > "05")
          r = parseInt(arr[0]) + 543 + 1;
      }
          */
      if (arr[1] > "06") {
        r = parseInt(arr[0]) + 543 + 1;
      }
    }
    resolve(r);
  });
};

export const getOTP = () => {
  return new Promise(async (resolve) => {
    let otp = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
    resolve(otp.data);
  });
};

export const diffYearMonthDay = (dt1, dt2) => {
  return new Promise(async (resolve) => {
    var months;
    months = (dt2.getFullYear() - dt1.getFullYear()) * 12;
    months -= dt1.getMonth();
    months += dt2.getMonth();
    months = months <= 0 ? 0 : months;
    if (dt2.getDate() + 1 < dt1.getDate()) months--;
    let diffTime = Math.abs(dt2 - dt1);
    let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    let years = Math.floor(months / 12);
    resolve({
      year: years,
      month: months,
      days,
    });
  });
};
