import React, { useState, useEffect, Fragment } from "react";
import "./styles.css";
// import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import axios from "axios";
import Conf from "../Conf";
import { useParams } from "react-router-dom";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const styles = {
  h1: { fontSize: "11pt", fontWeight: 400, paddingTop: "0.5cm" },
  h2: { fontSize: "11pt", paddingLeft: ".5cm", paddingTop: ".3cm" },
  h3: { fontSize: "11pt", paddingLeft: "1.2cm", paddingTop: ".3cm" },
  fill: { paddingTop: ".3cm", lineHeight: "13pt", fontSize: "11pt" },
  subTitle: { fontSize: "10pt", color: "#888" },
  subTitleH1: { fontSize: "10pt", color: "#888", paddingLeft: ".5cm" },
  subTitleH2: {
    fontSize: "10pt",
    color: "#888",
    paddingLeft: ".6cm",
    textAlign: "left",
  },
  underline: { borderBottom: "1px dotted #333" },
};
export default function Healthy(props) {
  const params = useParams();
  const register_id = params.id;
  const [register, setRegister] = useState();
  const [healthy, setHealthy] = useState();
  useEffect(() => {
    async function getRegister() {
      let register = await axios.get(
        Conf.endpoint.tonkla + "/ca/query/registers/" + register_id,
        {
          headers: { Authorization: cookie.access_token },
        }
      );

      let OTP = await axios.get(Conf.endpoint.tonkla + "/ca/otp");
      let healthy = await axios.get(
        Conf.endpoint.tonkla + "/ca/healthy/register/" + register_id,
        { headers: { Authorization: OTP.data } }
      );
      register = register.data;
      healthy = healthy.data;
      if (register && healthy) {
        setRegister(register);
        console.log(healthy);
        setHealthy(healthy);
        console.log(register);
      }
    }
    if (register_id) getRegister();
  }, [register_id]);
  useEffect(() => {
    if (register) {
      setTimeout(() => {
        window.print();
      }, 2000);
    }
  }, [register]);
  return (
    <>
      {healthy && (
        <div style={{ width: "18cm", margin: "auto" }}>
          <div
            style={{ fontSize: "18pt", textAlign: "center" }}
            className="sarabun"
          >
            ข้อมูลด้านสุขภาพของนักรียน ด้านพฤติกรรมและอุปนิสัย
          </div>
          <div style={styles.subTitle} className="center sarabun">
            Student Health information and behavior habit
          </div>
          <div className="bold center sarabun" style={{ marginTop: ".3cm" }}>
            {register.prefix} {register.first_name} {register.last_name} (
            {register.nickname})
          </div>

          <SpanLabel
            width={10}
            label="1. ข้อมูลด้านสุขภาพของนักเรียน"
            subLabel="Student Health information"
            styles={styles.h1}
            subStyles={styles.subTitleH1}
            align="left"
          />
          <SpanLabel
            width={10}
            label="1.1 ประวัติการคลอด"
            subLabel="Childbirth information"
            styles={styles.h2}
            subStyles={styles.subTitleH2}
            align="left"
          />
          <div style={{ marginTop: ".3cm" }}>
            <SpanLabel
              width={1.7}
              label="วิธีคลอด"
              styles={{ paddingLeft: "1.2cm" }}
              align="left"
            />
            <SpanFill width={3} value={healthy.childbirth_way} />
            <SpanLabel width={3} label="ชื่อโรงพยาบาล" />
            <SpanFill width={8.3} value={healthy.childbirth_place} />
          </div>
          <div>
            <SpanLabel
              width={2}
              label="เวลาคลอด"
              styles={{ paddingLeft: "1.2cm" }}
              align="left"
            />
            <SpanFill width={14.4} value={healthy.childbirth_time} />
          </div>
          <div>
            <SpanLabel
              width={1.8}
              label="อายุครรภ์"
              align="left"
              styles={{ paddingLeft: "1.2cm" }}
            />
            <SpanFill width={1} value={healthy.gestational_age} />
            <SpanLabel width={3} label="น้ำหนักแรกเกิด" />
            <SpanFill width={1.6} value={healthy.birth_weight} />
            <SpanLabel width={3} label="น้ำหนักปัจจุบัน" />
            <SpanFill width={1.6} value={healthy.current_weight} />
            <SpanLabel width={1.5} label="ส่วนสูง" />
            <SpanFill width={1.7} value={healthy.height} />
            <SpanLabel
              width={4}
              label="การได้รับภูมิคุ้มกันโรค"
              styles={{ paddingLeft: "1.2cm" }}
              subStyles={{ fontSize: "10pt" }}
              align="left"
            />
            <SpanFill width={12.4} value={healthy.vaccination} />
          </div>

          <div style={{ paddingTop: ".1cm" }}>
            <SpanLabel
              width={5.5}
              label="1.2 ประวัติการเจ็บป่วย"
              subLabel="Illness Information"
              styles={styles.h2}
              subStyles={styles.subTitleH2}
              align="left"
            />
            <SpanFill
              width={11.3}
              value={
                healthy.Illness_Info && healthy.Illness_Info[0]
                  ? healthy.Illness_Info.map((item) => item + ", ")
                  : "-"
              }
              styles={styles.h2}
            />
            <SpanLabel
              width={4.8}
              label="เคยเข้ารับการผ่าตัด (ครั้ง)"
              subLabel="Operation Time"
              align="left"
              styles={{ paddingLeft: "1.2cm" }}
            />
            <SpanFill width={11.6} value={healthy.operation_time} />
            <SpanLabel
              width={4.8}
              label="รายละเอียดการผ่าตัด"
              subLabel="Operation Detail"
              align="left"
              styles={{ paddingLeft: "1.2cm" }}
            />
            <SpanFill width={11.6} value={healthy.operation_detail} />
            <SpanLabel
              width={15}
              label="ประวัติการเข้ารักษาเป็นผู้ป่วยใน (ระบุสถานพยาบาลและอาการ / โรคที่เป็น)"
              subLabel="Inpatient treatment history (please state the disease and the name of the hospital)"
              align="left"
              styles={{ paddingLeft: "1.2cm" }}
            />
            <SpanFill
              width={16.4}
              align="left"
              value={healthy.operation_detail}
              styles={{ marginLeft: "1.2cm", marginTop: ".2cm" }}
            />
          </div>

          {healthy.congenital_disease && healthy.congenital_disease[0] && (
            <div style={{ paddingTop: ".3cm" }}>
              <SpanLabel
                width={16}
                label={
                  <>
                    <Icon style={{ marginRight: ".3cm", fontSize: "13pt" }}>
                      coronavirus
                    </Icon>
                    โรคประจำตัว (Congenital Disease)
                  </>
                }
                align="left"
                styles={{ paddingLeft: "1.2cm" }}
              />
              {healthy.congenital_disease.map((item, key) => (
                <div key={key}>
                  <SpanLabel
                    width={1.5}
                    label="ชื่อโรค"
                    align="left"
                    styles={{ paddingLeft: "1.2cm" }}
                  />
                  <SpanFill width={5.5} value={item.disease_name} />
                  <SpanLabel width={1.7} label="อาการ" />
                  <SpanFill width={7.3} value={item.symptoms} />
                </div>
              ))}
            </div>
          )}

          {healthy.drug_allergy && healthy.drug_allergy[0] && (
            <div style={{ paddingTop: ".3cm" }}>
              <SpanLabel
                width={16}
                label={
                  <>
                    <Icon style={{ marginRight: ".3cm", fontSize: "13pt" }}>
                      medication_liquid
                    </Icon>
                    การแพ้ยา (Drug allergy)
                  </>
                }
                align="left"
                styles={{ paddingLeft: "1.2cm" }}
              />
              {healthy.drug_allergy.map((item, key) => (
                <div key={key}>
                  <SpanLabel
                    width={1.5}
                    label="ชื่อโรค"
                    align="left"
                    styles={{ paddingLeft: "1.2cm" }}
                  />
                  <SpanFill width={5.5} value={item.drug_name} />
                  <SpanLabel width={1.7} label="อาการ" />
                  <SpanFill width={7.3} value={item.symptoms} />
                </div>
              ))}
            </div>
          )}

          {healthy.food_allergy && healthy.food_allergy[0] && (
            <div style={{ paddingTop: ".3cm" }}>
              <SpanLabel
                width={16}
                label={
                  <>
                    <Icon style={{ marginRight: ".3cm", fontSize: "13pt" }}>
                      fastfood
                    </Icon>
                    การแพ้อาหาร (Drug allergy)
                  </>
                }
                align="left"
                styles={{ paddingLeft: "1.2cm" }}
              />
              {healthy.food_allergy.map((item, key) => (
                <div key={key}>
                  <SpanLabel
                    width={1.5}
                    label="ชื่อโรค"
                    align="left"
                    styles={{ paddingLeft: "1.2cm" }}
                  />
                  <SpanFill width={5.5} value={item.food_name} />
                  <SpanLabel width={1.7} label="อาการ" />
                  <SpanFill width={7.3} value={item.symptoms} />
                </div>
              ))}
            </div>
          )}

          <div style={{ paddingTop: ".1cm" }}>
            <SpanLabel
              width={5.5}
              label="1.3 ประวัติการประสบอุบัติเหตุ"
              subLabel="Accident information"
              styles={styles.h2}
              subStyles={styles.subTitleH2}
              align="left"
            />
            <SpanFill
              width={11.6}
              align="left"
              value={healthy.accident_info}
              styles={styles.fill}
            />

            <SpanLabel
              width={4.8}
              label="สุขภาพโดยทั่วไปของนักเรียน"
              subLabel="Student health"
              align="left"
              styles={styles.h3}
            />
            <SpanFill
              width={11.6}
              styles={styles.fill}
              value={healthy.overall_health}
            />
            <SpanLabel
              width={16}
              label="รายละเอียดกรณีที่เด็กต้องได้รับการดูแลเป็นพิเศษ"
              subLabel="Detail of what kind of special care does your child need"
              align="left"
              styles={styles.h3}
            />
            <SpanFill
              width={16.6}
              styles={{ ...styles.fill, ...{ marginLeft: "1.2cm" } }}
              value={healthy.special_care}
              align="left"
            />
            <SpanLabel
              width={16}
              label="ยาประจำตัวตามแพทย์จัดให้"
              subLabel="Personal Medication"
              align="left"
              styles={styles.h3}
            />
            <SpanFill
              width={16.6}
              styles={{ ...styles.fill, ...{ marginLeft: "1.2cm" } }}
              value={healthy.personal_medication}
              align="left"
            />
          </div>

          <div>
            <SpanLabel
              width={16}
              label="2. ข้อมูลด้านพฤติกรรมและอุปนิสัย"
              subLabel="Behavior habit information"
              styles={styles.h1}
              subStyles={styles.subTitleH1}
              align="left"
            />
            <SpanLabel
              width={3}
              label="2.1 การขับถ่าย"
              subLabel="Excretion"
              styles={styles.h2}
              subStyles={styles.subTitleH2}
              align="left"
            />
            <SpanFill
              width={5.1}
              styles={styles.fill}
              value={healthy.excretion}
            />
            <SpanLabel
              width={3}
              label="2.2 การดื่มนม"
              subLabel="Milk drinking"
              styles={styles.h2}
              subStyles={styles.subTitleH2}
              align="left"
            />
            <SpanFill
              width={5.1}
              styles={styles.fill}
              value={healthy.milk_drinking}
            />
            <SpanLabel
              width={3}
              label="2.3 การนอน"
              subLabel="Sleeping habit"
              styles={styles.h2}
              subStyles={styles.subTitleH2}
              align="left"
            />
            <SpanFill
              width={2.5}
              styles={styles.fill}
              value={
                <>
                  {healthy.go_bed} - {healthy.wake_up}
                </>
              }
            />
            <SpanLabel
              width={4.5}
              label="ปัญหาเกี่ยวกับการนอน"
              styles={{ paddingTop: ".3cm" }}
              subLabel="Any problems or concerns"
              subStyles={styles.subTitleH2}
            />
            <SpanFill
              width={6.7}
              styles={styles.fill}
              value={healthy.sleep_problem}
            />
            <SpanLabel
              width={4.3}
              label="นอนด้วยวิธีการ"
              align="left"
              styles={styles.h2}
              subLabel="Any problems or concerns"
            />
            <SpanFill
              width={12.8}
              styles={styles.fill}
              value={healthy.how_to_sleep}
              align="left"
            />

            <SpanLabel
              width={4.3}
              label="2.4 การรับประทานอาหาร"
              subLabel="Eating habit"
              styles={styles.h2}
              subStyles={styles.subTitleH2}
              align="left"
            />
            <SpanFill
              width={12.8}
              styles={styles.fill}
              value={healthy.eating_habit}
              align="left"
            />
            <SpanLabel
              width={3.7}
              label="รายละเอียด"
              subLabel="Detail"
              styles={{ marginLeft: "1.1cm" }}
              align="left"
            />
            <SpanFill
              width={12.8}
              styles={styles.fill}
              value={healthy.eating_habit_detail}
              align="left"
            />
            <SpanLabel
              width={3.7}
              label="เหตุผล"
              subLabel="Becouse"
              styles={{ marginLeft: "1.1cm" }}
              align="left"
            />
            <SpanFill
              width={12.8}
              styles={styles.fill}
              value={healthy.eating_habit_because}
              align="left"
            />
          </div>
        </div>
      )}
    </>
  );
}

function SpanLabel(props) {
  const [style, setStyle] = useState({});
  const [subStyle, setSubStyle] = useState({});
  useEffect(() => {
    function createStyle() {
      let { align, width, styles, subStyles } = props;
      let style = {
        width: (width || 1) + "cm",
        textAlign: align || "center",
        display: "inline-block",
        fontSize: "11pt",
        verticalAlign: "top",
      };
      style = { ...style, ...styles };
      setStyle(style);
      style = { fontSize: "10pt", color: "#888", textAlign: "left" };
      style = { ...style, ...subStyles };
      setSubStyle(style);
    }
    createStyle();
  }, [props]);
  return (
    <>
      <span style={style}>
        <div className="sarabun">{props.label}</div>
        <div style={subStyle} className="sarabun">
          {props.subLabel}
        </div>
      </span>
    </>
  );
}

function SpanFill(props) {
  const [style, setStyle] = useState({});
  useEffect(() => {
    function createStyle() {
      let { align, width, styles } = props;
      let style = {
        width: (width || 1) + "cm",
        textAlign: align || "center",
        borderBottom: "1px dotted #555",
        padding: "0.02cm 0.2cm",
        display: "inline-block",
        fontSize: "11pt",
        lineHeight: "11pt",
        verticalAlign: "top",
      };
      style = { ...style, ...styles };
      setStyle(style);
    }
    createStyle();
  }, [props]);
  return (
    <>
      <span style={style} className="sarabun">
        {props.value || "-"}
      </span>
    </>
  );
}
