import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import Conf from "../Conf";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const dataSet = [
//   {
//     name: "Johson",
//     amount: 30000,
//     sex: "M",
//     is_married: true,
//   },
//   {
//     name: "Monika",
//     amount: 355000,
//     sex: "F",
//     is_married: false,
//   },
//   {
//     name: "John",
//     amount: 250000,
//     sex: "M",
//     is_married: false,
//   },
//   {
//     name: "Josef",
//     amount: 450500,
//     sex: "M",
//     is_married: true,
//   },
// ];

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Excel({ columns, sheet }) {
  const [data, setData] = useState();
  useEffect(() => {
    console.log(cookie.excel);
    let data = {};
    if (cookie && cookie.excel) {
      data.columns = cookie.excel.columns;
      data.sheet = cookie.excel.sheet;
    }
    setData(data);
  }, []);

  return (
    <div>
      {data && data.sheet && (
        <ExcelFile hideElement={true}>
          <ExcelSheet data={data.sheet} name="Sheet 1">
            {data.columns.map((col, key) => (
              <ExcelColumn label={col.label} key={key} value={col.value} />
            ))}
          </ExcelSheet>
        </ExcelFile>
      )}
    </div>
  );
}
