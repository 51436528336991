import React, { useState, useEffect } from "react";
import { getPermission, getStampTime, getLevelList } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import MultiType from "../charts/MultiType";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import TableStudentStatus from "../components/TableStudentStatus";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function RegisterLevel({ page }) {
  const [profile, setProfile] = useState();
  const [state, setState] = useState();
  const [report, setReport] = useState();
  const [registers, setRegisters] = useState();
  const [loading, setLoading] = useState(true);
  const [levels, setLevels] = useState([]);
  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      setProfile(profile);
      let dt = await getStampTime();
      let years = [];
      for (let i = dt.detail.year - 10; i < dt.detail.year + 5; i++) {
        years.push({ value: i, label: i + 543 });
      }
      let levels = await getLevelList();
      setLevels(levels);
      setState({
        schoolYear: dt.detail.year + 1,
        years,
        levels,
        showReport: 0,
      });
    })();
  }, []);
  useEffect(() => {
    async function getReport() {
      setLoading(true);
      // let startDate = state.schoolYear - 1 + "-07-01";
      // let endDate = state.schoolYear + "-07-01";
      let label = "สมัครเรียน";
      if (state.showReport === 1) label = "มอบตัว";
      let match = {
        $and: [
          { "status_date.label": label },
          // { attending_year: { $gte: startDate } },
          // { attending_year: { $lt: endDate } },
          { attending_year: state.schoolYear },
          { term: { $exists: true } },
          { grade_year: { $exists: true } },
          { level: { $exists: true } },
        ],
      };
      let registers = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "registers",
          select: "level grade_year attending_year term",
          limit: 100000,
          match,
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      console.log(registers.data);
      setRegisters(registers.data.result);
    }
    if (state) getReport();
  }, [state]);
  useEffect(() => {
    async function getReport() {
      let report = [
        { term: "1", levels: JSON.parse(JSON.stringify(levels)) },
        { term: "2", levels: JSON.parse(JSON.stringify(levels)) },
      ];
      for (let register of registers) {
        let i = report.findIndex((j) => j.term === register.term);
        if (i > -1) {
          let j = report[i].levels.findIndex(
            (j) =>
              j.level_name === register.level &&
              j.level_year === register.grade_year
          );
          if (j > -1 && report[i].levels[j].count) {
            report[i].levels[j].count++;
            report[i].levels[j].register_ids.push(register._id);
          } else {
            report[i].levels[j].count = 1;
            report[i].levels[j].register_ids = [register._id];
          }
        }
      }
      setLoading(false);
      setReport(report);
    }
    if (registers) getReport();
  }, [registers, levels]);
  function changeSchoolYear(val) {
    let startDate = val - 1 + "-07-01";
    let endDate = val + "-07-01";
    setState({ ...state, schoolYear: val, startDate, endDate });
  }
  return (
    <div className="content">
      {state && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />

          <Grid container spacing={1}>
            <Grid item>
              <FormControl variant="outlined" value={state.showReport}>
                <InputLabel shrink>เลือกรายงาน</InputLabel>
                <Select
                  native
                  label="เลือกรายงาน"
                  value={state.showReport}
                  style={{ width: "400px" }}
                  onChange={(e) =>
                    setState({ ...state, showReport: parseInt(e.target.value) })
                  }
                  size="small"
                >
                  {["รายงานสถิติการสมัครเรียน", "รายงานสถิติการมอบตัว"].map(
                    (item, key) => (
                      <option key={key} value={key}>
                        {item}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                style={{ width: "150px" }}
                value={state.schoolYear}
              >
                <InputLabel shrink>ปีการศึกษา</InputLabel>
                <Select
                  native
                  label="ปีการศึกษา"
                  size="small"
                  value={state.schoolYear}
                  onChange={(e) => changeSchoolYear(e.target.value)}
                >
                  {state.years.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          {loading === true && <LinearProgress />}
          {report && (
            <>
              <ReportTable report={report} />
              <br />
              <ChartArea state={{ title: "รายงาน", report: report }} />
            </>
          )}
        </>
      )}
    </div>
  );
}

function ChartArea({ state }) {
  const [data, setData] = useState();
  useEffect(() => {
    function rendomColor(opacity) {
      return new Promise(async (resolve) => {
        let r = Math.floor(Math.random() * 256);
        let g = Math.floor(Math.random() * 256);
        let b = Math.floor(Math.random() * 256);
        let txt = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
        resolve(txt);
      });
    }
    async function createData() {
      let labels = [];

      let datasets = [
        {
          type: "bar",
          label: "Term 1",
          borderColor: await rendomColor(1),
          backgroundColor: await rendomColor(0.8),
          borderWidth: 1,
          fontSize: 40,
          fill: false,
          data: [],
        },
        {
          type: "bar",
          label: "Term 2",
          borderColor: await rendomColor(1),
          backgroundColor: await rendomColor(0.8),
          borderWidth: 1,
          fontSize: 40,
          fill: false,
          data: [],
        },
      ];
      let key = 0;
      for (let term of state.report) {
        for (let level of term.levels) {
          let i = labels.findIndex((j) => j === level.abbrev);
          if (i < 0) labels.push(level.abbrev);
          if (level.count) datasets[key].data.push(level.count);
          else datasets[key].data.push(0);
        }
        key++;
      }
      let data = {
        labels: labels,
        datasets: datasets,
      };
      setData(data);
    }
    if (state.report) createData();
  }, [state]);
  return (
    <div>
      {data && (
        <Grid container component={Paper}>
          <Grid item xs={12} style={{ padding: "20px 20px" }}>
            <MultiType
              state={{
                title: state.title,
                data: data,
                version: 1,
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function ReportTable({ report }) {
  const [listMatch, setListMatch] = useState();
  useEffect(() => {
    setListMatch(null);
  }, [report]);
  function showRegisterList(level) {
    if (level.register_ids && level.register_ids.length > 0) {
      setListMatch({ _id: { $in: level.register_ids } });
    } else setListMatch(null);
  }
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: "80px" }}
                className="td"
                align="center"
              >
                ลำดับ
              </TableCell>
              <TableCell className="td" align="center">
                ภาคเรียน
              </TableCell>
              {report[0].levels.map((level) => (
                <TableCell className="td" align="center" key={level.abbrev}>
                  {level.abbrev}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {report.map((r, key) => (
              <TableRow key={key}>
                <TableCell className="td" align="center">
                  {key + 1}
                </TableCell>
                <TableCell className="td" align="center">
                  {r.term}
                </TableCell>
                {report[key].levels.map((level) => (
                  <TableCell
                    className="td"
                    align="center"
                    key={level.abbrev}
                    style={{ width: "70px" }}
                  >
                    <Button
                      onClick={() => showRegisterList(level)}
                      className="kanit controlTd"
                    >
                      {level.count || 0}
                    </Button>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {listMatch && (
        <TableStudentStatus match={listMatch} title="รายชื่อผู้สมัครเรียน" />
      )}
    </div>
  );
}
