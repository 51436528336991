import React from "react";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

export default function SimpleBreadcrumbs(props) {
  const { breadcrumbs } = props;
  const navigate = useNavigate();
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "20px" }}>
      {breadcrumbs.map((menu, key) => (
        <div key={key}>
          {menu.href ? (
            <Link
              color="inherit"
              component="a"
              href="#"
              onClick={() => navigate(menu.href)}
            >
              {menu.label}
            </Link>
          ) : (
            <Typography
              color="textPrimary"
              component="a"
              style={{ fontWeight: 300 }}
            >
              {menu.label}
            </Typography>
          )}
        </div>
      ))}
    </Breadcrumbs>
  );
}
