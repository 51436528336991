import React from "react";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

export default function PageNameBox(props) {
  const { page } = props;
  return (
    <div>
      <Grid container>
        <Grid item style={{ width: "45px", paddingTop: "4px" }}>
          <Icon style={{ fontSize: "2rem" }}>{page.icon}</Icon>
        </Grid>
        <Grid item>
          <h1 style={{ padding: "0px 0px", margin: "0px", fontSize: "1.6rem" }}>
            {page.label}
          </h1>
          {page.alt_label && (
            <div style={{ color: "#666" }}>{page.alt_label}</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
