import * as React from "react";
// import DateAdapter from "@mui/lab/AdapterDateFns";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import Conf from "../Conf";
import { getStampTime } from "../js/main";
import dayjs from "dayjs";
import moment from "moment";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function DialogChangeStatus(props) {
  const { open, status, register_id } = props;

  const [value, setValue] = React.useState(new Date());

  const handleChange = (newValue) => {
    newValue = moment(new Date(newValue)).format("YYYY-MM-DD");
    setValue(newValue);
  };
  async function changeStatus() {
    let change_date = await getStampTime(value);
    change_date = change_date.iso_date;
    await axios.put(
      Conf.endpoint.tonkla + "/ca/register/status",
      {
        register_id: register_id,
        status: status,
        change_date: change_date,
      },
      { headers: { Authorization: cookie.access_token } }
    );
    props.onClose();
  }
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">เปลี่ยนสถานะ</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ paddingTop: "10px", minWidth: "300px" }}
            component="div"
          >
            ต้องการเปลี่ยนสถานะเป็น{" "}
            <span style={{ color: "#00f" }}>{status}</span> หรือไม่
            <br />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="วันที่เปลี่ยนสถานะ"
                inputFormat="YYYY-MM-DD"
                value={dayjs(value)}
                onChange={handleChange}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </LocalizationProvider>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => changeStatus()}
            component="div"
            style={{ color: "#666" }}
          >
            เปลี่ยน
          </Button>
          <Button
            onClick={() => props.onClose()}
            component="div"
            style={{ color: "#666" }}
          >
            ไม่เปลี่ยน
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
