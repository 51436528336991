import React, { useState, useEffect } from "react";
import uuid from "react-uuid";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import DialogZip from "../../components/DialogZip";
import { getStampTime } from "../../js/main";
import {
  GridSelectItem,
  RadioForm,
  GridInputItem as GridInput,
} from "../../components/InputForm";
import { genders, registration_documents } from "../../const";
import dayjs from "dayjs";

const styles = {
  title: {
    fontWeight: 500,
    marginTop: "30px",
  },
};
export default function FormStudentInfo({
  student,
  state,
  changeForm,
  changeFields,
}) {
  const [form, setForm] = useState({});
  const [dialogState, setDialogState] = useState({ open: false });
  useEffect(() => {
    if (student) {
      setForm(student);
    }
  }, [student]);
  function changeValue(e) {
    let id = e.target.id;
    let value = e.target.value;
    changeForm(id, value);
  }

  function selectArea(area) {
    if (dialogState.type === "current_") {
      changeFields({
        current_area: area.area,
        current_district: area.district,
        current_province: area.province,
      });
    } else {
      changeFields({
        area: area.area,
        district: area.district,
        province: area.province,
      });
    }
    setDialogState({ ...dialogState, open: false });
  }
  async function changeDate(val) {
    val = await getStampTime(val);
    val = val.iso_date;
    changeFields({
      birth_date: val,
    });
  }
  function copyAddress() {
    changeFields({
      current_house_number: form.house_number,
      current_village: form.village,
      current_moo: form.moo,
      current_soi: form.soi,
      current_street: form.street,
      current_zip_code: form.zip_code,
      current_area: form.area,
      current_district: form.district,
      current_province: form.province,
    });
  }
  function copyCurrentAddress() {
    changeFields({
      house_number: form.current_house_number,
      village: form.current_village,
      moo: form.current_moo,
      soi: form.current_soi,
      street: form.current_street,
      zip_code: form.current_zip_code,
      area: form.current_area,
      district: form.current_district,
      province: form.current_province,
    });
  }
  return (
    <div>
      <DialogZip
        state={dialogState}
        onClose={() => setDialogState({ open: false })}
        onSelect={selectArea}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            align="center"
            component="h1"
            style={{ fontSize: "1.5rem" }}
          >
            ข้อมูลนักเรียน
            <div style={{ color: "#888", fontSize: "1rem" }}>
              Student information
            </div>
          </Grid>
          <Grid item xs={12}>
            <h3 style={styles.title}>ข้อมูลส่วนตัวนักเรียน</h3>
          </Grid>
          <>
            <GridSelectItem
              size={[12, 6, 3, 3]}
              label="Prefix"
              id="prefix"
              options={state.student_prefixs || []}
              changeValue={changeValue}
              value={form.prefix || "-"}
            />
            <GridInput
              size={[12, 6, 3, 3]}
              label="Student first name"
              placeholder="ชื่อนักเรียน"
              id="first_name"
              changeValue={changeValue}
              value={form.first_name}
            />
            <GridInput
              size={[12, 6, 3, 3]}
              label="Student middle name"
              placeholder="ชื่อกลางนักเรียน"
              id="middle_name"
              changeValue={changeValue}
              value={form.middle_name}
            />
            <GridInput
              size={[12, 6, 3, 3]}
              label="Student last name"
              id="last_name"
              placeholder="นามสกุลนักเรียน"
              changeValue={changeValue}
              value={form.last_name}
            />

            <GridSelectItem
              size={[12, 6, 3, 3]}
              label="Prefix (EN)"
              id="prefix_en"
              options={state.student_prefixs_en || []}
              changeValue={changeValue}
              value={form.prefix_en || "-"}
            />
            <GridInput
              size={[12, 6, 3, 3]}
              label="Student first name (EN)"
              placeholder="ชื่อนักเรียน (ภาษาอังกฤษ)"
              id="first_name_en"
              changeValue={changeValue}
              value={form.first_name_en}
            />
            <GridInput
              size={[12, 6, 3, 3]}
              label="Student middle name (EN)"
              placeholder="ชื่อกลางนักเรียน (ภาษาอังกฤษ)"
              id="middle_name_en"
              changeValue={changeValue}
              value={form.middle_name_en}
            />
            <GridInput
              size={[12, 6, 3, 3]}
              label="Student last name (EN)"
              id="last_name_en"
              placeholder="นามสกุลนักเรียน (ภาษาอังกฤษ)"
              changeValue={changeValue}
              value={form.last_name_en}
            />
            <GridInput
              size={[12, 12, 4, 4]}
              label="Nickname"
              placeholder="ชื่อเล่น"
              changeValue={changeValue}
              id="nickname"
              value={form.nickname}
            />
            <GridInput
              size={[12, 12, 4, 4]}
              label="Nickname (EN)"
              placeholder="ชื่อเล่น (EN)"
              changeValue={changeValue}
              id="nickname_en"
              value={form.nickname_en}
            />

            <GridInput
              size={[12, 12, 4, 4]}
              label="ID card number"
              placeholder="เลขบัตรประชาชน"
              changeValue={changeValue}
              id="idx"
              value={form.idx}
            />
            <GridInput
              size={[12, 12, 4, 4]}
              label="Passport number"
              placeholder="เลขหนังสือเดินทาง"
              changeValue={changeValue}
              id="passport"
              value={form.passport}
            />
            <GridSelectItem
              size={[12, 12, 4, 4]}
              label="Gender"
              options={genders}
              changeValue={changeValue}
              id="gender"
              value={form.gender}
            />
            <GridSelectItem
              size={[12, 12, 4, 4]}
              label="Blood Group"
              options={[
                { label: "A" },
                { label: "B" },
                { label: "O" },
                { label: "AB" },
              ]}
              changeValue={changeValue}
              id="blood_group"
              value={form.blood_group}
            />
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <DatePicker
                label="Birth Date"
                format="YYYY-MM-DD"
                value={dayjs(form.birth_date)}
                onChange={changeDate}
                slotProps={{
                  textField: {
                    InputLabelProps: { shrink: true },
                    fullWidth: true,
                    size: "small",
                  },
                }}
              />
            </Grid>
            <GridSelectItem
              size={[12, 12, 3, 3]}
              label="Nationality"
              options={state.nationalities}
              changeValue={changeValue}
              id="nationality"
              value={form.nationality}
            />
            <GridSelectItem
              size={[12, 12, 3, 3]}
              label="Ethnicity"
              options={state.origins}
              changeValue={changeValue}
              id="origin"
              value={form.origin}
            />
            <GridSelectItem
              size={[12, 12, 3, 3]}
              label="Religion"
              options={state.religions}
              changeValue={changeValue}
              id="religion"
              value={form.religion}
            />
          </>
          <AddressForm
            type=""
            onChange={changeValue}
            form={form}
            setDialogState={setDialogState}
            copyCurrentAddress={copyCurrentAddress}
          />
          <AddressForm
            type="current_"
            onChange={changeValue}
            form={form}
            setDialogState={setDialogState}
            copyAddress={copyAddress}
          />
          <Grid item xs={12}>
            <StudyHistoryForm
              values={student.study_history || []}
              onChange={(form) => {
                changeFields({ study_history: form });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DocumentCheck
              choices={registration_documents}
              values={student.documents || []}
              onChange={(e) => {
                let chk = e.target.checked;
                let val = e.target.value;
                let documents = JSON.parse(JSON.stringify(form.documents));
                if (chk === true) {
                  documents.push(val);
                } else {
                  documents = documents.filter((j) => j !== val);
                }
                changeFields({ documents });
              }}
            />
            <div style={{ marginTop: "30px", textIndent: "50px" }}>
              ข้าพเจ้าขอรับรองว่าจะให้ความอุปการะเลี้ยงดู
              และรับผิดชอบต่อค่าธรรมเนียมต่าง ๆ ตลอดถึง
              ความประพฤติและยินดีปฏิบัติตามข้อบังคับของโรงเรียน
              และของกระทรวงศึกษาธิการ ข้าพเจ้าทราบ
              ระเบียบและข้อบังคับของโรงเรียนแล้ว
              จึงมอบตัวนักเรียนเข้าโรงเรียนต้นกล้าตั้งแต่บัดนี้เป็นต้นไป
            </div>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  );
}

function DocumentCheck({ choices, values, onChange }) {
  const types = [
    {
      title: "เอกสารนักเรียน",
      title_en: "Student Documents",
      type: "นักเรียน",
    },
    {
      title: "เอกสารบิดา",
      title_en: "Father Documents",
      type: "บิดา",
    },
    {
      title: "เอกสารมารดา",
      title_en: "Mother Documents",
      type: "มารดา",
    },
  ];
  return (
    <div>
      <h3 style={styles.title}>
        ข้าพเจ้าขอส่งเอกสารการมอบตัว ดังนี้ (I would like to deliver the
        following document)
      </h3>
      <FormGroup onChange={onChange}>
        <Grid container spacing={3}>
          {types.map((type, key) => (
            <Grid item xs={12} sm={12} md={4} key={key}>
              <div style={{ fontWeight: 400 }}>{type.title}</div>
              <div
                style={{
                  color: "#777",
                  fontSize: ".8rem",
                  marginBottom: "10px",
                }}
              >
                {type.title_en}
              </div>
              {choices
                .filter((j) => j.type === type.type)
                .map((choice, key) => (
                  <div key={key} style={{ marginTop: "15px" }}>
                    <FormControlLabel
                      value={choice.label_th}
                      checked={
                        values.findIndex((j) => j === choice.label_th) > -1
                      }
                      control={<Checkbox />}
                      label={
                        <div style={{ fontSize: ".9rem" }}>
                          {choice.label_th}
                          {choice.label_en && (
                            <div style={{ color: "#888", fontSize: ".8rem" }}>
                              {choice.label_en}
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>
                ))}
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </div>
  );
}

function StudyHistoryForm({ values, onChange }) {
  const [form, setForm] = useState({});
  async function addHistory() {
    let Form = JSON.parse(JSON.stringify(form));
    let Values = JSON.parse(JSON.stringify(values));
    if (form.uuid || form._id) {
      let i = Values.findIndex(
        (j) =>
          (form._id && j._id === form._id) ||
          (form.uuid && j.uuid === form.uuid)
      );
      if (i > -1) {
        Values[i] = Form;
      }
    } else {
      Form.uuid = uuid();
      Values.push(Form);
    }
    await onChange(Values);
    setForm({});
  }
  async function deleteHistory(history) {
    let Values = JSON.parse(JSON.stringify(values));
    if (history._id) {
      Values = Values.filter((j) => j._id !== history._id);
    } else {
      Values = Values.filter((j) => j.uuid !== history.uuid);
    }
    await onChange(Values);
    setForm({});
  }
  function checkSelected(history) {
    if (
      (history._id && history._id === form._id) ||
      (history.uuid && form.uuid === history.uuid)
    )
      return true;
    else return false;
  }
  return (
    <div>
      <h3 style={styles.title}>ประวัติการศึกษา</h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ width: "60px" }}
                className="td"
              >
                No
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "200px" }}
                className="td"
              >
                Level
              </TableCell>
              <TableCell align="center" className="td">
                School name
              </TableCell>
              <TableCell
                align="center"
                style={{ width: "200px" }}
                className="td"
              >
                Province
              </TableCell>
              <TableCell style={{ width: "60px" }} className="td" />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className="controlTd" align="center">
                <Icon style={{ color: "#777" }}>add</Icon>
              </TableCell>
              <TableCell className="controlTd">
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{ className: "input_small" }}
                  value={form.level || ""}
                  onChange={(e) => setForm({ ...form, level: e.target.value })}
                />
              </TableCell>
              <TableCell className="controlTd">
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{ className: "input_small" }}
                  value={form.school_name || ""}
                  onChange={(e) =>
                    setForm({ ...form, school_name: e.target.value })
                  }
                />
              </TableCell>
              <TableCell className="controlTd">
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{ className: "input_small" }}
                  value={form.school_province || ""}
                  onChange={(e) =>
                    setForm({ ...form, school_province: e.target.value })
                  }
                />
              </TableCell>

              <TableCell className="controlTd" align="center">
                <IconButton color="primary" onClick={addHistory}>
                  <Icon>save</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
            {values.map((history, key) => (
              <TableRow
                key={key}
                style={
                  checkSelected(history) === true
                    ? { background: "#dfd" }
                    : { background: "#fff" }
                }
              >
                <TableCell align="center" className="controlTd">
                  {checkSelected(history) === true ? (
                    <IconButton
                      color="error"
                      onClick={() => deleteHistory(history)}
                    >
                      <Icon>clear</Icon>
                    </IconButton>
                  ) : (
                    <>{key + 1}</>
                  )}
                </TableCell>
                <TableCell align="center" className="td">
                  {history.level}
                </TableCell>
                <TableCell align="center" className="td">
                  {history.school_name}
                </TableCell>
                <TableCell align="center" className="td">
                  {history.school_province}
                </TableCell>
                <TableCell className="controlTd" align="center">
                  {checkSelected(history) === true ? (
                    <IconButton color="success" onClick={() => setForm({})}>
                      <Icon>undo</Icon>
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={() => setForm(history)}
                    >
                      <Icon>edit</Icon>
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function AddressForm({
  type,
  form,
  onChange,
  setDialogState,
  copyAddress,
  copyCurrentAddress,
}) {
  function changeValue(e) {
    onChange(e);
  }
  return (
    <>
      <Grid item xs={12} sm={12} md={8}>
        <h3 style={styles.title}>
          {type === "current_"
            ? "ข้อมูลที่อยู่ปัจจุบัน"
            : "ข้อมูลที่อยู่ตามทะเบียนบ้าน"}
        </h3>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        align="right"
        style={{ paddingTop: "30px" }}
      >
        {type === "current_" ? (
          <>
            <Button
              variant="outlined"
              startIcon={<Icon>content_copy</Icon>}
              onClick={copyAddress}
            >
              <div>เหมือนที่อยู่ตามทะเบียนบ้าน</div>
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              startIcon={<Icon>content_copy</Icon>}
              onClick={copyCurrentAddress}
            >
              <div>เหมือนที่อยู่ตามปัจจุบัน</div>
            </Button>
          </>
        )}
      </Grid>

      {type === "current_" && (
        <Grid item xs={12}>
          <RadioForm
            title_th="ประเภทที่อยู่ (House Type)"
            choices={[
              {
                label_th: "บ้านของตนเอง",
                label_en: "Own home",
                value: "บ้านของตนเอง",
              },
              {
                label_th: "บ้านเช่า",
                label_en: "Rental house",
                value: "บ้านเช่า",
              },
              {
                label_th: "หอพัก",
                label_en: "Dormitory",
                value: "หอพัก",
              },
            ]}
            grids={[12, 6, 4, 4]}
            value={form.house_type}
            onCheck={(val) => {
              changeValue({ target: { value: val, id: "house_type" } });
            }}
          />
        </Grid>
      )}
      <GridInput
        size={[12, 6, 4, 4]}
        label="House No"
        placeholder="บ้านเลขที่"
        id={type + "house_number"}
        changeValue={changeValue}
        value={form[type + "house_number"]}
      />
      <GridInput
        size={[12, 6, 4, 4]}
        label="Village"
        placeholder="หมู่บ้าน"
        id={type + "village"}
        changeValue={onChange}
        value={form[type + "village"]}
      />
      <GridInput
        size={[12, 6, 4, 4]}
        label="Moo"
        placeholder="หมู่"
        id={type + "moo"}
        changeValue={onChange}
        value={form[type + "moo"]}
      />

      <GridInput
        size={[12, 6, 4, 4]}
        label="Soi"
        placeholder="ซอย"
        id={type + "soi"}
        changeValue={onChange}
        value={form[type + "soi"]}
      />
      <GridInput
        size={[12, 6, 4, 4]}
        label="Street"
        placeholder="ถนน"
        id={type + "street"}
        changeValue={onChange}
        value={form[type + "street"]}
      />

      <Grid item xs={12} sm={6} md={4} lg={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel shrink>Zip</InputLabel>
          <OutlinedInput
            id={type + "zip_code"}
            value={form[type + "zip_code"] || ""}
            onChange={onChange}
            onKeyUp={(e) =>
              e.key === "Enter" &&
              setDialogState({
                open: true,
                type: type,
                code: form[type + "zip_code"],
              })
            }
            size="small"
            inputProps={{ style: { padding: "11px 12px" } }}
            placeholder="รหัสไปรษณีย์"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => {
                    setDialogState({
                      open: true,
                      type: type,
                      code: form[type + "zip_code"],
                    });
                  }}
                >
                  <Icon>search</Icon>
                </IconButton>
              </InputAdornment>
            }
            label="Zip"
          />
        </FormControl>
      </Grid>

      <GridInput
        disabled={true}
        size={[12, 6, 4, 4]}
        label="Sub-district"
        placeholder="ตำบล"
        id={type + "area"}
        value={form[type + "area"]}
      />
      <GridInput
        disabled={true}
        size={[12, 6, 4, 4]}
        label="District"
        placeholder="อำเภอ"
        id={type + "district"}
        value={form[type + "district"]}
      />
      <GridInput
        disabled={true}
        size={[12, 6, 4, 4]}
        label="Province"
        placeholder="จังหวัด"
        id={type + "province"}
        value={form[type + "province"]}
      />
    </>
  );
}
