import React, { useState, useEffect } from "react";
import { getPermission, getStampTime } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import MultiType from "../charts/MultiType";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import axios from "axios";
import Conf from "../Conf";
import { not_purchase_choice } from "../const";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Factor({ page }) {
  const reports = [
    "รายงานปัจจัยที่ยังไม่ซื้อใบสมัครเรียน",
    "รายงานช่องทางการได้รับข่าวสารข้อมูลของโรงเรียน",
    "ปัจจัยที่มีผลต่อการตัดสินใจเลือกโรงเรียน",
  ];

  const [profile, setProfile] = useState();
  const [state, setState] = useState();
  const [report, setReport] = useState();
  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      setProfile(profile);
      let dt = await getStampTime();
      let years = [];
      for (let i = dt.detail.year - 10; i < dt.detail.year + 5; i++) {
        years.push({ value: i, label: i + 543 });
      }
      let startDate = dt.detail.year + "-07-01";
      let endDate = dt.detail.year + 1 + "-07-01";
      setState({
        schoolYear: dt.detail.year + 1,
        startDate,
        endDate,
        years,
        showReport: 0,
      });
    })();
  }, []);
  useEffect(() => {
    async function getNotPurchase() {
      return new Promise(async (resolve) => {
        let registers = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          {
            collection: "ca_assessments",
            select: "not_purchase_choice",
            match: {
              $and: [
                { not_purchase_choice: { $exists: true } },
                { stamp_date: { $gte: state.startDate } },
                { stamp_date: { $lt: state.endDate } },
              ],
            },
          },
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        let report = { person_count: registers.data.count, result: [] };
        if (registers.data.count > 0) {
          for (let register of registers.data.result) {
            for (let factor of register.not_purchase_choice) {
              let i = report.result.findIndex((j) => j.value === factor);
              if (i < 0) {
                if (not_purchase_choice[factor]) {
                  let label = not_purchase_choice[factor].label_th;
                  report.result.push({ value: factor, label, count: 1 });
                }
              } else report.result[i].count++;
            }
          }
        }
        resolve(report);
      });
    }
    async function registerReport(field, otherField) {
      return new Promise(async (resolve) => {
        let registers = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          {
            collection: "registers",
            select: field + " " + otherField,
            match: {
              $and: [
                { [field]: { $exists: true } },
                { "status_date.stamp_date": { $gte: state.startDate } },
                { "status_date.stamp_date": { $lt: state.endDate } },
              ],
            },
          },
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        let report = {
          person_count: registers.data.count,
          result: [],
          others: [],
        };
        if (registers.data.count > 0) {
          for (let register of registers.data.result) {
            for (let factor of register[field]) {
              let i = report.result.findIndex((j) => j.label === factor);
              if (i < 0) {
                report.result.push({ label: factor, count: 1 });
              } else report.result[i].count++;
            }
            if (register[otherField] && register[otherField] !== "")
              report.others.push(register[otherField]);
          }
        }
        resolve(report);
      });
    }
    async function getReports() {
      let reports = [];
      if (state.showReport === 0) {
        reports = await getNotPurchase();
        setReport(reports);
      } else if (state.showReport === 1) {
        reports = await registerReport("promotion_media", "promotion_other");
        setReport(reports);
      } else if (state.showReport === 2) {
        reports = await registerReport(
          "influence_factors",
          "influence_factor_other"
        );
        setReport(reports);
      }
    }
    if (state && state.schoolYear) getReports();
  }, [state]);
  function changeSchoolYear(val) {
    let startDate = val - 1 + "-07-01";
    let endDate = val + "-07-01";
    setState({ ...state, schoolYear: val, startDate, endDate });
  }
  return (
    <div className="content">
      {state && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />
          <Grid container spacing={1}>
            <Grid item>
              <FormControl variant="outlined" value={state.showReport}>
                <InputLabel shrink>เลือกรายงาน</InputLabel>
                <Select
                  native
                  label="เลือกรายงาน"
                  value={state.showReport}
                  style={{ width: "400px" }}
                  onChange={(e) =>
                    setState({ ...state, showReport: parseInt(e.target.value) })
                  }
                  size="small"
                >
                  {reports.map((item, key) => (
                    <option key={key} value={key}>
                      {item}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                style={{ width: "150px" }}
                value={state.schoolYear}
              >
                <InputLabel shrink>ปีการศึกษา</InputLabel>
                <Select
                  native
                  label="ปีการศึกษา"
                  size="small"
                  value={state.schoolYear}
                  onChange={(e) => changeSchoolYear(e.target.value)}
                >
                  {state.years.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {report && (
              <>
                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                  <ReportTable report={report} />
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                  <ChartArea
                    state={{ title: reports[state.showReport], report: report }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  );
}

function ChartArea({ state }) {
  const [data, setData] = useState();
  useEffect(() => {
    async function createData() {
      let data = [];
      let labels = [];
      for (let item of state.report.result) {
        labels.push(item.label);
        data.push(item.count);
      }
      data = {
        labels: labels,
        datasets: [
          {
            type: "bar",
            label: "People count",
            data: data,
            backgroundColor: "rgba(51,215,133,.8)",
            borderColor: "rgb(51,185,190)",
            borderWidth: 1,
          },
        ],
      };
      setData(data);
    }
    if (state.report) createData();
  }, [state]);
  return (
    <div>
      {data && (
        <Grid container component={Paper}>
          <Grid item xs={12} style={{ padding: "20px 20px" }}>
            <MultiType
              state={{
                title: state.title,
                data: data,
                options: { indexAxis: "y" },
                version: 1,
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function ReportTable({ report }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: "100px" }}
                className="td"
                align="center"
              >
                ลำดับ
              </TableCell>
              <TableCell className="td" align="center">
                หัวข้อ
              </TableCell>
              <TableCell
                style={{ width: "200px" }}
                className="td"
                align="center"
              >
                จำนวน (คน)
              </TableCell>
              <TableCell
                style={{ width: "200px" }}
                className="td"
                align="center"
              >
                คิดเป็นร้อยละ
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.result.map((item, key) => (
              <TableRow key={key} hover>
                <TableCell className="td" align="center">
                  {key + 1}
                </TableCell>
                <TableCell className="td">{item.label}</TableCell>
                <TableCell className="td" align="center">
                  {item.count}
                </TableCell>
                <TableCell className="td" align="center">
                  {((item.count / report.person_count) * 100).toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                className="td"
                align="center"
                colSpan={2}
                component="th"
              >
                รวมทั้งหมด
              </TableCell>
              <TableCell className="td" align="center" component="th">
                {report.person_count}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {report.others && report.others.length > 0 && (
        <div style={{ paddingTop: "20px" }}>
          <div style={{ fontWeight: 400, paddingLeft: "20px" }}>อื่น ๆ</div>
          <ul>
            {report.others.map((other, key) => (
              <li key={key}>{other}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
