import React, { useEffect, useState } from "react";
import { getPermission } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import RegisterList from "../components/RegisterList";
import PageNameBox from "../components/PageNameBox";
import PageNumberBox from "../components/PageNumberBox";
import DialogChangeStatus from "../components/DialogChangeStatus";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import VisitForm from "../components/VisitForm";
import { GridInputItem } from "../components/InputForm";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import Grid from "@mui/material/Grid";
import axios from "axios";
import Conf from "../Conf";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate, useParams } from "react-router-dom";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Visit(props) {
  const params = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({ showChangeSatus: false });
  const [profile, setProfile] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [visits, setVisits] = useState();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    collection: "registers",
    keyword: {
      value: "",
      fields: [
        "contact",
        "first_name",
        "last_name",
        "nickname",
        "phone",
        "email",
      ],
    },
    limit: 50,
    showSearch: params.id ? false : true,
    skip: 0,
  });

  const getProfile = async () => {
    let p = await getPermission();
    if (p) setProfile(p);
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    const renderPage = async () => {
      getProfile();
    };
    renderPage();
  }, []);

  useEffect(() => {
    async function getVisits() {
      setLoading(true);
      /*
      let match = {
        $and: [
          { register_form_no: { $exists: false } },
          { waiver: { $ne: true } },
        ],
      };
      */
      let match = {
        $and: [
          { $or: [{ bill_no: { $exists: false } }, { bill_no: "" }] },
          { waiver: { $ne: true } },
        ],
      };

      let showAll = document.getElementById("showAll");
      if (showAll && showAll.checked === true) {
        match = null;
      }
      let send = {
        collection: "registers",
        match: match,
        keyword: {
          value: filter.keyword.value,
          fields: [
            "contact_first_name",
            "contact_last_name",
            "first_name",
            "last_name",
            "nickname",
            "phone",
            "email",
          ],
        },
        sort: "-visit_schedules.visit_date",
        limit: filter.limit,
        skip: filter.skip,
      };
      let result = await axios.post(Conf.endpoint.tonkla + "/ca/query", send, {
        headers: { Authorization: cookie.access_token },
      });
      setLoading(false);
      if (result.data.error) alert(result.data.error.message);
      else setVisits(result.data);
    }
    if (filter.showSearch === true) getVisits();
  }, [filter]);
  async function goSearch() {
    // if (keyword === "") setVisits(null);
    // else {
    let Filter = JSON.parse(JSON.stringify(filter));
    Filter.keyword.value = keyword;
    Filter.showSearch = true;
    Filter.skip = 0;
    setFilter(Filter);
    // }
  }
  function changePage(value) {
    let S = (value - 1) * filter.limit;
    setFilter({ ...filter, skip: S });
  }
  function checkStatus(visit) {
    let i = visit.status_date.findIndex((j) => j.label === "เยี่ยมชม");
    alert("Save Completed");
    if (i < 0) {
      setState({ ...state, showChangeSatus: true });
    }
  }
  return (
    <>
      {profile && filter && (
        <>
          {loading === true && <LinearProgress />}
          <div className="content">
            <div style={{ textAlign: "right" }}>
              <ProfileBox profile={profile} />
            </div>
            <Grid container style={{ marginTop: "30px" }}>
              <Grid item xs={12} sm={6} md={8} lg={9}>
                <PageNameBox page={props.page} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} align="right">
                <Grid container>
                  <Grid item xs={12}>
                    <GridInputItem
                      size={[12, 12, 12, 12]}
                      label="Search"
                      placeholder="ค้นหา..."
                      value={keyword}
                      gridStyle={{ paddingBottom: "0px" }}
                      changeValue={(e) => setKeyword(e.target.value)}
                      enterValue={goSearch}
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="showAll"
                      onChange={() =>
                        setFilter(JSON.parse(JSON.stringify(filter)))
                      }
                    />
                  }
                  label={<div>แสดงทั้งหมด</div>}
                />
              </Grid>
            </Grid>
            {filter.showSearch === true ? (
              <>
                {visits && (
                  <div style={{ paddingTop: "20px" }}>
                    <div style={{ padding: "00px 10px", fontWeight: 400 }}>
                      Result : {visits.count} record(s)
                    </div>
                    <Grid container>
                      <Grid item xs={12} sm={7}>
                        <PageNumberBox
                          count={
                            parseInt((visits.count - 1) / filter.limit) + 1
                          }
                          onChangePage={(value) => changePage(value)}
                          page={parseInt(filter.skip / filter.limit) + 1}
                        />
                      </Grid>
                      <Grid item xs={12} sm={5} align="right">
                        <Button
                          component="a"
                          href={process.env.PUBLIC_URL + "/form/visitregister"}
                          target="_blank"
                          variant="outlined"
                          startIcon={<Icon>add_circle</Icon>}
                          color="primary"
                        >
                          นัดวันเยี่ยมชม
                        </Button>
                      </Grid>
                    </Grid>
                    <br />
                    <RegisterList
                      registers={visits}
                      state={{
                        checkStatus: ["visited", "registerPay"],
                      }}
                      onSelect={(register) => {
                        navigate(
                          process.env.PUBLIC_URL + "/form/visit/" + register._id
                        );
                        setFilter({ ...filter, showSearch: false });
                        setVisits(null);
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <DialogChangeStatus
                  open={state.showChangeSatus}
                  status="เยี่ยมชม"
                  register_id={params.id}
                  onClose={() => setState({ ...state, showChangeSatus: false })}
                />
                <VisitForm
                  register_id={params.id}
                  {...props}
                  onSaveCompleted={checkStatus}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}
