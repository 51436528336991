import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
// import TextField from "@mui/material/TextField";
import { v4 as uuidv4 } from "uuid";

import {
  GridInputItem,
  RadioForm,
  CheckForm,
} from "../../components/InputForm";

const styles = {
  title: { fontWeight: 400, padding: "13px 15px", fontSize: "1rem" },
  subTitle: {
    color: "#888",
    fontSize: ".9rem",
    paddingTop: "3px",
    paddingLeft: "13px",
  },
  questionTitle: { padding: "5px 15px", fontSize: "1rem" },
};
export default function FormHealthy({ form, changeForm, changeFields }) {
  function changeValue(e) {
    let id = e.target.id;
    changeForm(id, e.target.value);
  }
  // async function save() {
  //   let result = await axios.put(
  //     Conf.endpoint.tonkla + "/ca/register/healthy",
  //     form,
  //     { headers: { Authorization: cookie.access_token } }
  //   );
  //   if (result.data.error) alert(result.data.error.message);
  //   else alert("Save completed");
  // }

  function addTableItem(field, values) {
    let Form = JSON.parse(JSON.stringify(form));
    if (!Form[field]) Form[field] = [];
    values.uuid = uuidv4();
    Form[field].push(values);
    changeFields({ [field]: Form[field] });
    // setForm(Form);
  }
  function deleteTableItem(field, id) {
    let Form = JSON.parse(JSON.stringify(form));
    Form[field] = Form[field].filter((j) => j.uuid !== id);

    changeFields({ [field]: Form[field] });
    // setForm(Form);
  }
  return (
    <div>
      {form && (
        <>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              align="center"
              component="h1"
              style={{ fontSize: "1.5rem" }}
            >
              ข้อมูลด้านสุขภาพของนักรียน ด้านพฤติกรรมและอุปนิสัย
              <div style={{ color: "#888", fontSize: "1rem" }}>
                Student Health information and behavior habit
              </div>
            </Grid>
            <>
              <Grid item xs={12} style={styles.title}>
                1. ข้อมูลด้านสุขภาพของนักเรียน
                <div style={styles.subTitle}>Student Health information</div>
              </Grid>

              <Grid item xs={12}>
                <RadioForm
                  title_th="1.1 ประวัติการคลอด"
                  title_en="Childbirth information"
                  styles={{
                    title: styles.questionTitle,
                    subTitle: styles.subTitle,
                  }}
                  choices={[
                    {
                      label_th: "คลอดธรรมชาติ",
                      label_en: "Natural Delivery",
                      value: "คลอดธรรมชาติ",
                    },
                    {
                      label_th: "ผ่าตัดคลอด",
                      label_en: "Caesarean Section",
                      value: "ผ่าตัดคลอด",
                    },
                  ]}
                  grids={[12, 6, 4, 4]}
                  value={form.childbirth_way}
                  onCheck={(value) => {
                    changeFields({ childbirth_way: value });
                  }}
                />
              </Grid>

              <GridInputItem
                size={[12, 12, 12, 12]}
                label="Hospital name"
                placeholder="สถานที่คลอด"
                id="childbirth_place"
                value={form.childbirth_place}
                changeValue={changeValue}
              />

              <Grid item xs={12}>
                <RadioForm
                  choices={[
                    {
                      label_th: "คลอดตามกำหนด",
                      label_en: "Scheduled Delivery",
                      value: "คลอดตามกำหนด",
                    },
                    {
                      label_th: "ก่อนกำหนด",
                      label_en: "Premature Delivery",
                      value: "ก่อนกำหนด",
                    },
                  ]}
                  grids={[12, 6, 4, 4]}
                  value={form.childbirth_time}
                  onCheck={(value) => {
                    changeFields({ childbirth_time: value });
                  }}
                />
              </Grid>

              <GridInputItem
                size={[12, 6, 3, 3]}
                label="Gestational Age (weeks)"
                placeholder="อายุครรภ์ (สัปดาห์)"
                id="gestational_age"
                value={form.gestational_age}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 6, 3, 3]}
                label="Birth weight (kg.)"
                placeholder="น้ำหนักแรกคลอด (กก.)"
                id="birth_weight"
                value={form.birth_weight}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 6, 3, 3]}
                label="Current weight	(kg.)"
                placeholder="น้ำหนักปัจจุบัน (กก.)"
                id="current_weight"
                value={form.current_weight}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 6, 3, 3]}
                label="Height(cm.)"
                placeholder="ส่วนสูง (ซม.)"
                id="height"
                value={form.height}
                changeValue={changeValue}
              />
              <Grid item xs={12}>
                <RadioForm
                  title_th="การได้รับภูมิคุ้มกันโรค"
                  title_en="Vaccination"
                  styles={{
                    title: { padding: "20px 20px" },
                    subTitle: { color: "#999" },
                  }}
                  choices={[
                    {
                      label_th: "ครบตามกำหนดตั้งแต่เกิด",
                      label_en: "Got all essential vaccination",
                      value: "ครบตามกำหนดตั้งแต่เกิด",
                    },
                    {
                      label_th: "ไม่ครบตามกำหนด",
                      label_en: "Did not get essential vaccination",
                      value: "ไม่ครบตามกำหนด",
                    },
                  ]}
                  grids={[12, 6, 4, 4]}
                  value={form.vaccination}
                  onCheck={(value) => {
                    // setForm({ ...form, vaccination: value });
                    changeFields({ vaccination: value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckForm
                  title_th="1.2 ประวัติการเจ็บป่วย "
                  title_en="Illness Information"
                  styles={{
                    title: styles.questionTitle,
                    subTitle: styles.subTitle,
                  }}
                  choices={[
                    {
                      label_th: "หัด",
                      label_en: "measles",
                      value: "หัด",
                    },
                    {
                      label_th: "คางทูม",
                      label_en: "mumps",
                      value: "คางทูม",
                    },
                    {
                      label_th: "อีสุกอีใส",
                      label_en: "chickenpox",
                      value: "อีสุกอีใส",
                    },
                    {
                      label_th: "ไข้สมองอักเสบ",
                      label_en: "encephalitis",
                      value: "ไข้สมองอักเสบ",
                    },
                    {
                      label_th: "ชัก",
                      label_en: "seizures",
                      value: "ชัก",
                    },
                  ]}
                  grids={[6, 4, 3, 2]}
                  values={form.Illness_Info || []}
                  onCheck={(values) =>
                    // setForm({ ...form, Illness_Info: values })
                    changeFields({ Illness_Info: values })
                  }
                />
              </Grid>
              <GridInputItem
                size={[12, 6, 5, 4]}
                label="Operation (Time)"
                placeholder="เคยเข้ารับการผ่าตัด (ครั้ง)"
                id="operation_time"
                type="number"
                value={form.operation_time}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 6, 7, 8]}
                label="Operation Dime"
                placeholder="รายละเอียดการผ่าตัด"
                id="operation_detail"
                value={form.operation_detail}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                label="Inpatient treatment history (please state the disease and the name of the hospital)"
                placeholder="ประวัติการเข้ารักษาเป็นผู้ป่วยใน (ระบุสถานพยาบาลและอาการ / โรคที่เป็น)"
                id="treatment_history"
                value={form.treatment_history}
                rows={4}
                changeValue={changeValue}
              />
              <Grid item xs={12} style={{ padding: "15px 5px" }}>
                <InputTable
                  state={{
                    title_th: "โรคประจำตัว",
                    title_en: "Congenital Disease",
                    icon: "coronavirus",
                    col1_en: "Disease name",
                    col1_th: "ชื่อโรค",
                    col2_en: "Symptom",
                    col2_th: "อาการ",
                    fields: ["disease_name", "symptoms"],
                  }}
                  values={form.congenital_disease || []}
                  onSave={(values) =>
                    addTableItem("congenital_disease", values)
                  }
                  onDelete={(id) => deleteTableItem("congenital_disease", id)}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "15px 5px" }}>
                <InputTable
                  state={{
                    title_th: "การแพ้ยา",
                    title_en: "Drug allergy",
                    icon: "medication_liquid",
                    col1_en: "Drug name",
                    col1_th: "ชื่อยา",
                    col2_en: "Symptom",
                    col2_th: "อาการ",
                    fields: ["drug_name", "symptoms"],
                  }}
                  values={form.drug_allergy || []}
                  onSave={(values) => addTableItem("drug_allergy", values)}
                  onDelete={(id) => deleteTableItem("drug_allergy", id)}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "15px 5px" }}>
                <InputTable
                  state={{
                    title_th: "การแพ้อาหาร",
                    title_en: "Drug allergy",
                    icon: "fastfood",
                    col1_en: "Menu",
                    col1_th: "ชื่ออาหาร",
                    col2_en: "Symptom",
                    col2_th: "อาการ",
                    fields: ["food_name", "symptoms"],
                  }}
                  values={form.food_allergy || []}
                  onSave={(values) => addTableItem("food_allergy", values)}
                  onDelete={(id) => deleteTableItem("food_allergy", id)}
                />
              </Grid>
              {/* <GridInputItem
                size={[12, 12, 12, 12]}
                label="Congenital disease"
                placeholder="โรคประจำตัว"
                id="congenital_disease"
                value={form.congenital_disease}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                label="Symptoms"
                placeholder="โรค/อาการที่เป็นบ่อย"
                id="symptoms"
                value={form.symptoms}
                changeValue={changeValue}
              /> */}
            </>
            <>
              <Grid item xs={12}>
                <div style={styles.questionTitle}>
                  1.3 ประวัติการประสบอุบัติเหตุ
                </div>
                <div style={styles.subTitle}>Accident information</div>
              </Grid>
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="accident_info"
                rows={2}
                value={form.accident_info}
                changeValue={changeValue}
              />
              {/* <Grid item xs={12}>
                <div style={styles.questionTitle}>1.4 ประวัติการแพ้ยา</div>
                <div style={styles.subTitle}>Allergy to</div>
              </Grid>
              <GridInputItem
                size={[12, 6, 6, 6]}
                id="allergy_medicine"
                placeholder="ยาที่แพ้"
                label="Medicine"
                value={form.allergy_medicine}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 6, 6, 6]}
                id="allergy_other"
                placeholder="การแพ้อื่น ๆ"
                label="Other"
                value={form.allergy_other}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="allergy_symptoms"
                placeholder="อาการเมื่อแพ้"
                label="Symptoms"
                value={form.allergy_symptoms}
                changeValue={changeValue}
              /> */}
              <Grid item xs={12}>
                <RadioForm
                  title_th="สุขภาพโดยทั่วไปของนักเรียน"
                  title_en="Student health"
                  styles={{
                    title: styles.questionTitle,
                    subTitle: styles.subTitle,
                  }}
                  choices={[
                    {
                      label_th: "แข็งแรงดี",
                      label_en: "very healthy",
                      value: "แข็งแรงดี",
                    },
                    {
                      label_th: "ค่อนข้างแข็งแรง",
                      label_en: "healthy",
                      value: "ค่อนข้างแข็งแรง",
                    },
                    {
                      label_th: "เจ็บป่วยง่าย",
                      label_en: "Gets ick easily",
                      value: "เจ็บป่วยง่าย",
                    },
                    {
                      label_th: "เจ็บป่วยบ่อยครั้ง",
                      label_en: "often gets sick",
                      value: "เจ็บป่วยบ่อยครั้ง",
                    },
                    {
                      label_th: "มีโรคประจำตัวต้องดูแลพิเศษ",
                      label_en:
                        "Has a congenital disease and requires special care",
                      value: "มีโรคประจำตัวต้องดูแลพิเศษ",
                    },
                  ]}
                  grids={[12, 6, 4, 4]}
                  value={form.overall_health}
                  onCheck={(value) => {
                    // setForm({ ...form, overall_health: value });
                    changeFields({ overall_health: value });
                  }}
                />
              </Grid>
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="special_care"
                placeholder="โปรดระบุรายละเอียดกรณีที่เด็กต้องได้รับการดูแลเป็นพิเศษ"
                label="Detail of what kind of special care does your child need"
                rows={2}
                value={form.special_care}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="personal_medication"
                placeholder="ยาประจำตัวตามแพทย์จัดให้"
                label="Personal Medication"
                rows={2}
                value={form.personal_medication}
                changeValue={changeValue}
              />
            </>
            <>
              <Grid item xs={12} style={styles.title}>
                2. ข้อมูลด้านพฤติกรรมและอุปนิสัย
                <div style={styles.subTitle}>Behavior habit information</div>
              </Grid>
              <Grid item xs={12}>
                <RadioForm
                  title_th="2.1	การขับถ่าย"
                  title_en="Excretion"
                  styles={{
                    title: styles.questionTitle,
                    subTitle: styles.subTitle,
                  }}
                  choices={[
                    {
                      label_th: "ยังใส่แพมเพิส",
                      label_en: "Diapers",
                      value: "ยังใส่แพมเพิส",
                    },
                    {
                      label_th: "ไม่ใส่แพมเพิสแล้ว",
                      label_en: "No diapers",
                      value: "ไม่ใส่แพมเพิสแล้ว",
                    },
                  ]}
                  grids={[12, 6, 4, 4]}
                  value={form.excretion}
                  onCheck={(value) => {
                    // setForm({ ...form, excretion: value });
                    changeFields({ excretion: value });
                  }}
                />
              </Grid>
              <Grid item xs={12} align="center">
                (หากยังไม่แน่ใจเรื่องการสื่อสารกับคุณครู
                ขอรบกวนผู้ปกครองใส่แพมเพิสและเตรียมแพมเพิสสำรองมาด้วยในช่วงวันแรกของการเตรียมความพร้อม)
                <div style={styles.subTitle}>
                  (If you are not sure that your child can communication with
                  teacher or not, please prepare spare one at the first day)
                </div>
              </Grid>
              <Grid item xs={12}>
                <RadioForm
                  title_th="2.2	การดื่มนม"
                  title_en="Milk drinking"
                  styles={{
                    title: styles.questionTitle,
                    subTitle: styles.subTitle,
                  }}
                  choices={[
                    {
                      label_th: "ดื่มขวดนม",
                      label_en: "Milk bottle",
                      value: "ดื่มขวดนม",
                    },
                    {
                      label_th: "ดื่มนมกล่อง",
                      label_en: "Milk box",
                      value: "ดื่มนมกล่อง",
                    },
                  ]}
                  grids={[12, 6, 4, 4]}
                  value={form.milk_drinking}
                  onCheck={(value) => {
                    // setForm({ ...form, milk_drinking: value });
                    changeFields({ milk_drinking: value });
                  }}
                />
              </Grid>
              <Grid item xs={12} style={styles.questionTitle}>
                2.3 การนอน
                <div style={styles.subTitle}>Sleeping habit</div>
              </Grid>
              <GridInputItem
                size={[6, 6, 6, 6]}
                id="go_bed"
                placeholder="เวลาเข้านอน"
                label="Go to bed at"
                type="time"
                value={form.go_bed}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[6, 6, 6, 6]}
                id="wake_up"
                placeholder="เวลาตื่นนอน"
                label="Wake up at"
                type="time"
                value={form.wake_up}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="sleep_problem"
                placeholder="ปัญหาเกี่ยวกับการนอน"
                label="Any problems or concerns"
                value={form.sleep_problem}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="how_to_sleep"
                placeholder="นอนด้วยวิธีการอย่างไร เช่น ลูบหัว ลูกแขน/ขา กอดหมอน เป็นต้น"
                label="How do they sleep Rub the child on the head/ Rub the child on their body/ Pillow hugging sleep"
                value={form.how_to_sleep}
                changeValue={changeValue}
              />
            </>
            <>
              <Grid item xs={12}>
                <RadioForm
                  title_th="2.4	การรับประทานอาหาร"
                  title_en="Eating habit"
                  styles={{
                    title: styles.questionTitle,
                    subTitle: styles.subTitle,
                  }}
                  choices={[
                    {
                      label_th: "รับประทานอาหารได้ทุกประเภท",
                      label_en: "Eat all kinds of food",
                      value: "รับประทานอาหารได้ทุกประเภท",
                    },
                    {
                      label_th: "เลือกไม่รับประทาน",
                      label_en: "Food they does not like to eat",
                      value: "เลือกไม่รับประทาน",
                    },
                    {
                      label_th: "มีอาหารบางประเภทที่ทางบ้านไม่ให้รับประทาน",
                      label_en: "Food that is not allowed to be eaten",
                      value: "มีอาหารบางประเภทที่ทางบ้านไม่ให้รับประทาน",
                    },
                  ]}
                  grids={[12, 6, 4, 4]}
                  value={form.eating_habit}
                  onCheck={(value) => {
                    // setForm({ ...form, eating_habit: value });
                    changeFields({ eating_habit: value });
                  }}
                />
              </Grid>
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="eating_habit_detail"
                placeholder="ระบุรายละเอียด"
                label="Personal Medication"
                value={form.eating_habit_detail}
                changeValue={changeValue}
              />
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="eating_habit_because"
                placeholder="ระบุเหตุผล"
                label="Because"
                value={form.eating_habit_because}
                changeValue={changeValue}
              />

              <Grid item xs={12} style={styles.questionTitle}>
                2.5 สิ่งที่สามารถดึงดูดความสนใจน้อง กรณีที่ร้องไห้ เช่น
                เล่นเลโก้ ระบายสี ชอบสัตว์อะไร เป็นต้น
                <div style={styles.subTitle}>
                  They are attended to (in case they are crying) e.g. playing
                  lego, painting or animals they like.
                </div>
              </Grid>
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="attract_attention"
                placeholder="ระบุ"
                rows={2}
                value={form.attract_attention}
                changeValue={changeValue}
              />
              <InputThreeIssue
                title_th="2.6	เมื่ออยู่ที่บ้านนักเรียนอยู่ในความดูแลของผู้ใดมากที่สุด (เลือกตอบ 3 ลำดับแรกและระบุกิจกรรมที่นักเรียนมักจะทำร่วมกับผู้ดูแลแต่ละคน)"
                title_en="Who does your child spend most of the time with at home and what does he/she like to do with each person?"
                values={form.spend_time_with_persons || []}
                onChangeThreeIssue={(values) =>
                  changeFields({ spend_time_with_persons: values })
                }
              />
              <InputThreeIssue
                title_th="2.7	ระบุกิจกรรมที่นักเรียนใช้เวลาในการทำมากที่สุดเมื่อยู่บ้าน 3 อันดับแรก (ไม่รวมการนอน)"
                title_en="What does your child like to frequently do at home? (Does not include sleeping)"
                values={form.spend_time_activities || []}
                onChangeThreeIssue={(values) =>
                  changeFields({ spend_time_activities: values })
                }
              />
              <InputThreeIssue
                title_th="2.8	ขอให้ครอบครัวร่วมกันค้นคว้าและระบุอุปนิสัย/บุคลิกที่โดดเด่นของเด็กมา 3 ข้อ โดยเรียงลำดับความชัดเจนจากมากที่สุดตามลำดับ"
                title_en="What are 3 characteristics or personalities of your child?"
                values={form.child_habits || []}
                onChangeThreeIssue={(values) =>
                  changeFields({ child_habits: values })
                }
              />
              <InputThreeIssue
                title_th="2.9	ขอให้ครอบครัวร่วมกันค้นคว้าและระบุอุปนิสัยของนักเรียน 3 ลำดับแรกที่เห็นว่าควรแก้ไข"
                title_en="What are 3 characteristics or personalities of your child that you would like to alter/solve?"
                values={form.bad_child_habits || []}
                onChangeThreeIssue={(values) =>
                  changeFields({ bad_child_habits: values })
                }
              />
            </>
            <>
              <Grid item xs={12} style={styles.questionTitle}>
                2.10 ครอบครัวของท่านประสบปัญหาในการเลี้ยงดูนักเรียนหรือไม่
                <div style={styles.subTitle}>
                  Does your family have any problem or concern on parenting your
                  child?
                </div>
              </Grid>
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="parenting_problem"
                rows={3}
                value={form.parenting_problem}
                changeValue={changeValue}
              />
              <Grid item xs={12} style={styles.questionTitle}>
                ท่านมีวิธีการแก้ปัญหาอย่างไรบ้าง
                <div style={styles.subTitle}>
                  What is/are solution(s) you have been practicing?
                </div>
              </Grid>
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="parenting_problem_solve"
                rows={3}
                value={form.parenting_problem_solve}
                changeValue={changeValue}
              />
              <Grid item xs={12} style={styles.questionTitle}>
                2.11
                สิ่งที่ผู้ปกครองมีความเป็นห่วงและกังวลใจที่ต้องการคุณครูดูแลเป็นพิเศษ
                <div style={styles.subTitle}>
                  Any concerns that parent would like to require special care?
                </div>
              </Grid>
              <GridInputItem
                size={[12, 12, 12, 12]}
                id="any_concerns"
                rows={3}
                value={form.any_concerns}
                changeValue={changeValue}
              />
            </>
            {/* <Grid item xs={12} style={{ padding: "20px 10px" }} align="right">
              <Button
                variant="contained"
                startIcon={<Icon>save</Icon>}
                style={{ width: "180px" }}
                onClick={save}
              >
                save
              </Button>
            </Grid> */}
          </Grid>
        </>
      )}
    </div>
  );
}

function InputThreeIssue(props) {
  const { title_th, title_en, values, onChangeThreeIssue } = props;
  const [form, setForm] = useState();
  useEffect(() => {
    setForm(values);
  }, [values]);
  function onChangeValue(e, key) {
    let Values = JSON.parse(JSON.stringify(form));
    Values[key] = { value: e.target.value };
    // let Values = { ...form, [key]: { value: e.target.value } };
    onChangeThreeIssue(Values);
  }
  return (
    <>
      {form && (
        <>
          <Grid item xs={12} style={styles.questionTitle}>
            {title_th}
            <div style={styles.subTitle}>{title_en}</div>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody>
                  {[0, 1, 2].map((key) => (
                    <TableRow key={key}>
                      <TableCell
                        style={{ width: "80px" }}
                        align="center"
                        className="controlTd"
                      >
                        {key + 1}
                      </TableCell>
                      <TableCell className="controlTd">
                        {/* <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          value={!form[key] ? "" : form[key].value}
                          style={{ padding: "10px 14px" }}
                          onChange={(e) => onChangeValue(e, key)}
                        /> */}
                        <Grid container>
                          <GridInputItem
                            size={[12, 12, 12, 12]}
                            gridStyle={{ padding: "0px 0px" }}
                            value={!form[key] ? "" : form[key].value}
                            changeValue={(e) => onChangeValue(e, key)}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </>
  );
}

function InputTable({ state, values, onSave, onDelete }) {
  const [form, setForm] = useState({});
  async function addForm() {
    if (form[state.fields[0]] && form[state.fields[0]] !== "") {
      await onSave(form);
      setForm({});
    } else alert("Please fill completely information");
  }

  async function deleteForm(id) {
    if (window.confirm("Confirm to remove")) onDelete(id);
  }
  return (
    <div>
      <Grid container>
        <Grid item style={{ width: "40px" }}>
          <Icon>{state.icon}</Icon>
        </Grid>
        <Grid item>
          {state.title_th} ({state.title_en})
        </Grid>
      </Grid>

      <TableContainer component={Paper} style={{ marginTop: "5px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                style={{ width: "60px" }}
                className="td"
              >
                No.
              </TableCell>
              <TableCell align="center" className="td">
                {state.col1_en}
              </TableCell>
              <TableCell align="center" className="td">
                {state.col2_en}
              </TableCell>
              <TableCell
                align="center"
                className="td"
                style={{ width: "60px" }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                align="center"
                style={{ width: "60px", color: "#999" }}
                className="controlTd"
              >
                <Icon>add</Icon>
              </TableCell>
              <TableCell align="center" className="controlTd">
                <Grid container>
                  <GridInputItem
                    size={[12, 12, 12, 12]}
                    gridStyle={{ padding: "0px 0px" }}
                    placeholder={state.col1_th}
                    value={form[state.fields[0]] || ""}
                    changeValue={(e) =>
                      setForm({ ...form, [state.fields[0]]: e.target.value })
                    }
                  />
                </Grid>
              </TableCell>
              <TableCell align="center" className="controlTd">
                <Grid container>
                  <GridInputItem
                    size={[12, 12, 12, 12]}
                    gridStyle={{ padding: "0px 0px" }}
                    value={form[state.fields[1]] || ""}
                    placeholder={state.col2_th}
                    changeValue={(e) =>
                      setForm({ ...form, [state.fields[1]]: e.target.value })
                    }
                  />
                </Grid>
              </TableCell>
              <TableCell align="center" className="controlTd">
                <IconButton color="success" component="span" onClick={addForm}>
                  <Icon>add</Icon>
                </IconButton>
              </TableCell>
            </TableRow>
            {values.map((item, key) => (
              <TableRow key={key}>
                <TableCell align="center" className="td">
                  {key + 1}
                </TableCell>
                <TableCell className="td">{item[state.fields[0]]}</TableCell>
                <TableCell className="td">{item[state.fields[1]]}</TableCell>

                <TableCell align="center" className="controlTd">
                  <IconButton
                    color="error"
                    component="span"
                    onClick={() => deleteForm(item.uuid)}
                  >
                    <Icon>clear</Icon>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
