import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PageNameBox from "../../components/PageNameBox";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { GridInputItem } from "../../components/InputForm";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import DialogSendMail from "../../components/DialogSendMail";

import axios from "axios";
import Conf from "../../Conf";
import { not_purchase_choice, satisfaction_choice } from "../../const";
import {
  getOTP /* , getPermission, getStampTime */,
  getPermission,
} from "../../js/main";
import { useParams } from "react-router-dom";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
export default function SelfAssessment(props) {
  const params = useParams();
  const schedule_id = params.schedule_id;
  const [state, setState] = useState({});

  useEffect(() => {
    async function getAssessment(id) {
      return new Promise(async (resolve) => {
        let otp = await getOTP();
        let assessment = await axios.get(
          Conf.endpoint.tonkla + "/ca/visit/assessment/" + id,
          {
            headers: { Authorization: otp },
          }
        );
        resolve(assessment.data);
      });
    }
    async function renderPage() {
      let otp = await getOTP();
      let schedules = await axios.post(
        Conf.endpoint.tonkla + "/ca/visit",
        {
          match: { "visit_schedules._id": schedule_id },
          select: "visit_schedules email",
        },
        {
          headers: { Authorization: otp },
        }
      );

      if (schedules.data.count && schedules.data.count > 0) {
        schedules = JSON.parse(JSON.stringify(schedules.data));
        schedules = schedules.result[0];
        let i = schedules.visit_schedules.findIndex(
          (j) => j._id === schedule_id
        );
        let assessment = null;
        if (i > -1) {
          let assessment_id = schedules.visit_schedules[i].assessments;
          if (assessment_id) assessment = await getAssessment(assessment_id);
        }
        setState({
          show: true,
          page: "form",
          register_id: schedules._id,
          email: schedules.email,
          assessment: assessment,
        });
      }
    }
    renderPage();
  }, [schedule_id]);
  return (
    <>
      {state.show === true && (
        <div style={{ padding: "10px 5px" }}>
          <Container maxWidth="md">
            <div
              style={{
                textAlign: "right",
                borderBottom: "2px solid #333",
                marginBottom: "20px",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/img/form_logo_en.png"}
                style={{ width: "180px" }}
                alt=""
              />
            </div>
            <PageNameBox page={props.page} />
            {
              {
                form: (
                  <FormArea
                    state={{
                      register_id: state.register_id,
                      schedule_id: schedule_id,
                      assessment: state.assessment,
                      email: state.email,
                    }}
                    onFinish={() => setState({ ...state, page: "completed" })}
                  />
                ),
                completed: (
                  <>
                    <br />
                    <CompletedArea />
                  </>
                ),
              }[state.page]
            }
          </Container>
        </div>
      )}
    </>
  );
}

export function FormArea(props) {
  const [state, setState] = useState({ open_dialog: false });
  const [profile, setProfile] = useState();
  const [form, setForm] = useState();
  const [notPurchaseEmpty, setNotPurchaseEmpty] = useState(false);
  const styles = {
    groupHead: {
      paddingBottom: "20px",
      fontSize: "1rem",
      fontWeight: 400,
      marginTop: "10px",
    },
    subGroupHeader: {
      color: "#888",
      fontSize: ".9rem",
    },
  };

  useEffect(() => {
    async function getForm() {
      let Not_purchase_choice = JSON.parse(JSON.stringify(not_purchase_choice));
      let Satisfaction_choice = JSON.parse(JSON.stringify(satisfaction_choice));
      let Form = {
        not_purchase_choice: Not_purchase_choice,
        satisfaction_choice: Satisfaction_choice,
        remark: "",
      };
      if (props.state.assessment && props.state.assessment._id) {
        for (let choice of props.state.assessment.not_purchase_choice) {
          let i = Form.not_purchase_choice.findIndex((j) => j.value === choice);
          if (i > -1) Form.not_purchase_choice[i].checked = true;
        }
        for (let choice of props.state.assessment.satisfaction_choice) {
          let i = Form.satisfaction_choice.findIndex((j) => j.id === choice.id);
          if (i > -1) Form.satisfaction_choice[i].value = choice.value;
        }
        Form._id = props.state.assessment._id;
        Form.remark = props.state.assessment.remark;
      }
      setForm(Form);
      let Profile = await getPermission();
      setProfile(Profile);
      // setProfile(Profile);
    }
    getForm();
  }, [props.state]);
  function changeSatisfaction(id, val) {
    let Form = JSON.parse(JSON.stringify(form));
    let i = Form.satisfaction_choice.findIndex((j) => j.id === id);
    if (i > -1) {
      Form.satisfaction_choice[i].value = val;
      setForm(Form);
    }
  }
  async function changeChoice(value) {
    let Choices = JSON.parse(JSON.stringify(form.not_purchase_choice));
    let i = await Choices.findIndex((j) => j.value === value);
    if (i > -1) {
      Choices[i].checked = !Choices[i].checked;
      setForm({ ...form, not_purchase_choice: Choices });
    }
  }
  async function save() {
    let not_purchase_choice = [];
    let satisfaction_choice = [];
    let checkSave = true;
    for (let ch of form.not_purchase_choice.filter((j) => j.checked === true)) {
      not_purchase_choice.push(ch.value);
    }
    if (not_purchase_choice.length === 0) {
      setNotPurchaseEmpty(true);
      checkSave = false;
    } else setNotPurchaseEmpty(false);
    let allChoices = form.satisfaction_choice.filter((j) => j.subOf);
    for (let ch of allChoices) {
      if (!ch.value) {
        checkSave = false;
        document.getElementById("row-" + ch.id).style.background = "#fdd";
      } else {
        document.getElementById("row-" + ch.id).style.background = "#fff";
        satisfaction_choice.push({ id: ch.id, value: ch.value });
      }
    }
    if (checkSave === false) {
      alert("กรุณากรอกข้อมูลให้ครบถ้วน\n(Please fill coomplete information)");
    } else {
      let send = {
        _id: form._id,
        schedule_id: props.state.schedule_id,
        register: props.state.register_id,
        not_purchase_choice: not_purchase_choice,
        satisfaction_choice: satisfaction_choice,
        remark: form.remark,
      };
      let otp = await getOTP();
      console.log(send);
      let result = await axios.put(
        Conf.endpoint.tonkla + "/ca/register/assessment",
        send,
        {
          headers: { Authorization: otp },
        }
      );
      console.log(result);
      props.onFinish();
    }
  }
  async function sendMail(email) {
    let url =
      "https://tca.tonkla.ac.th/form/self/assessment/" +
      props.state.schedule_id;
    let message =
      "ตามที่ท่านได้เข้าเยี่ยมชมโรงเรียนต้นกล้า<br />ทางโรงเรียนขอความอนุเคราะห์ให้ท่านกรอกแบบประเมินการเยี่ยมชมที่<br /><br />";
    message += "<a href='" + url + "' target='_blank'>" + url + "</a>";
    let send = {
      email: email,
      message: message,
      subject: "แบบประเมินการเยี่ยมชมโรงเรียนต้นกล้า",
    };
    let result = await axios.post(Conf.endpoint.tonkla + "/ca/sendmail", send, {
      headers: { Authorization: cookie.access_token },
    });
    console.log(result);
    if (result.data.error) alert(result.data.error.message);
    else {
      alert("Send completed");
      setState({ ...state, open_dialog: false });
    }
  }
  return (
    <div>
      {form && (
        <>
          <DialogSendMail
            state={{
              open: state.open_dialog,
              head: "ยืนยันการส่งแบบประเมิน",
              message: "ส่งอีเมลแบบประเมินการเยี่ยมชมไปที่",
              email: props.state.email || "",
            }}
            onClose={() => setState({ ...state, open_dialog: false })}
            onSendMail={(email) => sendMail(email)}
            // form={form}
          />
          <Grid
            container
            // component={Paper}
            spacing={1}
            style={{ padding: "15px 15px" }}
          >
            <Grid item xs={12} align="center" style={{ paddingBottom: "10px" }}>
              <h2 style={{ marginBottom: "5px", fontWeight: 400 }}>
                Evaluation Form: School visit
              </h2>
              <div>(แบบประเมินการเยี่ยมชม)</div>
              <div style={{ marginTop: "15px", fontWeight: 400 }}>
                Please fill in the following form regarding school visit in
                order to improve our school visit and admission process.
              </div>
              <div style={{ color: "#666" }}>
                ขอความอนุเคราะห์ให้ผู้เยี่ยมชมประเมินเพื่อร่วมเป็นส่วนหนึ่งในการพัฒนาระบบเยี่ยมชมโรงเรียน
              </div>
            </Grid>
            <Grid item xs={12} style={styles.groupHead}>
              Part 1: Survey on why parents have not yet decided to purchase
              application form
              <div style={styles.subGroupHeader}>
                ตอนที่ 1
                แบบสำรวจสาเหตุที่ผู้ปกครองยังไม่ตัดสินใจซื้อใบสมัครเรียน
              </div>
            </Grid>
            {form.not_purchase_choice.map((choice, key) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                style={{ padding: "3px 5px" }}
                key={key}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={choice.checked || false}
                      onChange={() => changeChoice(choice.value)}
                      color="primary"
                    />
                  }
                  label={
                    <>
                      <div
                        style={
                          notPurchaseEmpty === true ? { color: "#d00" } : {}
                        }
                      >
                        {choice.label_en}
                      </div>
                      <div
                        style={
                          notPurchaseEmpty === true
                            ? { color: "#f88" }
                            : { color: "#888", fontSize: ".9rem" }
                        }
                      >
                        {choice.label_th}
                      </div>
                    </>
                  }
                />
              </Grid>
            ))}
            <Grid item xs={12} style={styles.groupHead}>
              Part 2: Satisfaction of school visit
              <div style={styles.subGroupHeader}>
                ตอนที่ 2 แบบประเมินความพึงพอใจต่อการเยื่ยมชมโรงเรียน
              </div>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" rowSpan={2}>
                      <div style={{ fontWeight: 400 }}>Description</div>
                      <div>รายการ</div>
                    </TableCell>
                    <TableCell align="center" colSpan={5} className="td">
                      <div style={{ fontWeight: 400 }}>Satisfaction Level</div>
                      <div>ระดับความพึงพอใจ</div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {[5, 4, 3, 2, 1].map((val, key) => (
                      <TableCell
                        align="center"
                        style={{ width: "8%" }}
                        key={key}
                        className="td"
                      >
                        {val}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {form.satisfaction_choice
                  .filter((j) => !j.subOf)
                  .map((choiceHead, key) => (
                    <TableBody key={key}>
                      <TableRow>
                        <TableCell className="td" colSpan={6}>
                          <div style={{ fontWeight: 400 }}>
                            {choiceHead.no}. {choiceHead.label_en} (
                            {choiceHead.label_th})
                          </div>
                        </TableCell>
                      </TableRow>

                      {form.satisfaction_choice
                        .filter((j) => j.subOf === choiceHead.id)
                        .map((choice, key) => (
                          <TableRow key={key} hover id={"row-" + choice.id}>
                            <TableCell className="td">
                              <div style={{ paddingLeft: "10px" }}>
                                {choice.no}. {choice.label_en} (
                                {choice.label_th})
                              </div>
                            </TableCell>
                            {[5, 4, 3, 2, 1].map((val, key) => (
                              <TableCell
                                align="center"
                                key={key}
                                className="controlTd"
                              >
                                <Radio
                                  checked={choice.value === val}
                                  onChange={() =>
                                    changeSatisfaction(choice.id, val)
                                  }
                                  value={val}
                                  name="radio-button-demo"
                                  style={{ padding: "3px 3px" }}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </TableBody>
                  ))}
              </Table>
            </Grid>

            <Grid item xs={12} style={styles.groupHead}>
              Comment
            </Grid>
            <GridInputItem
              size={[12, 12, 12, 12]}
              label="Comment"
              placeholder="หมายเหตุอื่น ๆ"
              id="remark"
              value={form.remark}
              changeValue={(e) => setForm({ ...form, remark: e.target.value })}
            />
            {!form._id && (
              <>
                <Grid xs={6} item style={{ paddingTop: "20px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon>save</Icon>}
                    style={{ minWidth: "160px" }}
                    onClick={save}
                  >
                    Save Form
                  </Button>
                </Grid>
                {profile && (
                  <Grid
                    xs={6}
                    item
                    style={{ paddingTop: "20px" }}
                    align="right"
                  >
                    <Button
                      variant="contained"
                      color="inherit"
                      className="bg_orange"
                      startIcon={<Icon>mail</Icon>}
                      style={{ minWidth: "160px" }}
                      onClick={() => setState({ ...state, open_dialog: true })}
                    >
                      Send Form
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </>
      )}
    </div>
  );
}

function CompletedArea() {
  return (
    <div style={{ textAlign: "center" }}>
      <h3 style={{ fontWeight: 400 }}>
        โรงเรียนต้นกล้าได้รับแบบฟอร์มการประเมินของท่านแล้ว
      </h3>
      <div style={{ color: "#555" }}>ขอบคุณสำหรับข้อมูลและข้อเสนอแนะ</div>
    </div>
  );
}
