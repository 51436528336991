import React, { useState, useEffect, Fragment } from "react";
import { getPermission, getStampTime } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";

import axios from "axios";
import Conf from "../Conf";
import { satisfaction_choice } from "../const";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Assessment({ page }) {
  const [profile, setProfile] = useState();
  const [state, setState] = useState();
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(true);
  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      setProfile(profile);
      let dt = await getStampTime();
      let years = [];
      for (let i = dt.detail.year - 10; i < dt.detail.year + 5; i++) {
        years.push({ value: i, label: i + 543 });
      }
      let startDate = dt.detail.year + "-07-01";
      let endDate = dt.detail.year + 1 + "-07-01";

      let counselor = await axios.get(
        Conf.endpoint.tonkla + "/ca/report/counselor",
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      counselor = counselor.data;
      counselor.unshift({ label: "ทั้งหมด", value: "-" });
      setState({
        schoolYear: dt.detail.year + 1,
        startDate,
        endDate,
        years,
        counselors: counselor,
        counselor: "-",
      });
    })();
  }, []);
  useEffect(() => {
    async function getReport() {
      setLoading(true);
      let counselor = null;
      let match = {
        $and: [
          { "status_date.stamp_date": { $gte: state.startDate } },
          { "status_date.stamp_date": { $lt: state.endDate } },
        ],
      };
      if (state.counselor !== "-") {
        match["$and"].push({ "visit_schedules.counselor": state.counselor });
        counselor = state.counselor;
      }
      let report = await axios.post(
        Conf.endpoint.tonkla + "/ca/report/assessment",
        {
          match,
          counselor,
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      setReport(report.data);
      setLoading(false);
    }
    if (state) getReport();
  }, [state]);
  function changeSchoolYear(val) {
    let startDate = val - 1 + "-07-01";
    let endDate = val + "-07-01";

    setState({ ...state, schoolYear: val, startDate, endDate });
  }
  return (
    <div className="content">
      {state && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />
          <Grid container spacing={1}>
            <Grid item>
              <FormControl variant="outlined" value={state.counselor}>
                <InputLabel shrink>ผู้รับเยี่ยมชม</InputLabel>
                <Select
                  native
                  label="ผู้รับเยี่ยมชม"
                  value={state.counselor}
                  style={{ width: "400px" }}
                  onChange={(e) =>
                    setState({ ...state, counselor: e.target.value })
                  }
                  size="small"
                >
                  {state.counselors.map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="outlined"
                style={{ width: "150px" }}
                value={state.schoolYear}
              >
                <InputLabel shrink>ปีการศึกษา</InputLabel>
                <Select
                  native
                  label="ปีการศึกษา"
                  size="small"
                  value={state.schoolYear}
                  onChange={(e) => changeSchoolYear(e.target.value)}
                >
                  {state.years.map((option, key) => (
                    <option key={key} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "20px" }}>
              {loading === true && <LinearProgress />}
              {report && <ReportTable report={report} />}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

const styles = {
  div_percent: {
    color: "#3a3",
    fontSize: ".7rem",
  },
};
function ReportTable({ report }) {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: "80px" }}
                className="td"
                align="center"
              >
                ลำดับ
              </TableCell>
              <TableCell className="td" align="center">
                หัวข้อ
              </TableCell>
              {[5, 4, 3, 2, 1].map((item) => (
                <Fragment key={item}>
                  <TableCell
                    style={{ width: "80px" }}
                    className="td"
                    align="center"
                  >
                    {item}
                  </TableCell>
                </Fragment>
              ))}
              <TableCell
                className="td"
                align="center"
                style={{ width: "80px" }}
              >
                รวม
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {satisfaction_choice
              .filter((j) => !j.subOf)
              .map((item, key) => (
                <Fragment key={key}>
                  <TableRow>
                    <TableCell className="td" align="center" component="th">
                      {key + 1}
                    </TableCell>
                    <TableCell className="td" component="th" colSpan={7}>
                      {item.label_th}
                    </TableCell>
                  </TableRow>
                  {satisfaction_choice
                    .filter((j) => j.subOf === item.id)
                    .map((item2, key) => (
                      <TableRow key={key} hover>
                        <TableCell className="td" align="center">
                          {item2.no}
                        </TableCell>
                        <TableCell className="td">{item2.label_th}</TableCell>
                        {report.result.length === 0 ? (
                          <BlankRow />
                        ) : (
                          <>
                            {report.result
                              .filter((j) => j.id === item2.id)
                              .map((question, key) => (
                                <Fragment key={key}>
                                  {[5, 4, 3, 2, 1].map((value) => (
                                    <TableCell
                                      className="controlTd"
                                      align="center"
                                      key={value}
                                    >
                                      {question.values[value] ? (
                                        <>
                                          {question.values[value].count}{" "}
                                          <div style={styles.div_percent}>
                                            (
                                            {(
                                              (question.values[value].count /
                                                question.count) *
                                              100
                                            ).toFixed(2)}{" "}
                                            %)
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          0
                                          <div style={styles.div_percent}>
                                            (0.00 %)
                                          </div>
                                        </>
                                      )}
                                    </TableCell>
                                  ))}
                                  <TableCell
                                    className="td"
                                    component="th"
                                    align="center"
                                  >
                                    {question.count}
                                  </TableCell>
                                </Fragment>
                              ))}
                          </>
                        )}
                      </TableRow>
                    ))}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function BlankRow() {
  return (
    <>
      {[1, 2, 3, 4, 5].map((item) => (
        <TableCell className="controlTd" key={item} align="center">
          0<div style={styles.div_percent}>(0.00 %)</div>
        </TableCell>
      ))}
      <TableCell className="td" component="th" align="center">
        0
      </TableCell>
    </>
  );
}
