import React, { useState, useEffect } from "react";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
// import { Bar /*, defaults*/ } from "react-chartjs-2";
// import { defaults } from "react-chartjs-2";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

// defaults.font.family = "Kanit";
// defaults.font.weight = 300;

function MultiType({ state }) {
  // const [options, setOptions] = useState();
  const [charts, setCharts] = useState();

  // useEffect(() => {
  //   function getOptions() {
  //     let options = {
  //       plugins: {
  //         legend: {
  //           position: "bottom",
  //           labels: {
  //             font: {
  //               family: "Kanit",
  //               size: 14,
  //               weight: 300,
  //             },
  //           },
  //         },
  //       },
  //     };
  //     if (state.options) options = { ...options, ...state.options };
  //     setOptions(options);
  //   }
  //   if (state) getOptions();
  // }, [state]);
  useEffect(() => {
    (async () => {
      if (state) {
        if (state.version === 1) {
          let charts = {
            labels: state.data.labels,
            datasets: state.data.datasets,
          };
          setCharts(charts);
        }
      }
    })();
  }, [state]);

  return (
    <>
      {charts && (
        <div className="header">
          {state.title && (
            <h2 className="title" style={{ marginTop: "8px", fontWeight: 400 }}>
              {state.title}
            </h2>
          )}

          <Chart type="bar" data={charts} />
          {/* <Bar data={state.data} options={options} height={state.height} /> */}
        </div>
      )}
    </>
  );
}

export default MultiType;
