import React, { useEffect, useState } from "react";
import axios from "axios";
import Conf from "../Conf";
// import { influence_factors } from "../const";
import Grid from "@mui/material/Grid";
import { getPermission, getAge } from "../js/main";
import { useParams } from "react-router-dom";

// import { DriveEtaRounded } from "@material-ui/icons";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function Application(props) {
  const params = useParams();
  const styles = {
    gridValue: {
      width: "4cm",
      borderBottom: "1px dotted #111",
      paddingTop: "20px",
    },
    gridValueCenter: {
      width: "4cm",
      borderBottom: "1px dotted #111",
      paddingTop: "20px",
      textAlign: "center",
    },
    gridValueAddr: {
      width: "4cm",
      borderBottom: "1px dotted #111",
      height: "60px",
      paddingTop: "18px",
      verticalAlign: "middle",
      paddingLeft: "5px",
      fontSize: "10pt",
    },
    gridLabel: { paddingTop: "8px" },
    gridLabelCenter: { textAlign: "center", paddingTop: "8px" },
    addr: { paddingRight: "8px" },
  };
  const [register, setRegister] = useState();
  useEffect(() => {
    function getRegister() {
      return new Promise(async (resolve) => {
        let result = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          {
            match: { _id: params.id },
            collection: "registers",
            limit: 1,
          },
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (result.data.count > 0) resolve(result.data.result[0]);
      });
    }
    (async () => {
      let p = await getPermission();
      if (p && p.usn) {
        let register = await getRegister();
        register.register_form_year =
          parseInt(register.register_form_year) + 543;
        register.register_form_year -= 2500;
        register.register_form_no =
          "AD" +
          register.register_form_year +
          String(register.register_form_no).padStart(5, "0");
        register.age = getAge(register.birth_date);
        setRegister(register);
      }
    })();
  }, [params]);
  useEffect(() => {
    if (register) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [register]);
  return (
    <div>
      {register && (
        <div
          style={{
            width: "21.5cm",
            padding: ".3cm .3cm",
            paddingTop: "0cm",
            margin: "auto",
          }}
        >
          <div
            style={{
              textAlign: "right",
              borderBottom: "1px solid #111",
              paddingBottom: ".5cm",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/form_logo_en.png"}
              alt=""
              style={{ width: "4cm" }}
            />
          </div>
          <h1
            style={{
              fontSize: "18pt",
              padding: ".5cm .5cm",
              textAlign: "center",
            }}
          >
            Application Form (ใบสมัครเรียน)
          </h1>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item style={{ width: "4cm" }}>
              <div>Application No.</div>
              <div>เลขที่ใบสมัคร</div>
            </Grid>
            <Grid item style={styles.gridValueCenter}>
              {register.register_form_no}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            style={{ borderBottom: "1px solid #111", padding: "1cm 0px" }}
          >
            <>
              <Grid style={styles.gridLabel} xs={2} item>
                <div>Student's name</div>
                <div>ชื่อ-สกุล นักเรียน</div>
              </Grid>
              <Grid item style={styles.gridValue} xs={5}>
                {register.prefix} {register.first_name} {register.middle_name}{" "}
                {register.last_name}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={2} item>
                <div>Nickname</div>
                <div>ชื่อเล่น</div>
              </Grid>
              <Grid item style={styles.gridValueCenter} xs={3}>
                {register.nickname}
              </Grid>

              <Grid style={styles.gridLabel} xs={2} item>
                <div>Date of Birth</div>
                <div>วันที่เกิด</div>
              </Grid>
              <Grid item style={styles.gridValueCenter} xs={3}>
                {register.birth_date}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={1} item>
                <div>Age</div>
                <div>อายุ</div>
              </Grid>
              <Grid item style={styles.gridValueCenter} xs={1}>
                {register.age.years}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={1} item>
                <div>Years</div>
                <div>ขวบ</div>
              </Grid>
              <Grid item style={styles.gridValueCenter} xs={1}>
                {register.age.months}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={1} item>
                <div>Months</div>
                <div>เดือน</div>
              </Grid>
              <Grid item style={styles.gridValueCenter} xs={1}>
                {register.age.days}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={1} item>
                <div>Days</div>
                <div>วัน</div>
              </Grid>
            </>
            <>
              <Grid style={styles.gridLabel} xs={2} item>
                <div>Home Address</div>
                <div>ที่อยู่ปัจจุบัน</div>
              </Grid>
              <Grid item style={styles.gridValueAddr} xs={10}>
                {register.house_number !== "-" && (
                  <span style={styles.addr}>{register.house_number}</span>
                )}
                {register.village !== "-" && (
                  <span style={styles.addr}>{register.village}</span>
                )}
                {register.moo !== "-" && (
                  <span style={styles.addr}>{register.moo}</span>
                )}
                {register.soi !== "-" && (
                  <span style={styles.addr}>{register.soi}</span>
                )}
                {register.street !== "-" && (
                  <span style={styles.addr}>{register.street}</span>
                )}
                {register.area !== "-" && (
                  <span style={styles.addr}>{register.area}</span>
                )}
                {register.district !== "-" && (
                  <span style={styles.addr}>{register.district}</span>
                )}
                {register.province !== "-" && (
                  <span style={styles.addr}>{register.province}</span>
                )}
                {register.zip_code !== "-" && (
                  <span style={styles.addr}>{register.zip_code}</span>
                )}
              </Grid>
            </>
            <>
              <Grid style={styles.gridLabel} xs={2} item>
                <div>Father's name</div>
                <div>ชื่อ-สกุล บิดา</div>
              </Grid>
              <Grid item style={styles.gridValueAddr} xs={3}>
                {register.father_prefix} {register.father_first_name}{" "}
                {register.father_middle_name} {register.father_last_name}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={2} item>
                <div>Mobile No.</div>
                <div>เบอร์โทรศัพท์</div>
              </Grid>
              <Grid item style={styles.gridValueAddr} xs={2}>
                {register.father_phone}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={1} item>
                <div>Email</div>
                <div>อีเมล</div>
              </Grid>
              <Grid item style={styles.gridValueAddr} xs={2}>
                {register.father_email}
              </Grid>
              <Grid style={styles.gridLabel} xs={2} item>
                <div>Occupation</div>
                <div>อาชีพ</div>
              </Grid>
              <Grid item style={styles.gridValue} xs={3}>
                {register.father_job}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={2} item>
                <div>Office Address</div>
                <div>สถานที่ทำงาน</div>
              </Grid>
              <Grid item style={styles.gridValue} xs={5}>
                {register.father_workplace}
              </Grid>
            </>
            <>
              <Grid style={styles.gridLabel} xs={2} item>
                <div>Mother's name</div>
                <div>ชื่อ-สกุล มารดา</div>
              </Grid>
              <Grid item style={styles.gridValueAddr} xs={3}>
                {register.mother_prefix} {register.mother_first_name}{" "}
                {register.mother_middle_name} {register.mother_last_name}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={2} item>
                <div>Mobile No.</div>
                <div>เบอร์โทรศัพท์</div>
              </Grid>
              <Grid item style={styles.gridValueAddr} xs={2}>
                {register.mother_phone}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={1} item>
                <div>Email</div>
                <div>อีเมล</div>
              </Grid>
              <Grid item style={styles.gridValueAddr} xs={2}>
                {register.mother_email}
              </Grid>

              <Grid style={styles.gridLabel} xs={2} item>
                <div>Occupation</div>
                <div>อาชีพ</div>
              </Grid>
              <Grid item style={styles.gridValue} xs={3}>
                {register.mother_job}
              </Grid>
              <Grid style={styles.gridLabelCenter} xs={2} item>
                <div>Office Address</div>
                <div>สถานที่ทำงาน</div>
              </Grid>
              <Grid item style={styles.gridValue} xs={5}>
                {register.mother_workplace}
              </Grid>
            </>
          </Grid>
          <h2
            style={{ fontSize: "16pt", marginTop: ".5cm", textAlign: "center" }}
          >
            For Staff (สำหรับเจ้าหน้าที่)
          </h2>
          <Grid container>
            <Grid style={styles.gridLabel} xs={2} item>
              <div>Application for</div>
              <div>ระดับชั้นที่สมัคร</div>
            </Grid>
            <Grid item style={styles.gridValue} xs={10}>
              {register.level} {register.grade_year}
            </Grid>
            <Grid style={styles.gridLabel} xs={2} item>
              <div>Program</div>
              <div>หลักสูตรที่สมัคร</div>
            </Grid>
            <Grid item style={styles.gridValue} xs={10}>
              {register.program}
            </Grid>
            <Grid style={styles.gridLabel} xs={3} item>
              <div>Date of Interview</div>
              <div>นัดสัมภาษณ์ผู้ปกครองวันที่</div>
            </Grid>
            <Grid item style={styles.gridValueCenter} xs={4}>
              {register.interview_date}
            </Grid>
            <Grid style={styles.gridLabelCenter} xs={2} item>
              <div>Time</div>
              <div>เวลา</div>
            </Grid>
            <Grid item style={styles.gridValueCenter} xs={3}>
              {register.interview_time}
            </Grid>
            <Grid style={styles.gridLabel} xs={3} item>
              <div>Staff Attending</div>
              <div>เจ้าหน้าที่ที่รับสมัคร</div>
            </Grid>
            <Grid item style={styles.gridValue} xs={4}>
              {register.staff_attending}
            </Grid>
            <Grid style={styles.gridLabelCenter} xs={2} item>
              <div>Date</div>
              <div>วันที่รับสมุคร</div>
            </Grid>
            <Grid item style={styles.gridValueCenter} xs={3}>
              {register.register_date}
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
}
