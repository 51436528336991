import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PageNameBox from "../components/PageNameBox";
import VisitSelfForm from "../components/VisitSelfForm";
import CalendarTable from "../components/CalendarTable";

import { getOTP, getStampTime, getCloses } from "../js/main";

import Conf from "../Conf";
import axios from "axios";
import { Stack } from "@mui/system";
import { Divider } from "@mui/material";

const monthNames = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function VisitRegister({ page }) {
  const [state, setState] = useState();
  const [form, setForm] = useState();
  useEffect(() => {
    setState({
      activeStep: 0,
      numberStudent: 1,
      visited: false,
    });
  }, []);

  function changeStep0(visited) {
    setState({ ...state, visited, activeStep: 1 });
  }

  function changeStep12(val) {
    setForm(val);
    setState({ ...state, activeStep: 2 });
  }

  function changeStep(val) {
    let step = state.activeStep;
    step += val;
    setState({ ...state, activeStep: step });
  }

  async function submit(value) {
    let send = JSON.parse(JSON.stringify(form || {}));
    if (send.students)
      send.students = send.students.filter((j) => j.no <= send.numberStudents);
    send = { ...send, ...value };
    let visit = await axios.put(
      Conf.endpoint.tonkla + "/ca/visit/register",
      send
    );
    if (visit.data.error) alert(visit.data.error.message);
    else {
      alert("Register Completed");
      setForm({ ...form, ...value });
      setState({ ...state, activeStep: 3 });
    }
  }

  function selectStudent(id) {
    setForm({ _id: id });
    setState({ ...state, activeStep: 2 });
  }
  return (
    <div style={{ borderTop: "3px solid #3f51b5", paddingTop: "30px" }}>
      {state && (
        <Container maxWidth="lg">
          <PageNameBox page={page} />
          <br />
          <Stepper activeStep={state.activeStep} alternativeLabel>
            {[
              { label: "คัดกรองผู้เยี่ยมชม", alt_label: "School visit" },
              { label: "ข้อมูลการเยี่ยมชม", alt_label: "Fill the form" },
              { label: "นัดหมาย", alt_label: "Make an appointment" },
            ].map((step) => (
              <Step key={step.label}>
                <StepLabel>
                  <div style={{ color: "#111" }}>{step.label}</div>
                  <div style={{ color: "#66f" }}>{step.alt_label}</div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {
            {
              0: <Step0 onChangeStep={changeStep0} />,
              1: (
                <>
                  {state.visited === true ? (
                    <Step11
                      onChangeStep={changeStep}
                      onSelectStudent={(id) => selectStudent(id)}
                    />
                  ) : (
                    <Step12
                      //   form={form}
                      onChangeStep={changeStep}
                      onNext={changeStep12}
                    />
                  )}
                </>
              ),
              2: <Step2 onChangeStep={changeStep} onSubmit={submit} />,
              3: (
                <>
                  {form && (
                    <CompletedForm
                      visit_date={form.visit_date || null}
                      visit_time={form.visit_time || null}
                    />
                  )}
                </>
              ),
            }[state.activeStep]
          }
        </Container>
      )}
    </div>
  );
}

function Step2(props) {
  const [form, setForm] = useState({});
  const [state, setState] = useState();
  const allTimeSlot = [
    "09:00 - 09:30",
    "09:30 - 10:00",
    "10:00 - 10:30",
    "10:30 - 11:00",
    "11:00 - 11:30",
    "11:30 - 12:00",
    // "09.00 – 09.45",
    // "10.00 – 10.45",
    // "11.00 – 11.45",
  ];
  const [schedules, setSchedules] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);

  useEffect(() => {
    (async () => {
      changeMonth();
    })();
  }, []);
  function save() {
    if (
      !form.visit_date ||
      form.visit_date === "" ||
      !form.visit_time ||
      form.visit_time === "" ||
      form.visit_time === "-"
    ) {
      alert("Please fill completed information");
    } else {
      // console.log(form);
      props.onSubmit(form);
    }
  }
  async function changeMonth(dt) {
    if (dt) dt = await getStampTime(dt);
    else dt = await getStampTime();
    let now = await getStampTime();
    let yearNow = now.detail.year;
    let monthNow = now.detail.month;
    let { year, month } = dt.detail;
    dt = dt.iso_date.substring(0, 7);
    let Schedules = await axios.get(
      Conf.endpoint.tonkla + "/ca/visit/schedule/month/" + dt
    );

    let dayClose = await getCloses();

    let disabled = dayClose
      .filter((j) => j.value.indexOf(dt) > -1)
      .map((j) => j.value);
    if (yearNow + "-" + monthNow <= dt) {
      for (let i = 1; i < 32; i++) {
        let chkDate = dt + "-" + String(i).padStart(2, "0");
        if (now.iso_date >= chkDate) {
          disabled.push(chkDate);
        }
      }
    }
    setState({ month, year, disabled, cellsDateStyle: [] });
    setSchedules(Schedules.data);
  }
  function shiftMonth(value) {
    let m = parseInt(state.month);
    let y = state.year;
    m += value;
    if (m < 1) {
      m = 12;
      y--;
    } else if (m > 12) {
      m = 1;
      y++;
    }
    m = String(m).padStart(2, "0");
    changeMonth(y + "-" + m + "-01");
  }

  async function getTimeSlot(dt) {
    let slots = [];
    for (let slot of allTimeSlot) {
      let i = schedules.findIndex(
        (j) => j.visit_date === dt && j.visit_time === slot
      );
      if (i < 0) slots.push(slot);
    }
    setTimeSlot(slots);
  }
  return (
    <div>
      {state && (
        <Container maxWidth="sm">
          <h2
            style={{ textAlign: "center", marginBottom: "0px" }}
            className="bold"
          >
            ระบุวันและเวลาที่สะดวกเยี่ยมชม
          </h2>
          <div
            style={{ color: "#999", textAlign: "center", marginBottom: "20px" }}
          >
            Please select the date and time to visit the school
          </div>
          <div id="visit_date" className="" style={{ padding: "5px 5px" }}>
            <span style={{ fontWeight: 400, marginRight: "10px" }}>
              Select Visit Date :{" "}
            </span>{" "}
            {form.visit_date || "-"}
          </div>
          <Grid container style={{ marginTop: "10px" }}>
            <Grid
              item
              xs={8}
              style={{
                float: "left",
                color: "#717479",
                fontSize: "1.7rem",
              }}
            >
              {monthNames[parseInt(state.month)]} {state.year}
            </Grid>
            <Grid item xs={4} style={{ paddingTop: "10px" }}>
              <div style={{ float: "none", textAlign: "right" }}>
                <IconButton
                  style={{
                    padding: "3px 3px",
                    color: "#717479",
                  }}
                  component="span"
                  onClick={() => shiftMonth(-1)}
                >
                  <Icon>chevron_left</Icon>
                </IconButton>
                <IconButton
                  style={{ padding: "3px 3px", color: "#717479" }}
                  component="span"
                  onClick={() => shiftMonth(1)}
                >
                  <Icon>chevron_right</Icon>
                </IconButton>
              </div>
            </Grid>
          </Grid>
          <div id="visitdate-container" style={{ marginTop: "20px" }}>
            <CalendarTable
              month={state.month}
              year={state.year}
              disabled={state.disabled}
              cellsDateStyle={state.cellsDateStyle}
              onSelect={(value) => {
                getTimeSlot(value);
                setState({
                  ...state,
                  cellsDateStyle: [
                    {
                      date: value,
                      style: {
                        background: "#bbdefb",
                        borderRadius: "5px",
                      },
                    },
                  ],
                });
                setForm({ ...form, visit_date: value, visit_time: "-" });
              }}
            />
            {/* <Calendar
              onSelect={(value) => {
                getTimeSlot(value);
                setForm({ ...form, visit_date: value, visit_time: "-" });
              }}
              onChangeMonth={(dt) => changeMonth(dt)}
            /> */}
          </div>
          <div style={{ padding: "25px 0px" }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Visit Time</InputLabel>
              <Select
                native
                value={form.visit_time}
                // onChange={handleChange}
                id="visit_time"
                onChange={(e) =>
                  setForm({ ...form, [e.target.id]: e.target.value })
                }
                label="Visit Time"
              >
                <option value="-"></option>
                {timeSlot.map((val, key) => (
                  <option key={key}>{val}</option>
                ))}
              </Select>
            </FormControl>
          </div>
          <Grid container>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="secondary"
                style={{ minWidth: "180px" }}
                size="large"
                onClick={() => props.onChangeStep(-1)}
                startIcon={<Icon>arrow_back_ios</Icon>}
              >
                <div>previous Step</div>
              </Button>
            </Grid>
            <Grid item xs={6} align="right">
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "180px" }}
                size="large"
                onClick={() => save()}
                endIcon={<Icon>send</Icon>}
              >
                <div>Submit</div>
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
      <br />
    </div>
  );
}

function Step11(props) {
  const keywordRef = useRef("");
  const [visits, setVisits] = useState();
  async function goSearch() {
    let otp = await getOTP();
    let send = {
      // match: { register_form_no: { $exists: false } },
      match: { bill_no: { $exists: false } },
      keyword: {
        value: keywordRef.current.value,
        fields: ["first_name", "last_name", "nickname"],
      },
      limit: 10,
    };
    let result = await axios.post(Conf.endpoint.tonkla + "/ca/visit", send, {
      headers: { Authorization: otp },
    });
    if (result.data.error) {
      alert(result.data.error.message);
    } else {
      setVisits(result.data);
    }
    // let fields[""]
  }
  return (
    <div>
      <Container maxWidth="sm">
        <h3
          className="bold"
          style={{ marginBottom: "0px", textAlign: "center" }}
        >
          กรุณาเลือกนักเรียนที่เคยเยี่ยมชมโดยการสืบค้นจากชื่อ หรือนามสกุล
        </h3>
        <div
          style={{ color: "#999", textAlign: "center", marginBottom: "20px" }}
        >
          If you ever came to visit school, please search student’s first name,
          middle name or last name.
        </div>

        <TextField
          fullWidth
          inputRef={keywordRef}
          label="Student Search"
          placeholder="ค้นหานักเรียนจาก ชื่อ นามสกุล หรือชื่อเล่นนักเรียน..."
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          onKeyUp={(e) => e.key === "Enter" && goSearch()}
        />
        {visits && (
          <>
            <List
              component="nav"
              style={{
                border: "1px solid #ddd",
                borderRadius: "3px",
                marginTop: "10px",
              }}
            >
              {visits.result.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    color: "#aaa",
                    lineHeight: "100px",
                  }}
                >
                  ...ไม่พบข้อมูล...
                </div>
              ) : (
                <>
                  {visits.result.map((student, key) => (
                    <ListItem
                      key={key}
                      button
                      onClick={() => props.onSelectStudent(student._id)}
                      style={
                        key < visits.result.length - 1
                          ? {
                              borderBottom: "1px solid #ddd",
                            }
                          : {}
                      }
                    >
                      <ListItemIcon>
                        <Icon>person</Icon>
                      </ListItemIcon>
                      <ListItemText>
                        <div>
                          {student.prefix} {student.first_name}{" "}
                          {student.last_name}
                          {student.nickname && student.nickname !== "" && (
                            <span> ({student.nickname})</span>
                          )}
                        </div>
                      </ListItemText>
                    </ListItem>
                  ))}
                </>
              )}
            </List>
          </>
        )}
        <Button
          variant="contained"
          color="secondary"
          style={{ minWidth: "180px", marginTop: "20px" }}
          size="large"
          onClick={() => props.onChangeStep(-1)}
          startIcon={<Icon>arrow_back_ios</Icon>}
        >
          <div>previous Step</div>
        </Button>
      </Container>
    </div>
  );
}

function Step12(props) {
  return (
    <div>
      <VisitSelfForm
        {...props}
        onChangeStep={props.onChangeStep}
        form={props.form}
        onFillCompleted={(r) => props.onNext(r)}
      />
      <br />
    </div>
  );
}

function Step0(props) {
  return (
    <>
      <Container maxWidth="sm">
        <h1
          style={{ textAlign: "center", margin: "20px auto 0px" }}
          className="bold"
        >
          ท่านเคยเข้าเยี่ยมชมโรงเรียนมาก่อนหรือไม่
        </h1>
        <div style={{ textAlign: "center", color: "#999", fontSize: "1.3rem" }}>
          Have you visited Tonkla school before ?
        </div>
        <div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: "35px" }}
            size="large"
            className="bg_green"
            onClick={() => props.onChangeStep(false)}
          >
            <Stack>
              <div className="bold">ยังไม่เคยเข้าเยี่ยมชม</div>
              <div>No, I have not</div>
            </Stack>
          </Button>
        </div>
        <div>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: "10px" }}
            size="large"
            onClick={() => props.onChangeStep(true)}
          >
            <Stack>
              <div className="bold">เคยเข้าเยี่ยมชมแล้ว</div>
              <div>Yes, I have</div>
            </Stack>
          </Button>
        </div>
      </Container>
      <div
        style={{
          marginTop: "50px",
          fontWeight: 400,
          fontSize: "1.2rem",
          textAlign: "center",
        }}
      >
        ตรวจสอบการยืนยันการจองเข้าเยี่ยมชมจากเจ้าหน้าที่ผ่านช่องทาง email
        ของผู้ปกครอง
      </div>
      <div style={{ textAlign: "center", color: "#999" }}>
        The admission officer will send you the confirmation via email.
      </div>
    </>
  );
}

function CompletedForm(props) {
  const { visit_date, visit_time } = props;
  return (
    <Container
      maxWidth="sm"
      style={{ textAlign: "center", paddingTop: "20px" }}
    >
      <Paper style={{ padding: "15px 15px" }}>
        <h2 style={{ fontWeight: 400 }}>COMPLETED</h2>
        <div>โรงเรียนได้รับการนัดวันที่เยี่ยมชมของท่านใน</div>
        <div
          style={{
            fontWeight: 400,
            padding: "10px 10px",
            fontSize: "1.2rem",
            color: "#00f",
          }}
        >
          วันที่{" "}
          <span style={{ margin: "auto 15px auto 15px" }}>{visit_date}</span>{" "}
          เวลา{" "}
          <span style={{ margin: "auto 15px auto auto" }}>{visit_time} น.</span>
        </div>
        <div>เรียบร้อยแล้ว</div>
        <Divider sx={{ margin: "30px auto 30px" }} />
        <div>Completed Your appointment is</div>

        <div
          style={{
            fontWeight: 400,
            padding: "10px 10px",
            fontSize: "1.2rem",
            color: "#00f",
          }}
        >
          Date{" "}
          <span style={{ margin: "auto 15px auto 15px" }}>{visit_date}</span>{" "}
          Time{" "}
          <span style={{ margin: "auto 15px auto auto" }}>{visit_time}</span>
        </div>
        <Button
          component="a"
          href="https://www.tonkla.ac.th"
          target="_blank"
          variant="contained"
          color="primary"
          size="large"
          style={{ margin: "20px auto 15px", minWidth: "250px" }}
          startIcon={<Icon>home</Icon>}
        >
          <div>เยี่ยมชมเว็บไซต์โรงเรียน</div>
        </Button>
      </Paper>
    </Container>
  );
}
