import React, { useState, useEffect } from "react";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import CalendarTable from "../components/CalendarTable";
import { getPermission, getStampTime } from "../js/main";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const styles = {
  selected: {
    background: "#f48fb1",
    borderRadius: "5px",
  },
};

export default function Users({ page }) {
  const [profile, setProfile] = useState();
  const [cellsDateStyle, setCellsDateStyle] = useState([]);
  const [state, setState] = useState();
  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      setProfile(profile);
    })();
  }, []);
  useEffect(() => {
    async function fetchUsers() {
      let dt = await getStampTime();
      setState({
        dateValue: dt.iso_date,
        month: dt.detail.month,
        year: dt.detail.year,
      });
      let closes = await getCloses(dt.detail.month, dt.detail.year);
      setCellsDateStyle(closes);
    }
    if (profile) fetchUsers();
  }, [profile]);

  function getCloses(month, year) {
    return new Promise(async (resolve) => {
      let closes = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "day_closes",
          match: { type: "ca" },
          keyword: {
            value: year + "-" + month,
            fields: ["close_date"],
          },
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      let r = [];
      for (let c of closes.data.result) {
        r.push({
          date: c.close_date,
          style: styles.selected,
        });
      }
      resolve(r);
    });
  }

  function clickDate(d) {
    let Cells = [...cellsDateStyle];
    let i = Cells.findIndex((j) => j.date === d);
    if (i < 0) {
      Cells.push({
        date: d,
        style: styles.selected,
      });
    } else Cells = Cells.filter((j) => j.date !== d);
    setCellsDateStyle(Cells);
  }

  async function save() {
    let send = { clearMonth: state.year + "-" + state.month, close_dates: [] };
    for (let cell of cellsDateStyle) {
      send.close_dates.push(cell.date);
    }
    let result = await axios.put(Conf.endpoint.tonkla + "/ca/dayclose", send, {
      headers: { Authorization: cookie.access_token },
    });
    if (result.data && result.data.success === true) alert("Save completed");
    else alert("Error");
  }
  return (
    <div className="content">
      {state && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <br />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* <DatePicker
              views={["year", "month"]}
              label="Select Month"
              value={dayjs(state.dateValue)}
              onChange={async (newValue) => {
                let dt = await getStampTime(newValue);
                setState({
                  dateValue: dt.iso_date,
                  month: dt.detail.month,
                  year: dt.detail.year,
                });

                let closes = await getCloses(dt.detail.month, dt.detail.year);
                setCellsDateStyle(closes);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  helperText={null}
                  style={{ marginBottom: "20px" }}
                />
              )}
            /> */}
            <DatePicker
              views={["year", "month"]}
              label="Select Month"
              // value={dayjs(state.dateValue)}
              value={new Date(state.dateValue)}
              onChange={async (newValue) => {
                let dt = await getStampTime(newValue);
                setState({
                  dateValue: dt.iso_date,
                  month: dt.detail.month,
                  year: dt.detail.year,
                });

                let closes = await getCloses(dt.detail.month, dt.detail.year);
                setCellsDateStyle(closes);
              }}
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: true },
                  fullWidth: true,
                  size: "small",
                  style: { marginBottom: "20px" },
                },
              }}
            />
          </LocalizationProvider>
          <CalendarTable
            month={state.month}
            year={state.year}
            onSelect={clickDate}
            cellsDateStyle={cellsDateStyle}
          />
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button variant="contained" onClick={save}>
              Save Day Close
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
