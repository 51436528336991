import React, { useState, useEffect } from "react";
import { getPermission, getStampTime } from "../js/main";
import ProfileBox from "../components/ProfileBox";
import PageNameBox from "../components/PageNameBox";
import MultiType from "../charts/MultiType";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

// import { GridSelectItem } from "../components/InputForm";

import axios from "axios";
import Conf from "../Conf";
const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function RptMonthlyStatus({ page }) {
  const [profile, setProfile] = useState();
  const [filter, setFilter] = useState();
  const [report, setReport] = useState();
  const [total, setTotal] = useState();
  const [statuses, setStatuses] = useState([]);

  const getProfile = async () => {
    let p = await getPermission();
    if (p) return p;
    else window.location = process.env.PUBLIC_URL + "/";
  };

  const getStatus = async () => {
    let result = await axios.post(
      Conf.endpoint.tonkla + "/ca/query",
      { collection: "register_statuses" },
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    return result.data.result;
  };
  useEffect(() => {
    (async () => {
      let profile = await getProfile();
      let dt = new Date();
      let weekDay = dt.getDay();
      let status_date = [];
      for (let i = 0; i < 7; i++) {
        let startDate = 6 - (6 - weekDay) - i;
        startDate = new Date().setDate(dt.getDate() - startDate);
        startDate = await getStampTime(startDate);
        startDate = startDate.iso_date;
        status_date.push(startDate);
      }
      setProfile(profile);
      setFilter({
        collection: "registers",
        select: "status_date",
        match: { "status_date.stamp_date": { $in: status_date } },
        status_date,
        limit: 0,
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (filter) {
        let result = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          filter,
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        if (result.data.error) alert(result.data.error.message);
        else {
          result = result.data.result;
          let statuses = await getStatus();
          let report = [];
          let total = { person_count: 0 };
          for (let d of filter.status_date) {
            for (let status of statuses) {
              let c = await countStatus(d, status.label, result);
              if (!total[status.label]) total[status.label] = 0;
              total[status.label] += c;
              total.person_count += c;
              report.push({
                status: status.label,
                d: d,
                count: c,
              });
            }
          }
          setTotal(total);
          setStatuses(statuses);
          setReport(report);
        }
      }
    })();
  }, [filter]);
  function countStatus(d, status, result) {
    return new Promise(async (resolve) => {
      let c = 0;
      for (let r of result) {
        c += r.status_date.filter(
          (j) => j.label === status && j.stamp_date === d
        ).length;
      }
      resolve(c);
    });
  }
  function getChartData() {
    let result = [];
    for (let status of statuses) {
      result.push({
        label: status.label,
        count: total[status.label],
      });
    }
    return {
      person_count: total.person_count,
      result,
    };
  }
  return (
    <div className="content">
      {report && (
        <>
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={page} />
          <>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="td" align="center">
                          วันที่
                        </TableCell>
                        {statuses.map((status, key) => (
                          <TableCell className="td" align="center" key={key}>
                            {status.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filter.status_date.map((d, key) => (
                        <TableRow key={key}>
                          <TableCell className="td" align="center">
                            {d}
                          </TableCell>
                          {statuses.map((status, key) => (
                            <TableCell className="td" align="center" key={key}>
                              {
                                report.filter(
                                  (j) => j.status === status.label && j.d === d
                                )[0].count
                              }
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}

                      <TableRow>
                        <TableCell className="td bold" align="center">
                          รวม
                        </TableCell>
                        {statuses.map((status, key) => (
                          <TableCell
                            className="td bold"
                            align="center"
                            key={key}
                          >
                            {total[status.label]}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid xs={12} item lg={6}>
                <ChartArea
                  state={{
                    title: "รายงานประจำสัปดาห์",
                    report: getChartData(),
                  }}
                />
              </Grid>
            </Grid>
          </>
        </>
      )}
    </div>
  );
}

function ChartArea({ state }) {
  const [data, setData] = useState();
  useEffect(() => {
    async function createData() {
      let data = [];
      let labels = [];
      for (let item of state.report.result) {
        labels.push(item.label);
        data.push(item.count);
      }
      data = {
        labels: labels,
        datasets: [
          {
            type: "bar",
            label: "People count",
            data: data,
            backgroundColor: "rgba(51,215,133,.8)",
            borderColor: "rgb(51,185,190)",
            borderWidth: 1,
          },
        ],
      };
      setData(data);
    }
    if (state.report) createData();
  }, [state]);
  return (
    <div>
      {data && (
        <Grid container component={Paper}>
          <Grid item xs={12} style={{ padding: "20px 20px" }}>
            <MultiType
              state={{
                title: state.title,
                data: data,
                options: { indexAxis: "y" },
                version: 1,
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
