import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { auth, googleProvider } from "./firebase";
import axios from "axios";
import Conf from "./Conf";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"All rights reserved © "}
      Tonkla school
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {
  useEffect(() => {
    (async () => {
      auth.signOut();
    })();
  }, []);

  const signInWithGoogle = async () => {
    try {
      let profile = await auth.signInWithPopup(googleProvider);
      if (profile && profile.credential) {
        auth.currentUser.getIdToken(true).then(async (idToken) => {
          let user = await axios.get(Conf.endpoint.tonkla + "/ca/admin/auth", {
            headers: { Authorization: idToken },
          });
          user = user.data;

          if (user.error) {
            alert(user.error.message);
            window.location = process.env.PUBLIC_URL + "/";
          } else {
            let access_token = user.access_token;
            localStorage.setItem(
              Conf.cookie_name,
              JSON.stringify({ access_token: access_token })
            );

            window.location = process.env.PUBLIC_URL + "/form/visit";
          }
        });
      }
      // The user is signed in with Google.
    } catch (error) {
      console.error(error.message);
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ marginTop: "20px" }}>
              Authentication
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ mt: 3, mb: 2 }}
                onClick={signInWithGoogle}
              >
                Sign In
              </Button>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
