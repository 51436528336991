import { Fragment, useEffect, useState } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";

import { weekDays } from "../const";

export default function CalendarTable({
  month,
  year,
  cellsDateStyle,
  onSelect,
  disabled,
}) {
  const [calendar, setCalendar] = useState();
  useEffect(() => {
    (async () => {
      if ((month, year)) {
        let startDay = year + "-" + String(month).padStart(2, "0") + "-01";
        let monthWeekDay = new Date(startDay).getDay();
        let monthDays = new Date(year, month, 0).getDate();
        let calendar = [];
        let row = 0;
        for (let i = 1; i <= monthDays; i++) {
          let d =
            year +
            "-" +
            String(month).padStart(2, "0") +
            "-" +
            String(i).padStart(2, "0");
          let disable = false;
          if (disabled && disabled.findIndex((j) => j === d) > -1)
            disable = true;
          let obj = {
            day: i,
            date: d,
            weekDay: monthWeekDay++,
            row,
            disabled: disable,
          };
          if (monthWeekDay === 7) {
            monthWeekDay = 0;
            row++;
          }
          calendar.push(obj);
        }
        setCalendar(calendar);
      }
    })();
  }, [month, year, disabled]);

  return (
    <div>
      {calendar && (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {weekDays.map((day) => (
                  <TableCell
                    align="center"
                    key={day.value}
                    style={{ width: "14.2%" }}
                  >
                    {day.abbrev}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[0, 1, 2, 3, 4, 5, 6].map((row) => (
                <Fragment key={row}>
                  {calendar[calendar.length - 1].row >= row && (
                    <CalendarRow
                      days={calendar.filter((j) => j.row === row)}
                      cellsDateStyle={cellsDateStyle || []}
                      onSelect={onSelect}
                    />
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

function CalendarRow({ days, numberStyle, cellsDateStyle, onSelect }) {
  function setCellStyle(date) {
    let i = cellsDateStyle.findIndex((j) => j.date === date);
    let r = {};
    if (i > -1 && cellsDateStyle[i].style) {
      r = cellsDateStyle[i].style;
    }
    return r;
  }
  return (
    <TableRow>
      {days[0].weekDay !== 0 && (
        <>
          {[0, 1, 2, 3, 4, 5, 6].map((wd, key) => (
            <Fragment key={key}>
              {days[0].weekDay > wd && <TableCell />}
            </Fragment>
          ))}
        </>
      )}
      {days.map((day) => (
        <TableCell style={{ padding: "1px 1px", height: "35px" }} key={day.day}>
          <div style={setCellStyle(day.date)}>
            <div style={numberStyle || { textAlign: "right" }}>
              {day.disabled && day.disabled !== false ? (
                <div
                  style={{
                    padding: "0px",
                    paddingRight: "5px",
                    fontWeight: 400,
                    color: "#aaa",
                  }}
                >
                  {day.day}
                </div>
              ) : (
                <Button
                  variant="text"
                  style={{ padding: "0px", minWidth: "25px" }}
                  onClick={() => onSelect(day.date)}
                >
                  {day.day}
                </Button>
              )}
            </div>
          </div>
        </TableCell>
      ))}
    </TableRow>
  );
}
