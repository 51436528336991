import React, { Fragment, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DialogChangeStatus from "../components/DialogChangeStatus";
import { GridSelectItem } from "../components/InputForm";
import { getStampTime } from "../js/main";

import Radio from "@mui/material/Radio";
import axios from "axios";
import Conf from "../Conf";

const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

const styles = {
  pathHead: {
    fontSize: "1.1rem",
    fontWeight: 400,
    margin: "10px auto 10px",
  },
};

const compairQuestions = [
  {
    id: "understanding",
    title: "ความเข้าใจต่อแนวทางการจัดการศึกษาทางโรงเรียน",
    choices: [
      { label: "มาก" },
      { label: "ปานกลาง" },
      { label: "ไม่ได้เข้าใจ" },
    ],
  },
  {
    id: "parenting",
    title: "การเลี้ยงดู",
    choices: [
      { label: "ใช้เหตุผล" },
      { label: "ตามใจ" },
      { label: "ดูเลี้ยงไม่เป็น" },
      { label: "Over Protect" },
    ],
  },
  {
    id: "characteristics",
    title: "ลักษณะของผู้ปกครอง",
    choices: [
      { label: "ไม่ค่อยละเอียด สื่อสารเข้าใจ" },
      { label: "ละเอียดมาก แต่สื่อสารแล้วเข้าใจ" },
      { label: "ละเอียดมาก สื่อสารไม่พร้อมเข้าใจ" },
      { label: "ไม่รู้เรื่องอะไรเลย" },
    ],
  },
];
const communicationQuestions = [
  { title: "1. นโยบาย 3 ข้อ", id: "rule" },
  {
    title: "2. จำนวนการเรียน 2 ภาคเรียน + Pre-Semester Course",
    id: "total_period",
  },
  { title: "3. อบรมสำหรับผู้ปกครอง 20-30 ชั่วโมง", id: "train" },
];

export default function FormInterviewStaff(props) {
  const { register } = props;
  const [form, setForm] = useState({ register_id: register._id });
  const [state, setState] = useState({ showChangeSatus: false });
  const [statusDate, setStatusDate] = useState();
  useEffect(() => {
    async function getForm() {
      let register_id = props.register._id;
      let form = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "staff_interviews",
          match: { register_id: register_id },
        },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      if (form.data.result[0]) {
        form = form.data.result[0];
        setStatusDate(props.register.status_date || []);
        setForm(form);
      }
    }
    if (props.register._id) getForm();
  }, [props.register]);
  function addVisitSchool() {
    let val = document.getElementById("visit_schools").value;
    if (val.trim() !== "") {
      let visit_schools = form.visit_schools || [];
      visit_schools.push({ name: val });
      setForm({ ...form, visit_schools });
      document.getElementById("visit_schools").value = "";
    }
  }
  function removeVisitSchool(key) {
    let visit_schools = JSON.parse(JSON.stringify(form.visit_schools));
    visit_schools = visit_schools.filter(
      (j) => j.name !== visit_schools[key].name
    );
    setForm({ ...form, visit_schools });
  }
  async function save() {
    let result = await axios.put(
      Conf.endpoint.tonkla + "/ca/interview/staff",
      form,
      {
        headers: { Authorization: cookie.access_token },
      }
    );
    if (result.data.error) alert(result.data.error.message);
    else {
      let i = statusDate.findIndex((j) => j.label === "ไม่ผ่าน");
      let newStatusDate = JSON.parse(JSON.stringify(statusDate));
      if (form.consider_result === "ไม่ผ่าน" && i < 0) {
        // setState({ ...state, showChangeSatus: true });
        let dt = await getStampTime();
        dt = dt.iso_date;
        await axios.put(
          Conf.endpoint.tonkla + "/ca/register/status",
          {
            register_id: register._id,
            status: "ไม่ผ่าน",
            change_date: dt,
          },
          { headers: { Authorization: cookie.access_token } }
        );
        // newStatusDate.push({ label: "ไม่ผ่าน" });
      } else if (i > -1 && form.consider_result === "ผ่าน") {
        await axios.put(
          Conf.endpoint.tonkla + "/ca/register/status",
          {
            method: "delStatus",
            register_id: register._id,
            status: "ไม่ผ่าน",
          },
          {
            headers: { Authorization: cookie.access_token },
          }
        );
        newStatusDate = newStatusDate.filter((j) => j.label !== "ไม่ผ่าน");
      }
      setStatusDate(newStatusDate);
      alert("Save completed");
    }
  }
  function changeAccept(field, val) {
    setForm({ ...form, [field]: val });
  }
  return (
    <div style={{ paddingTop: "20px" }}>
      <DialogChangeStatus
        open={state.showChangeSatus}
        status="ไม่ผ่าน"
        register_id={props.register._id}
        onClose={() => setState({ ...state, showChangeSatus: false })}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3 style={styles.pathHead}>ส่วนที่ 1 : ข้อมูลจากการเยี่ยมชม</h3>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            paddingTop: "22px",
            width: "280px",
            fontWeight: 400,
            fontSize: "1rem",
            color: "#555",
          }}
        >
          มาเยี่ยมชมจำนวน
          <span style={{ padding: "0px 30px", color: "#00f" }}>
            {register.visit_schedules.length}
          </span>
          ครั้ง
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="โรงเรียนที่เคยเข้าเยี่ยมชม"
            id="visit_schools"
            size="small"
            fullWidth
            onKeyUp={(e) => e.key === "Enter" && addVisitSchool()}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={addVisitSchool} edge="start">
                    <Icon>add</Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "0px" }}>
          {form.visit_schools &&
            form.visit_schools.map((school, key) => (
              <Grid container key={key}>
                <Grid item style={{ width: "50px", textAlign: "center" }}>
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    style={{ padding: "2px 2px" }}
                    onClick={() => removeVisitSchool(key)}
                  >
                    <Icon style={{ color: "#f00" }}>clear</Icon>
                  </IconButton>
                </Grid>
                <Grid item style={{ lineHeight: "28px" }}>
                  {school.name}
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={12}>
          <h3 style={styles.pathHead}>
            ส่วนที่ 2 : ข้อมูลของผู้รับสมัคร / ผู้สัมภาษณ์
          </h3>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="td"
                    align="center"
                    style={{ width: "80px" }}
                  >
                    ลำดับ
                  </TableCell>
                  <TableCell className="td" align="center">
                    รายละเอียดความคิดเห็น
                  </TableCell>
                  <TableCell
                    className="td"
                    align="center"
                    style={{ width: "160px" }}
                  >
                    ผู้รับสมัคร
                  </TableCell>
                  <TableCell
                    className="td"
                    align="center"
                    style={{ width: "160px" }}
                  >
                    ผู้สัมภาษณ์
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {compairQuestions.map((question, key) => (
                  <Fragment key={key}>
                    <TableRow>
                      <TableCell
                        className="td"
                        align="center"
                        style={{ verticalAlign: "top" }}
                      >
                        {key + 1}
                      </TableCell>
                      <TableCell
                        className="td"
                        style={{ verticalAlign: "top" }}
                      >
                        {question.title}
                      </TableCell>
                      <TableCell className="td">
                        <RadioChoice
                          choices={question.choices}
                          value={form[question.id + "_recruiter"]}
                          onChangeField={(val) =>
                            changeAccept(question.id + "_recruiter", val)
                          }
                        />
                      </TableCell>
                      <TableCell className="td">
                        <RadioChoice
                          choices={question.choices}
                          value={form[question.id + "_interviewer"]}
                          onChangeField={(val) =>
                            changeAccept(question.id + "_interviewer", val)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <h3 style={{ fontWeight: 400, margin: "0px auto 5px" }}>
            รายละเอียดเพิ่มเติม
          </h3>

          <TextField
            fullWidth
            multiline
            value={form.note}
            rows={4}
            onChange={(e) => setForm({ ...form, note: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={styles.pathHead}>ส่วนที่ 3 : ประเด็นที่ต้องสื่อสาร</h3>
        </Grid>
        {communicationQuestions.map((question, key) => (
          <Grid item xs={12} key={key}>
            <RadioQuestion
              question={question}
              form={form}
              onChangeField={(field, val) =>
                changeAccept(field + "_" + question.id, val)
              }
            />
          </Grid>
        ))}

        <Grid item xs={12}>
          <h3 style={styles.pathHead}>ส่วนที่ 4 : ความคิดเห็นของผู้สัมภาษณ์</h3>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            label="ประเด็นสำคัญที่ควรพิจารณา"
            InputLabelProps={{ shrink: true }}
            value={form.consider_issue}
            onChange={(e) =>
              setForm({ ...form, consider_issue: e.target.value })
            }
          />
        </Grid>

        <Grid item xs={12} style={{ padding: "0px 40px" }}>
          <RadioGroup
            onChange={(e) =>
              setForm({ ...form, consider_result: e.target.value })
            }
          >
            <Grid container sx={{ marginTop: "10px" }}>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="ผ่าน"
                  checked={form.consider_result === "ผ่าน" || false}
                  control={<Radio />}
                  label={<div styl={{ fontSize: ".8rem" }}>ผ่าน</div>}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="ไม่ผ่าน"
                  checked={form.consider_result === "ไม่ผ่าน" || false}
                  control={<Radio />}
                  label={<div styl={{ fontSize: ".8rem" }}>ไม่ผ่าน</div>}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="รอพิจารณา"
                  checked={form.consider_result === "รอพิจารณา" || false}
                  control={<Radio />}
                  label={<div styl={{ fontSize: ".8rem" }}>รอพิจารณา</div>}
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={form.register_condition}
            label="เงื่อนไขการรับสมัครเรียน"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setForm({ ...form, register_condition: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={styles.pathHead}>ส่วนที่ 5 : ผลทดสอบ MEL</h3>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "0px", paddingLeft: "35px" }}>
          <RadioGroup
            onChange={(e) => setForm({ ...form, MEL_exam: e.target.value })}
          >
            <Grid container style={{ paddingTop: "0px" }}>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="ผ่าน"
                  checked={form.MEL_exam === "ผ่าน" || false}
                  control={<Radio />}
                  label={<div styl={{ fontSize: ".8rem" }}>ผ่าน</div>}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="ไม่ผ่าน"
                  checked={form.MEL_exam === "ไม่ผ่าน" || false}
                  control={<Radio />}
                  label={<div styl={{ fontSize: ".8rem" }}>ไม่ผ่าน</div>}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControlLabel
                  value="ผ่านแบบมีเงื่อนไข"
                  checked={form.MEL_exam === "ผ่านแบบมีเงื่อนไข" || false}
                  control={<Radio />}
                  label={
                    <div styl={{ fontSize: ".8rem" }}>ผ่านแบบมีเงื่อนไข</div>
                  }
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>

        <GridSelectItem
          size={[12, 12, 12, 12]}
          label="ผู้สัมภาษณ์"
          id="staff_name"
          value={form.staff_name}
          changeValue={(e) => {
            setForm({ ...form, staff_name: e.target.value });
          }}
          options={[
            { value: "ชลิดา สถิตนิรามัย", label: "ชลิดา สถิตนิรามัย" },
            { value: "จีรพร จงเจริญ", label: "จีรพร จงเจริญ" },
            { value: "สาเกต ทองเที่ยง", label: "สาเกต ทองเที่ยง" },
            { value: "กนกวรรณ์ โปธิปัน", label: "กนกวรรณ์ โปธิปัน" },
          ]}
        />
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={save}
            style={{ width: "180px" }}
            startIcon={<Icon>save</Icon>}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

const RadioChoice = (props) => (
  <>
    <RadioGroup>
      {props.choices.map((choice, key) => (
        <FormControlLabel
          key={key}
          style={{ marginBottom: "5px" }}
          value={choice.label}
          checked={choice.label === props.value || false}
          control={<Radio style={{ padding: "3px 3px", marginRight: "3px" }} />}
          label={<div style={{ fontSize: ".8rem" }}>{choice.label}</div>}
          onChange={(e) => props.onChangeField(e.target.value)}
        />
      ))}
    </RadioGroup>
  </>
);

function RadioQuestion(props) {
  const { question, onChangeField, form } = props;
  const acceptId = "accept_" + question.id;
  const otherId = "other_" + question.id;
  return (
    <div>
      <div style={{ paddingBottom: "15px", fontSize: "1rem" }}>
        {question.title}
      </div>
      <RadioGroup
        onChange={(e) => onChangeField("accept", parseInt(e.target.value))}
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={4} style={{ paddingLeft: "15px" }}>
            <FormControlLabel
              style={{ marginBottom: "5px" }}
              checked={form[acceptId] === 1 || false}
              value={1}
              control={
                <Radio style={{ padding: "3px 3px", marginRight: "3px" }} />
              }
              label={<div styl={{ fontSize: ".8rem" }}>รับได้ ปฏิบัติ</div>}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={{ paddingLeft: "15px" }}>
            <FormControlLabel
              style={{ marginBottom: "5px" }}
              checked={form[acceptId] === 0 || false}
              value={0}
              control={
                <Radio style={{ padding: "3px 3px", marginRight: "3px" }} />
              }
              label={<div styl={{ fontSize: ".8rem" }}>ไม่เห็นด้วย</div>}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "10px 10px" }}>
            <TextField
              label="ระบุ"
              variant="outlined"
              size="small"
              value={form[otherId]}
              InputLabelProps={{ shrink: true }}
              fullWidth
              onChange={(e) => onChangeField("other", e.target.value)}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </div>
  );
}
