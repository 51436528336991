import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import {
  EducationForm,
  CheckForm,
  ChioceQuestion,
  RadioInput,
} from "../forms/Interview";
import { parents_married_status } from "../const.js";

import {
  GridInputItem,
  GridSelectItem,
  RadioForm,
} from "../components/InputForm";
import axios from "axios";
import Conf from "../Conf";

const styles = {
  groupHead: {
    paddingBottom: "20px",
    fontSize: "1.2rem",
    fontWeight: 400,
    marginTop: "10px",
  },
  subGroupHeader: {
    color: "#888",
    fontSize: ".9rem",
  },
  subLabel: {
    color: "#888",
    fontSize: ".8rem",
  },
};

// const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));

export default function FormInterview(props) {
  const { form } = props;
  // const [gradeYears, setGradeYears] = useState([]);
  // const [levels, setLevels] = useState([]);
  // const [levelGrade, setLevelGrade] = useState([]);
  const [provinces, setProvinces] = useState([]);
  // const [gradeLevel, setGradeLevel] = useState("");
  // const [level, setLevel] = useState();
  // const programs = [
  //   {
  //     label: "MEP (Mini English Program)",
  //     levels: [
  //       "เตรียมอนุบาล",
  //       "อนุบาล 1",
  //       "อนุบาล 2",
  //       "อนุบาล 3",
  //       "ประถมศึกษา 1",
  //       "ประถมศึกษา 2",
  //       "ประถมศึกษา 3",
  //       "ประถมศึกษา 4",
  //       "ประถมศึกษา 6",
  //       "ประถมศึกษา 5",
  //     ],
  //   },
  //   {
  //     label: "IEP (Integrated English Program)",
  //     levels: [
  //       "เตรียมอนุบาล",
  //       "อนุบาล 1",
  //       "อนุบาล 2",
  //       "อนุบาล 3",
  //       "ประถมศึกษา 1",
  //       "ประถมศึกษา 2",
  //       "ประถมศึกษา 3",
  //       "ประถมศึกษา 4",
  //       "ประถมศึกษา 6",
  //       "ประถมศึกษา 5",
  //     ],
  //   },
  //   {
  //     label: "IP (Inter Program)",
  //     levels: [
  //       "เตรียมอนุบาล",
  //       "อนุบาล 1",
  //       "อนุบาล 2",
  //       "อนุบาล 3",
  //       "ประถมศึกษา 1",
  //       "ประถมศึกษา 2",
  //       "ประถมศึกษา 3",
  //       "ประถมศึกษา 4",
  //       "ประถมศึกษา 6",
  //       "ประถมศึกษา 5",
  //     ],
  //   },
  // ];
  useEffect(() => {
    async function setDefault() {
      /*
      let Groups = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        { collection: "groups", limit: 1000, select: "-student_id" },
        {
          headers: { Authorization: cookie.access_token },
        }
      );
      let Groups = await axios.get(Conf.endpoint.tonkla + "/ca/register/group");
      Groups = Groups.data.result;
      let GradeLevels = [];
      let Grades = [];
      for (let group of Groups) {
        if (group.level_year === "-") group.level_year = "--";
        let i = Grades.findIndex(
          (j) => j.level === group.level && j.value === group.level_year
        );
        if (i < 0) {
          Grades.push({
            label: group.level_year,
            value: group.level_year,
            level: group.level,
            level_abbrev: group.level_abbrev,
          });
          let value =
            group.level +
            (group.level_year !== "--" ? " " + group.level_year : "");
          GradeLevels.push({
            label: value,
            obj: {
              level: group.level,
              grade: group.level_year,
            },
            value: value,
          });
        }
      }
      */
      // setLevelGrade(GradeLevels);
      // setLevels(newLevels);
      // setGradeYears(Grades);
    }
    async function setDefaultProvinces() {
      let Provinces = await axios.post(Conf.endpoint.tonkla + "/ca/zip", {
        limit: 10000,
        select: "province",
        sort: "province",
      });
      let newProvinces = [];
      for (let province of Provinces.data.result.filter((j) => j.province)) {
        let i = newProvinces.findIndex((j) => j.label === province.province);
        if (i < 0)
          newProvinces.push({
            value: province.province,
            label: province.province,
          });
      }
      setProvinces(newProvinces);
    }
    setDefault();
    setDefaultProvinces();
  }, []);

  useEffect(() => {
    if (props.form) {
      // if (props.form.level) {
      //   let val = props.form.level;
      //   if (props.form.grade_year) val += " " + props.form.grade_year;
      //   setLevel(val);
      // }
    }
  }, [props.form]);

  async function changeForm(e) {
    props.onUpdateField(e.target.id, e.target.value);
  }
  async function changeFieldValue(id, value) {
    props.onUpdateField(id, value);
  }
  async function changeGroupFieldValue(obj) {
    props.onUpdateGroupFields(obj);
  }
  /*
  function changeLevelValue(e) {
    let val = e.target.value;
    let i = levelGrade.findIndex((j) => j.value === val);
    if (i > -1) {
      let grade_year = levelGrade[i].obj.grade;
      if (grade_year === "--") grade_year = null;
      changeGroupFieldValue({
        level: levelGrade[i].obj.level,
        grade_year: grade_year,
        program: "",
      });
    }
  }
  */
  return (
    <>
      {form && (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12} style={styles.groupHead}>
              1. Why did you choose Tonkla School? (Specify 3 reasons in order
              of importance)
              <div style={styles.subGroupHeader}>
                คำชี้แจง: ขอให้ผู้ปกครองกรอกข้อมูลให้ครบถ้วน
                และตรงตามความเป็นจริง
                และกรอกข้อมูลให้เรียบร้อยก่อนการเข้าสัมภาษณ์
              </div>
            </Grid>
            <Grid item xs={12}>
              <CheckForm
                checkboxs={form.consider_chooses}
                other={form.other_consider_choose}
                id="why_choose"
                onCheck={(key, checked) => {
                  let arr = JSON.parse(JSON.stringify(form.consider_chooses));
                  arr[key].checked = checked;
                  // setForm({ ...form, consider_chooses: arr });
                  changeFieldValue("consider_chooses", arr);
                }}
                onChangeOther={(e) =>
                  // setForm({ ...form, other_consider_choose: e.target.value })
                  changeFieldValue("other_consider_choose", e.target.value)
                }
              />
            </Grid>
            {/* No 2 */}

            <>
              <Grid item xs={12} style={styles.groupHead}>
                2. Student and Parent Information
                <div style={styles.subGroupHeader}>
                  ข้อมูลนักเรียนและข้อมูลส่วนตัวของครอบครัวนักเรียน
                </div>
              </Grid>
              {/* <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                2.1 Choose grade level:
              </Grid>
              <GridSelectItem
                size={[12, 4, 4, 4]}
                label="Level of attending"
                id="level"
                value={level}
                changeValue={(e) => {
                  let lv = e.target.options[e.target.selectedIndex].text;
                  if (lv !== "") {
                    setGradeLevel(
                      e.target.options[e.target.selectedIndex].text
                    );
                  } else setGradeLevel("");
                  changeLevelValue(e);
                }}
                options={levelGrade}
              />
              <GridSelectItem
                size={[12, 2, 2, 2]}
                label="Semester"
                id="term"
                value={form.term}
                changeValue={(
                  e 
                ) => changeForm(e)}
                options={[
                  { label: "1", value: "1" },
                  { label: "2", value: "2" },
                ]}
              />
              <GridSelectItem
                size={[12, 6, 6, 6]}
                label="Program "
                id="program"
                value={form.program}
                changeValue={(e) => {
                  changeForm(e);
                }}
                options={programs.filter(
                  (j) => j.levels.indexOf(gradeLevel) > -1
                )}
              /> */}

              <Grid item xs={12} style={{ paddingBottom: "15px" }}>
                2.1 Has your child been enrolled in school before?
                <div style={styles.subLabel}>
                  นักเรียนเคยเข้าโรงเรียนมาก่อนหรือไม่
                </div>
              </Grid>
              <GridInputItem
                size={[12, 7, 7, 7]}
                label="Old school name"
                placeholder="ชื่อโรงเรียเก่า"
                id="old_school"
                value={form.old_school}
                changeValue={(e) =>
                  // setForm({ ...form, [e.target.id]: e.target.value })

                  changeForm(e)
                }
              />
              <GridSelectItem
                size={[12, 5, 5, 5]}
                label="Old school province"
                id="old_school_province"
                value={form.old_school_province}
                changeValue={(e) =>
                  // setForm({ ...form, [e.target.id]: e.target.value })

                  changeForm(e)
                }
                options={provinces}
              />
              <Grid
                item
                xs={12}
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
              >
                2.2 Parent Information
                <div style={styles.subLabel}>ข้อมูลของบิดา-มารดา</div>
              </Grid>
              <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                A. Status of Father and Mother
                <div style={styles.subLabel}>ข้อมูลของบิดา-มารดา</div>
              </Grid>
              <Grid item xs={12}>
                <RadioForm
                  choices={parents_married_status}
                  grids={[12, 6, 3, 3]}
                  value={form.parents_married_status}
                  other={form.other_parents_married_status}
                  onCheck={(value) => {
                    changeFieldValue("parents_married_status", value);
                  }}
                  onChangeOther={(e) =>
                    changeFieldValue(
                      "other_parents_married_status",
                      e.target.value
                    )
                  }
                />
                {/* <CheckForm
                  checkboxs={form.parents_married_status}
                  other={form.other_parents_married_status}
                  // id="why_choose"
                  onCheck={(key, checked) => {
                    let arr = JSON.parse(
                      JSON.stringify(form.parents_married_status)
                    );
                    arr[key].checked = checked;
                    changeFieldValue("parents_married_status", arr);
                  }}
                  onChangeOther={(e) =>
                    // setForm({ ...form, other_consider_choose: e.target.value })
                    changeFieldValue(
                      "other_parents_married_status",
                      e.target.value
                    )
                  }
                /> */}
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} style={{ paddingLeft: "20px" }}>
                A. Status of Father and Mother
                <div style={styles.subLabel}>ข้อมูลของบิดา-มารดา</div>
              </Grid>
              <Grid item xs={12} sm={6} md={8} style={{ paddingLeft: "20px" }}>
                <RadioGroup
                  row
                  onChange={(e) =>
                    changeFieldValue("parents_married_status", e.target.value)
                  }
                  value={form.parents_married_status || ""}
                >
                  <FormControlLabel
                    value="Married"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>Married</div>
                        <div style={styles.subLabel}>อยู่ร่วมกัน</div>
                      </div>
                    }
                    style={{ marginRight: "50px" }}
                  />
                  <FormControlLabel
                    value="Separated"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>Separated</div>
                        <div style={styles.subLabel}>แยกกันอยู่</div>
                      </div>
                    }
                    style={{ marginRight: "50px" }}
                  />
                  <FormControlLabel
                    value="Divorced"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>Divorced</div>
                        <div style={styles.subLabel}>หย่าร้าง</div>
                      </div>
                    }
                  />
                </RadioGroup>
              </Grid> */}
              <Grid item xs={12} sm={6} md={4} style={{ paddingLeft: "20px" }}>
                If separated or divorced, the student stays primarily with
                <div style={styles.subLabel}>
                  หากแยกกันอยู่ โดยนักเรียนอยู่กับ
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={8} style={{ paddingLeft: "20px" }}>
                <RadioGroup
                  row
                  onChange={(e) => {
                    changeFieldValue("stay_with", e.target.value);
                  }}
                  value={form.stay_with || ""}
                >
                  <FormControlLabel
                    value="Father"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>Father</div>
                        <div style={styles.subLabel}>พ่อ</div>
                      </div>
                    }
                    style={{ marginRight: "60px" }}
                  />
                  <FormControlLabel
                    value="Mother"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>Mother</div>
                        <div style={styles.subLabel}>แม่</div>
                      </div>
                    }
                  />
                </RadioGroup>
              </Grid>
              <EducationForm
                id="father_"
                label_th="การศึกษาของบิดา"
                label_en="B. Education level of father"
                value={form}
                onChangeForm={(Form) => changeGroupFieldValue(Form)}
              />
              <EducationForm
                id="mother_"
                label_th="การศึกษาของมารดา"
                label_en="C. Education level of mother"
                value={form}
                onChangeForm={(Form) => changeGroupFieldValue(Form)}
              />
            </>

            {/* No 3 */}
            <>
              <Grid item xs={12} style={styles.groupHead}>
                3. Parenting Style
                <div style={styles.subGroupHeader}>วิธีการเลี้ยงดู</div>
              </Grid>

              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                3.1 Individuals responsible for the child (answer more than one
                if applicable)
                <div style={styles.subGroupHeader}>
                  คนที่มีบทบาทต่อการดูแลนักเรียนในปัจจุบัน (ตอบมากกว่า 1 ข้อ)
                </div>
              </Grid>

              <Grid item xs={12}>
                <CheckForm
                  checkboxs={form.individuals_responsible}
                  other={form.other_individuals_responsible}
                  id="individuals_responsible"
                  onCheck={(key, checked) => {
                    let arr = JSON.parse(
                      JSON.stringify(form.individuals_responsible)
                    );
                    arr[key].checked = checked;
                    changeFieldValue("individuals_responsible", arr);
                  }}
                  onChangeOther={(e) =>
                    changeFieldValue(
                      "other_individuals_responsible",
                      e.target.value
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                3.2 Number of hours each parent cares for the child each day
                <div style={styles.subGroupHeader}>
                  เวลาของพ่อและแม่ในการดูแลนักเรียนต่อวัน
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={3} style={{ paddingLeft: "20px" }}>
                Father
                <div style={styles.subLabel}>บิดา</div>
              </Grid>
              <Grid item xs={12} sm={6} md={9} style={{ paddingLeft: "20px" }}>
                <RadioGroup
                  row
                  value={form.father_care_hour || ""}
                  onChange={(e) =>
                    // setForm({ ...form, father_care_hour: e.target.value })
                    changeFieldValue("father_care_hour", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="1-3 Hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>1-3 Hours</div>
                        <div style={styles.subLabel}>1-3 ชั่วโมง</div>
                      </div>
                    }
                    style={{ marginRight: "60px" }}
                  />
                  <FormControlLabel
                    value="4-6 Hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>4-6 Hours</div>
                        <div style={styles.subLabel}>4-6 ชั่วโมง</div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    value="More than 6 Hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>More than 6 Hours</div>
                        <div style={styles.subLabel}>มากกว่า 6 ชั่วโมง</div>
                      </div>
                    }
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{ paddingLeft: "20px" }}>
                Mother
                <div style={styles.subLabel}>มารดา</div>
              </Grid>
              <Grid item xs={12} sm={6} md={9} style={{ paddingLeft: "20px" }}>
                <RadioGroup
                  row
                  value={form.mother_care_hour || ""}
                  onChange={(e) =>
                    // setForm({ ...form, mother_care_hour: e.target.value })

                    changeFieldValue("mother_care_hour", e.target.value)
                  }
                >
                  <FormControlLabel
                    value="1-3 Hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>1-3 Hours</div>
                        <div style={styles.subLabel}>1-3 ชั่วโมง</div>
                      </div>
                    }
                    style={{ marginRight: "60px" }}
                  />
                  <FormControlLabel
                    value="4-6 Hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>4-6 Hours</div>
                        <div style={styles.subLabel}>4-6 ชั่วโมง</div>
                      </div>
                    }
                  />
                  <FormControlLabel
                    value="More than 6 Hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>More than 6 Hours</div>
                        <div style={styles.subLabel}>มากกว่า 6 ชั่วโมง</div>
                      </div>
                    }
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                3.3 Activities that parents participate in with their children
                <div style={styles.subGroupHeader}>
                  กิจกรรมที่ผู้ปกครองทำร่วมกับนักเรียนต่อวัน
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={3} style={{ paddingLeft: "20px" }}>
                Play Time
                <div style={styles.subLabel}>เล่น</div>
              </Grid>
              <Grid item xs={12} sm={6} md={9} style={{ paddingLeft: "20px" }}>
                <RadioGroup
                  row
                  value={form.curator_play_time || ""}
                  onChange={(e) => {
                    changeFieldValue("curator_play_time", e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="less than 2 hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>less than 2 hours</div>
                        <div style={styles.subLabel}>ต่ำกว่า 2 ชั่วโมง</div>
                      </div>
                    }
                    style={{ marginRight: "60px" }}
                  />
                  <FormControlLabel
                    value="more than 2 hours"
                    control={<Radio color="primary" />}
                    label={
                      <div>
                        <div>more than 2 hours</div>
                        <div style={styles.subLabel}>มากกว่า 2 ชั่วโมง</div>
                      </div>
                    }
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={3} style={{ paddingLeft: "20px" }}>
                Read/Telling the stories
                <div style={styles.subLabel}>อ่าน/เล่านิทาน</div>
              </Grid>
              <Grid item xs={12} sm={6} md={9} style={{ paddingLeft: "20px" }}>
                <RadioGroup
                  onChange={(e) =>
                    // setForm({ ...form, telling_story: e.target.value })
                    changeFieldValue("telling_story", e.target.value)
                  }
                  value={form.telling_story || ""}
                >
                  <RadioInput
                    label_th="ไม่สม่ำเสมอ"
                    label_en="Irregular"
                    value="Irregular"
                  />
                  <RadioInput
                    label_th="สม่ำเสมอ (น้อยกว่า 30 นาที)"
                    label_en="regularly (less than 30 minute)"
                    value="less than 30 minute"
                  />
                  <RadioInput
                    label_th="สม่ำเสมอ (มากกว่า 30 นาที)"
                    label_en="regularly (more than 30 minute)"
                    value="more than 30 minute"
                  />
                </RadioGroup>
              </Grid>

              <Grid item xs={12} sm={6} md={3} style={{ paddingLeft: "20px" }}>
                Promotes housework
                <div style={styles.subLabel}>ส่งเสริมการช่วยงานบ้าน</div>
              </Grid>
              <Grid item xs={12} sm={6} md={9} style={{ paddingLeft: "20px" }}>
                <RadioGroup
                  row
                  onChange={(e) =>
                    changeFieldValue("housework", e.target.value)
                  }
                  value={form.housework || ""}
                >
                  <RadioInput
                    label_th="สม่ำเสมอ"
                    label_en="Regularly"
                    value="Regularly"
                  />
                  <RadioInput
                    label_th="ไม่สม่ำเสมอ"
                    label_en="Not Regularly"
                    value="Not Regularly"
                  />
                </RadioGroup>
              </Grid>

              {[
                {
                  title_en: "3.4	How does the student eat?",
                  title_th: "นักเรียนทานข้าวอย่างไร",
                  id: "parenting_eating",
                  row: true,
                  choices: [
                    {
                      label_th: "ทานเอง",
                      label_en: "By himself/herself",
                      value: "By himself/herself",
                    },
                    {
                      label_th: "ต้องป้อน",
                      label_en: "Fed by an adult",
                      value: "Fed by an adult",
                    },
                    {
                      label_th: "แล้วแต่อารมณ์",
                      label_en: "Depends on the child’s mood",
                      value: "Depends on the child’s mood",
                    },
                  ],
                  value: form.parenting_eating || "",
                },
                {
                  id: "parenting_sleeping",
                  title_en:
                    "3.5	How often does the student wake up/go to bed on time?",
                  title_th: "นักเรียนเข้านอน/ตื่นนอน ตรงเวลาหรือไม่",
                  row: true,
                  value: form.parenting_sleeping || "",
                  choices: [
                    {
                      label_th: "ตรงเวลาทุกวัน",
                      label_en: "On time every day",
                      value: "On time every day",
                    },
                    {
                      label_th: "ไม่ตรงเวลา",
                      label_en: "Not on time",
                      value: "Not on time",
                    },
                  ],
                },
                {
                  id: "parenting_media_using",
                  title_en:
                    "3.6	Is the student using social media or technology (Mobile Phone/Tablet/Television)?",
                  title_th:
                    "นักเรียนใช้สื่อ/เทคโนโลยีหรือไม่ (มือถือ/แท็บเล็ต/ทีวี)",
                  row: true,
                  value: form.parenting_media_using || "",
                  choices: [
                    {
                      label_th: "ใช่",
                      label_en: "YES",
                      value: "YES",
                    },
                    {
                      label_th: "ไม่ใช่",
                      label_en: "NO",
                      value: "NO",
                    },
                  ],
                },
                {
                  id: "parenting_using_media_hour",
                  title_en:
                    "3.7	If yes how long is the student using social media per day?",
                  title_th:
                    "หากนักเรียนใช้สื่อ/ใช้เทคโนโลยีระยะเวลาที่ใช้ใน 1 วัน",
                  value: form.parenting_using_media_hour || "",
                  choices: [
                    {
                      label_th: "น้อยกว่า 1 ชั่วโมง",
                      label_en: "Less than 1 hour/day",
                      value: "Less than 1 hour/day",
                    },
                    {
                      label_th: "2 ชั่วโมง",
                      label_en: "2 hours/day",
                      value: "2 hours/day",
                    },
                    {
                      label_th: "มากกว่า 2 ชั่วโมง",
                      label_en: "More than 2 hours/day",
                      value: "More than 2 hours/day",
                    },
                  ],
                },
                {
                  id: "parenting_spoil",
                  title_en: "3.8	How often do you spoil your child?",
                  title_th: "ท่านเลี้ยงลูกตามใจหรือไม่",
                  value: form.parenting_spoil || "",
                  choices: [
                    { label_th: "ตามใจ", label_en: "Often", value: "Often" },
                    {
                      label_th: "ตามใจบางครั้ง",
                      label_en: "Sometimes",
                      value: "Sometimes",
                    },
                    { label_th: "ไม่ตามใจ", label_en: "Never", value: "Never" },
                  ],
                },
                {
                  id: "parenting_offended_reaction",
                  title_en:
                    "3.9	When the student is being offended, what is his/her reaction?",
                  title_th: "เมื่อนักเรียนถูกขัดใจ มีพฤติกรรมอย่างไร",
                  value: form.parenting_offended_reaction || "",
                  choices: [
                    {
                      label_th: "เข้าใจข้อตกลง",
                      label_en: "Understand the situation",
                      value: "Understand the situation",
                    },
                    {
                      label_th: "กรีดร้อง ทิ้งตัว",
                      label_en: "Scream, drop his/her body",
                      value: "Scream, drop his/her body",
                    },
                    { label_th: "ร้องไห้งอแง", label_en: "Cry", value: "Cry" },
                    {
                      label_th: "ทำร้ายตัวเอง/ผู้อื่น",
                      label_en: "Self-harms or harms others",
                      value: "Self-harms or harms others",
                    },
                  ],
                },
                {
                  id: "parenting_offended_reaction_manage",
                  title_en:
                    "3.10	How do you react to the behavior from inquiry no. 3.9?",
                  title_th:
                    "ผู้ปกครองรับมือกับพฤติกรรมของนักเรียนตามข้อ 3.9 อย่างไร",
                  value: form.parenting_offended_reaction_manage || "",
                  choices: [
                    {
                      label_th: "เฉย ๆ",
                      label_en: "Inaction",
                      value: "Inaction",
                    },
                    {
                      label_th: "เบี่ยงเบนความสนใจนักเรียน",
                      label_en: "Divert",
                      value: "Divert",
                    },
                    {
                      label_th: "ไม่รู้จะทำอย่างไร",
                      label_en: "Unsure",
                      value: "Unsure",
                    },
                    {
                      label_th: "พยายามอธิบายเหตุผลให้นักเรียนเข้าใจ",
                      label_en:
                        "Try to give the student understanding/explanation",
                      value:
                        "Try to give the student understanding/explanation",
                    },
                  ],
                },
              ].map((question, key) => (
                <ChioceQuestion
                  key={key}
                  onSelected={(value) =>
                    // setForm({ ...form, [question.id]: value })

                    changeFieldValue(question.id, value)
                  }
                  title_en={question.title_en}
                  title_th={question.title_th}
                  row={true}
                  value={question.value}
                  choices={question.choices}
                />
              ))}
            </>

            {/* No 4 */}
            <>
              <Grid item xs={12} style={styles.groupHead}>
                4. School Policies
                <div style={styles.subGroupHeader}>นโยบายโรงเรียน</div>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "20px" }}>
                4.1 Give a score to rank the importance of each category (the
                three categories should total 100 points)
                <div style={styles.subLabel}>
                  ผู้ปกครองกรุณาให้น้ำหนักความสุข ความคิด ความรู้
                  (ให้แต่ละหัวข้อกี่คะแนนรวมกัน 3 รายการ คะแนนเต็ม 100 คะแนน){" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                <TextField
                  type="number"
                  label="Happiness"
                  placeholder="ความสุข"
                  variant="outlined"
                  size="small"
                  id="score_happyess"
                  onChange={(e) => changeForm(e)}
                  value={form.score_happyess || ""}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                <TextField
                  type="number"
                  label="Thinking"
                  placeholder="ความคิด"
                  variant="outlined"
                  size="small"
                  id="score_thinking"
                  onChange={(e) => changeForm(e)}
                  value={form.score_thinking || ""}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
                <TextField
                  type="number"
                  label="Knowledge"
                  placeholder="ความรู้"
                  variant="outlined"
                  size="small"
                  id="score_knowledge"
                  onChange={(e) => changeForm(e)}
                  value={form.score_knowledge || ""}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {[
                {
                  id: "accept_read",
                  title_en:
                    "4.2	Parents are willing to set aside approximately 15 minutes per day for their child to read (children should be able to read a book of their choice)",
                  title_th:
                    "ผู้ปกครองยินดีอ่านหนังสือที่นักเรียนชอบ  (ไม่ใช่ผู้ปกครองอยากให้อ่าน) ให้บุตรหลานวันละ 15 นาที",
                  row: true,
                  value: form.accept_read || "",
                  choices: [
                    {
                      label_th: "Agree",
                      label_en: "ยินดี",
                      value: "Agree",
                    },
                    {
                      label_th: "Disagree",
                      label_en: "ไม่ยินดี",
                      value: "Disagree",
                    },
                  ],
                },
                {
                  id: "accept_no_junkfood",
                  title_en:
                    "4.3	Parents are willing to restrict their child from eating fast food, soft drinks, snacks and sweets, etc.",
                  title_th:
                    "ผู้ปกครองยินดีหลีกเลี่ยงการนำพาบุตรหลานทานอาหารฟาสฟู้ด  น้ำอัดลม  ขนมกรุบกรอบ ขนมหวาน เป็นต้น",
                  row: true,
                  value: form.accept_no_junkfood || "",
                  choices: [
                    {
                      label_th: "Agree",
                      label_en: "ยินดี",
                      value: "Agree",
                    },
                    {
                      label_th: "Disagree",
                      label_en: "ไม่ยินดี",
                      value: "Disagree",
                    },
                  ],
                },
                {
                  id: "accept_no_bad_activity",
                  title_en:
                    "4.4	Parents are willing to refrain from doing things that are not beneficial to the well-being of the household. For example, watching soap operas, drinking or having a negative attitude.",
                  title_th:
                    "ผู้ปกครองยินดี งดเว้นสิ่งที่ไม่เป็นประโยชน์ในบ้าน เช่น ละครน้ำเน่า  การดื่มสุรา  หรือการมี ทัศนคติเชิงลบ เป็นต้น",
                  row: true,
                  value: form.accept_no_bad_activity || "",
                  choices: [
                    {
                      label_th: "Agree",
                      label_en: "ยินดี",
                      value: "Agree",
                    },
                    {
                      label_th: "Disagree",
                      label_en: "ไม่ยินดี",
                      value: "Disagree",
                    },
                  ],
                },
                {
                  id: "accept_meeting",
                  title_en:
                    "4.5 Parents are willing to cooperate with having family meetings regularly, including family counseling.",
                  title_th:
                    "ผู้ปกครองยินดีให้ความร่วมมือกับการเข้าร่วมประชุมทั้งการประชุมรวมและปรึกษารายครอบครัว",
                  value: form.accept_meeting || "",
                  choices: [
                    { label_th: "ยินดี", label_en: "Pleased" },
                    { label_th: "ไม่ยินดี", label_en: "Not Pleased" },
                  ],
                },
                {
                  id: "accept_training",
                  title_en:
                    "4.6 Parents are willing to participate in training about child care courses for both physical and mental health by studying the Tonkla School’s policies.",
                  title_th:
                    "ผู้ปกครองยินดีเข้าร่วมลงทะเบียนเรียน/อบรมเรื่องการดูแลบุตรหลานทั้งทางสุขภาพกาย ใจและศึกษาในแนวทางของโรงเรียน",
                  value: form.accept_training || "",
                  choices: [
                    { label_th: "ยินดี", label_en: "Pleased" },
                    { label_th: "ไม่ยินดี", label_en: "Not Pleased" },
                  ],
                },
                {
                  id: "accept_non_intensive",
                  title_en:
                    "4.7 Parents agree to provide non-intensive and natural education for their children based on their age.",
                  title_th:
                    "ผู้ปกครองเห็นด้วยกับการจัดการศึกษาที่ไม่เร่งรัดและจัดการศึกษาตามธรรมชาติของช่วงวัย",
                  value: form.accept_non_intensive || "",
                  choices: [
                    { label_th: "ยินดี", label_en: "Agree" },
                    { label_th: "ไม่ยินดี", label_en: "Disagree" },
                  ],
                },
                {
                  id: "accept_living_learning",
                  title_en:
                    "4.8 Parents agree that the student comes to school for learning how to live with others, be patient, show appreciation, and manage emotions, etc.",
                  title_th:
                    "ผู้ปกครองยอมรับที่จะให้นักเรียนมาโรงเรียนเพื่อเรียนรู้การใช้ชีวิตร่วมกับผู้อื่น อดทน รอคอย ดีใจ เสียใจ สมหวัง ผิดหวัง เป็นต้น",
                  value: form.accept_living_learning || "",
                  choices: [
                    { label_th: "ยินดี", label_en: "Agree" },
                    { label_th: "ไม่ยินดี", label_en: "Disagree" },
                  ],
                },
                {
                  id: "accept_school_management",
                  title_en:
                    "4.9 Parents will trust school management and will not interfere with the work of the teachers and the school.",
                  title_th:
                    "ผู้ปกครองมีความเชื่อมั่นในการจัดการศึกษาของโรงเรียน (Trust) และยินดีให้ความร่วมมือโดยไม่เข้าไปแทรกแซงการทำงานของครูและโรงเรียน",
                  value: form.accept_school_management || "",
                  choices: [
                    { label_th: "ยินดี", label_en: "Agree" },
                    { label_th: "ไม่ยินดี", label_en: "Disagree" },
                  ],
                },
                {
                  id: "accept_conflict_management",
                  title_en:
                    "4.10 If there is a conflict between students, parents will give students the opportunity to learn and manage the problem by his/herself",
                  title_th:
                    "หากเกิดความขัดแย้งระหว่างนักเรียน ผู้ปกครองจะเปิดโอกาสให้นักเรียนได้เรียนรู้และจัดการปัญหาต่าง ๆ ด้วยตนเอง",
                  value: form.accept_conflict_management || "",
                  choices: [
                    { label_th: "ยินดี", label_en: "Agree" },
                    { label_th: "ไม่ยินดี", label_en: "Disagree" },
                  ],
                },
              ].map((question, key) => (
                <ChioceQuestion
                  key={key}
                  onSelected={(value) =>
                    // setForm({ ...form, [question.id]: value })
                    changeFieldValue(question.id, value)
                  }
                  title_en={question.title_en}
                  title_th={question.title_th}
                  value={question.value}
                  row={true}
                  choices={question.choices}
                />
              ))}
            </>

            {/* No 5 */}
            <>
              <Grid item xs={12} style={styles.groupHead}>
                5. Planning for further study
                <div style={styles.subGroupHeader}>การวางแผนการศึกษาต่อ</div>
              </Grid>
              {[
                {
                  id: "planning_study",
                  title_en: "5.1 Willing to study at Tonkla until",
                  title_th: "ประสงค์เรียนโรงเรียนต้นกล้าถึงระดับ",
                  value: form.planning_study || "",
                  choices: [
                    {
                      label_th: "ระดับชั้นอนุบาล 2",
                      label_en: "Through Kindergarten level 2",
                    },
                    {
                      label_th: "ระดับชั้นอนุบาล 3",
                      label_en: "Through Kindergarten Level 3",
                    },
                    {
                      label_th: "ระดับชั้นประถมศึกษาปีที่ 6",
                      label_en: "Through Grade 6",
                    },
                  ],
                },
                {
                  id: "planning_future_study",
                  title_en: "5.2 Type of schools for future study",
                  title_th: "โรงเรียนที่มีความประสงค์จะไปศึกษาต่อ",
                  value: form.planning_future_study || "",
                  choices: [
                    { label_th: "โปรแกรมปกติ", label_en: "Normal Program " },
                    {
                      label_th: "International School/ต่างประเทศ",
                      label_en: "International School/Foreign Country",
                    },
                    {
                      label_th: "โปรแกรมภาษาอังกฤษ",
                      label_en: "English Program",
                    },
                  ],
                },
              ].map((question, key) => (
                <ChioceQuestion
                  key={key}
                  onSelected={(value) =>
                    // setForm({ ...form, [question.id]: value })
                    changeFieldValue(question.id, value)
                  }
                  title_en={question.title_en}
                  title_th={question.title_th}
                  row={true}
                  value={question.value}
                  choices={question.choices}
                />
              ))}
            </>
          </Grid>
        </div>
      )}
    </>
  );
}
