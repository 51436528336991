import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { GridInputItem, GridSelectItem } from "../components/InputForm";
import FormInterview from "../components/FormInterview";
// import { InterviewForm } from "../forms/Interview";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Icon from "@mui/material/Icon";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  student_prefixs,
  jobs,
  consider_chooses,
  individuals_responsible,
  parents_married_status,
} from "../const";

import axios from "axios";
import Conf from "../Conf";
import { getOTP, getPermission, getStampTime } from "../js/main";
// import DateFnsUtils from "@date-io/date-fns";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";

// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment/moment";

function getForm(token) {
  return new Promise(async (resolve) => {
    let otp = await getOTP();
    let register = await axios.get(
      Conf.endpoint.tonkla + "/ca/register/application/" + token,
      { headers: { Authorization: otp } }
    );
    register = register.data;
    let relation = register.relation;
    if (relation === "บิดา") {
      register.father_prefix =
        register.father_prefix || register.contact_prefix;
      register.father_first_name =
        register.father_first_name || register.contact_first_name;
      register.father_last_name =
        register.father_last_name || register.contact_last_name;
      register.father_phone = register.father_phone || register.phone;
      register.father_email = register.father_email || register.email;
      register.father_job = register.father_job || register.job;
      register.father_workplace =
        register.father_workplace || register.workplace;
    } else if (relation === "มารดา") {
      register.mother_prefix =
        register.mother_prefix || register.contact_prefix;
      register.mother_first_name =
        register.mother_first_name || register.contact_first_name;
      register.mother_last_name =
        register.mother_last_name || register.contact_last_name;
      register.mother_phone = register.mother_phone || register.phone;
      register.mother_email = register.mother_email || register.email;
      register.mother_job = register.mother_job || register.job;
      register.mother_workplace =
        register.mother_workplace || register.workplace;
    }
    resolve(register);
  });
}

export default function Application(props) {
  const params = useParams();
  const [state, setState] = useState({});
  const [form, setForm] = useState({});
  const [formInterview, setFormInterview] = useState();

  useEffect(() => {
    function getParents(register) {
      return new Promise(async (resolve) => {
        if (register.relation === "บิดา") {
          register.father_prefix = register.contact_prefix;
          register.father_first_name = register.contact_first_name;
          register.father_last_name = register.contact_last_name;
          register.father_phone = register.phone;
          register.father_email = register.email;
          register.father_job = register.job;
          register.father_workplace = register.workplace;
        } else if (register.relation === "มารดา") {
          register.mother_prefix = register.contact_prefix;
          register.mother_first_name = register.contact_first_name;
          register.mother_last_name = register.contact_last_name;
          register.mother_phone = register.phone;
          register.mother_email = register.email;
          register.mother_job = register.job;
          register.mother_workplace = register.workplace;
        }
        resolve(register);
      });
    }
    async function checkAdmin() {
      let register_id = params.register_id;
      let profile = await getPermission();
      if (profile.usn) {
        const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
        let result = await axios.post(
          Conf.endpoint.tonkla + "/ca/query",
          { collection: "registers", match: { _id: register_id }, limit: 1 },
          { headers: { Authorization: cookie.access_token } }
        );
        if (result.data.count && result.data.count > 0) {
          result = result.data.result[0];
          result = await getParents(result);
          setForm(result);
          getInterviewForm(register_id, result);
          setState({
            login: true,
            show: true,
            activeStep: 0,
          });
        }
      }
    }
    async function checkToken() {
      setState({ show: true, activeStep: 0 });
    }
    async function getInterviewForm(register_id, form) {
      const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
      let interview = await axios.post(
        Conf.endpoint.tonkla + "/ca/query",
        {
          collection: "interviews",
          match: { register_id: register_id },
          limit: 1,
        },
        { headers: { Authorization: cookie.access_token } }
      );
      if (interview.data.result[0]) {
        interview = interview.data.result[0];
        let items = [];
        for (let item of consider_chooses) {
          if (interview.consider_chooses.indexOf(item.value) > -1)
            item.checked = true;
          else item.checked = false;
          items.push(item);
        }
        interview.consider_chooses = items;
        items = [];
        for (let item of individuals_responsible) {
          if (interview.individuals_responsible.indexOf(item.value) > -1)
            item.checked = true;
          else item.checked = false;
          items.push(item);
        }
        interview.individuals_responsible = items;

        // items = [];
        // for (let item of parents_married_status) {
        //   if (interview.parents_married_status.indexOf(item.value) > -1)
        //     item.checked = true;
        //   else item.checked = false;
        //   items.push(item);
        // }
        // interview.parents_married_status = items;

        interview.old_school = form.old_school;
        setFormInterview(interview);
      } else {
        setFormInterview({
          consider_chooses: await consider_chooses.map((item) => {
            item.checked = false;
            return item;
          }),
          individuals_responsible: await individuals_responsible.map((item) => {
            item.checked = false;
            return item;
          }),
          parents_married_status: await parents_married_status.map((item) => {
            item.checked = false;
            return item;
          }),
          old_school: form.old_school || "",
        });
      }
    }

    async function goForm() {
      let Form = await getForm(params.token);

      let interview = {
        consider_chooses: await consider_chooses.map((item) => {
          item.checked = false;
          return item;
        }),
        individuals_responsible: await individuals_responsible.map((item) => {
          item.checked = false;
          return item;
        }),
        parents_married_status: await parents_married_status.map((item) => {
          item.checked = false;
          return item;
        }),
      };
      setForm(Form);
      setFormInterview(interview);
      setState({
        // ...state,
        login: true,
        activeStep: 0,
        show: true,
        // usn: form.usn,
        // pwd: form.pwd,
      });
    }

    (async () => {
      if (params.token) {
        await checkToken();

        await goForm();
      } else if (params.register_id) {
        checkAdmin();
      }
    })();
  }, [params]);
  /*
  useEffect(() => {
    async function getFormInterview() {
      setFormInterview({
        consider_chooses: await consider_chooses.map((item) => {
          item.checked = false;
          return item;
        }),
        individuals_responsible: await individuals_responsible.map((item) => {
          item.checked = false;
          return item;
        }),
      });
    }
    if (!formInterview) getFormInterview();
  }, [formInterview]);
  */

  async function nextStep() {
    let requires = [
      "current_area",
      "birth_date",
      "father_email",
      "father_first_name",
      "father_job",
      "father_last_name",
      "father_phone",
      "father_prefix",
      "father_workplace",
      "mother_email",
      "mother_first_name",
      "mother_job",
      "mother_last_name",
      "mother_phone",
      "mother_prefix",
      "mother_workplace",
      "first_name",
      "last_name",
      "current_house_number",
      "current_moo",
      "nickname",
      "prefix",
      "current_village",
    ];
    if (form.register_level && form.register_level !== "-") {
      let register_level = JSON.parse(form.register_level);
      form.level = register_level.name;
      form.grade_year = register_level.year;
    }
    let chk = true;
    for (let require of requires) {
      let Class = document.getElementById(require).getAttribute("class");
      Class = Class.replaceAll("error-text-field", "");
      if (!form[require] || form[require] === "") {
        Class += " error-text-field";
        chk = false;
      }
      document.getElementById(require).setAttribute("class", Class);
    }
    if (chk === false) alert("Please fill out the information completely");
    else {
      setFormInterview({
        ...formInterview,
        father_job: form.father_job,
        mother_job: form.mother_job,
      });
      setState({ ...state, activeStep: 1 });
    }
  }

  function convertInterviewForm() {
    return new Promise(async (resolve) => {
      let send = JSON.parse(JSON.stringify(formInterview));
      send.consider_chooses = send.consider_chooses
        .filter((j) => j.checked === true)
        .map((item) => item.value);

      send.individuals_responsible = send.individuals_responsible
        .filter((j) => j.checked === true)
        .map((item) => item.value);

      if (send.level === "เตรียมอนุบาล") delete send.grade_year;
      resolve(send);
    });
  }
  async function save() {
    let send = JSON.parse(JSON.stringify(form));
    send.interview = await convertInterviewForm();
    if (typeof send.interview.parents_married_status === "object")
      send.interview.parents_married_status = "";
    if (params.register_id) {
      const cookie = JSON.parse(localStorage.getItem(Conf.cookie_name));
      let result = await axios.put(
        Conf.endpoint.tonkla + "/ca/register",
        send,
        { headers: { Authorization: cookie.access_token } }
      );
      if (result.data.error) alert(result.data.error.message);
      else {
        alert("Register Completed");
      }
    } else if (
      window.confirm(
        "Confirm to send form.\nYou will no longer be able to edit the form."
      )
    ) {
      let otp = await getOTP();
      send.usn_register = state.usn;
      send.pwd_register = state.pwd;
      send.register_form_token = params.token;
      let result = await axios.put(
        Conf.endpoint.tonkla + "/ca/register",
        send,
        { headers: { Authorization: otp } }
      );
      if (result.data.error) alert(result.data.error.message);
      else {
        alert("Register Completed");
        window.location =
          "https://tca.tonkla.ac.th/form/application/" + params.token;
      }
    }
  }
  return (
    <>
      {state.show === true && (
        <div style={{ padding: "10px 5px" }}>
          <Container maxWidth="md">
            <div style={{ textAlign: "right" }}>
              <img
                src={process.env.PUBLIC_URL + "/img/form_logo_en.png"}
                style={{ width: "180px" }}
                alt=""
              />
            </div>
            {!state.login || state.login !== true ? (
              <div
                style={{ borderTop: "2px solid #1b5e20", paddingTop: "20px" }}
              >
                {/* <LoginArea onSubmit={(form) => signIn(form)} /> */}
              </div>
            ) : (
              <>
                <div
                  style={{
                    margin: "0px auto 10px",
                    borderTop: "2px solid #1b5e20",
                    padding: "15px 5px",
                  }}
                >
                  <Stepper
                    activeStep={state.activeStep}
                    alternativeLabel
                    style={{ margin: "0px" }}
                  >
                    {["Application Form", "Interview Questionnaire"].map(
                      (label) => (
                        <Step key={label}>
                          <StepLabel>
                            <div>{label}</div>
                          </StepLabel>
                        </Step>
                      )
                    )}
                  </Stepper>
                  {
                    {
                      0: (
                        <>
                          <h2
                            style={{
                              // color: "#1b5e20",
                              fontWeight: 400,
                              margin: "10px auto 30px",
                              textAlign: "center",
                              fontSize: "1.5rem",
                            }}
                          >
                            Application Form (ใบสมัครเรียน)
                          </h2>
                          <FormArea
                            token={params.token}
                            form={form}
                            onChangeInput={(id, value) =>
                              setForm({ ...form, [id]: value })
                            }
                            // onSubmit={(form) => save(form)}
                          />
                          <div
                            style={{
                              margin: "20px auto 20px",
                              textAlign: "right",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={nextStep}
                              endIcon={<Icon>navigate_next</Icon>}
                            >
                              Next Step
                            </Button>
                          </div>
                        </>
                      ),
                      1: (
                        <>
                          <FormInterview
                            form={formInterview}
                            onUpdateGroupFields={(fields) =>
                              setFormInterview({
                                ...formInterview,
                                ...fields,
                              })
                            }
                            onUpdateField={(field, value) => {
                              setFormInterview({
                                ...formInterview,
                                [field]: value,
                              });
                            }}
                          />
                          <Grid container style={{ marginTop: "30px" }}>
                            <Grid item xs={6}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  setState({ ...state, activeStep: 0 })
                                }
                                startIcon={<Icon>arrow_back_ios</Icon>}
                              >
                                Previous Step
                              </Button>
                            </Grid>
                            <Grid item xs={6} align="right">
                              <Button
                                variant="contained"
                                color="primary"
                                className="bg_green"
                                onClick={() => save()}
                                endIcon={<Icon>send</Icon>}
                              >
                                Send Form
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ),
                    }[state.activeStep]
                  }
                </div>
              </>
            )}
          </Container>
        </div>
      )}
    </>
  );
}
/*
function LoginArea(props) {
  const [form, setForm] = useState({ usn: "", pwd: "" });
  function sendForm() {
    if (form.pwd && form.pwd !== "" && form.usn && form.usn !== "") {
      props.onSubmit(form);
    } else {
      alert("Please fill out the information completely");
    }
  }
  return (
    <Container
      maxWidth="xs"
      style={{ padding: "10px 10px", textAlign: "center" }}
    >
      <h2
        style={{
          margin: "0px auto 0px",
        }}
      >
        AUTHENTICATION
      </h2>
      <div style={{ color: "#666" }}>กรุณาลงชื่อเข้ากรอกแบบฟอร์ม</div>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="usn"
            label="Username"
            placeholder="ชื่อผู้ใช้งาน"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            value={form.usn}
            style={{ maxWidth: "300px", minWidth: "200px" }}
            onChange={(e) => setForm({ ...form, usn: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="pwd"
            size="small"
            label="Password"
            type="password"
            placeholder="รหัสผ่าน"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={form.pwd}
            style={{ maxWidth: "300px", minWidth: "200px" }}
            onChange={(e) => setForm({ ...form, pwd: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className="bg_green"
            startIcon={<Icon>key</Icon>}
            style={{ maxWidth: "300px", minWidth: "220px" }}
            onClick={() => sendForm()}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
*/
export function FormArea(props) {
  const [form, setForm] = useState();
  const [levels, setLevels] = useState();

  const [focusYear, setFocusYear] = useState([]);
  useEffect(() => {
    async function getGroups() {
      let groups = await axios.get(Conf.endpoint.tonkla + "/reg/group");
      let levels = [];
      for (let group of groups.data) {
        let i = levels.findIndex(
          (j) => j.name === group.level && j.year === group.level_year
        );
        if (i < 0) levels.push({ name: group.level, year: group.level_year });
      }
      setLevels(levels);
    }
    async function getForm() {
      if (!props.form.register_level) {
        props.form.register_level = JSON.stringify({
          name: props.form.level,
          year: props.form.grade_year,
        });
      }
      setForm(props.form);
      let dt = await getStampTime();
      let arrYear = [];
      for (let i = dt.detail.year - 1; i < dt.detail.year + 11; i++) {
        arrYear.push(i);
      }
      setFocusYear(arrYear);
    }
    if (props.form._id) {
      getForm();
      getGroups();
    }
  }, [props.form]);
  function changeForm(e) {
    props.onChangeInput(e.target.id, e.target.value);
    // setForm({ ...form, [e.target.id]: e.target.value });
  }
  // function sendForm() {
  //   props.onSubmit(form);
  // }
  return (
    <div>
      {form && (
        <Grid container spacing={1}>
          <HeadArea label="1. ข้อมูลนักเรียน" />
          <GridSelectItem
            size={[12, 4, 2, 2]}
            label="Prefix"
            id="prefix"
            value={form.prefix}
            changeValue={changeForm}
            options={student_prefixs}
          />
          <GridInputItem
            size={[12, 4, 4, 4]}
            label="First name"
            id="first_name"
            placeholder="ชื่อนักเรียน"
            value={form.first_name}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 4, 4, 4]}
            label="Last name"
            id="last_name"
            placeholder="นามสกุลนักเรียน"
            value={form.last_name}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 6, 2, 2]}
            label="First name"
            id="nickname"
            placeholder="ชื่อเล่น"
            value={form.nickname}
            changeValue={changeForm}
          />
          <Grid item xs={12} sm={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                label="Birth date"
                format="YYYY-MM-DD"
                value={dayjs(form.birth_date)}
                onChange={(value) =>
                  setForm({
                    ...form,
                    birth_date: moment(new Date(value)).format("YYYY-MM-DD"),
                  })
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    id: "birth_date",
                  },
                }}
                inputVariant="outlined"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={9}>
            <AgeArea dt={form.birth_date} />
          </Grid>
          <HeadArea label="2. ที่อยู่" />
          <GridInputItem
            size={[12, 4, 4, 4]}
            label="House number"
            id="current_house_number"
            placeholder="เลขที่"
            value={form.current_house_number}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 4, 4, 4]}
            label="Village"
            id="current_village"
            placeholder="หมู่บ้าน"
            value={form.current_village}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 4, 4, 4]}
            label="Moo"
            id="current_moo"
            placeholder="หมู่ที่"
            value={form.current_moo}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Sub district"
            id="current_area"
            placeholder="ตำบล"
            value={form.current_area}
            changeValue={changeForm}
          />
          {/* <GridInputItem
            size={[12, 6, 6, 6]}
            label="Zip code"
            id="current_zip_code"
            placeholder="รหัสไปรษณีย์"
            value={form.zip_code}
            changeValue={changeForm}
          /> */}
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <TextField
              fullWidth
              id="current_zip_code"
              placeholder="รหัสไปรษณีย์"
              label="Zip code"
              size="small"
              variant="outlined"
              value={form.current_zip_code || ""}
              onChange={(e) =>
                setForm({ ...form, current_zip_code: e.target.value })
              }
              InputLabelProps={{ shrink: true }}
              // onBlur={(e) => console.log(e.target.value)}
            />
          </Grid>
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="District"
            id="current_district"
            placeholder="อำเภอ"
            value={form.current_district}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Province"
            id="current_province"
            placeholder="จังหวัด"
            value={form.current_province}
            changeValue={changeForm}
          />
          <HeadArea label="3. ข้อมูลบิดา" />
          <GridSelectItem
            size={[12, 2, 2, 2]}
            label="Prefix"
            id="father_prefix"
            value={form.father_prefix}
            changeValue={changeForm}
            options={[{ value: "นาย", label: "นาย (Mr.)" }]}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="First name"
            id="father_first_name"
            placeholder="ชื่อบิดา"
            value={form.father_first_name}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Last name"
            id="father_last_name"
            placeholder="นามสกุลบิดา"
            value={form.father_last_name}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Mobile No."
            id="father_phone"
            placeholder="เบอร์โทรศัพท์"
            value={form.father_phone}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="e-Mail"
            id="father_email"
            placeholder="อีเมล"
            value={form.father_email}
            changeValue={changeForm}
          />
          <GridSelectItem
            size={[12, 6, 3, 3]}
            label="Job"
            id="father_job"
            value={form.father_job}
            changeValue={changeForm}
            options={jobs}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Office Address"
            id="father_workplace"
            placeholder="สถานที่ทำงาน"
            value={form.father_workplace}
            changeValue={changeForm}
          />
          <HeadArea label="4. ข้อมูลมารดา" />
          <GridSelectItem
            size={[12, 2, 2, 2]}
            label="Prefix"
            id="mother_prefix"
            value={form.mother_prefix}
            changeValue={changeForm}
            options={[
              { value: "นาง", label: "นาง (Mrs.)" },
              { value: "นางสาว", label: "นางสาว (Miss)" },
            ]}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="First name"
            id="mother_first_name"
            placeholder="ชื่อมารดา"
            value={form.mother_first_name}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 5, 5, 5]}
            label="Last name"
            id="mother_last_name"
            placeholder="นามสกุลมารดา"
            value={form.mother_last_name}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Mobile No."
            id="mother_phone"
            placeholder="เบอร์โทรศัพท์"
            value={form.mother_phone}
            changeValue={changeForm}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="e-Mail"
            id="mother_email"
            placeholder="อีเมล"
            value={form.mother_email}
            changeValue={changeForm}
          />
          <GridSelectItem
            size={[12, 6, 3, 3]}
            label="Job"
            id="mother_job"
            value={form.mother_job}
            changeValue={changeForm}
            options={jobs}
          />
          <GridInputItem
            size={[12, 6, 3, 3]}
            label="Office Address"
            id="mother_workplace"
            placeholder="สถานที่ทำงาน"
            value={form.mother_workplace}
            changeValue={changeForm}
          />
          <HeadArea label="5. การสมัครเรียน" />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingBottom: "10px" }}
          >
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel shrink>Application for</InputLabel>
              <Select
                native
                label="Application for"
                id="register_level"
                value={form.register_level}
                onChange={changeForm}
              >
                <option value="-"></option>
                {levels &&
                  levels.map((level, key) => (
                    <option key={key} value={JSON.stringify(level)}>
                      {level.name} {level.year !== "-" && level.year}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{ paddingBottom: "10px" }}
          >
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel shrink>Study year</InputLabel>
              <Select
                native
                id="attending_year"
                label="Study year"
                value={form.attending_year || "-"}
                // onChange={(e) => {
                //   console.log("aaa");
                //   setForm({ ...form, attending_year: e.target.value });
                // }}

                onChange={changeForm}
              >
                <option value="-"></option>
                {focusYear &&
                  focusYear.map((y, key) => (
                    <option value={y} key={key}>
                      {y + 543}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            style={{ paddingBottom: "10px" }}
          >
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel shrink>Semester</InputLabel>
              <Select
                native
                id="term"
                label="Semester"
                value={form.term || "-"}
                // onChange={(e) => setForm({ ...form, term: e.target.value })}
                onChange={changeForm}
              >
                <option value="-"></option>
                <option value="1">1</option>
                <option value="2">2</option>
              </Select>
            </FormControl>
          </Grid>{" "}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{ paddingBottom: "10px" }}
          >
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel shrink>Program</InputLabel>
              <Select
                native
                id="program"
                label="Program"
                value={form.program || "-"}
                // onChange={(e) => setForm({ ...form, program: e.target.value })}

                onChange={changeForm}
              >
                <option value="-"></option>
                {[
                  // {
                  //   label: "MEP (Mini English Program)",
                  // },
                  {
                    label: "IEP (Integrated English Program)",
                  },
                  {
                    label: "IP (Inter Program)",
                  },
                ].map((choice, key) => (
                  <option value={choice.label} key={key}>
                    {choice.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* {(typeof props.showSubmit === "undefined" ||
            props.showSubmit === true) && (
            <Grid item xs={12} align="right" style={{ padding: "10px 5px" }}>
              <Button
                variant="outlined"
                color="primary"
                className="bg_green"
                endIcon={<Icon>send</Icon>}
                onClick={() => sendForm(null)}
              >
                Send form
              </Button>
            </Grid>
          )} */}
        </Grid>
      )}
    </div>
  );
}
function HeadArea(props) {
  return (
    <Grid
      item
      xs={12}
      style={{
        padding: "10px 10px",
        fontWeight: 400 /* , color: "#1b5e20"  */,
      }}
    >
      {props.label}
    </Grid>
  );
}

function AgeArea(props) {
  const [state, setState] = useState();
  useEffect(() => {
    function getDayDiff(strDate1, strDate2) {
      var theDate1 = Date.parse(strDate1) / 1000;
      var theDate2 = Date.parse(strDate2) / 1000;
      var diff = (theDate2 - theDate1) / (60 * 60 * 24);
      return diff.toFixed(0);
    }
    async function dateDiff() {
      let today = await getStampTime();
      today = today.iso_date;
      let dt = props.dt;
      let arrToday = today.split("-");
      let arrDt = dt.split("-");
      let yearDiff = arrToday[0] - arrDt[0];
      let monthDiff = arrToday[1] - arrDt[1];

      let dayDiff = getDayDiff(
        arrToday[0] + "-" + arrToday[1] + "-" + arrDt[2],
        today
      );
      if (dayDiff < 0) {
        dayDiff = getDayDiff(
          arrToday[0] + "-" + (parseInt(arrToday[1]) - 1) + "-" + arrDt[2],
          today
        );
      }
      if (
        arrToday[1] < arrDt[1] ||
        (arrToday[1] === arrDt[1] && arrToday[2] < arrDt[2])
      )
        yearDiff--;
      if (monthDiff < 0) {
        monthDiff = parseInt(arrToday[1]) + (12 - parseInt(arrDt[1]));
      } else if (monthDiff === 0 && arrToday[2] < arrDt[2]) monthDiff = "11";
      setState({ yearDiff, monthDiff, dayDiff });
    }
    dateDiff();
  }, [props.dt]);

  return (
    <div
      style={{
        textAlign: "center",
        padding: "6px 0px",
        // color: "#1b5e20",
      }}
    >
      {state && (
        <>
          <div style={{ display: "inline" }}>อายุ ณ วันที่สมัคร (Age)</div>
          <span style={{ padding: "0px 13px" }}>{state.yearDiff}</span> ขวบ
          (Years) <span style={{ padding: "0px 13px" }}>{state.monthDiff}</span>
          เดือน (Months)
          <span style={{ padding: "0px 13px" }}>{state.dayDiff}</span> วัน
          (Days)
        </>
      )}
    </div>
  );
}
