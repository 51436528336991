import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { GridInputItem } from "../components/InputForm";

export default function DialogSendMail(props) {
  const { state } = props;
  const [form, setForm] = useState({});
  useEffect(() => {
    setForm({ email: props.state.email });
  }, [props]);
  async function sendMail() {
    if (form.email && form.email !== "") {
      props.onSendMail(form.email);
    }
  }
  return (
    <>
      {state && (
        <Dialog
          maxWidth="sm"
          open={state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{state.head}</DialogTitle>
          <DialogContent style={{ width: "350px" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ color: "#555" }}
              component="div"
            >
              {state.message}
              <br />
              <br />
              <GridInputItem
                size={[12, 12, 12, 12]}
                label="e-Mail"
                placeholder="อีเมล"
                id="email"
                value={form.email || ""}
                changeValue={(e) => setForm({ ...form, email: e.target.value })}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={sendMail} color="primary">
              Send Mail
            </Button>
            <Button onClick={() => props.onClose()} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
