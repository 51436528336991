import React, { useEffect, useState } from "react";
import { getPermission } from "./js/main";
import ProfileBox from "./components/ProfileBox";
import PageNameBox from "./components/PageNameBox";

export default function Student(props) {
  const [profile, setProfile] = useState(null);
  const getProfile = async () => {
    let p = await getPermission();
    if (p) setProfile(p);
    else window.location = process.env.PUBLIC_URL + "/";
  };
  useEffect(() => {
    const renderPage = async () => {
      getProfile();
    };
    renderPage();
  }, []);
  return (
    <>
      {profile && (
        <div className="content">
          <div style={{ textAlign: "right" }}>
            <ProfileBox profile={profile} />
          </div>
          <PageNameBox page={props.page} />
        </div>
      )}
    </>
  );
}
